import { Injectable } from '@angular/core'
import { IFlowReservation, LocalStorageStepData, SelectionTypeEnum } from 'app/shared/steps/step.model'
import { LoggingService, Payload } from 'app/shared/logging.service'
import { CookieService } from 'app/shared/cookie.service'
import { LocalDateTime } from 'js-joda'
import { StorageService } from '../storage.service';

@Injectable()
export class StepService {

    private localStorageKey = 'sbw_Steps'

    constructor(private loggingService: LoggingService, private cookieService: CookieService, private storage: StorageService) { }

    public saveStepData(stepData: any, pathname: string, clearSubsequentSteps: boolean) {
        let preparedStepData = this.prepareStepData(stepData, pathname)
        let steps = this.loadStepsFromStore()
        let matchingStepIndex = steps.map((s: any) => s.sbwStepId).indexOf(pathname)
        if (matchingStepIndex === -1) {
            steps.push(preparedStepData)
        } else {
            if (clearSubsequentSteps) {
                steps = this.getStepsWithoutSubsequent(steps, matchingStepIndex)
            }
            steps[matchingStepIndex] = preparedStepData
        }
        this.storeStep(steps)
    }

    public getStep(pathName: string): any {
        return this.loadStepsFromStore().find(x => x.sbwStepId === pathName)
    }

    private isLocalDateTimeString(value: string) {
        return /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/.exec(value)
    }
    private isLocalDateTimeStringWithoutSeconds(value: string) {
        return /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})$/.exec(value)
    }

    public unwrapStepValues(step: any): any {
        let stepValues = {}

        Object.keys(step).forEach(key => {
            if (key === 'sbwStepId') { return }

            // if the string is in the format "0001-01-01T00:00:00" or "0001-01-01T00:00", convert it to LocalDateTime
            if (this.isLocalDateTimeString(step[key].value) || this.isLocalDateTimeStringWithoutSeconds(step[key].value))
                stepValues[key] = LocalDateTime.parse(step[key].value)
            else
                stepValues[key] = step[key].value
        })

        return stepValues
    }

    public combineToReservation(): IFlowReservation {
        let reservation = {}
        const steps = this.loadStepsFromStore()
        steps.forEach(step => {
            reservation = { ...reservation, ...this.unwrapStepValues(step), }
        })
        this.logSteps(JSON.stringify(reservation), SessionStorageType.Get)
        let result = reservation as IFlowReservation
        return result
    }

    private loadStepsFromStore() {
        let steps = this.storage.getItem(this.localStorageKey)
        return steps ? JSON.parse(steps) : []
    }

    private logSteps(message: any, ssType: SessionStorageType): void {
        if (!this.cookieService.getDebuggingEnabled()) { return }
        if (ssType === SessionStorageType.None) { return }
        this.loggingService.logInfo({ message: `${ssType === SessionStorageType.Get ? 'step-get |' : 'step-set |'} ${message}` })
    }

    private prepareStepData(stepData: any, pathname: string) {
        if (Object.assign) {
            return Object.assign({}, stepData, { sbwStepId: pathname })
        }
        let stepDataClone = JSON.parse(JSON.stringify(stepData))
        stepDataClone.sbwStepId = pathname
        return stepDataClone
    }

    private getStepsWithoutSubsequent(steps: any[], matchingStepIndex: number) {
        return steps.slice(0, matchingStepIndex)
    }

    private storeStep(steps: {}[]) {
        const json = JSON.stringify(steps)
        this.logSteps(json, SessionStorageType.Set)
        this.storage.setItem(this.localStorageKey, json)
    }

    public updateValueInStep(pathname: string, values: object) {
        let steps = this.loadStepsFromStore()
        let matchingStepIndex = steps.map((s: any) => s.sbwStepId).indexOf(pathname)
        if (matchingStepIndex === -1) { throw new Error(`Found no step with ${pathname}`) }
        let keys = Object.keys(values)
        let converted = keys.map(key => ({ key: key, val: new LocalStorageStepData(SelectionTypeEnum.User, values[key]) })).reduce((acc, cur) => { acc[cur.key] = JSON.parse(JSON.stringify(cur.val)); return acc }, {})
        Object.assign(steps[matchingStepIndex], converted)
        this.storeStep(steps)
    }

    public isFlowActivated(): boolean {
        let steps = this.storage.getItem(this.localStorageKey)
        let stepsParsed = JSON.parse(steps)
        return stepsParsed ? stepsParsed.some(x => x.flowActivated) : false
    }

    public clearAllSteps(): void {
        this.storage.removeItem(this.localStorageKey)
    }

}

enum SessionStorageType {
    Get,
    Set,
    None
}
