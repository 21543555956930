import { Component, Input} from '@angular/core';

@Component({
    selector: 'sbw-disclaimer',
    templateUrl: './disclaimer.component.html'
})


export class DisclaimerComponent {
    @Input() resourceKey: string;
}
