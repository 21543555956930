<div class="header">
  <h2 class="top">{{ leg.routeName }}</h2>
</div>
<div class="inline" *ngIf="showDepartureTime">
  <span class="date">{{ formattedDate }}</span>
</div>
<div class="clear-both"></div>
<div class="inline passengers">
  <span *ngFor="let category of nonEmptyCategories()">{{ category.count }} {{ passengerName(category) }}</span>
  <div class="clear-both"></div>
</div>

<div *ngFor="let group of leg.priceSpecificationGroups" class="block">
  <div class="price-items data">
    <span *ngFor="let priceItem of group.priceSpecifications" class="price-item">
      <span
        *ngIf="group.type == priceSpecificationType.Fares"
        class="label"
        [attr.data-quote-type-e2e]="getQuoteType(group.type)"
        [attr.data-quote-model]="leg.leg == 0 ? 'Outbound.Product.Label' : 'Return.Product.Label'"
      >
        <span class="count">{{ itemCount(priceItem) }}</span
        >{{ priceItem.label }}
      </span>

      <span
        *ngIf="group.type != priceSpecificationType.Fares && priceItem.label"
        class="label"
        [attr.data-quote-type-e2e]="getQuoteType(group.type)"
        ><span class="count">{{ itemCount(priceItem) }}</span
        >{{ priceItem.label }}
        <span *ngIf="group.type === priceSpecificationType.FuelFees && priceItem.label && emissionDateValid(leg.routeDateTime)"
          ><sbw-information-popup
            [heading]="'EMISSION_SURCHARGE_POP_UP_TITLE' | resource : 'Quote'"
            [text]="'EMISSION_SURCHARGE_POP_UP_DESCRIPTION' | resource : 'Quote'"
          ></sbw-information-popup
        ></span>
      </span>
      <span *ngIf="priceItem.price" class="price">
        {{ priceItem.currencyCode | resource }}<span>{{ multiplyPrice(priceItem) | resourceformat : 'CURRENCY_FORMAT' }}</span>
      </span>
    </span>
  </div>
</div>
