import { Component, Input, Output, EventEmitter, Inject, OnDestroy, HostBinding, ViewChild, ComponentFactoryResolver, Type, ViewContainerRef, ComponentRef, ElementRef, ContentChild, AfterViewInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { InfoBlockBehaviour } from '../../contentful/info-block-behaviour';

@Component({
    selector: 'sbw-popup',
    styleUrls: ['./popup.component.css'],
    templateUrl: './popup.component.html'
})
export class PopupComponent implements OnDestroy, AfterViewInit {

    @Input() padding = true
    @Input() allowXL = false
    @Input() header?: string
    @Input() actionButtonText: string
    @Input() closable = true;

    @Input() get showPopup(): boolean { return this._showPopup; }
    set showPopup(value: boolean) {
        this.setOverFlow(value)
        this._showPopup = value;
    }

    @Output() showPopupChange = new EventEmitter();
    @Output() actionButtonClick = new EventEmitter();
    @ViewChild('popupContainer', { read: ElementRef }) popupContainer: ElementRef

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (!this.showPopup) { return }
        const code = `${(event.which || event.keyCode || event.code)}`
        if (code === '9') { return event.preventDefault() }
        if (code === '27' && this.closable) {
            this.closePopup();
        }
    }

    InfoBlockBehaviourEnum: typeof InfoBlockBehaviour = InfoBlockBehaviour;

    private _showPopup = true;

    ngAfterViewInit(): void {
        if (this.popupContainer) {
            this.popupContainer.nativeElement.parentNode.removeChild(this.popupContainer.nativeElement);
            document.body.insertBefore(this.popupContainer.nativeElement, document.body.firstChild)
        }
    }

    ngOnDestroy(): void {
        this.setOverFlow(false)
    }

    private closePopup() {
        if (!this.closable)
            return
        this.showPopup = false;

        this.popupContainer.nativeElement.parentNode.removeChild(this.popupContainer.nativeElement);
        this.showPopupChange.emit(this._showPopup);
    }

    setOverFlow(value: boolean) {
        let body = document.querySelector('body');
        if (value === true) body.classList.add('popup-open')
        else body.classList.remove('popup-open')
    }

    performActionButtonClick() {
        this.closePopup()
        this.actionButtonClick.emit();
    }

}
