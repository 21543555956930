import { Injectable } from '@angular/core'
import { BreadcrumbModel } from 'app/shared/breadcrumb/breadcrumb.models'
import { Observable, ReplaySubject } from 'rxjs'
import { StatefulHttpService } from 'app/shared/stateful-http.service'

@Injectable()
export class BreadcrumbService {

    public data = new ReplaySubject<BreadcrumbModel>(1)
    private apiKey

    constructor(private statefulHttpService: StatefulHttpService) { }

    public setApiKey(key) {
        this.apiKey = { 'X-Api-Key': key }
    }

    public loadBreadcrumb(): Observable<BreadcrumbModel> {
        let headers = { 'Accept': 'application/json', ...this.apiKey }
        this.statefulHttpService.post<BreadcrumbModel>(false, 'steps', {}, { headers }).subscribe(x => this.data.next(x))
        return this.data;
    }
}
