import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from 'app/shared'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Routes, RouterModule } from '@angular/router'
import { FlowTravelComponent } from './flow-travel/flow-travel.component'
import { TravelService } from './travel.service'
import { TravelDepartureComponent } from './travel-departure/travel-departure.component'
import { TravelPassengersComponent } from './travel-passengers/travel-passengers.component'
import { VehiclesModule } from 'app/vehicles/vehicles.module'
import { StepService } from 'app/shared/steps/step.service'
import { TravelAgentProductComponent } from './travel-agent-product/travel-agent-product.component'
import { TravelPetsComponent } from './travel-pets/travel-pets.component'
import { UIModule } from 'app/shared/UI.module'
import { TravelTrackingService } from 'app/shared/tracking/travel-tracking.service'
import { TravelCodeComponent } from './travel-code/travel-code.component'
import { DropdownModule } from 'primeng/dropdown'

const appRoutes: Routes = [
  { path: '', component: FlowTravelComponent, data: { isAgent: false } },
  {
    path: 'travel',
    component: FlowTravelComponent,
    data: { isAgent: false },
  },
  {
    path: 'Travel',
    component: FlowTravelComponent,
    data: { isAgent: false },
  },
  { path: 'agent', component: FlowTravelComponent, data: { isAgent: true } },
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes),
    SharedModule,
    UIModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    VehiclesModule,
  ],
  providers: [TravelService, StepService, TravelTrackingService],
  declarations: [
    FlowTravelComponent,
    TravelDepartureComponent,
    TravelCodeComponent,
    TravelPassengersComponent,
    TravelAgentProductComponent,
    TravelPetsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TravelModule {}
