import { Component, Input, OnChanges } from '@angular/core'
import { ITpaCategory, ITpaItemSelection, ITpaGroupItem, ITpaItem } from 'app/tpa/tpa.interfaces'
import 'app/shared/utils'
import { ResourceService } from 'app/shared/resources/resource.service';

@Component({
    selector: 'sbw-tpa-popup',
    templateUrl: './tpa-popup.component.html',
    styleUrls: ['./tpa-popup.component.css']
})
export class TpaPopupComponent {
    private resourceKey = 'INSURANCE_POPUP'
    private initialSet = true
    private checkedIds: string[] = []
    public showPopup = false
    public text = ''

    @Input() tpaCategories: ITpaCategory[]

    private _selections: ITpaItemSelection[] = []
    @Input() set selections(value: ITpaItemSelection[]) {
        this._selections = value
        if (this.initialSet) {
            if  (this.tpaCategories) { // This way we know that the page has loaded
                this.initialSet = false
            }
            return
        }
        this.setText()
        this.setShowPopup()
    }
    get selections(): ITpaItemSelection[] {
        return this._selections
    }

    constructor(private resourceService: ResourceService) { }

    public close() {
        this.showPopup = false
    }

    private setText() {
        if (this.text === '') {
            this.resourceService.get(this.resourceKey).subscribe(response => {
                this.text = response
            })
        }
    }

    private setShowPopup() {
        if (!this.selections || !this.tpaCategories || this.text === this.resourceKey) { return }

        for (let s of this.selections) {
            for (let c of this.tpaCategories) {
                const tpaItem = c.tpaGroupItems
                    .flatMap<ITpaGroupItem, ITpaItem>(g => g.tpaItems)
                    .find(item => (item.id === s.id && c.categoryCode && c.categoryCode.toUpperCase() === 'INSURANCE'))

                if (tpaItem) {
                    const foundIndex = this.checkedIds.findIndex(id => (id === tpaItem.id))
                    if (foundIndex !== -1 && s.count === 0) {
                        this.checkedIds.splice(foundIndex, 1)
                    }

                    if (foundIndex === -1 && s.count > 0) {
                        this.showPopup = true
                        this.checkedIds.push(tpaItem.id)
                        return
                    }
                }
            }
        }
        this.showPopup = false
    }
}
