import { Component, Input } from '@angular/core';

@Component({
  selector: 'sbw-validation-summary',
  templateUrl: "./validation-summary.component.html",
  styleUrls: ["./validation-summary.component.css"]
})
export class ValidationSummaryComponent {

  @Input() errorList: string[]

}
