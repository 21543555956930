import { OnInit, Component } from '@angular/core'
import { FormGroup, FormControl, FormArray } from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { Observable, of, empty, zip } from 'rxjs'
import { HybridService } from 'app/shared/hybrid/hybrid.service'
import { SbEvent } from 'app/shared/sb-event.emitter'
import { QuoteChanged, QuoteLine, PriceSpecificationType, UserQuoteDetails, QuoteDeposit } from 'app/shared/quote/quote.events'
import { Country } from 'app/passengers/passengers.models'
import { IInputTermsAndCondition } from 'app/passengers/terms-and-conditions/terms-and-conditions.component'
import { IInputPassengers } from 'app/passengers/passengers/passengers.component'
import { IInputEditUser, IUserDetails, IEditDetaisChangedEvent } from 'app/passengers/edit-user-details/edit-user-details.component'
import { IInputAdditionalPayments, IAdditionalPayments } from 'app/passengers/additional-payments/additional-payments.component'
import { IFlowReservation, SelectionTypeEnum, IFlowReservationPassengers, LocalStorageStepData } from 'app/shared/steps/step.model'
import { ResourceService } from 'app/shared/resources/resource.service'
import { StepService } from 'app/shared/steps/step.service'
import { PassengerService, IAgentInfo } from 'app/passengers/passenger.service'
import { QuoteService } from 'app/shared/quote/quote.service'
import { IPassenger } from 'app/shared/models/reservation.interfaces'
import { IFlowPassengersAgentWire, IFlowPassengersUserWire, IVehicleResult } from 'app/passengers/passengers.interfaces'
import { IInputAgentDetails } from 'app/passengers/agent-details/agent-details.component'
import { IInputCreditCard } from 'app/payment/credit-cards/credit-cards.component'
import { IVehicleSelection } from 'app/vehicles/vehicles.interfaces'
import { LayoutState } from 'app/shared/layout/layout-state'
import { EnvironmentService } from 'app/environment.service'
import { Router } from '@angular/router'
import { TrackingService } from 'app/shared/tracking/tracking.service'
import { TrackingFlowStep, ITracking, IWireProduct } from 'app/shared/tracking/tracking-wire.interfaces'
import { PriceChangedPopupModel } from '../price-changed-popup/price-changed-popup.component'
import { StorageService } from 'app/shared/storage.service'
import { VehicleAndTrailerType } from 'app/travel/travel.service'
import { requiredNonWhiteSpaceValidator } from 'app/shared/utils'
import { CookieService } from 'app/shared/cookie.service'

@Component({
  selector: 'sbw-flow-passengers',
  templateUrl: './flow-passengers.component.html',
  styleUrls: ['./flow-passengers.component.css'],
})
export class FlowPassengersComponent implements OnInit {
  shouldShowErrors: boolean = false
  validationMessage: string
  priceChangedPopupModel: PriceChangedPopupModel
  inputPassengers: IInputPassengers
  inputTermsAndCondition: IInputTermsAndCondition
  inputAgentDetails: IInputAgentDetails
  inputAdditionalPayments: IInputAdditionalPayments
  inputEditUser: IInputEditUser
  inputCreditCard: IInputCreditCard
  selectedVehicleControl: FormControl
  selectedTrailerControl: FormControl
  editModel: IEditDetaisChangedEvent
  vehicleWire: IVehicleResult
  mandatoryCampaign: boolean
  reservation: IFlowReservation
  passengerForm: FormGroup
  errorList: string[]
  specialInstructionsSelected = false
  showSpecialInstructions = false
  hasInvalidUserDetails = false
  loggedInUserType: ELoggedInUserType
  loggedIn = false
  loading = true
  useCreditCards = true
  preCardNumber = ''

  public quoteData: Observable<QuoteChanged> = empty()
  public isAgent: boolean
  public validationPassed = false

  showCreditCardValidationMessage = false
  private campaignDiscountText: string

  constructor(
    private resourceService: ResourceService,
    private stepService: StepService,
    private passengerService: PassengerService,
    private environmentService: EnvironmentService,
    private hybridService: HybridService,
    private sbEvent: SbEvent,
    private quoteService: QuoteService,
    private titleService: Title,
    private layoutState: LayoutState,
    private router: Router,
    private trackingService: TrackingService,
    private storage: StorageService,
    private cookieService: CookieService
  ) {}

  get vehicleDetails() {
    return this.passengerForm.get('vehicleDetails') as FormArray
  }

  ngOnInit() {
    this.isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
    this.resourceService.get('PASSENGERS_TITLE', false).subscribe((t) => this.titleService.setTitle(t))
    this.resourceService.get('PASSENGERS_VALIDATION_REQUIRED', false).subscribe((t) => (this.validationMessage = t))
    this.reservation = this.stepService.combineToReservation()
    this.resourceService
      .loadResourcesPromise(
        this.reservation.outboundRouteCode,
        this.resourceService.getLocale(),
        ['Common', 'Account', 'Passengers', 'AdditionalInformation', 'Payment', 'Quote', 'Currency'].concat(
          this.isAgent ? ['Agent', 'MenuAgent'] : []
        )
      )
      .then(() => {
        const fromTravel = new Array(this.vehicle().count).fill({}).map((a) => a as IVehicleSelection)
        const fromPassengers = this.reservation.flowPassengers && this.reservation.flowPassengers.vehicles

        this.passengerForm = new FormGroup({
          vehicleDetails: new FormArray(
            (fromPassengers || fromTravel).map((v) => {
              const trailerRegistrationNumber = new FormControl(v.trailerRegistrationNumber, requiredNonWhiteSpaceValidator)
              const vehicle = this.vehicle()
              if (!vehicle.trailerType) trailerRegistrationNumber.disable()
              return new FormGroup({
                vehicleRegistrationNumber: new FormControl(v.vehicleRegistrationNumber, requiredNonWhiteSpaceValidator),
                trailerRegistrationNumber,
              })
            })
          ),
        })

        zip(
          this.passengerService.getPassengersFlow(),
          this.resourceService.get('PASSENGERS_SELECT_NATIONALITY'),
          this.resourceService.get('CAMPAIGN_DISCOUNT')
        ).subscribe(([passengersWire, defaultNationalityText, campaignDiscountText]) => {
          this.mandatoryCampaign = passengersWire.campaignRules.isCampaignCodeMandatory
          this.vehicleWire = passengersWire.vehicle
          if (!this.vehicleWire || !this.vehicleWire.isVehicleRegistrationMandatory) this.vehicleDetails.disable()
          this.campaignDiscountText = campaignDiscountText
          const { titles, countries, phonePrefixes } = passengersWire.passenger

          this.setUserType(passengersWire.agentContact, passengersWire.userProfile)
          this.inputPassengers = {
            form: this.passengerForm,
            userProfile: passengersWire.userProfile,
            titles, // Move this to passenger component, and fetch by itself?
            countries, // Move this to passenger component, and fetch by itself?
          }
          const sessionFlowPassengers = this.reservation.flowPassengers

          if (this.loggedInUserType === ELoggedInUserType.ordinaryUser) {
            const { additionalPayments } = sessionFlowPassengers || <any>{}
            this.inputAdditionalPayments = {
              form: this.passengerForm,
              payment: (additionalPayments && additionalPayments.payment) || 'deposit-payment',
              depositPayment: passengersWire.deposit,
              productCode: this.reservation.productCode,
              campaignCode: additionalPayments && additionalPayments.campaignCode,
            }
          }

          if (passengersWire.creditCards.length > 0 && this.loggedInUserType !== ELoggedInUserType.creditAgent) {
            this.inputCreditCard = {
              form: this.passengerForm,
              creditCards: passengersWire.creditCards,
              creditCardCode: sessionFlowPassengers && sessionFlowPassengers.creditCardCode,
            }
          }

          this.inputTermsAndCondition = {
            form: this.passengerForm,
          }

          if (passengersWire.agentContact) {
            const agentUser = sessionFlowPassengers && sessionFlowPassengers.agentInfo
            const fromStorage = agentUser && {
              agentReference: agentUser.agentReference,
              phonePrefix: agentUser.contactPhoneNumberPrefix,
              contactPhoneNumber: agentUser.contactPhoneNumber,
            }
            this.inputAgentDetails = {
              form: this.passengerForm,
              agentContact: { ...passengersWire.agentContact, ...fromStorage },
              phonePrefixes: phonePrefixes,
              countries: countries,
            }
          }

          if (passengersWire.userProfile) {
            const user: IFlowPassengersUserWire = (sessionFlowPassengers && sessionFlowPassengers.userProfile) || passengersWire.userProfile
            this.inputEditUser = {
              form: this.passengerForm,
              countries,
              titles,
              phonePrefixes,
              userDetails: {
                id: user.id,
                titleCode: user.titleCode?.toUpperCase() || titles[0].code,
                firstName: user.firstName,
                lastName: user.lastName,
                countryCode: user.countryCode,
                postalCode: user.postalCode,
                city: user.city,
                addressLine1: user.addressLine1,
                addressLine2: user.addressLine2,
                email: user.emailAddress,
                phonePrefix: user.phonePrefix,
                phone: user.mobileNumber,
              },
            }
          }

          countries.unshift(new Country('', defaultNationalityText))
          this.quoteData = of(this.updateQuote(null, null, null, null, null))
          this.loading = false
          this.layoutState.setIsContentLoaded(true)

          this.trackingService.trackFlow(TrackingFlowStep.PASSENGERS, 'Passengers', this.reservation, true)
          let token = sessionStorage.getItem('pax_Token')
          this.cookieService.setAuthorizationToken(token)

          // Clear errors if exist when form is valid
          this.passengerForm.valueChanges.subscribe(() => {
            if (this.passengerForm.valid) {
              if (this.errorList && this.errorList.length > 0) this.errorList = []
            }
          })
        })
      })
  }

  private getTotalPriceFromSessionStorage(): number {
    return this.quoteService.getQuoteFromSessionStorage().totalPrice
  }

  vehicle(): VehicleAndTrailerType {
    const v = this.reservation.vehicleAndTrailerTypes[0]
    return v || ({ count: 0 } as VehicleAndTrailerType)
  }

  editDetailsEventHandler(bum: IEditDetaisChangedEvent) {
    this.editModel = bum
  }

  updateQuote(type: PriceSpecificationType, text: string, amount: number, currenyCode: string, count: number): QuoteChanged {
    const quoteLine = new QuoteLine(text, amount, currenyCode, count)
    const quoteDetails = Number.isInteger(amount) ? new UserQuoteDetails([quoteLine]) : new UserQuoteDetails([])
    const quoteChanged = new QuoteChanged(type, null, null, quoteDetails)
    return quoteChanged
  }

  validate() {
    this.shouldShowErrors = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.errorList = [this.validationMessage ?? 'Please fill out all required fields before continuing with payment.']
  }

  campaignCodeRedeemed(campaignDiscount) {
    const userQuoteDetails = campaignDiscount
      ? new UserQuoteDetails([new QuoteLine(this.campaignDiscountText, -Number(campaignDiscount), this.reservation.currencyCode, 1)])
      : new UserQuoteDetails([])
    this.quoteData = of(new QuoteChanged(PriceSpecificationType.CampaignDiscounts, null, null, userQuoteDetails))
    this.useCreditCards = this.getTotalPriceFromSessionStorage() > campaignDiscount
    if (!this.useCreditCards) this.passengerForm.removeControl('creditCards')
  }

  updateQuoteWithAdditionalPayment(addDepositToQuote: boolean) {
    const quoteDeposit = addDepositToQuote
      ? new QuoteDeposit(
          this.inputAdditionalPayments.depositPayment.amountToPay,
          this.inputAdditionalPayments.depositPayment.remainingAmount
        )
      : null
    this.quoteData = of(new QuoteChanged(PriceSpecificationType.Discounts, null, null, null, quoteDeposit))
  }

  setUserType(agentContact: IFlowPassengersAgentWire, userProfile: IFlowPassengersUserWire) {
    if (agentContact && agentContact.type.toLocaleLowerCase() === 'credit') {
      this.loggedInUserType = ELoggedInUserType.creditAgent
    }
    if (agentContact && agentContact.type.toLocaleLowerCase() === 'cash') {
      this.loggedInUserType = ELoggedInUserType.cashAgent
    }
    if (userProfile) {
      this.loggedInUserType = ELoggedInUserType.ordinaryUser
    }
    if (userProfile == null && agentContact == null) {
      throw new Error('user must be either agent or ordinary user')
    }
  }

  specialInstructionsDisabledClicked() {
    if (!this.specialInstructionsSelected) {
      this.showSpecialInstructions = true
    }
    this.specialInstructionsSelected = true
  }

  specialInstructionsNoneDisabledClicked() {
    this.specialInstructionsSelected = false
  }

  public continue() {
    this.layoutState.setIsContinueLoading(true)
    const passengers = <IPassenger[]>this.passengerForm.get('passengers').value
    const userDetails = this.passengerForm.get('userDetails') && <IUserDetails>this.passengerForm.get('userDetails').value
    const userProfile =
      this.loggedInUserType === ELoggedInUserType.ordinaryUser
        ? <IFlowPassengersUserWire>{
            id: userDetails.id,
            titleCode: userDetails.titleCode?.toUpperCase(),
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            countryCode: userDetails.countryCode,
            postalCode: userDetails.postalCode,
            city: userDetails.city,
            addressLine1: userDetails.addressLine1,
            addressLine2: userDetails.addressLine2,
            emailAddress: userDetails.email,
            phonePrefix: userDetails.phonePrefix,
            mobileNumber: userDetails.phone,
          }
        : null

    const agent =
      this.loggedInUserType === ELoggedInUserType.creditAgent || this.loggedInUserType === ELoggedInUserType.cashAgent
        ? <IAgentInfo>{
            agentReference:
              this.passengerForm.get('agentDetails.agentReference') && this.passengerForm.get('agentDetails.agentReference').value,
            contactPhoneNumberPrefix:
              this.passengerForm.get('agentDetails.contactPhonePrefix') && this.passengerForm.get('agentDetails.contactPhonePrefix').value,
            contactPhoneNumber:
              this.passengerForm.get('agentDetails.contactPhoneNumber') && this.passengerForm.get('agentDetails.contactPhoneNumber').value,
          }
        : null
    const creditCardCode = this.useCreditCards
      ? this.passengerForm.get('creditCards.creditCard') && this.passengerForm.get('creditCards.creditCard').value
      : 'CC'
    const selectedCard = creditCardCode && this.inputCreditCard.creditCards.find((c) => c.code == creditCardCode)
    const vehicles = this.passengerForm.get('vehicleDetails').value
    const additionalPayments =
      this.passengerForm.get('additionalPayments') && <IAdditionalPayments>this.passengerForm.get('additionalPayments').value
    const depositCode = this.getDepositCode()

    if (this.environmentService.getSbMockBackend() && this.storage.getItem('e2emock') == 'true') {
      let fakeTrackingData: ITracking = {
        marketingHost: '',
        creditCardFee: 0,
        depositAmount: 0,
        price: 0,
        products: <IWireProduct[]>[],
      }

      this.trackingService.saveFlowTrackingData(fakeTrackingData)

      let params = {
        currency: '£',
        amountToPay: '299',
        paymentType: 'VISA',
      }
      this.router.navigate(['/ecommerce/fake-payment'], {
        queryParams: { currency: params.currency, amountToPay: params.amountToPay, paymentType: params.paymentType },
      })
    } else {
      this.passengerService
        .postHybrid(creditCardCode, additionalPayments, depositCode, passengers, vehicles, userProfile, agent, {
          route: this.reservation.routeCode,
          card: selectedCard && selectedCard.description,
        })
        .subscribe((res) => {
          this.layoutState.setIsContentLoaded(false)
          this.layoutState.setIsContinueLoading(false)
          if (res.nextStepUrl) {
            this.updateStepInLocalStorage(creditCardCode, additionalPayments, passengers, vehicles, userProfile, agent, res.reservationCode)
            let priceChanged = new PriceChangedPopupModel(
              this.getTotalPriceFromSessionStorage(),
              res.quote.totalPrice,
              this.reservation.currencyCode,
              res.nextStepUrl,
              this.reservation.outboundRouteCode
            )
            if (priceChanged.priceDiff()) {
              this.layoutState.setIsContentLoaded(true)
              this.quoteService.updateBackendQuote(res.quote)
              this.priceChangedPopupModel = priceChanged
            } else {
              console.log("passenger flow changeWindowLocation:", res.nextStepUrl)
              this.hybridService.changeWindowLocation(res.nextStepUrl)
            }
          } else {
            this.layoutState.setIsContinueLoading(false)
            this.layoutState.setIsContentLoaded(true)
            this.errorList = res.errorList.map((e) => e.message)
          }
          this.trackingService.saveFlowTrackingData(res.trackingData)
        })
    }
  }

  private updateStepInLocalStorage(
    creditCardCode: string,
    additionalPayments: IAdditionalPayments,
    passengers: IPassenger[],
    vehicles: IVehicleSelection[],
    userProfile: IFlowPassengersUserWire,
    agentInfo: IAgentInfo,
    reservationCode: string
  ) {
    let step: any = {}
    const data = <IFlowReservationPassengers>{
      creditCardCode,
      additionalPayments,
      passengers,
      vehicles,
      userProfile,
      agentInfo,
      reservationCode,
    }
    step.flowPassengers = new LocalStorageStepData(SelectionTypeEnum.User, data)
    this.stepService.saveStepData(step, window.location.pathname, false)
  }

  getDepositCode() {
    if (this.passengerForm.get('additionalPayments.payment') == null) {
      return null
    }
    if (this.passengerForm.get('additionalPayments.payment').value === 'deposit-payment' && this.inputAdditionalPayments.depositPayment) {
      return this.inputAdditionalPayments.depositPayment.depositCode
    }
    return null
  }

  public navigateBack() {
    this.sbEvent.historyGoBack.next()
  }
}
enum ELoggedInUserType {
  creditAgent,
  cashAgent,
  ordinaryUser,
}
