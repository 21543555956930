import { Component, OnInit, Input } from '@angular/core'
import { Quote } from '../quote.models'
import moment from 'moment'
import { PriceSpecificationType } from 'app/shared/quote/quote.events'

@Component({
  selector: 'sbw-quotedetail',
  templateUrl: './quotedetail.component.html',
  styleUrls: ['./quotedetail.component.css'],
})
export class QuotedetailComponent {
  @Input() leg: Quote.QuoteDetail
  @Input() dateFormat: string
  @Input() showDepartureTime: boolean = true

  public priceSpecificationType = PriceSpecificationType

  constructor() {}

  public itemCount(priceSpec: Quote.IPriceSpecification): string {
    return priceSpec.displayCount == 0 ? '' : `${priceSpec.displayCount} x `
  }

  public multiplyPrice(priceSpec: Quote.IPriceSpecification): number {
    return priceSpec.count > 0 ? priceSpec.price * priceSpec.count : priceSpec.price
  }

  get formattedDate() {
    if (!this.leg.routeDateTime) {
      return ''
    }
    if (this.dateFormat === 'ITINERARY_DATETIME_FORMAT') {
      return this.dateFormat
    }
    let theMoment = moment(this.leg.routeDateTime)
    return theMoment.format(this.dateFormat)
  }

  public nonEmptyCategories() {
    return this.leg.allCustomerCategories && this.leg.allCustomerCategories.filter((c) => c.count > 0)
  }

  public passengerName(category: Quote.CustomerCategory): string {
    return category && category.count == 1 ? category.nameSingular : category.namePlural
  }

  public emissionDateValid(routeDateTime: string): boolean {
    const year = moment(routeDateTime).year()
    return year !== 2023
  }

  getQuoteType(type: number): string {
    return PriceSpecificationType[type]
  }
}
