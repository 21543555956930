import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'sbw-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit {
    @Input() id: string;
    @Input() dataId: string

    private _checked = false;
    @Input() set checked(s: boolean) {
        this._checked = s
        this.checkedChange.emit(this._checked)
    }
    get checked(): boolean {
        return this._checked
    }
    @Output() checkedChange = new EventEmitter<boolean>()

    @Input() readonly: boolean = false;
    @Input() disabled: boolean = false;
    @Output() changed = new EventEmitter();

    //Since the styling of this checkbox needs a label we provide the option to say if this checkbox is wrapped in a label or one should be provided.
    @Input() label: boolean = true;

    constructor() { }

    ngOnInit() {
        if (!this.id)
            this.id = Math.random().toString();
    }

    public changedEvent(e: Event) {
        this.checked = !this.checked;
        this.changed.emit(this.checked);
    }
}
