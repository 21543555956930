import { ResourceService } from 'app/shared/resources/resource.service'
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, AfterViewChecked } from '@angular/core'
import { Router } from '@angular/router'
import { interval, zip } from 'rxjs'
import { IReservation, IPriceItem } from 'app/shared/models/reservation.interfaces'
import { ButtonStateType } from 'app/shared/button/button.component'
import { AmendService } from 'app/shared/reservation/amend.service'
import { PassengerService } from 'app/passengers/passenger.service'
import { SnapshotService } from 'app/shared/snapshot.service'
import { IInputBookingInfo } from 'app/amendment/bookings/booking-info/booking-info.component'
import { IInputContactDetails } from 'app/amendment/bookings/contact-details/contact-details.component'
import { TrackingService } from 'app/shared/tracking/tracking.service'
import { TrackingAmendmentStep } from 'app/shared/tracking/tracking-wire.interfaces'
import { ContentfulClientApiFast } from 'app/shared/resources/contentfulClientApiFast'
import { LayoutState } from 'app/shared/layout/layout-state'
import { EcommerceTrackingMealsPreparerService } from 'app/shared/tracking/ecommerce-tracking/ecommerce-amendment/meals-preparer.service'
import { ReservationService } from 'app/shared/reservation/reservation.service'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'sbw-active',
  templateUrl: './active.component.html',
  styleUrls: ['./active.component.css']
})
export class ActiveComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input() reservation: IReservation
  @Output() loadingDone = new EventEmitter<string>()
  @Input() public printConfCallback: Function
  @Input() pathToMyBookings: string
  public buttonStatePayNow: ButtonStateType = 'activated'
  public buttonStateReservationChanged: ButtonStateType = 'disabled'
  public updatingReservation = false
  public reservationHasChanged: boolean
  public amendmentBookingContent?: any;
  public showCancelablePopup = false
  public agentContact: string
  public errors: string[] = []
  public reservationCode: string
  private stickyElement: HTMLElement
  private buttonStackElement: HTMLElement
  private bodyElement: HTMLElement
  private unloadMessage: string
  private bindedUnloadMessagePopup = this.unloadMessagePopup.bind(this)
  public buttonStateConfirmCancelReservation: ButtonStateType = 'activated'
  public priceItems: IPriceItem[]
  public done = false

  inputBookingInfo: IInputBookingInfo
  inputContactDetails: IInputContactDetails
  disabledLinksPopup: { text: string, heading: string }
  constructor(
    private router: Router,
    private reservationService: ReservationService,
    private amendService: AmendService,
    private snapshotService: SnapshotService,
    private resourceService: ResourceService,
    private passengerService: PassengerService,
    private trackingService: TrackingService,
    private contentful: ContentfulClientApiFast,
    private layoutState: LayoutState,
    private ecommerceTrackingMealsPreparerService: EcommerceTrackingMealsPreparerService) { }
  
  ngOnInit() {
    this.reservationHasChanged = this.snapshotService.hasSnapshots(this.reservation.reservationCode)
    this.buttonStateReservationChanged = this.reservationHasChanged ? 'activated' : 'disabled'
    this.agentContact = this.reservation.agentContact
    this.reservationCode = this.reservation.reservationCode;
    zip(this.passengerService.getTitles(this.reservation.salesOwnerId.toString(), this.reservation.locale), this.passengerService.getCountries(this.reservation.locale), this.resourceService.get('UNLOAD'), this.contentful.getEntries<any>('amendmentBooking'))
      .subscribe(response => {
        const [titles, countries, unloadMessage, content] = response
        this.amendmentBookingContent = content.items[0].fields
        this.unloadMessage = unloadMessage
        this.disabledLinksPopup = { text: this.amendmentBookingContent.disabledLinksPopupText, heading: this.amendmentBookingContent.disabledLinksPopupHeading }
        let snapshot = this.snapshotService.getSnapshot(this.reservation.reservationCode)
        let snapshotMeals = snapshot && snapshot.meals
        let snapshotPassengers = snapshot && snapshot.passengers ? snapshot.passengers : []
        let snapshotDepartures = snapshot && snapshot.departures ? snapshot.departures : null
        let snapshotTpas = snapshot && snapshot.tpas ? snapshot.tpas : []
        let snapshotVehicles = snapshot && snapshot.vehicles
        let snapshotCabins = snapshot && snapshot.cabins
        let snapshotCabinAllocations = snapshot && snapshot.cabinAllocations
        this.inputContactDetails = <IInputContactDetails>{
          title: this.reservation.contact.title,
          firstName: this.reservation.contact.firstName,
          lastName: this.reservation.contact.lastName,
          addressLine1: this.reservation.contact.addressLine1,
          addressLine2: this.reservation.contact.addressLine2,
          city: this.reservation.contact.city,
          county: this.reservation.contact.county,
          country: this.reservation.contact.country,
          postalCode: this.reservation.contact.postalCode,
          mobileNumber: this.reservation.contact.mobileNumber,
          emailAddress: this.reservation.contact.emailAddress,
        }

        if (this.reservation.reservationStatus === 'Preliminary' || this.reservation.reservationStatus === 'Pending') {
          this.errors = [this.amendmentBookingContent.preliminaryPendingMessage]
        }

        this.inputBookingInfo = <IInputBookingInfo>{
          departures: {
            out: this.reservation.departures[0],
            return: this.reservation.departures.length > 1 ? this.reservation.departures[1] : null
          },
          snapshotDepartures: {
            out: snapshotDepartures ? snapshotDepartures.out : null,
            return: snapshotDepartures ? snapshotDepartures.return : null
          },
          vehicles: this.reservation.departures[0].vehicles,
          snapshotVehicle: snapshotVehicles,
          titles,
          countries,
          pets: this.reservation.departures[0].pets,
          passengers: this.reservation.passengers,
          snapshotPassengers: snapshotPassengers,
          bookedOn: this.reservation.bookingDate,
          creditCard: this.reservation.creditCard,
          modified: this.reservation.modifiedDate
        }
        this.amendService.getTotals(this.reservation.reservationCode, this.reservation.isMiniCruise, snapshotDepartures, snapshotMeals && snapshotMeals.patch, snapshotPassengers, snapshotTpas, snapshotVehicles, snapshotCabins, snapshotCabinAllocations).subscribe(response2 => {
          let patchReservation = response2.reservation
          if (patchReservation) {
            this.reservation.remainingAmount = patchReservation.remainingAmount
            this.reservation.totalPrice = patchReservation.totalPrice
          }
          this.priceItems = patchReservation && patchReservation.priceItems
          this.done = true
          this.layoutState.setIsContentLoaded(true)
        })
        this.trackingService.trackAmendment(TrackingAmendmentStep.ACTIVE, false, this.reservation)
      })
    if (this.reservationHasChanged) {
      window.addEventListener('beforeunload', this.bindedUnloadMessagePopup, false)
    }
  }

  ngAfterViewChecked(): void {
    if (!this.stickyElement || !this.buttonStackElement) {
      this.loadStickyConfiguration()
    }
  }

  loadStickyConfiguration(): void {
    this.stickyElement = document.getElementById('sticky-container')
    this.buttonStackElement = document.getElementById('button-container')
    this.bodyElement = document.getElementsByTagName('body')[0]

    if (this.stickyElement && this.buttonStackElement) {
      let bodyPosition: number = this.bodyElement.getBoundingClientRect().top
      let stickyElementPosition: number;
      let buttonStackPosition: number;

      document.addEventListener('scroll', (e) =>{
        stickyElementPosition = this.stickyElement.getBoundingClientRect().top - bodyPosition
        buttonStackPosition = this.buttonStackElement.getBoundingClientRect().top - bodyPosition

        if (stickyElementPosition - buttonStackPosition <= 0) {
          this.stickyElement.style.visibility = 'visible'
        }

        if (stickyElementPosition - buttonStackPosition > 0) {
          this.stickyElement.style.visibility = 'hidden'
        } 
      })
    }
  }

  unloadMessagePopup(event) {
    // Some browsers do not show the string, but shows a default message
    let msg = this.unloadMessage || 'Are you sure?'
    event.returnValue = msg
    return msg
  }

  ngOnDestroy(): void {
    if (this.reservationHasChanged) {
      window.removeEventListener('beforeunload', this.bindedUnloadMessagePopup)
    }
  }

  undoAmendment() {
    this.snapshotService.clearSnapshot(this.reservation.reservationCode)
    this.navigateToBookingOverview()
  }

  navigateToBookingOverview = () => {
    const href = window.location.href
    const origin = window.location.origin
    const locale = href.split('/')[3]

    if (this.agentContact) {
      const agentBookingsUrl = `${origin}/${locale}/${href.split('/')[4]}/booking/agent/bookings`
      window.location.href = agentBookingsUrl
    }
    else {
      const bookingsUrl = `${origin}/${locale}/${this.pathToMyBookings}`
      window.location.href = bookingsUrl
    }
  }

  updateReservation() {
    this.layoutState.setIsContentLoaded(false)
    this.updatingReservation = true
    let snapshots = this.snapshotService.getSnapshot(this.reservation.reservationCode)
    if (!snapshots) {
      this.updatingReservation = false
      return
    }
    let ssMeals = snapshots.meals && snapshots.meals && snapshots.meals.patch
    this.amendService.patchReservation(this.reservation.reservationCode, this.reservation.isMiniCruise, snapshots.departures, ssMeals, snapshots.passengers, snapshots.tpas, snapshots.vehicles, snapshots.cabins, snapshots.cabinAllocations).subscribe(response => {
      if (!response.errorMsgs || response.errorMsgs?.length === 0){
        this.ecommerceTrackingMealsPreparerService.prepareMealsForAmendmentTracking(this.reservation, response.reservation)
      }
      this.updatingReservation = false
      if (response.errorMsgs && response.errorMsgs.length) {
        this.errors = response.errorMsgs.map(m => m.message)
        this.layoutState.setIsContentLoaded(true)
        return
      }
      this.reservation = response.reservation

      if (!response.reservation.payOnline) {
        this.trackingService.saveAmendmentTrackingData(this.reservation) // in below else block reservation object will be saved in remanining component
        this.snapshotService.clearSnapshot(this.reservation.reservationCode)
        this.router.navigate(['/amendment/confirmation', this.reservation.reservationCode])
      } else {
        this.redirectAmendmentPayment(this.reservation.reservationCode)
      }
    })
  }

  payRemainingAmount() {
    this.redirectAmendmentPayment(this.reservation.reservationCode)
  }

  private redirectAmendmentPayment(reservationCode: string) {
    this.router.navigate(['/payment/amendment', reservationCode])
  }


  cancelReservation() {
    this.resourceService.get('CONFIRM_CANCEL_RESERVATION').subscribe(response => {
      this.showCancelablePopup = true
    })
  }

  confirmCancelReservation(): void {
    this.buttonStateConfirmCancelReservation = 'spinner'
    this.amendService.cancelReservation(this.reservation.reservationCode).subscribe(() => {
      const timer = interval(1000).pipe(
        switchMap(() => this.reservationService.getReservation(this.reservation.reservationCode))
      ).subscribe((result) => {
          if (result.reservationStatus === 'Cancelled') {
            timer.unsubscribe()
            const currentUrl = this.router.url;
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                this.router.navigate([currentUrl]);
            });
          }
      })
    })
  }

  closeCancelReservationPopup(): void {
    this.showCancelablePopup = false
  }

  displaySaveChangesBtn() {
    return this.reservation.remainingAmount <= 0 || this.reservationHasChanged
  }

  displayOutstandingAmountBtn() {
    return this.reservation.remainingAmount > 0 && !this.reservationHasChanged && this.reservation.agentType.toLowerCase() !== 'credit'
  }

  clearStorage(reservationCode: string) {
    this.snapshotService.clearSnapshot(reservationCode)
  }
}
