<div class="gutter leg-container">
    <div class="row">
        <div class="column small-12">
            <div class="allocations-header">
                <h2>{{departure.routeName}}
                    <span>{{departure.departureDate | resourceDateformat:'MOMENT_DEPARTURE_HEADER_DATE_FORMAT'}}</span>
                </h2>
            </div>
        </div>
    </div>
    <div class="row">
        <div *ngFor="let cabin of departure.cabins">
            <div class="column small-12 medium-12 large-6 xlarge-6">
                <sbw-cabin-allocation [cabin]="cabin" [formPassengerSelections]="formPassengerSelections"></sbw-cabin-allocation>
            </div>
        </div>
    </div>
</div>