import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { StatelessHttpService } from './stateless-http.service'

@Injectable()
export class UserDetailsService {
  constructor(private statelessHttpService: StatelessHttpService) {}

  getUserDetails(): Observable<UserDetailsWire> {
    const token = sessionStorage.getItem('pax_Token')
    return this.statelessHttpService.get<UserDetailsWire>(false, 'userdetails', { headers: token ? this.getHeaders(token) : {} })
  }

  private getHeaders(token: string): any {
    return {
      Authorization: `Bearer ${token}`,
    }
  }
}

export interface UserDetailsWire {
  user: UserDetails
  agent: AgentDetails
  isAuthenticated: boolean
  authType: 'OKTA' | 'SSO' | 'AGENT' | ''
  isAgent: boolean
  secondsBeforeExpiry: number
  windowToRefresh: number
}

export interface UserDetails {
  name: string
  email: string
}

export interface AgentDetails {
  company: string
  name: string
  address: string
  address2: string
  agentType: string
  city: string
  country: string
  email: string
  fax: string
  phone: string
  postalCode: string
  reference: string
}
