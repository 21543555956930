<div *ngIf="tpaGroupItem != null">
    <div class="row collapse half-gutter">
        <div class="column">
            <div class="column small-12 half-gutter-top">
                <sbw-information-popup [text]="tpaGroupItem.popupInfoText" label="{{tpaGroupItem.name}}"></sbw-information-popup>
            </div>
        </div>
    </div>

    <div *ngFor="let tpaItem of tpaGroupItem.tpaItems; let i = index; let last = last" class="row uncollapse group-item" [ngClass]="{'last': last}" [attr.data-e2e-id]="tpaItem.id" [attr.data-e2e]="hasOptions(tpaItem) ? 'tpa-time' : 'no-tpa-time'">
        <div class="column small-12 medium-4 large-5 option-name">
            {{tpaItem.name}}
        </div>
        <div class="column small-12 medium-4 large-3 option-date">
            <span *ngIf="!hasOptions(tpaItem)">&nbsp;</span>
            <sbw-select *ngIf="hasOptions(tpaItem)" [(ngModel)]="tpaItem.selectedOption" (ngModelChange)="changed()" [items]="tpaItem.options" [key]="'id'" [htmlClass]="'select-option-group-item'" [attr.data-e2e-id]="'option-' + tpaItem.id"></sbw-select>
        </div>

        <div class="column small-8 medium-2 hide-for-medium-up option-text">
            <h5>{{'ARRANGEMENT_OPTIONS_PRICE' | resource }}</h5>
        </div>
        <div class="column small-4 medium-2 option-price">
            {{currency | resource}}{{tpaItem.price | resourceformat:'CURRENCY_FORMAT'}}
        </div>

        <div class="column small-8 medium-2 hide-for-medium-up option-text">
            <h5>{{'TPA_OPTIONS_SELECTED' | resource }}</h5>
        </div>

        <div class="column small-4 medium-2 option-count">
            <sbw-select [(ngModel)]="tpaItem.selectedCount" (ngModelChange)="changed()" [items]="tpaItem.selections" [htmlClass]="'select-count-group-item'" [attr.data-e2e-id]="'count-' + tpaItem.id"></sbw-select>
        </div>
    </div>
</div>
