import { Injectable } from '@angular/core'
import { HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { StepService } from '../shared/steps/step.service'
import { LocalStorageStepData, SelectionTypeEnum } from '../shared/steps/step.model'
import { QuoteChanged, PriceSpecificationType, QuoteLine, UserQuoteDetails } from '../shared/quote/quote.events'
import { ResourceService } from '../shared/resources/resource.service'
import { ITpaModel, ITpaItemSelection, ITpaResult, ITpaCategory, ITpaGroupItem, ITpaItem } from 'app/tpa/tpa.interfaces'
import { TpaLeg } from 'app/tpa/tpa.enums'
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service'
import 'app/shared/extensions/array.extensions.ts'
import { StatelessHttpService } from 'app/shared/stateless-http.service'
import { StatefulHttpService } from 'app/shared/stateful-http.service'

@Injectable()
export class TpaService {
    private step: any

    constructor(
        private statelessHttpService: StatelessHttpService, 
        private statefulHttpService: StatefulHttpService, 
        private resourceService: ResourceService, 
        private salesChannelService: SalesChannelService, 
        private stepService: StepService) 
    { }

    public getTpaAmendment(reservationCode: string): Observable<ITpaModel> {
        let params = new HttpParams()
            .set('reservationCode', reservationCode)
        const options = { params }
        return this.statelessHttpService.get<ITpaModel>(true, 'tpa-amendment', options)
    }

    public getTpaFlow(): Observable<ITpaModel> {
        const reservation = this.stepService.combineToReservation()
        const vehicleAndTrailerTypes = reservation.vehicleAndTrailerTypes.filter(t => t.vehicleType != null)

        let params = new HttpParams()
            .set('outboundDepartureId', reservation.outboundDepartureId)
            .set('returnDepartureId', reservation.returnDepartureId)
            .set('productCode', reservation.productCode)
            .set('outboundRouteCode', reservation.routeCode)
            .set('salesOwnerId',  this.resourceService.getSalesOwner())
            .set('localeCode', this.resourceService.getLocale())
            .set('salesChannelCode', this.salesChannelService.getCode())
            .set('adults', reservation.adults.toString())
            .set('children', reservation.children.toString())
            .set('infants', reservation.infants.toString())

        vehicleAndTrailerTypes.forEach(vt => {
            Array(vt.count).fill(vt.vehicleType).forEach(vehicleType => {
                params = params.append('leadVehicles', vehicleType)
            })
            if (vt.trailerType) {
                Array(vt.count).fill(vt.trailerType).forEach(trailerType => {
                    params = params.append('trailerVehicles', trailerType)
                })
            }
        })

        const options = { params }
        return this.statelessHttpService.get<ITpaModel>(false, 'tpa-flow-vehicle-types', options)
    }

    public postHybridTpa(selections: ITpaItemSelection[]): Observable<ITpaResult> {
        this.updateStepInLocalStorage(selections)
        return this.statefulHttpService.post<ITpaResult>(false, 'tpa-flow-temp', {selections})
    }

    public getSelections(tpaCategories: ITpaCategory[]): ITpaItemSelection[] {
        return tpaCategories
            .flatMap<ITpaCategory, ITpaGroupItem>(x => x.tpaGroupItems)
            .flatMap<ITpaGroupItem, ITpaItem>(x => x.tpaItems)
            .map(x => ({
                id: x.id,
                tpaType: x.tpaType,
                count: Number(x.selectedCount),
                optionId: x.selectedOption === '0' ? null : x.selectedOption
            }))
    }

    public updateIsOpen(tpaCategories: ITpaCategory[]) {
        tpaCategories.forEach(category => {
            category.isOpen = category.tpaGroupItems.some(g => g.tpaItems.some(i => parseInt(i.selectedCount) > 0))
        });
    }

    public getQuoteModel(tpaCategories: ITpaCategory[], tpaItemSelection: ITpaItemSelection[], currencyCode: string): QuoteChanged {
        return new QuoteChanged(PriceSpecificationType.Tpa,
            this.getQuoteData(tpaCategories, tpaItemSelection, TpaLeg.Out, currencyCode),
            this.getQuoteData(tpaCategories, tpaItemSelection, TpaLeg.Return, currencyCode),
            this.getQuoteData(tpaCategories, tpaItemSelection, TpaLeg.Extra, currencyCode)
        )
    }

    public setSelectionsOnViewModel(tpaCategories: ITpaCategory[], selections: ITpaItemSelection[] = []) {
        tpaCategories
            .flatMap<ITpaCategory, ITpaGroupItem>(c => c.tpaGroupItems)
            .flatMap<ITpaGroupItem, ITpaItem>(c => c.tpaItems)
            .forEach(c => {
                let tpaItem: ITpaItemSelection = selections.find(x => x.id === c.id && x.tpaType === c.tpaType)

                c.selectedCount = tpaItem ? tpaItem.count.toString() : c.minNoOrder.toString()
                c.selectedOption = tpaItem ? tpaItem.optionId : null
            })
    }

    private getQuoteData(tpaCategories: ITpaCategory[], tpaItemSelection: ITpaItemSelection[], leg: TpaLeg, currencyCode: string): UserQuoteDetails {
        return new UserQuoteDetails(tpaItemSelection.map(
            s => tpaCategories
                .flatMap<ITpaCategory, ITpaGroupItem>(c => c.tpaGroupItems)
                .filter(c => c.tpaLeg === leg)
                .flatMap<ITpaGroupItem, ITpaItem>(c => c.tpaItems)
                .filter(c => s.id === c.id && s.count !== 0)
                .map(c => new QuoteLine(c.name, c.price, currencyCode, s.count))
        )
            .reduce((a, b) => a.concat(b), []))
    }

    private updateStepInLocalStorage(selections: ITpaItemSelection[]) {
        let step: any = {}
        step.tpaSelections = new LocalStorageStepData(SelectionTypeEnum.User, selections)
        this.stepService.saveStepData(step, window.location.pathname, false)
    }

}
