<sbw-layout
    [backButtonVisible]="true"
    [type]="'Amendment'"
    [form]="amendmentsForm"
    [continueButtonDisabled]="!this.isAmendmentCompatible || !this.cabinsChanged"
    (continueEvent)="continue()"
    (backEvent)="navigateBack()"
    >
    <div class="sbw_pagebox">
        <div class="row uncollapse cabin-fares-container">
            <h1>{{'CABINS_AMENDMENT_TITLE' | resource}}</h1>
            <div class="row">
                <div class="column small-12 large-10 sbw_legend" [innerHTML]="'CABINS_AMENDMENT_TEASER' | resource"></div>
            </div>
            <div *ngIf="errors.length > 0" class="row">
                <div class="column small-12">
                    <sbw-validation-summary [errorList]="errors"></sbw-validation-summary>
                </div>
            </div>

            <!-- outbound leg -->
            <sbw-cabins-selector 
                *ngIf="isAmendmentCompatible" 
                [formDepartureGroup]="outboundDepartureGroup" 
                [formSelections]="outboundSelections" 
                [content]="amendmentCabinsContent"
                [osloFrederikshavnHack]="osloFrederikshavnHack"
             ></sbw-cabins-selector>

            <!-- return leg -->
            <sbw-cabins-selector 
                *ngIf="isAmendmentCompatible && !isOneway" 
                [formDepartureGroup]="returnDepartureGroup" 
                [formSelections]="returnSelections" 
                [content]="amendmentCabinsContent" 
                [osloFrederikshavnHack]="osloFrederikshavnHack"
            ></sbw-cabins-selector>

        </div>
    </div>
</sbw-layout>
