import { LocalDate } from 'js-joda';
import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ChildrenAges {
    constructor(public ages: number[], public providedChildCount: number) { }

    static fromStrings(ages: string[], providedChildCount?: number) {
        let topBoundary = providedChildCount != null ? providedChildCount : 99
        return new ChildrenAges(
            ages.map(a => parseInt(a)).filter(a => !isNaN(a)).slice(0, topBoundary),
            providedChildCount != null ? providedChildCount : ages.length)
    }

    get allAgesProvided() { return this.ages.length == this.providedChildCount }
    get infants() { return this.ages.filter(a => 0 <= a && a <= 3).length }
    get children() { return this.providedChildCount - this.infants }

}

export class DisabledDateValidator {
    constructor(private getDates: () => LocalDate[]) { }

    validate = (control: AbstractControl): ValidationErrors | null => {
        let dates = this.getDates()
        return dates && dates.some(d => d.equals(control.value)) ? { selectedDateDisabled: true } : null
    }

}
