import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { TpaService } from './tpa.service'
import { RouterModule, Routes } from '@angular/router'
import { SharedModule } from '../shared/shared.module'
import { StepService } from '../shared/steps/step.service'
import { FlowTpaComponent } from './flow-tpa/flow-tpa.component'
import { TpaSelectorComponent } from './tpa-selector/tpa-selector.component'
import { TpaCategoryGroupComponent } from './tpa-category-group/tpa-category-group.component'
import { SbEvent } from 'app/shared/sb-event.emitter'
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service'
import { AmendmentTpaComponent } from './amendment-tpa/amendment-tpa.component'
import { TpaPopupComponent } from './tpa-popup/tpa-popup.component'
import { UIModule } from 'app/shared/UI.module'
import { FlowGuard } from 'app/shared/guards/flow.guard'
import { AuthGuard } from 'app/shared/guards/auth.guard'

const appRoutes: Routes = [
  { path: 'tpa', component: FlowTpaComponent, canActivate: [FlowGuard] },
  { path: 'amendment/booking/:reservationCode/tpas', component: AmendmentTpaComponent, canActivate: [AuthGuard] }
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes),
    SharedModule,
    UIModule,
    FormsModule,
    BrowserModule,
  ],
  declarations: [FlowTpaComponent, TpaSelectorComponent, TpaCategoryGroupComponent, AmendmentTpaComponent, TpaPopupComponent],
  bootstrap: [],
  providers: [TpaService, StepService, SbEvent, SalesChannelService]
})
export class TpaModule { }
