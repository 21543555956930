import { Component, OnInit, Input } from '@angular/core'
import { FormControl } from '@angular/forms'

import { IFlowReservation } from 'app/shared/steps/step.model';
import { Direction } from '../cabin-fares.interfaces';
import { EnvironmentService } from 'app/environment.service';

@Component({
    selector: 'sbw-cabin-fares-selector',
    templateUrl: './cabin-fares-selector.component.html',
    styleUrls: ['./cabin-fares-selector.component.css']
})
export class CabinFaresSelectorComponent implements OnInit {

    @Input() model: IInputCabinFaresSelector
    @Input() reservation: IFlowReservation
    @Input() form: FormControl
    public contentfulEditMode: boolean
    adults: number
    children: number
    infants: number
    numberOfAvailableCabins: number[];

    constructor(private environmentService: EnvironmentService) { }

    ngOnInit() {
        this.environmentService.contentfulEditModeChanged$
            .subscribe(enabled => {
                this.contentfulEditMode = enabled
            })

        if (this.reservation) {
            this.adults = this.reservation.adults
            this.children = this.reservation.children
            this.infants = this.reservation.infants
        }
        this.generateNumberOfAvailableCabinsForDropDown()
    }

    generateNumberOfAvailableCabinsForDropDown() {
        if (this.isOnlyRestAreaOnOsFh()) {
            this.numberOfAvailableCabins = Array.from({ length: + this.adults + this.children + 1 }, (_, k) => k)      
        } else {
            this.numberOfAvailableCabins = Array.from({ length: + this.adults + 1 }, (_, k) => k)
        }
        if (this.model.availableCapacity < this.adults + 1) this.numberOfAvailableCabins.length = this.model.availableCapacity + 1
    }

    isOnlyRestAreaOnOsFh = () : boolean => 
        /^dt$/i.test(this.model.cabinTypeCode) && 
        (/^osfh$/i.test(this.reservation.routeCode) || 
        /^fhos$/i.test(this.reservation.routeCode))
}

export interface IInputCabinFaresSelector {
    genericId: string
    cabinTypeCode: string
    direction: Direction
    description: string
    currency: string
    price: number
    outboundPrice?: number
    returnPrice?: number
    originalPrice?: number
    numberOfOccupiableBerths: number
    cabinDetailsFromContentful?: any
    numberOfSuggested: number
    availableCapacity: number
}
