import { Component, NgZone, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'

import { Observable, of, empty } from 'rxjs'

import { ResourceService } from 'app/shared/resources/resource.service'
import { CookieService } from 'app/shared/cookie.service'
import { StepService } from 'app/shared/steps/step.service'
import { QuoteChanged } from 'app/shared/quote/quote.events'
import 'app/shared/extensions/array.extensions'
import { ITpaCategory, ITpaGroupItem, ITpaItemSelection, ITpaModel, ITpaItem } from 'app/tpa/tpa.interfaces'
import { TpaService } from 'app/tpa/tpa.service'
import { SbEvent } from 'app/shared/sb-event.emitter'
import { HybridService } from 'app/shared/hybrid/hybrid.service'
import { QuoteService } from 'app/shared/quote/quote.service'
import { LayoutState } from 'app/shared/layout/layout-state'
import { TrackingService } from 'app/shared/tracking/tracking.service'
import { TrackingFlowStep } from 'app/shared/tracking/tracking-wire.interfaces'
import { LoggingService } from 'app/shared/logging.service'
import { AuthService } from 'app/auth/auth.service'
import { UserDetailsService } from 'app/shared/user-details.service'
import { getPassengerRedirectUri } from 'app/auth/auth.config'

@Component({
  selector: 'sbw-flow-tpa',
  templateUrl: './flow-tpa.component.html',
  styleUrls: ['./flow-tpa.component.css'],
})
export class FlowTpaComponent implements OnInit {
  public errorList = []

  public currency: string
  public tpaCategories: ITpaCategory[]
  public selections: ITpaItemSelection[] = []
  public quoteData: Observable<QuoteChanged> = empty()
  public isAgent: boolean

  constructor(
    private resourceService: ResourceService,
    private cookieService: CookieService,
    private authService: AuthService,
    private stepService: StepService,
    private tpaService: TpaService,
    private hybridService: HybridService,
    private title: Title,
    private sbEvent: SbEvent,
    private quoteService: QuoteService,
    private layoutState: LayoutState,
    private trackingService: TrackingService,
    private loggingService: LoggingService,
    private userDetailsService: UserDetailsService
  ) {}

  ngOnInit() {
    this.isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
    let locale = this.cookieService.getCookie('sbw_Locale')

    if (locale == null) {
      locale = 'int'
    }

    const reservation = this.stepService.combineToReservation()
    this.resourceService
      .loadResourcesPromise(
        reservation.routeCode,
        locale,
        ['Common', 'Account', 'ArrangementOptions', 'Currency', 'Quote'].concat(this.isAgent ? ['Agent', 'MenuAgent'] : [])
      )
      .then(() => {
        this.resourceService.get('ARRANGEMENT_OPTIONS_TITLE', false).subscribe((s) => this.title.setTitle(s))
        this.tpaService.getTpaFlow().subscribe((response) => {
          this.currency = response.currency
          this.tpaCategories = response.categories
          this.buildSelections(response, reservation && reservation.tpaSelections)
          this.tpaService.setSelectionsOnViewModel(this.tpaCategories, this.selections)
          this.setCategoryOpenStateFromSelections(this.tpaCategories, this.selections)
          this.quoteData = of(this.prepareQuoteData(this.selections))

          this.layoutState.setIsContentLoaded(true)
        })
        this.trackingService.trackFlow(TrackingFlowStep.TPA, 'Extras', reservation, true)
      })
  }

  private buildSelections(tpaModel: ITpaModel, selectionsFromStorage: ITpaItemSelection[]) {
    let selections: ITpaItemSelection[] = selectionsFromStorage || tpaModel.selections || []
    let categories = tpaModel.categories

    categories
      .flatMap<ITpaCategory, ITpaGroupItem>((x) => x.tpaGroupItems)
      .flatMap<ITpaGroupItem, ITpaItem>((x) => x.tpaItems)
      .forEach((i) => {
        let s = selections.find((f) => f.id === i.id)
        if (!s) {
          let option = i.options && i.options.length !== 0 ? i.options[0].id : null
          selections.push(<ITpaItemSelection>{ id: i.id, count: i.maxNoOrder, optionId: option, tpaType: i.tpaType })
        }
      })
    this.selections = selections
  }

  private setCategoryOpenStateFromSelections(tpaCategories: ITpaCategory[], selections: ITpaItemSelection[]) {
    for (let category of tpaCategories) {
      category.tpaGroupItems
        .flatMap<ITpaGroupItem, ITpaItem>((x) => x.tpaItems)
        .forEach((item) => {
          if (Number(item.selectedCount) > 0) {
            category.isOpen = true
          }
        })
    }
  }

  private modelChange() {
    this.selections = this.tpaService.getSelections(this.tpaCategories)
    this.quoteData = of(this.prepareQuoteData(this.selections))
  }

  public continue() {
    this.layoutState.setIsContinueLoading(true)
    this.tpaService.postHybridTpa(this.selections).subscribe(
      (data) => {
        if (data.nextStepUrl === 'passengers' && !this.isAgent) {
          if (!this.authService.isLoggedIn()) {
            this.trackingService.saveFlowTrackingData(data.trackingData)
            this.quoteService.saveQuoteInSessionStorage(data.quote)
            this.layoutState.setIsContinueLoading(false)
            this.authService.initiateLogin(getPassengerRedirectUri())
          } else {
            this.userDetailsService.getUserDetails().subscribe((userDetails) => {
              this.trackingService.saveFlowTrackingData(data.trackingData)
              this.quoteService.saveQuoteInSessionStorage(data.quote)
              this.hybridService.changeWindowLocation(data.nextStepUrl)
              this.layoutState.setIsContinueLoading(false)
            })
          }
        } else {
          this.trackingService.saveFlowTrackingData(data.trackingData)
          this.quoteService.saveQuoteInSessionStorage(data.quote)
          this.hybridService.changeWindowLocation(data.nextStepUrl)
          this.layoutState.setIsContinueLoading(false)
        }
      },
      (err) => {
        this.layoutState.setIsContinueLoading(false)
        switch (err.status) {
          case 412: // PreconditionFailed
            let errors = err.error
            this.errorList = errors.errorList.map((e) => e.message)
            this.loggingService.logWarn({
              message: `Precondition on TPA was unmet: ${this.errorList.toString()}`, // This is changed to a warning as the known "error" is presented to the user
              path: window.location.pathname,
              query: window.location.search,
            })
            break
          default:
            throw err
        }
      }
    )
  }

  public navigateBack() {
    this.sbEvent.historyGoBack.next()
  }

  private prepareQuoteData(tpaItemSelection: ITpaItemSelection[]) {
    if (!tpaItemSelection) {
      return
    }
    return this.tpaService.getQuoteModel(this.tpaCategories, tpaItemSelection, this.currency)
  }
}
