import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { toParamsObj } from 'app/shared/utils';
import { StatelessHttpService } from 'app/shared/stateless-http.service'

@Injectable()
export class AddressService {

    constructor(
        private statelessHttpService: StatelessHttpService)
    { }

    findAddress(postalCode: string): Observable<IAddressSearchResult[]> {
        let params = {
            'country': 'GBR',
            'postalCode': postalCode
        }
        return this.statelessHttpService.get<IAddressSearchResult[]>(true, 'address/find', { params: toParamsObj(params) })
    }

    confirmAddress(moniker: string): Observable<IConfirmAddressResult> {
        let params = { 'moniker': moniker }
        return this.statelessHttpService.get<IConfirmAddressResult>(true, 'address/confirm', { params: toParamsObj(params) })
    }
}

export interface IAddressSearchResult {
    partialAddress: string
    moniker: string
}

export interface IConfirmAddressResult {
    line1: string
    line2: string
    town: string
    postalCode: string
}
