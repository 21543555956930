import { Injectable } from "@angular/core";
@Injectable()
export class ValidationModel {
    private validationErrorPool: ValidationError[] = []
    private validationErrors: ValidationError[] = []

    public get errorList(): string[] {
        return this.validationErrors.map(x => x.resourceText)
    }

    public getErrorListForNamespace(namespace: string) {
        return this.validationErrors.filter(x => x.namespace === namespace).map(x => x.resourceText)
    }

    public setError(key: string, namespace = '') {
        const foundValidationError = this.validationErrorPool.find(x => x.key === key && x.namespace === namespace)
        if (!foundValidationError) {
            throw new Error(`Validation message was not found for key "${key}" and namespace "${namespace}"`)
        }
        this.validationErrors.push({ ...foundValidationError })
    }

    public removeError(key: string, namespace = '') {
        const index = this.validationErrors.findIndex(x => x.key === key && x.namespace === namespace)
        if (index > -1) {
            this.validationErrors.splice(index, 1)
        }
    }

    public setValidationErrorPool(...args: ValidationError[]) {
        for (let arg of args) {
            this.addValidationErrorToPool(arg)
        }
    }

    public addValidationErrorToPool(validationError: ValidationError) {
        this.validationErrorPool.push(validationError)
    }
}

export class ValidationError {
    constructor(
        public key: string,
        public resourceText: string,
        public namespace = '',
    ) { }
}

