<div>
  <div class="header wrapper" [ngClass]="{ fixed: !contentLoaded }">
    <header class="row">
      <div class="column small-12">
        <a [href]="logoHref">
          <img class="sbw-logo" src="/_ng/booking/assets/logo.svg" title="SeaBookWeb" />
        </a>
      </div>
    </header>
  </div>
  <sbw-ship-loader *ngIf="!contentLoaded" [label]="getLoaderResource()"></sbw-ship-loader>
  <router-outlet></router-outlet>
  <sbw-chat></sbw-chat>
</div>
