import { Injectable } from '@angular/core'
import { MsalService } from '@azure/msal-angular'
import { CookieService } from 'app/shared/cookie.service'
import { delayPromise } from 'app/shared/utils'
import { getPathRedirectUri, msalAuthSettings } from './auth.config'

@Injectable()
export class AuthService {
  public userDetails: UserDetails = null
  public redirectObservableHandled: boolean = false

  constructor(
    private msalService: MsalService,
    private cookieService: CookieService,

  ) {
    // Get access token when logged in succesfully
    // Sets localstorage keys
    this.msalService.handleRedirectObservable().subscribe(async (response) => {
      this.redirectObservableHandled = false

      if (response != null && response.account != null) {
        this.msalService.instance.setActiveAccount(response.account)

        this.msalService
          .acquireTokenSilent({
            scopes: msalAuthSettings.scopes,
          })
          .subscribe((res: any) => {
            const dfdsId = res.idTokenClaims['extension_dfdsUserId']

            if (res.accessToken && dfdsId) {
              this.cookieService.setAuthType('Azure')
              sessionStorage.setItem('pax_Token', res.accessToken)
              sessionStorage.setItem('pax_Sub', dfdsId)
              this.redirectObservableHandled = true
            }
          })
      } else {
        this.redirectObservableHandled = true
      }
    })
  }

  public setAccessToken(redirectStartPage?: string | null): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.msalService
        .acquireTokenSilent({
          scopes: msalAuthSettings.scopes,
        })
        .subscribe(
          (res: any) => {
            const dfdsId = res.idTokenClaims['extension_dfdsUserId']

            if (res.accessToken && dfdsId) {
              this.cookieService.setAuthType('Azure')
              sessionStorage.setItem('pax_Token', res.accessToken)
              sessionStorage.setItem('pax_Sub', dfdsId)
              resolve()
            } else {
              sessionStorage.removeItem('pax_Token')
              sessionStorage.removeItem('pax_Sub')
              redirectStartPage ? this.initiateLogin(getPathRedirectUri(redirectStartPage)) : this.initiateLogin()
            }
          },
          (err) => {
            sessionStorage.removeItem('pax_Token')
            sessionStorage.removeItem('pax_Sub')
            redirectStartPage ? this.initiateLogin(getPathRedirectUri(redirectStartPage)) : this.initiateLogin()
          }
        )
    })
  }

  public initiateLogin = async (redirectStartPage?: string) => {
    let locale = this.getAzureADLocale()
    this.msalService.loginRedirect({
      scopes: msalAuthSettings.scopes,
      redirectUri: window.location.hostname === 'localhost' ? `https://localhost:7001/` : window.location.origin + '/redirect',
      redirectStartPage: redirectStartPage ?? window.location.href,
      extraQueryParameters: { ui_locales: locale ?? 'en' }
    })
  }

  public getUserDetails() {
    const { email } = this.msalService.instance.getActiveAccount().idTokenClaims
    return email
  }

  public waitForLogin(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      while (true) {
        await delayPromise(1000)
        if (this.redirectObservableHandled) {
          resolve(true)
        }
      }
    })
  }

  public initiateLogout() {
    sessionStorage.removeItem('pax_Token')
    sessionStorage.removeItem('pax_Sub')
    this.cookieService.deleteCookie('pax_AuthType')

    this.msalService.logout()
  }

  public isLoggedIn() {
    return this.msalService.instance.getActiveAccount() != null
  }

  private getAzureADLocale()
  {
    const locale = window && typeof window != 'undefined' ? window.location.pathname.split('/')[1] : 'en'
    const azureADLocales = ['sv-se', 'da-dk', 'fr-fr', 'de-de', 'nl-nl', 'nb-no', 'ru-ru', 'pl-pl', 'lt-lt', 'lv-lv', 'fi-fi', 'en-gb', 'et-ee']

    return azureADLocales.includes(locale.toLowerCase()) ? locale : 'en'
  }
}

export type UserDetails = {
  email: string
  dfdsUserId: string
}