import { Injectable } from '@angular/core'

import { Observable, forkJoin, concat, of, Subscription } from 'rxjs'
import { map, delay } from 'rxjs/operators';
import { DateTimeFormatter, LocalDate, convert as JsJodaConvert } from 'js-joda'

import { IInputCabinFaresGroup, ICabinGroup } from 'app/cabin-fares/cabin-fares-group/cabin-fares-group.component'
import { entries, flatMap } from '../shared/utils'
import { StepService } from '../shared/steps/step.service'
import { IFlowReservation } from '../shared/steps/step.model'
import { ContentfulSeabookWebService, FlowCabinFares } from 'app/shared/resources/contentful-seabookweb.service';
import { ICabinFaresWire, ICabinFaresDirectionWire, IWireLegSelection, IHybridResult, ICabinWire } from './cabin-fares-wire.interfaces';
import { ResourceService } from 'app/shared/resources/resource.service';
import { CabinDetailsService } from 'app/cabins/cabin-details/cabin-details.service';
import { Direction, ICabinFares, CabinFaresModelCache, ILegSelections, ILegSelection, ILegDates } from './cabin-fares.interfaces';
import { CabinFaresWireService } from './cabin-fares-wire.service';
import { CabinGroupCache } from 'app/cabins/cabin-group/cabin-group.cache';
import { Entry } from 'contentful';


@Injectable()
export class CabinFaresService {

    public cabinFaresCalendarCache: CabinFaresModelCache[] = <CabinFaresModelCache[]>[]
    private reservation: IFlowReservation
    private loadFromContentfulObservable: Promise<Entry<FlowCabinFares>>
    private preloader: Subscription

    constructor(
        private stepService: StepService,
        private cabinFaresWireService: CabinFaresWireService, 
        private contentfulSeabookWebService: ContentfulSeabookWebService, 
        private cabinDetailsService: CabinDetailsService, 
        private resourceService: ResourceService, 
        private cabinGroupCache: CabinGroupCache) {
    }

    public initialize()
    {
        this.reservation = this.stepService.combineToReservation()

        //This should only be loaded once, but getCabinFaresModel can be called multiple times - which is why this is in constructor
        this.loadFromContentfulObservable = this.contentfulSeabookWebService.loadFlowCabinFaresForRouteOrDefault(this.reservation.outboundRouteCode);
    }

    public getCabinFaresModel(outboundDepartureDate: LocalDate, returnDepartureDate: LocalDate): Observable<ICabinFares> {
        let loadFromSeabookObservable = this.getCabinFaresWireModel(outboundDepartureDate, returnDepartureDate)
        return forkJoin(loadFromSeabookObservable, this.loadFromContentfulObservable).pipe(map(([cabinFaresModel, flowCabinFaresContent]) => {
            this.formatDepartureTimes(cabinFaresModel, flowCabinFaresContent.fields)

            cabinFaresModel.flowCabinFaresContentfulEntry = flowCabinFaresContent
            cabinFaresModel.flowCabinFaresContent = flowCabinFaresContent.fields
            // load next days into cache
            let returnPreloadDays = cabinFaresModel.outbound.isMiniCruise ? 1 : 0
            if (this.preloader) this.preloader.unsubscribe()

            this.preloader = outboundDepartureDate && delay(50)(concat(
                this.getCabinFaresWireModel(outboundDepartureDate.plusDays(1), returnDepartureDate && returnDepartureDate.plusDays(returnPreloadDays)),
                this.getCabinFaresWireModel(outboundDepartureDate.plusDays(-1), returnDepartureDate && returnDepartureDate.plusDays(-returnPreloadDays)),
                returnDepartureDate ? this.getCabinFaresWireModel(outboundDepartureDate, returnDepartureDate.plusDays(1)) : of(<ICabinFares>null),
                returnDepartureDate ? this.getCabinFaresWireModel(outboundDepartureDate, returnDepartureDate.plusDays(-1)) : of(<ICabinFares>null)
            )).subscribe(_ => { /* puts data in browser cache */ })
            return cabinFaresModel
        }))
    }

    private flattenLegSelection(leg: ILegSelection): IWireLegSelection {
        return {
            departure: leg.departure,
            cabins: entries(leg.cabins)
                .filter(c => c.value != 0)
                .map(cabinType => ({
                    cabinCode: cabinType.key,
                    selection: cabinType.value
                }))
        };
    }

    postHybridCabinFares(a: ILegSelections): Observable<IHybridResult> {
        let res = {
            out: this.flattenLegSelection(a.out),
            return: a.return && this.flattenLegSelection(a.return)
        }
        return this.cabinFaresWireService.postCabinFaresFlow(res)
    }

    public getCabinFaresWireModel(outboundDepartureDate: LocalDate, returnDepartureDate: LocalDate): Observable<ICabinFares> {
        return this.cabinFaresWireService.getCabinFaresFlow(this.reservation, outboundDepartureDate, returnDepartureDate).pipe(map(wire => this.mapCabinFaresFlowToModel(wire)))
    }

    public mapCabinFaresFlowToModel(cabinFaresWire: ICabinFaresWire): ICabinFares {
        let isMiniCruise = cabinFaresWire.products.some(p => p.isMiniCruise)
        let cabinFaresModel = <ICabinFares>{
            outbound: this.mapCabinFaresDirectionRowToModel(cabinFaresWire.out, Direction.out, isMiniCruise),
            return: this.mapCabinFaresDirectionRowToModel(cabinFaresWire.return, Direction.return, isMiniCruise),
        }

        return cabinFaresModel
    }

    public mapCabinFaresDirectionRowToModel(cabinFaresDirectionWire: ICabinFaresDirectionWire, direction: Direction, isMiniCruise: boolean): IInputCabinFaresGroup {
        if (!cabinFaresDirectionWire)
            return null
        let model = <IInputCabinFaresGroup>{
            locale: this.resourceService.getLocale(),
            direction: direction,
            routeCode: cabinFaresDirectionWire.routeCode,
            departureDate: cabinFaresDirectionWire.dateOfDeparture,
            departureDateNative: JsJodaConvert(cabinFaresDirectionWire.dateOfDeparture).toDate(),
            dates: cabinFaresDirectionWire.dates && <ILegDates>{
                max: cabinFaresDirectionWire.dates.max,
                min: cabinFaresDirectionWire.dates.min,
                disabled: cabinFaresDirectionWire.dates.disabled || [],
                offer: cabinFaresDirectionWire.dates.offer || []
            },
            departures: cabinFaresDirectionWire.rows.map(r => ({
                departureTime: r.departureTime,
                ferryCode: r.ferryCode,
                ferryName: r.ferryName,
                departureId: r.departureId,
                cabinGroups: (isMiniCruise && direction == Direction.return) || !r.cabins ? [] : r.cabins.map(c => this.mapCabinGroup(c, direction))
            })),
            isMiniCruise: isMiniCruise,
            routeDescription: cabinFaresDirectionWire.rows[0] && cabinFaresDirectionWire.rows[0].route
        }

        this.loadCabinDetailsFromContentful(model)
        return model
    }

    private mapCabinGroup(cabinWire: ICabinWire, direction: Direction): ICabinGroup {
        return {
            cabinGroupCode: cabinWire.groupCode,
            cabinGroupDescription: cabinWire.groupDescription,
            inputCabinFaresSelectors: cabinWire.categories.map(categoryWire => ({
                cabinTypeCode: categoryWire.category,
                description: categoryWire.description,
                price: categoryWire.price,
                outboundPrice: categoryWire.outboundPrice,
                returnPrice: categoryWire.returnPrice,
                originalPrice: categoryWire.originalPrice,
                currency: categoryWire.currency,
                genericId: categoryWire.genericId,
                direction: direction,
                availableCapacity: categoryWire.availableCapacity,
                numberOfOccupiableBerths: categoryWire.numberOfOccupiableBerths,
                numberOfSuggested: categoryWire.numberOfSuggested
            }))
        }
    }

    public formatDepartureTimes(model: ICabinFares, flowCabinFaresContent: any) {
        let empty = { departures: [] }
        let formatter = DateTimeFormatter.ofPattern(flowCabinFaresContent.navigationDateFormat || "'at' HH:mm")
        let o = model.outbound || empty
        o.departures.concat((model.return || empty).departures).forEach(d => d.departureTimeFormatted = d.departureTime.format(formatter))
    }

    private loadCabinDetailsFromContentful(model: IInputCabinFaresGroup) {
        let cabinGroupCodes = flatMap(model.departures, dep => dep.cabinGroups).map(grp => grp.cabinGroupCode)
        let cabinTypeCodes = flatMap(flatMap(model.departures, dep => dep.cabinGroups), grp => grp.inputCabinFaresSelectors).map(sel => sel.cabinTypeCode)

        this.cabinDetailsService.loadCabinDetailsForCabinTypeCodes(cabinTypeCodes, model.routeCode).then(cabinDetailsList => {
            for (let contentfulCabinDetails of cabinDetailsList)
                for (let departure of model.departures)
                    for (let cabinGroup of departure.cabinGroups)
                        for (let cabin of cabinGroup.inputCabinFaresSelectors)
                            if (contentfulCabinDetails.cabinTypeCode == cabin.cabinTypeCode)
                                if (contentfulCabinDetails.contentfulEntry)
                                    cabin.cabinDetailsFromContentful = contentfulCabinDetails

        })

        this.cabinGroupCache.loadCabinGroupsForRouteOrDefault(cabinGroupCodes, model.routeCode).then(cabinGroupsFromContentful => {
            for (let cabinGroupFromContentful of cabinGroupsFromContentful)
                for (let departure of model.departures)
                    for (let cabinGroup of departure.cabinGroups)
                        if (cabinGroup.cabinGroupCode == cabinGroupFromContentful.cabinGroupCode)
                            cabinGroup.cabinGroupDetailsEntryFromContentful = cabinGroupFromContentful
        })

    }

}
