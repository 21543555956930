import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'sbw-travel-pets',
    templateUrl: './travel-pets.component.html',
    styleUrls: ['./travel-pets.component.css']
})
export class TravelPetsComponent {

    static idGenerator: number = 1
    id: string
    constructor() { this.id = "pets-" + TravelPetsComponent.idGenerator++ }

    @Input() petCount: FormControl
    @Input() petRange: number[]
    @Input() showPets: boolean
    @Input() fullWidth = false

}
