import { Injectable } from '@angular/core'
import { ContentfulSeabookWebService, CabinGroup } from 'app/shared/resources/contentful-seabookweb.service';

interface Cache<T> {
    [Key: string]: T;
}

@Injectable()
export class CabinGroupCache {

    constructor(private contentful: ContentfulSeabookWebService) { }

    cache: Cache<CabinGroup> = {}
    notExists: Cache<string> = {}

    public loadCabinGroupsForRouteOrDefault(cabinGroupCodes: string[], routeCode: string): Promise<CabinGroup[]> {
        let todo = cabinGroupCodes.filter(code => !this.cache[code] && !this.notExists[code])
        let cached = cabinGroupCodes.filter(code => this.cache[code]).map(c => this.cache[c])
        if (!todo.length) return Promise.resolve(cached)
        return this.contentful.loadCabinGroupsForRouteOrDefault(todo, routeCode)
            .then(r => {
                this.cache = Object.assign(this.cache, ...r.map(a => ({[a.cabinGroupCode]: a})))
                this.notExists = Object.assign(this.notExists, ...todo.filter(m => !this.cache[m]).map(m => ({[m]: 'not exists'})))
                return r.concat(cached)
            })
    }

}
