import { Component, Input, OnInit } from '@angular/core'
import { Location } from '@angular/common'

import { BreadcrumbService } from '../breadcrumb/breadcrumb.service'
import { FlowOrAmendment } from '../utils'
import { ResourceService } from '../resources/resource.service'

@Component({
  selector: 'sbw-top-box',
  templateUrl: './top-box.component.html',
  styleUrls: ['./top-box.component.css']
})
export class TopBoxComponent implements OnInit {
  public header: string

  @Input() type: FlowOrAmendment = 'Flow'

  constructor(
    private breadcrumbService: BreadcrumbService,
    private resourceService: ResourceService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.loadBreadcrumb().subscribe(data => {
      const compareUrl = location.pathname
      data.Items.forEach(b => b.ExternalUrl = this.location.prepareExternalUrl(b.Url))

      const lastEntryInPath = compareUrl.split('/').splice(-1)[0]

      if (lastEntryInPath === 'travel' || lastEntryInPath === 'agent' || compareUrl === '') {
        this.header = data.Items[0].Title
      } else if (lastEntryInPath === 'pets') {
        this.resourceService.get('PETS_HEADLINE', true, 'Pets').subscribe(res => {
          this.header = res
        })
      } else if (compareUrl.includes('/amendment')) {
        this.resourceService.get('TOP_BOX_HEADER', true, 'Amendment').subscribe(res => {
          this.header = res
        })
      }  else if ( compareUrl.includes('/error/sbw')) {
        this.resourceService.get('GLOBALERROR_HEADER', true, 'Common').subscribe(res => {
          this.header = res
        })
      } else {
        this.header = data.Items.find(x => x.ExternalUrl === compareUrl)?.Title
      }

      if (compareUrl.includes('/confirmation')) {
        this.resourceService.get('TITLE', true, 'Confirmation').subscribe(res => {
          this.header = res
        })
      }
      
    })
  }
}
