import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core'
import { Payload, LoggingService } from 'app/shared/logging.service'
import { Router, ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'
import StackTrace from 'stacktrace-js'
import { StorageService } from './shared/storage.service'
import { AuthService } from './auth/auth.service'
import { CookieService } from './shared/cookie.service'

declare const sbServerHint: string

enum HttpStatus {
  Unauthorized = 401,
  Forbidden = 403,
  InternalServerError = 500,
  SessionTimeout = 551,
}

class ErrorAction {
  static BaseErrorUrl = '/error/sbw'

  correlationId: string
  constructor(public handledAt: string, private _redirectUri: string, public useNgNavigation: boolean) {}

  get redirectUri() {
    if (this._redirectUri != ErrorAction.BaseErrorUrl) return this._redirectUri
    if (!this.correlationId) return ErrorAction.BaseErrorUrl
    return `${ErrorAction.BaseErrorUrl}?correlation=${this.correlationId}`
  }
}

@Injectable()
export class SbErrorHandler implements ErrorHandler {
  private router: Router

  constructor(
    private loggingService: LoggingService,
    private cookieService: CookieService,
    private injector: Injector,
    private ngZone: NgZone, // There is a bug in Angular. If ngZone is not used when navigate on router is used it behaves weird
    private location: Location,
    private storage: StorageService
  ) {}

  public get authService(): AuthService {
    return this.injector.get(AuthService)
  }

  public handleError(error) {
    // implemented to prevent all cancelled XHR --> 'response with status: 0  for url: null' to appear in log
    // note: must be changed when session on server dies as all pages in flow no longer will be reloaded
    const errorMessage = error?.message?.toString()?.toLowerCase()?.trim()
      if (
        errorMessage === 'response with status: 0  for url: null' || // Happens only in Firefox when ajax request are cancelled
        errorMessage === 'http failure response for (unknown url): 0 unknown error'
      ) {
        // After upgrading to 4.3.0 HttpClientModule is used instead of deprecated HttpModule, which caused above error to change to this
        return
      }
      if (errorMessage?.includes('monitor_window_timeout')) {
        // Above happens when callback hasn't happened within 6 seconds. It will not cause an error for the user.
        return
      }

    StackTrace.fromError(error)
      .then((a) => this.log(error, a.map((s) => s.toString()).join('\n')))
      .catch(() => this.log(error, error.stack))
  }

  private log(error, stacktrace) {
    const path = this.getPath()
    const action = this.determineAction(error, path)
    let payload = <Payload>{
      message: error && (error.message || error.toString()),
      path,
      query: window.location.search,
      stacktrace,
      steps: action.handledAt === 'spErrorPage' ? JSON.stringify(this.storage.getItem('sbw_Steps'), null, 2) : null, // we log this as string to ensure elastic don't choke on the value
    }
    if (!payload.message) payload.message = 'error message is null'
    let initialCorrelationId = this.getCorrelation(error) // this is only if the frontend failed because of a failed service call
    this.loggingService.logError(payload, initialCorrelationId).subscribe(
      (r) => this.handleAfterLog(error, r && r.CorrelationId, action), // the server return initialCorrelationId if it had a value or it will generate a new
      (_) => this.handleAfterLog(error, null, action)
    )
  }

  handleAfterLog(error, correlationId: string, action: ErrorAction) {
    action.correlationId = correlationId
    if (typeof console !== 'undefined' && console.error) {
      console.error('global error handler', error, action)
    }

    let url = window.location.search
    if (url.toLowerCase().indexOf('showerror=true') >= 0 || localStorage['showError'] === 'true') {
      console.log(error)
      document.write(error)
      return
    }

    if (action.redirectUri) {
      setTimeout(() => {
        if (action.useNgNavigation) {
          this.ngZone.run(() => {
            if (!this.router) {
              this.router = this.injector.get(Router)
            }
            this.router.navigateByUrl(action.redirectUri)
          })
        } else {
          window.location.href = action.redirectUri
        }
      }, 1000)
    }
  }

  //logs amendment/booking/123123/meals as amendment/booking/:reservationCode/meals
  private getPath() {
    try {
      return '/' + this.injector.get(ActivatedRoute).snapshot.firstChild.routeConfig.path
    } catch {
      return window.location.pathname
    }
  }

  private getCorrelation(error) {
    try {
      return error.headers.get('correlation')
    } catch {
      return null
    }
  }

  private determineAction(error, currentPath: string): ErrorAction {
    if (currentPath == ErrorAction.BaseErrorUrl) {
      return new ErrorAction('ignore', null, false)
    }
    if (error.status == HttpStatus.SessionTimeout) {
      const base = this.location.prepareExternalUrl('')
      return base === '/'
        ? new ErrorAction('restartFlow', '/', true) // just go to travel page
        : new ErrorAction('restartFlow', base.replace(/booking\/$/g, ''), false) // go from e.g. /da-dk/passager-faerger/booking/ to /da-dk/passager-faerger/
    }
    if (error.rejection && error.rejection.originalError && error.rejection.originalError.authError) {
      this.loggingService.logInfo({ message: 'Error: Rejection' }).subscribe((_) => {
        return
      })
    }
    if (error.status == HttpStatus.Forbidden) {
      this.loggingService.logInfo({ message: 'Error: Forbidden' }).subscribe((_) => {
        return
      })
    }
    if (error.status == HttpStatus.Unauthorized || error.message === 'No token for auth') {
      this.loggingService.logInfo({ message: 'Error: Unauthorized' }).subscribe((_) => {
        return
      })
    }
    if (error.status != null) {
      // CORS errors can set status 0
      return new ErrorAction('spErrorPage', ErrorAction.BaseErrorUrl, true)
    }

    return new ErrorAction('spErrorPage', ErrorAction.BaseErrorUrl, true)
  }
}
