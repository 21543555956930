import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ResourceService } from '../../shared/resources/resource.service'
import { QueryModel } from '../login/agent-login.models'
import { CookieService } from '../../shared/cookie.service'
import { SbEvent } from '../../shared/sb-event.emitter'
import { LayoutState } from 'app/shared/layout/layout-state'
import { FormControl } from '@angular/forms'
import { ButtonStateType } from 'app/shared/button/button.component'
import { Route, AgentProfileService, AgentProfileWire } from '../profile/agent-profile.service'
import { DateOfBirthSettings } from 'app/passengers/passengers.models'
import JSJoda, { LocalDate, Period, ChronoUnit, LocalDateTime } from 'js-joda'
import { IUserBooking } from 'app/agent/search/bookings.interfaces'
import { AgentSearchService } from './agent-search.service'

@Component({
  selector: 'agent-search',
  templateUrl: './agent-search.component.html',
  styleUrls: ['./agent-search.component.css'],
})
export class AgentSearchComponent implements OnInit {
  public selectedRoute: FormControl
  public timeframe: FormControl
  public dateFilter: FormControl
  public searchFilter: FormControl
  public formDateFrom: FormControl
  public formDateTo: FormControl
  public criteria: FormControl
  public resourcesLoaded: boolean = false
  public agentWire: AgentProfileWire
  public queryModel: QueryModel
  public dateOfBirthSettings: DateOfBirthSettings
  public numberOfBookingsToShow: number = 10
  public inputBookings: IAgentBooking[] = []
  public displayedBookings: IAgentBooking[]
  public submitButtonState: ButtonStateType = 'activated'
  public isAgent: boolean = true

  constructor(
    private resourceService: ResourceService,
    private route: ActivatedRoute,
    private layoutState: LayoutState,
    private cookieService: CookieService,
    private events: SbEvent,
    private agentProfileService: AgentProfileService,
    private agentSearchService: AgentSearchService
  ) {}

  ngOnInit() {
    this.agentProfileService.getAgentDetails(this.isAgent).subscribe((data) => {
      this.agentWire = data
      const locale = this.cookieService.getCookie('sbw_Locale')

      this.queryModel = this.getQueryModel()
      this.resourceService
        .loadResourcesPromise('Default', this.queryModel.locale, [
          'Amendment',
          'Agent',
          'MenuAgent',
          'AgentProduct',
          'AgentHome',
          'AgentSearch',
        ])
        .then(() => {
          const year = new Date().getFullYear()
          this.dateOfBirthSettings = new DateOfBirthSettings(null, locale || 'en', new Date('2000-01-01'), new Date(`${year + 3}-01-01`))
          this.selectedRoute = new FormControl('all')
          this.dateFilter = new FormControl('BookingDate')
          this.searchFilter = new FormControl('reservationNumber')
          this.criteria = new FormControl('')
          this.timeframe = new FormControl(-1)
          this.formDateFrom = new FormControl(LocalDate.now())
          this.formDateTo = new FormControl(LocalDate.now())
          this.onTimeframeChange()
          this.getBookings()
        })
    })
  }

  onTimeframeChange() {
    if (this.timeframe.value < 0) {
      this.formDateFrom.patchValue(LocalDate.now().minusDays(31))
      this.formDateTo.patchValue(LocalDate.now().plusDays(31))
    }
    if (this.timeframe.value > 0) {
      this.formDateFrom.patchValue(LocalDate.now().minusDays(this.timeframe.value))
      this.formDateTo.patchValue(LocalDate.now())
    }
  }

  getBookings() {
    this.submitButtonState = 'spinner'

    this.displayedBookings = []
    this.inputBookings = []

    const fromDate = this.formDateFrom.value.toString()
    const toDate = this.formDateTo.value.toString()

    let route = this.selectedRoute.value === 'all' ? '' : this.selectedRoute.value

    this.agentSearchService
      .getBookings(this.searchFilter.value, this.dateFilter.value, fromDate, toDate, route, this.criteria.value)
      .subscribe((data) => {
        if (data.length) {
          this.inputBookings = data.map(
            (d) =>
              <IAgentBooking>{
                status: d.statusCode,
                code: d.reservationCode,
                passenger: d.name,
                departure: d.departureDate,
                route: d.routeName,
                bookedOn: d.reservationDate,
                agentReference: d.agencyReference,
              }
          )
          this.displayedBookings = this.inputBookings.slice(0, this.numberOfBookingsToShow)
        }
        this.submitButtonState = 'activated'
        this.resourcesLoaded = true
        this.layoutState.setIsContentLoaded(true)
      })
  }

  hasMoreBookings() {
    return this.displayedBookings.length < this.inputBookings.length
  }

  showMore() {
    this.numberOfBookingsToShow = this.numberOfBookingsToShow + 10
    this.displayedBookings = this.inputBookings.slice(0, this.numberOfBookingsToShow)
  }

  getSpecificTimeframe() {
    const fromDate: LocalDate = this.formDateFrom.value
    const toDate: LocalDate = this.formDateTo.value
    return fromDate.until(toDate, ChronoUnit.DAYS)
  }

  routerAction(booking: IUserBooking): string {
    return `/amendment/booking/${booking.code}`
  }

  private getQueryModel(): QueryModel {
    let queryParams = this.route.snapshot.queryParams
    let salesOwner = this.getSalesOwner(queryParams)
    let locale = this.getLocale(queryParams)
    return new QueryModel(salesOwner, locale)
  }

  private getSalesOwner(queryParams): string {
    let salesOwner = queryParams['salesowner']

    if (typeof salesOwner === 'undefined') {
      salesOwner = this.cookieService.getCookie('sbw_SalesOwner')
    }
    return salesOwner
  }

  private getLocale(queryParams): string {
    let locale = queryParams['locale']

    if (typeof locale === 'undefined') {
      locale = this.cookieService.getCookie('sbw_Locale')
    }
    return locale
  }
}

export interface IAgentBooking {
  status: string
  code: string
  passenger: string
  departure: string
  route: string
  bookedOn: string
  agentReference: string
}
