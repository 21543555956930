import { Injectable } from '@angular/core';
import { DataLayerService } from './data-layer.service';
import { StatefulHttpService } from 'app/shared/stateful-http.service'

@Injectable({
    providedIn: 'root'
})
export class SessionPushService {

    constructor(private statefulHttpService: StatefulHttpService, private datalayer: DataLayerService) { }

    push(e) {
        this.getSession().subscribe(a => {
            e.session = a.session
            this.datalayer.push(e)
        })
    }

    getSession() {
        return this.statefulHttpService.post<ITrackingSession>(false, "tracking")
    }

}

interface ITrackingSession {
    session: string
}
