import { LocaleService } from './locale-service';
import { Injectable } from '@angular/core';
import { CookieService } from '../shared/cookie.service';
import { ResourceService } from './resources/resource.service';

@Injectable()
export class MarketService {
    constructor(private cookieService: CookieService,
        private localeService: LocaleService,
        private resourceService: ResourceService) { }


    public getMarket(): string
    {
        // if market parameter is specified, use market parameter
        let market = this.cookieService.getMarket()

        if (market)
            return market.toUpperCase()

        // map from salesowner (might be removed when/if dfds.com has implemented the market code parameter everywhere)
        return this.localeService.getCountryCodeForSalesOwner(this.resourceService.getSalesOwner())
    }

}
