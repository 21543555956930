import { Component, OnInit, Input } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ResourceService } from 'app/shared/resources/resource.service'
import { IValidationModel } from 'app/shared/utils'

@Component({
  selector: 'sbw-credit-cards',
  templateUrl: './credit-cards.component.html',
  styleUrls: ['./credit-cards.component.css'],
})
export class CreditCardsComponent implements OnInit {
  @Input() model: IInputCreditCard
  @Input() validationError: boolean
  creditCardForm: FormGroup

  constructor(private resourceService: ResourceService) {}

  ngOnInit() {
    if (this.model.creditCards === null) {
      return null
    }

    this.resourceService.get('SELECT').subscribe((data) => {
      if (typeof data === 'string' && this.model.creditCards[0].description != data) {
        this.model.creditCards.unshift(<ICreditCard>{ code: '', description: data })
      }

      this.model.creditCardCode = this.model.creditCardCode == null ? '' : this.model.creditCardCode // must check for both null  and undefined
    })

    this.creditCardForm = new FormGroup({
      creditCard: new FormControl(this.model.creditCardCode, Validators.required),
    })
    this.model.form.addControl('creditCards', this.creditCardForm)
  }
}

export interface IInputCreditCard {
  form: FormGroup
  creditCards: ICreditCard[]
  creditCardCode: string
}
export interface ICreditCard {
  code: string
  description: string
}
