<div class="row">
    <div *ngIf="!showPets">
        <h4 class="column small-12 label shared">
            <label [attr.for]="id">
                {{'PETS' | resource:'Travel'}}
            </label>
        </h4>
    </div>
    <div *ngIf="showPets" class="column small-12 xlarge-6" [ngClass]="{'large-6 medium-6': fullWidth}">
        <sbw-select-field name="petsCount" [control]="petCount" label="{{'PETS' | resource:'Travel'}}"
            [items]="petRange">
        </sbw-select-field>
    </div>
    <div *ngIf="showPets" class="column small-12 xlarge-12" [ngClass]="{'large-6 medium-6': fullWidth}">
      <sbw-information-popup text="{{ 'PETS_POP_UP' | resource }}" label="{{ 'PETS_NOTE' | resource }}"></sbw-information-popup>  
    </div>
    <div *ngIf="!showPets" class="column small-12">
      <sbw-information-popup text="{{ 'PETS_POP_UP' | resource }}" label="{{ 'PETS_TEASER' | resource }}"></sbw-information-popup>  
    </div>
</div>
