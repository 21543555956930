import { Injectable } from "@angular/core";
import { StatelessHttpService } from "app/shared/stateless-http.service";
import { ResourceService } from "app/shared/resources/resource.service";
import { toParamsObj } from "app/shared/utils";
import { Observable, of } from 'rxjs';

@Injectable()
export class AgentProfileService {
    constructor(
        private statelessHttpService: StatelessHttpService,
        private resourceService: ResourceService
    ) { }

    getAgentDetails(isAgent: boolean): Observable<AgentProfileWire> {
        if (!isAgent) return of<AgentProfileWire>(null);

        const agentGenericId = localStorage.getItem("sbw_AgentGenericId");

        let params = {
            salesOwner: this.resourceService.getSalesOwner(),
            locale: this.resourceService.getLocale(),
            agentGenericId: agentGenericId,
        };

        return this.statelessHttpService.get<AgentProfileWire>(
            true,
            "agent/profile",
            {
                params: toParamsObj(params),
            }
        );
    }

    savePreferedRoute(preferedRouteCode: string): Observable<any> {
        let body = {
            AgentGenericId: localStorage.getItem("sbw_AgentGenericId"),
            PreferredRoute: preferedRouteCode.toUpperCase(),
        };
        return this.statelessHttpService.post<any>(true, "agent/profile", body);
    }
}

export interface AgentProfileWire {
    agentDetails: AgentDetails;
    outboundRoutes: Route[];
}

export interface Route {
    name: string;
    code: string;
}

export interface AgentDetails {
    genericId: string;
    agentId: string;
    agentReference: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    company: string;
    country: string;
    email: string;
    fax: string;
    firstName: string;
    lastName: string;
    phone: string;
    postalCode: string;
    travelAgentIdPart: string;
    preferredRouteCode: string;
}
