import { Component, Input, OnInit, Output } from '@angular/core'
import { IFlowOrAmendment, FlowOrAmendment, ensureFlowOrAmendment } from 'app/shared/utils'
import { FormGroup } from '@angular/forms'
import { Validators } from '@angular/forms'
import { IInputFindAddressModel } from 'app/passengers/find-address/find-address.component'
import { IConfirmAddressResult, AddressService } from 'app/passengers/find-address/address.service'
import { FormBuilderTyped } from 'app/shared/form-builder-typed';
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'sbw-edit-user-details',
    templateUrl: './edit-user-details.component.html',
    styleUrls: ['./edit-user-details.component.css']
})
export class EditUserDetailsComponent implements OnInit, IFlowOrAmendment {
    @Input() mode: FlowOrAmendment
    @Input() model: IInputEditUser
    @Input() validationError: boolean
    @Output() editDetailsEvent: EventEmitter<IEditDetaisChangedEvent> = new EventEmitter<IEditDetaisChangedEvent>()

    @Input() isInEditMode: boolean
    @Output() isInEditModeChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    form: FormGroup
    inputFindAddressModel: IInputFindAddressModel
    showAddresses = false

    get formTitle() {
        return this.form.get('titleCode')
    }

    get formFirstName() {
        return this.form.get('firstName')
    }

    get formLastName() {
        return this.form.get('lastName')
    }

    get formCountry() {
        return this.form.get('countryCode')
    }

    get formPostalCode() {
        return this.form.get('postalCode')
    }

    get formCity() {
        return this.form.get('city')
    }

    get formAddressLine1() {
        return this.form.get('addressLine1')
    }

    get formAddressLine2() {
        return this.form.get('addressLine2')
    }

    get formPhone() {
        return this.form.get('phone')
    }

    constructor(
        private addressService: AddressService,
        private fb: FormBuilderTyped
    ) { }

    ngOnInit() {
        ensureFlowOrAmendment(this.mode)
        this.inputFindAddressModel = {
            addresses: []
        }
        if (this.mode === 'Flow') {
            this.useFlow()
        } else if (this.mode === 'Amendment') {
            this.useAmendment()
        }
    }
    useFlow() {
        const { id, titleCode, firstName, lastName, countryCode, postalCode, city, addressLine1, addressLine2, email, phonePrefix, phone } = this.model.userDetails || <any>{}
        const { titles, countries, phonePrefixes } = this.model

        this.form = this.fb.group<IUserDetails>({
            id: this.fb.control(id),
            titleCode: this.fb.control(titleCode || titles[0].code),
            firstName: this.fb.control(firstName, [Validators.required, Validators.maxLength(50)]),
            lastName: this.fb.control(lastName, [Validators.required, Validators.maxLength(50)]),
            countryCode: this.fb.control(countryCode || countries[0].code, [Validators.required]),
            postalCode: this.fb.control(postalCode, [Validators.required, Validators.maxLength(10)]),
            city: this.fb.control(city, [Validators.required, Validators.maxLength(50)]),
            addressLine1: this.fb.control(addressLine1, [Validators.required, Validators.maxLength(50)]),
            addressLine2: this.fb.control(addressLine2),
            email: this.fb.control({ value: email, disabled: true }),
            phonePrefix: this.fb.control(phonePrefix || phonePrefixes[0]),
            phone: this.fb.control(phone, [Validators.required, Validators.maxLength(25)]),
        })
        this.model.form.addControl('userDetails', this.form)
        this.formTitle.valueChanges.subscribe(x => this.editDetailsEvent.emit({ title: x, firstName: this.formFirstName.value, lastName: this.formLastName.value }))
        this.formFirstName.valueChanges.subscribe(x => this.editDetailsEvent.emit({ title: this.formTitle.value, firstName: x, lastName: this.formLastName.value }))
        this.formLastName.valueChanges.subscribe(x => this.editDetailsEvent.emit({ title: this.formTitle.value, firstName: this.formFirstName.value, lastName: x }))
        setTimeout(() => this.isInEditModeChange.emit(this.form.invalid))
    }
    useAmendment() {
        throw new Error('Method not implemented.')
    }

    public findAddresses() {
        if (this.formPostalCode.value === null || this.formPostalCode.value.trim() === '') { return }

        this.addressService.findAddress(this.formPostalCode.value).subscribe(addressSearchResult => {
            if (addressSearchResult === null || addressSearchResult.length === 0) { return }
            if (addressSearchResult.length === 1 && addressSearchResult[0].partialAddress.trim() === '') { return }

            this.inputFindAddressModel.addresses = addressSearchResult
            this.showAddresses = true
        })
    }

    public addressChanged(changed: IConfirmAddressResult) {
        this.formAddressLine1.setValue(changed.line1)
        this.formAddressLine2.setValue(changed.line2)
        this.formCity.setValue(changed.town)
        this.formPostalCode.setValue(changed.postalCode)
        this.showAddresses = false
    }

}

export interface IEditDetaisChangedEvent {
    title: string
    firstName: string
    lastName: string
}

export interface IInputEditUser {
    form: FormGroup
    userDetails: IUserDetails
    countries: ICountry[]
    titles: ITitle[]
    phonePrefixes: string[]
}

export interface IUserDetails {
    id: string
    titleCode: string
    firstName: string
    lastName: string
    countryCode: string
    postalCode: string
    city: string
    addressLine1: string
    addressLine2: string
    phonePrefix: string
    phone: string
    email: string
}

export interface ICountry {
    code: string
    description: string
}

export interface ITitle {
    code: string
    description: string
}
