import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'
import { IReservation } from 'app/shared/models/reservation.interfaces';
import { StatelessHttpService } from '../stateless-http.service';

@Injectable()
export class ReservationService {

    constructor(
        private statelessHttpService: StatelessHttpService) {
    }

    public getReservation(reservationCode: string): Observable<IReservation> {
        return this.statelessHttpService.get<IReservation>(true, `reservation/${reservationCode}/authorized`)
    }

    public getReservationNoCache(reservationCode: string): Observable<IReservation> {
        let headers = { 'Cache-Control': 'no-cache' }
        return this.statelessHttpService.get<IReservation>(true, `reservation/${reservationCode}/authorized`, { headers })
    }
}
