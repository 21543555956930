import { Component, OnInit, Input } from '@angular/core'

import { IPriceItem, IReservation } from '../../../shared/models/reservation.interfaces'

@Component({
    selector: 'sbw-price-breakdown',
    templateUrl: './price-breakdown.component.html',
    styleUrls: ['./price-breakdown.component.css']
})
export class PriceBreakdownComponent implements OnInit {
    @Input() reservation: IReservation
    @Input() priceItems: IPriceItem[]

    private stateEnum = PriceBreakDownItemState

    private originalItems: IPriceBreakdownItem[]
    private snapshotItems: IPriceBreakdownItem[]
    public priceBreakdownItems: IPriceBreakdownItem[]
    public currencyCode: string
    public totalPrice: number
    public remainingAmount: number
    public isCreditAgent: boolean

    constructor() { }

    ngOnInit() {
        this.currencyCode = this.reservation.currencyCode
        this.totalPrice = this.reservation.totalPrice
        this.remainingAmount = this.reservation.remainingAmount
        this.priceBreakdownItems = this.getPriceBreakdownItems(this.reservation.priceItems, this.priceItems ? this.priceItems : [])
        this.setIsReplace(this.priceBreakdownItems)
        this.isCreditAgent = this.reservation.agentType.toLowerCase() === 'credit'
    }

    setIsReplace(priceBreakdownItems: IPriceBreakdownItem[]) {
        for (let i = 0; i < priceBreakdownItems.length; i++) {
            const item = priceBreakdownItems[i]

            if (item.state === PriceBreakDownItemState.Removed) {
                const nextItem = i >= priceBreakdownItems.length ? null : priceBreakdownItems[i + 1]
                if (nextItem) {
                    item.isReplace = nextItem.state === PriceBreakDownItemState.Added
                }
            }
        }
    }

    private isMiniCruise(originalPriceItems: IPriceBreakdownItem[]): boolean {
        return originalPriceItems.every(s => !s.priceItem.departureId)
    }

    public getPriceBreakdownItems(originalPriceItems: IPriceItem[], snapshotPriceItems?: IPriceItem[]): IPriceBreakdownItem[] {
        this.originalItems = originalPriceItems.map(item => (<IPriceBreakdownItem>{ priceItem: item, showPrice: item.showPrice, state: PriceBreakDownItemState.Untouched }))
        this.snapshotItems = snapshotPriceItems.map(item => (<IPriceBreakdownItem>{ priceItem: item, showPrice: item.showPrice, state: PriceBreakDownItemState.Untouched }))
        if (this.snapshotItems.length === 0) {
            return this.originalItems
        }
        return this.isMiniCruise(this.originalItems) ?
            this.handleItems(this.findMiniCruise) :
            this.handleItems(this.findOther)
    }

    private findOther(original: IPriceBreakdownItem, snapshot: IPriceBreakdownItem): boolean {
        return original.priceItem.id === snapshot.priceItem.id && original.priceItem.departureId === snapshot.priceItem.departureId
    }

    private findMiniCruise(original: IPriceBreakdownItem, snapshot: IPriceBreakdownItem): boolean {
        return original.priceItem.id === snapshot.priceItem.id
    }

    private isAmendableItem(item: IPriceBreakdownItem): boolean {
        return !!item.priceItem.id && !item.priceItem.id.startsWith('Descr|')
    }

    private handleItems(comparer: (original: IPriceBreakdownItem, snapshot: IPriceBreakdownItem) => boolean): IPriceBreakdownItem[] {
        this.snapshotItems.forEach(snapItem => {
            if (!this.isAmendableItem(snapItem)) {
                snapItem.state = PriceBreakDownItemState.Unchanged
            } else {
                let exists = this.originalItems.find(f => comparer(f, snapItem))
                if (exists) {
                    if (snapItem.priceItem.price !== exists.priceItem.price) {
                        exists.state = PriceBreakDownItemState.PriceChanged
                        exists.previousPrice = exists.priceItem.price
                        exists.priceItem = snapItem.priceItem
                    } else if (snapItem.priceItem.label !== exists.priceItem.label) {
                        exists.state = PriceBreakDownItemState.Removed
                        this.addNewItem(this.originalItems, snapItem)
                    } else {
                        snapItem.state = PriceBreakDownItemState.Unchanged
                        exists.state = PriceBreakDownItemState.Unchanged
                    }
                } else {
                    this.addNewItem(this.originalItems, snapItem)
                }
            }
        })

        this.originalItems.filter(f => f.state === PriceBreakDownItemState.Untouched && this.isAmendableItem(f)).forEach(item => item.state = PriceBreakDownItemState.Removed)
        return this.originalItems
    }

    private addNewItem(originalPriceItems: IPriceBreakdownItem[], snapItem: IPriceBreakdownItem) {
        let lastIndex = this.getIndexInPriceItemList(originalPriceItems, snapItem.priceItem.departureId)
        snapItem.state = PriceBreakDownItemState.Added
        originalPriceItems.splice(lastIndex, 0, snapItem)
    }

    private getIndexInPriceItemList(priceItemsWrappers: IPriceBreakdownItem[], departureId: string) {
        let foundIndex = -1;
        if (!departureId) {
            return foundIndex
        }

        for (let i = 0; i < priceItemsWrappers.length; i++) {
            const item = priceItemsWrappers[i].priceItem
            if (item.departureId && item.departureId.toLowerCase() === departureId.toLowerCase()) {
                foundIndex = i
            }
        }
        return foundIndex === -1 ? priceItemsWrappers.length : foundIndex + 1;
    }

}

export enum PriceBreakDownItemState {
    Unchanged = 1,
    Added = 2,
    Removed = 3,
    PriceChanged = 4,
    Untouched = 5
}

export interface IPriceBreakdownItem {
    priceItem: IPriceItem
    showPrice: boolean
    isReplace: boolean
    state?: PriceBreakDownItemState
    previousPrice?: PriceBreakDownItemState
}

