<sbw-layout [backButtonVisible]="false" [isAgent]="isAgentRoute" [quoteModel]="quoteData" [quoteShowTotalPrice]="false"
    [quoteShowDepartureTime]="false" [form]="travelForm" quoteStep="Travel" (continueEvent)="continue()">
    <div class="sbw_pagebox">
        <div class="row uncollapse travel-container">
            <div class="row">
                <div *ngIf="isAgentRoute && travelForm" class="column small-12 large-5 right-border">
                    <h1>{{ "AGENT_PRODUCTS_AVAILABLE_TITLE" | resource }}</h1>
                    <sbw-travel-agent-product [currentCode]="outboundRouteCode.value"
                        (productSelected)="code.setValue($event)"></sbw-travel-agent-product>
                </div>
                <div class="column small-12" [ngClass]="{ 'large-7 left-border': isAgentRoute }">
                    <h1>{{ "DATES_ROUTES" | resource }}</h1>
                    <div class="row">
                        <div class="column small-12">
                            <sbw-validation-summary [errorList]="errorList"></sbw-validation-summary>
                        </div>
                    </div>
                    <div *ngIf="travelForm">
                        <div class="row">
                            <div class="column small-12">
                                <sbw-travel-departure [content]="travelContent"
                                    [formDepartureSelections]="departureSelections"
                                    [outboundRoute]="travelWire.departureOut" [returnRoute]="travelWire.departureReturn"
                                    [datePickerInfoLabel]="datePickerInfoLabel"></sbw-travel-departure>
                            </div>
                        </div>

                        <div class="row">
                            <div class="column small-12">
                                <h1 class="middle">
                                    {{ "PASSENGER_PET" | resource }}
                                </h1>
                                <sbw-travel-passengers [giveAttentionChildAges]="giveAttentionChildAges"
                                    [passengerRanges]="passengerRanges" [passengerSelections]="passengerSelections"
                                    [showPets]="showPets"></sbw-travel-passengers>
                            </div>
                        </div>

                        <div *ngIf="vehicleSelections.enabled" class="row">
                            <div class="column small-12">
                                <h1 class="middle">
                                    {{ "VEHICLE_TYPE" | resource }}
                                </h1>
                                <div class="row">
                                    <sbw-vehicles-selector [formVehicleSelector]="vehicleSelections"
                                        [vehicles]="travelWire.vehicles"
                                        [supportMultiple]="supportMultipleVehicles"
                                        [errorVehicle]="frontendErrors.pets.active"
                                        [errorTrailer]="frontendErrors.trailer.active"></sbw-vehicles-selector>
                                </div>
                            </div>
                            <div class="column small-12" *ngIf="'VEHICLE_POP_UP' | resource:'Travel':false">
                                <sbw-information-popup text="{{ 'VEHICLE_POP_UP' | resource }}" label="{{ 'VEHICLE_NOTE' | resource }}"></sbw-information-popup>
                            </div>
                        </div>

                        <sbw-travel-code [control]="code" [codeStatus]="codeStatus" heading="{{ 'OFFER_CODE' | resource }}" ></sbw-travel-code>
                        <div className="row">
                            <div className="column large-12" style="margin-top: 16px;">
                              <sbw-information-popup text="{{ 'OFFER_POP_UP' | resource }}" label="{{ 'OFFER_NOTE' | resource }}"></sbw-information-popup>
                            </div>
                        </div>
                        <br>

                        <div *ngIf="isAgentRoute" class="row">
                            <div class="column small-12 large-6 large-offset-6 agent-button gutter">
                                <sbw-button id="SubmitContinue" type="preferred" [(state)]="buttonState"
                                    [text]="'CONTINUE' | resource" (clicked)="continue()"></sbw-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</sbw-layout>
