<div *ngIf="items" class="row small-gutter">
  <h4 *ngIf="label" class="column label">
    <label>
      {{label}}
    </label>
  </h4>
  <div class="column">
    <p-dropdown 
      id={{name}}
      [(ngModel)]="selectedItem" 
      (ngModelChange)="onInputChange($event)" 
      [options]="items" 
      optionLabel="name" 
      [autoWidth]="false" 
      styleClass="p-dropdown-override"
    ></p-dropdown>
  </div>
</div>

