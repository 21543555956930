import { Component, OnInit } from '@angular/core'
import { ResourceService } from 'app/shared/resources/resource.service'
import { LayoutState } from 'app/shared/layout/layout-state'
import { ActivatedRoute } from '@angular/router'
import { CookieService } from 'app/shared/cookie.service'
import { SbEvent } from 'app/shared/sb-event.emitter'
import { PetsService } from '../pets.service'
import { IPetsSelection, Pet, PetAndCountModel, PetsPostWire, PetsWire } from '../pets.interface'
import { IFlowReservation } from 'app/shared/steps/step.model'
import { StepService } from 'app/shared/steps/step.service'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { empty, Observable, of } from 'rxjs'
import { QuoteChanged } from 'app/shared/quote/quote.events'
import { QuoteService } from 'app/shared/quote/quote.service'
import { HybridService } from 'app/shared/hybrid/hybrid.service'
import { TrackingService } from 'app/shared/tracking/tracking.service'

@Component({
  selector: 'sbw-pets',
  templateUrl: './pets.component.html',
  styleUrls: ['./pets.component.css'],
})
export class PetsComponent implements OnInit {
  public pets: Pet[]
  public isAgent: boolean
  public isValid: boolean = true
  public petsValidationMessage: string
  public errorList = []
  private queryModel: QueryModel
  private reservation: IFlowReservation
  public petsForm: FormGroup
  public petsModel: IPetsSelection
  public quoteData: Observable<QuoteChanged> = empty()

  get petSelections(): FormArray {
    return this.petsForm.get('selections') as FormArray
  }

  get selections(): PetAndCountModel[] {
    return this.petsForm.get('selections').value
  }

  constructor(
    private sbEvent: SbEvent,
    private resourceService: ResourceService,
    private layoutState: LayoutState,
    private hybridService: HybridService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private cookieService: CookieService,
    private trackingService: TrackingService,
    private stepService: StepService,
    private petsService: PetsService,
    private quoteService: QuoteService
  ) {}

  ngOnInit() {
    this.isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
    this.reservation = this.stepService.combineToReservation()
    this.queryModel = this.getQueryModel()

    this.petsService.getPetsFlow().subscribe((response) => {
      this.initForm(response.pets)

      this.resourceService
        .loadResourcesPromise(
          'Default',
          this.queryModel.locale,
          ['Pets', 'Menu', 'Account', 'Common', 'Currency', 'Quote'].concat(this.isAgent ? ['Agent', 'MenuAgent'] : [])
        )
        .then(() => {
          this.resourceService.get('VALIDATION_TOO_MANY_PETS', true, 'Pets').subscribe((x) => {
            const resource = x.replace('%count%', this.reservation.pets.toString())
            this.petsValidationMessage = resource
          })
          this.layoutState.setIsContentLoaded(true)
        })
    })
  }

  initForm(pets: Pet[]) {
    if (!pets) return null

    const step = this.stepService.getStep('/pets')
    const oldSelections = step && step.petsSelection.value

    const selections = (oldSelections || pets.map((pet) => ({ pet, count: 0 }))).map((a) => ({ ...a, options: this.addOptions(a) }))

    this.petsForm = this.formBuilder.group({
      selections: this.formBuilder.array(
        selections.map(
          (p) => new FormGroup({ count: new FormControl(p.count), pet: new FormControl(p.pet), options: new FormControl(p.options) })
        )
      ),
    })

    this.petsForm.valueChanges.subscribe((x) => {
      this.onPetsChange()
    })

    this.quoteData = of(this.prepareQuoteData(selections))
  }

  addOptions(selection: { pet: Pet; count: number }) {
    const maxCount = Math.min(selection.pet.availableCapacity, selection.pet.maxCapacity)
    var options = [0]

    for (var i = 1; i <= maxCount; i++) {
      options.push(i)
    }

    return options
  }

  getSelection(id: string) {
    return this.petsForm.value.selections.find((p) => p.pet.id === id).count
  }

  checkValidation() {
    const selections = this.selections.filter((p) => p.count > 0)
    let totalPets: number = 0
    selections.forEach((p) => {
      totalPets = totalPets + p.count
    })
    this.isValid = totalPets <= this.reservation.pets
  }

  isOneway() {
    return this.reservation.returnDepartureId == undefined ? true : false
  }

  onPetsChange() {
    this.checkValidation()
    this.quoteData = of(this.prepareQuoteData(this.selections))
  }

  private prepareQuoteData(selections: PetAndCountModel[]) {
    if (!selections) {
      return
    }
    return this.petsService.getQuoteModel(selections, this.reservation.currencyCode, this.isOneway())
  }

  private getSalesOwner(queryParams): string {
    let salesOwner = queryParams['salesowner']

    if (typeof salesOwner === 'undefined') {
      salesOwner = this.cookieService.getCookie('sbw_SalesOwner')
    }
    return salesOwner
  }

  private getQueryModel(): QueryModel {
    let queryParams = this.route.snapshot.queryParams
    let salesOwner = this.getSalesOwner(queryParams)
    let locale = this.getLocale(queryParams)
    return new QueryModel(salesOwner, locale)
  }

  private getLocale(queryParams): string {
    let locale = queryParams['locale']

    if (typeof locale === 'undefined') {
      locale = this.cookieService.getCookie('sbw_Locale')
    }
    return locale
  }

  public navigateBack() {
    this.sbEvent.historyGoBack.next()
  }

  public continue() {
    this.layoutState.setIsContinueLoading(true)

    const petsWire: PetsPostWire = {
      salesOwnerId: this.getSalesOwner(this.queryModel),
      selections: this.selections,
      productCode: this.reservation.productCode,
      outboundDepartureId: this.reservation.outboundDepartureId,
      returnDepartureId: this.reservation.returnDepartureId,
      isOneway: this.isOneway(),
    }

    this.petsService.postHybridPets(petsWire).subscribe(
      (data) => {
        this.trackingService.saveFlowTrackingData(data.trackingData) // What to do about tracking
        this.quoteService.saveQuoteInSessionStorage(data.quote)
        this.hybridService.changeWindowLocation(data.nextStepUrl)

        this.layoutState.setIsContinueLoading(false)
        this.layoutState.setIsContentLoaded(false)
      },
      (err) => {
        this.layoutState.setIsContentLoaded(true)
        switch (err.status) {
          case 412: // PreconditionFailed
            let errors = err.error
            this.errorList = errors.errorList.map((e) => e.message)
            break
          default:
            throw err
        }
      }
    )
  }
}

export class QueryModel {
  constructor(public salesOwner: string, public locale: string) {}
}
