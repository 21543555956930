import moment from 'moment'
import md5 from 'md5'
import { AbstractControl, FormArray, Validators } from '@angular/forms'
import { HttpParams } from '@angular/common/http'
import { LocalTime, ZonedDateTime, LocalDateTime, LocalDate } from 'js-joda'
import { MonoTypeOperatorFunction } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'

export function flatMap<T, U>(array: T[], callbackfn: (value: T, index: number, array: T[]) => U[]): U[] {
    return [].concat(...array.map(callbackfn));
}

export function toObj<T, U>(array: T[], keyfn: (value: T) => string, valuefn: (value: T) => U): { [key: string]: U; } {
    let res: { [key: string]: U; } = {}
    array.forEach(a => res[keyfn(a)] = valuefn(a))
    return res
}

export function entries<U>(obj: { [k: string]: U }): { key: string; value: U }[] {
    return Object.keys(obj).map(k => ({ key: k, value: obj[k] }))
}

export function formatDate(datetime: string): string {
    return moment(Date.parse(datetime)).format('DD-MM-YYYY');
}

export function formatTime(datetime: string): string {
    return moment(Date.parse(datetime)).format('HH:mm');
}

export function range(count): number[] {
    let res = []
    for (let i = 0; i < count; i++)res.push(i + 1)
    return res
}

// We just do the formatting ourselves, since i18n support seems pretty poor atm and would require polyfills
// https://github.com/angular/angular/issues/3333
export function format(format: string, value: string, locale: string): string {
    let digitsMatch = format.match(/^N(\d)$/);
    if (digitsMatch) {
        let parsed = parseFloat(value);
        if (!isNaN(parsed)) {
            let fixed = parsed.toFixed(parseInt(digitsMatch[1]));
            return locale === 'en' ? fixed : fixed.replace('.', ',');
        };
    }
    return null;
}

export function nullIfBlank(t: string) {
    return t == null || t.match(/^\s*$/) ? null : t
}

const dateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})/

export function withDatesConverted(input) {
    convertDateStringsToDates(input)
    return input
}

export function convertDateStringsToDates(input) {
    // Ignore things that aren't objects.
    if (typeof input !== 'object') { return };

    for (let key in input) {
        if (!input.hasOwnProperty(key)) { continue };

        let value = input[key];
        // Check for string properties which look like dates.
        if (typeof value === 'string') {
            if (value.match(dateFormat)) {
                let date = new Date(value)
                if (!isNaN(date.getTime())) {
                    input[key] = date
                    input[key + 'Str'] = value
                }
            }
        } else if (typeof value === 'object') {
            // Recurse into object
            convertDateStringsToDates(value);
        }
    }
}

export function isPrimitiveValue(value: any) {
    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        return true
    }
    return false
}

export function hashObject(target: object): string {
    if (typeof target !== 'object') { throw new Error('provide an object') }
    return md5(JSON.stringify(target))
}

export function arrayObjectComparerMatcher<T>(a: T[], b: T[], matcherFn: (a: T, b: T) => boolean, elementIgnoreMatcher?: (a: T) => boolean): boolean {
    let uniqA = a.filter((aObj) => !b.some((bObj) => matcherFn(aObj, bObj)))
    let uniqB = b.filter((bObj) => !a.some((aObj) => matcherFn(bObj, aObj)))
    if (uniqA.length === 0 && uniqB.length === 0) { return false }
    if (!elementIgnoreMatcher) { return true }
    let zeroA = uniqA.every(elementIgnoreMatcher)
    let zeroB = uniqB.every(elementIgnoreMatcher)
    return !(zeroA && zeroB)
}

export function sameDate(d1: Date, d2: Date): boolean {
    if (d1 == d2) return true
    if (!d1 || !d2) return false
    return d1.getFullYear() == d2.getFullYear() && d1.getMonth() == d2.getMonth() && d1.getDate() == d2.getDate()
}

export function sameDays(d1: LocalDate, d2: LocalDate) {
    if (d1 == null && d2 == null) return true
    if (d1 == null || d2 == null) return false
    return d1.isEqual(d2)
}

export function strEqualIgnoreCase(str1: string, str2: string) {
    if (str1 == str2) return true
    if (str1 == null || str2 == null) return false
    return str1.toUpperCase() == str2.toUpperCase()
}

export function findNested<TOuter, TInner>(outer: TOuter[], innerSelector: (outer: TOuter) => TInner[], predicate: (inner: TInner) => boolean) {
    let result = {}
    for (let o of outer) {
        let inner = innerSelector(o)
        for (let i of inner) {
            if (predicate(i)) {
                return {
                    outer: o,
                    inner: i
                }
            }
        }
    }
}

export function sortNumberAscending(a: number, b: number) {
    if (a < b) { return -1 }
    if (a > b) { return 1 }
    return 0
}

export function sortNumberDescending(a: number, b: number) {
    if (a < b) { return 1 }
    if (a > b) { return -1 }
    return 0
}

export interface IValidationModel {
    form: AbstractControl
}

export type FlowOrAmendment = 'Flow' | 'Amendment'

export function ensureFlowOrAmendment(value: any): void {
    if (value === 'Flow' || value === 'Amendment') {
        return
    }
    throw new Error(`Unknown mode: ${value}`)
}
export interface IFlowOrAmendment {
    readonly mode: FlowOrAmendment
    useFlow()
    useAmendment()
}
export const propertyNamesOf = <TObj>() => (name: keyof TObj) => name
export const propertyOf = <TObj>(name: keyof TObj) => name

export function addToFormArray(source: AbstractControl, target: AbstractControl): boolean {
    if (source && 'push' in source) {
        (<FormArray>source).push(target)
        return true
    }
    return false
}

export function removeLastFormArray(source: AbstractControl): boolean {
    if (source && 'removeAt' in source) {
        const formArray = <FormArray>source
        formArray.removeAt(formArray.length - 1)
        return true
    }
    return false
}

export function setControlState(control: AbstractControl, value: boolean) {
    if (value === true) {
        control.enable()
    } else {
        control.disable()
    }
}

export function click(native: Document, element: string) {
    const element2: any = native.querySelector(element)
    element2.click()
}

export function isBrowserStorageSupported(): boolean {
    try {
        sessionStorage.setItem('test', '1')
        sessionStorage.removeItem('test')
        return true
    } catch (error) {
        return false
    }
}

export interface IOutReturn<T> {
    out: T
    return?: T
}

export function toParams(map: Map<string, any>): HttpParams {
    let params = new HttpParams()
    map.forEach((v, k) => {
        if (v === undefined || v === null) { return }
        params = params.set(k, v)
    })
    return params
}
export function toParamsObj(obj: object): HttpParams {
    let params = new HttpParams()
    let keys = Object.keys(obj)
    for (let key of keys) {
        let v = obj[key]
        if (v === undefined || v === null) { continue }
        if (Array.isArray(v)) {
            v.forEach(x => params = params.append(key, x.toString()))
            continue
        }
        params = params.set(key, v)
    }
    return params
}

export function parseLocalDate(date: LocalDate | LocalDateTime | string): LocalDate {
    if (!date) return null

    if (date instanceof LocalDate) return date
    if (date instanceof LocalDateTime) return date.toLocalDate()
    if (date.length === 10) return LocalDate.parse(date)
    return LocalDateTime.parse(date).toLocalDate()
}

export function tryParseLocalDate(date: LocalDate | LocalDateTime | string): LocalDate {
    try {
        return parseLocalDate(date)
    } catch {
        return null
    }
}

export function tryParseLocalDateTime(str: string): LocalDateTime {
    try {
        return str && LocalDateTime.parse(str)
    } catch{
        return null
    }
}

export function tryParseLocalDateTimeToDate(str: string): LocalDate {
    try {
        return str && LocalDateTime.parse(str).toLocalDate()
    } catch{
        return null
    }
}

export function withSeconds(time: LocalTime): string {
    return moment(time.toString(), 'HH:mm:ss').format('HH:mm:ss')
}

/**
 * Detect a date string and convert it to a date object.
 * @param {*} key json property key.
 * @param {*} value json property value.
 * @returns {*} original value or the parsed date.
 */

export function reviveUtcDate(key: any, value: any): any {

    // todo: refactor this, so JsonInterceptor and all places we use JSON.parse, to share the same JSON date revival method.
    // Note that this copy of reviceUtcDate supports an extra format: "0001-01-01T00:00" (matchDateTimeWithoutSecondsAndTimeZone),
    // which must be tested globally before merging the functionality with JsonInterceptor's version.

    if (typeof value !== 'string') {
        return value
    }

    if (value === '0001-01-01T00:00:00') {
        return null
    }

    const matchDateTimeWithTimeZone = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)([+\-])(\d{2}):(\d{2})$/.exec(value)
    if (matchDateTimeWithTimeZone) {
        // when the time includes offset "0001-01-01T00:00:00+01:00", convert it to local time by ignoring timezone information from server
        return ZonedDateTime.parse(value).toLocalDateTime()
    }

    const matchDateTimeWithoutTimeZone = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/.exec(value)
    if (matchDateTimeWithoutTimeZone) {
        // when the time zone is not included, i.e. in the format "0001-01-01T00:00:00", treat it as local time
        return LocalDateTime.parse(value)
    }

    const matchDateTimeWithoutSecondsAndTimeZone = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})$/.exec(value)
    if (matchDateTimeWithoutSecondsAndTimeZone) {
        // when the time zone is not included, i.e. in the format "0001-01-01T00:00", treat it as local time
        return LocalDateTime.parse(value)
    }



    const matchTime = /^(?:[01]?\d|2[0-3]):[0-5]\d:[0-5]\d$/.exec(value)
    if (matchTime) {
        return LocalTime.parse(value)
    }

    const matchDate = /^(\d{4})-(\d{2})-(\d{2})$/.exec(value)
    if (matchDate) {
        // when it is just a date parse it to a localdate
        return LocalDate.parse(value)
    }

    // it is not a date or datetime so we just return it
    return value
}

export class FrontendError {
    private _value = false
    resource: string
    parameters = {}

    constructor(private callback: () => void) { }

    errors(): string[] {
        let r = this.resource
        for (var k in this.parameters) r = r.replace(new RegExp('\{' + k + '\}', 'g'), this.parameters[k])
        return this._value ? [r] : []
    }

    activate() {
        this._value = true
        this.callback()
    }

    disable() {
        this._value = false
        this.callback()
    }

    get active(): boolean { return this._value }

}

export function delayPromise(t) {
    return new Promise(function (resolve) {
        setTimeout(resolve.bind(null), t)
    });
}

export function miniMoize(fn: (...args: any[]) => any): (...args: any[]) => any {
    let cache = null
    let cacheArgs = null
    function wrapper() {
        if (cacheArgs && eq(arguments)) return cache
        let a = []
        for (let i = 0; i < arguments.length; i++)a.push(arguments[i])
        cache = fn(...a)
        cacheArgs = arguments
        return cache
    }

    function eq(arg) {
        if (arg.length != cacheArgs.length) return false
        for (let i = 0; i < arg.length; i++) if (arg[i] != cacheArgs[i]) return false
        return true
    }

    return wrapper
}

export function distinctUntilChangedDeep<T>(): MonoTypeOperatorFunction<T> {
    return distinctUntilChanged((a, b) => JSON.stringify(a) == JSON.stringify(b))
}

export const onlyUnique = (value, index, self) => self.indexOf(value) === index;
export const singleOr0 = s => {
    if(s.length > 1) throw new Error(`not expected ${s.length} elements`)
    return s[0]
}

///Either a single non-white space or it must start and end with non-white space with 0 or more characters between
const nonWhiteSpaceValidator = Validators.pattern(/^[^ ][\w\W ]*[^ ]/)

//angular only uses the pattern if the value is not empty string. In most cases it is more useful to combine with required
export const requiredNonWhiteSpaceValidator = [Validators.required, nonWhiteSpaceValidator]