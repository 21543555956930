import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class UserAgentProvider {
  getUserAgent() {
    return window.navigator.userAgent
  }
}

@Injectable({ providedIn: 'root' })
export class StorageService {
  private readonly SBWTRACKING = 'sbw_Tracking'
  private readonly SBWAMENDMENTTRACKING = 'sbw_Amendment_Tracking'
  private readonly SBWAMENDMENTMEALSTRACKING = 'sbw_Amendment_Meals_Tracking'

  private storage: Storage

  constructor(useragent: UserAgentProvider) {
    // for some reason IE and Edge are no longer able to persist sessionStorage after navigating (e.g. sso)
    // Could be related to a security patch for IE
    // We work around this by using localStorage for those
    // https://stackoverflow.com/questions/56053955/angular-couldnt-maintain-session-storage-when-navigate-to-other-system-and-bac
    this.storage = /Edge|Trident|MSIE/.test(useragent.getUserAgent()) ? localStorage : sessionStorage
  }

  public setItem(key: string, data: string): void {
    this.storage.setItem(key, data)
  }

  public getItem(key: string): string {
    return this.storage.getItem(key)
  }

  public getSbwTracking(): string {
    return this.storage.getItem(this.SBWTRACKING)
  }

  public setSbwTracking(data: string): void {
    this.storage.setItem(this.SBWTRACKING, data)
  }

  public getSbwAmendmentTracking(): string {
    return this.storage.getItem(this.SBWAMENDMENTTRACKING)
  }

  public setSbwAmendmentTracking(data: string): void {
    this.storage.setItem(this.SBWAMENDMENTTRACKING, data)
  }

  public getSbwAmendmentMealsTracking(): string {
    return this.storage.getItem(this.SBWAMENDMENTMEALSTRACKING)
  }
  public setSbwAmendmentMealsTracking(data: string): void {
    this.storage.setItem(this.SBWAMENDMENTMEALSTRACKING, data)
  }
  public removeSbwAmendmentMealsTracking(): void {
    this.storage.removeItem(this.SBWAMENDMENTMEALSTRACKING)
  }

  public getSbwToken(): string {
    return this.storage.getItem('pax_Token')
  }

  public removeSbwTracking(): void {
    this.storage.removeItem(this.SBWTRACKING)
  }

  public removeItem(key: string): void {
    this.storage.removeItem(key)
  }
}
