<sbw-popup
    [header]="'PASSENGERS_SELECT_ADDRESS_TITLE' | resource: 'Passengers'"
    [closable]="true"
    [showPopup]="showAddresses"
    [actionButtonText]="'CONTINUE' | resource: 'Common'"
    (actionButtonClick)="continue()"
>
    <form [formGroup]="findAddressForm">
        <div>
            <p>{{ "PASSENGERS_SELECT_ADDRESS" | resource: "Passengers" }}</p>
            <div class="column small-12 addresses-container">
                <div class="row">
                    <div
                        class="column small-12 radio-field"
                        *ngFor="
                            let searchResult of model.addresses;
                            let index = index
                        "
                    >
                        <input
                            type="radio"
                            formControlName="address"
                            name="address"
                            id="address_{{ index }}"
                            [value]="searchResult.moniker"
                        />
                        <label
                            class="address-label"
                            for="address_{{ index }}"
                            >{{ searchResult.partialAddress }}</label
                        >
                    </div>
                </div>
            </div>
        </div>
    </form>
</sbw-popup>
