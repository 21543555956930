import { Component, Input, SimpleChanges, OnChanges } from '@angular/core'

import { LocalDate, LocalDateTime } from 'js-joda'

import { EnvironmentService } from 'app/environment.service'
import { FormControl } from '@angular/forms'
import { LayoutState } from '../layout/layout-state'

@Component({
    selector: 'sbw-prev-next',
    templateUrl: './prev-next.component.html',
    styleUrls: ['./prev-next.component.css']
})
export class PrevNextComponent implements OnChanges {
    @Input() mode: Mode
    @Input() minDate: LocalDate
    @Input() maxDate: LocalDate
    @Input() disabledDates: LocalDate[]
    @Input() dateControl: FormControl
    @Input() textVisible: boolean = true
    @Input() allowSameDay: boolean

    public contentfulEditMode: boolean
    public isLoading: boolean
    public isQuoteLoading: boolean
    public resourceKey: string
    public visible: boolean = true
    public calculated: LocalDate

    modeEnum = Mode

    constructor(
        private environmentService: EnvironmentService,
        private layoutState: LayoutState) {
    }

    ngOnInit() {
        this.layoutState.isLoading$.subscribe(x => this.isLoading = x)
        this.contentfulEditMode = this.environmentService.contentfulEditMode

        this.environmentService.contentfulEditModeChanged$
            .subscribe(enabled => {
                this.contentfulEditMode = enabled
            })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.dateControl.value) { return }
        if (!this.minDate || !this.maxDate) { return }
        if (!this.disabledDates) { return }

        this.mode === Mode.Prev ? this.setPrevious() : this.setNext()
        this.setVisible()
    }

    setNext(): void {
        let result: LocalDate
        let dateToExamine = this.dateControl.value.plusDays(1)

        this.disabledDates.forEach(x => {
            if (dateToExamine.isEqual(x)) {
                dateToExamine = dateToExamine.plusDays(1)
            }
        })

        if (dateToExamine.isEqual(this.maxDate) || (dateToExamine.isBefore(this.maxDate))) { result = dateToExamine }
        if (!result) { result = this.dateControl.value }

        this.calculated = result
    }

    setPrevious(): void {
        let result: LocalDate
        let dateToExamine = this.dateControl.value.minusDays(1)

        this.disabledDates.forEach(x => {
            if (dateToExamine.isEqual(x)) {
                dateToExamine = dateToExamine.minusDays(1)
            }
        })

        if (dateToExamine.isEqual(this.minDate) || (dateToExamine.isAfter(this.minDate))) { result = dateToExamine }
        if (!result) { result = this.dateControl.value }

        this.calculated = result
    }


    setVisible(): void {
        this.visible = true

        if (!this.allowSameDay) {
            if (this.mode === Mode.Prev) {
                if (this.dateControl.value.minusDays(1).isBefore(this.minDate)) {
                    this.visible = false
                }
            }

            if (this.mode === Mode.Next) {
                if (this.dateControl.value.plusDays(1).isAfter(this.maxDate)) {
                    this.visible = false
                }
            }
        }

        if (this.allowSameDay) {
            if (this.mode === Mode.Prev) {
                if (this.dateControl.value.minusDays(1).isBefore(this.minDate)) {
                    this.visible = false
                }
            }

            if (this.mode === Mode.Next) {
                if (this.dateControl.value.plusDays(1).isAfter(this.maxDate)) {
                    this.visible = false
                }

                if (this.dateControl.value.isEqual(this.maxDate)) {
                    this.visible = false
                }
            }
        }
    }

    public click() {
        this.dateControl.setValue(this.calculated)
    }
}

export enum Mode { Prev, Next }
export interface IOnPrevNext {
    mode: Mode
    value: LocalDateTime
}
