import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { TravelService } from 'app/travel/travel.service';
import { Subscription } from 'rxjs'
import { IAgentProduct } from 'app/travel/travel.interfaces';
import { HybridService } from 'app/shared/hybrid/hybrid.service';

@Component({
    selector: 'sbw-travel-agent-product',
    templateUrl: './travel-agent-product.component.html',
    styleUrls: ['./travel-agent-product.component.css']
})
export class TravelAgentProductComponent implements OnChanges {

    @Input() currentCode
    @Output() productSelected = new EventEmitter()
    currentProducts: Array<IAgentProduct>
    private ongoing: Subscription

    constructor(private travelService: TravelService, private hybridService: HybridService) { }

    ngOnChanges(): void {
        if (this.currentCode) {
            if (this.ongoing) { this.ongoing.unsubscribe() }
            this.currentProducts = []
            this.ongoing = this.travelService.getAgentProducts(this.currentCode)
                .subscribe(
                s => this.currentProducts = s.productList,
                e => this.hybridService.agentLogin())
        }
    }

    onClick(code) {
        this.productSelected.emit(code)
    }

}
