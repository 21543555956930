import { Injectable } from '@angular/core'
import { Observable, Subject, Subscription, ReplaySubject } from 'rxjs'
import { Quote } from './quote.models'
import { QuoteChanged, PriceSpecificationType, UserQuoteDetails, OneWayUserQuoteDetails } from './quote.events'
import { flatMap } from '../utils'
import { StorageService } from '../storage.service';
import { StatefulHttpService } from 'app/shared/stateful-http.service'

@Injectable()
export class QuoteService {
    constructor(private statefulHttpService: StatefulHttpService, private storage: StorageService) { }

    quoteUpdates = new Subject<Quote.QuoteModel>()
    quoteErrors = new ReplaySubject<Quote.QuoteModel>(1)
    mostRecent: Quote.QuoteModel
    pendingUpdate$: Subscription
    private apiKey
    private path = ''


    public initialize() {
        this.quoteUpdates = new Subject<Quote.QuoteModel>()
        this.mostRecent = null
        this.pendingUpdate$ = null
    }

    public setApiKey(key) {
        this.apiKey = { 'X-Api-Key': key }
    }

    public useBlocking(waitHandle) {
        this.path = `/sbwapi/booking/blockingquote?wait=${waitHandle}&step=`
    }

    public getQuoteData(step: string): Observable<Quote.QuoteModel> {
        if (step) this.statefulHttpService.post<Quote.QuoteModel>(false, `quoteresponsive?step=${step}`, {}, { headers: this.apiKey }).subscribe(r => this.quoteUpdates.next(r))
        return this.quoteUpdates
    }

    public updateBackendQuote(fromBackend: Quote.QuoteModel) {
        this.quoteUpdates.next(fromBackend)
    }

    public updatePreviewQuote(outDeparture: string, returnDeparture: string, vehicleType: string, offer: string) {
        if (outDeparture === undefined || returnDeparture === undefined) return this.getQuoteData('Cabinfares')
        if (this.mostRecent && !vehicleType) return
        if (offer) return

        this.getQuotePreview(outDeparture, returnDeparture, null, null);
    }

    //It is important that returnDeparture is null for one-way. If it is undefined it is considered no departure for the day
    public getQuotePreview(outDeparture: string, returnDeparture: string, cabinout: any, cabinret: any) {
        if (outDeparture === undefined || returnDeparture === undefined) return this.getQuoteData('Cabinfares')
        if (this.mostRecent) {
            this.mostRecent.isUpdating = true
            this.updateBackendQuote(this.mostRecent)
        }

        if (this.pendingUpdate$)
            this.pendingUpdate$.unsubscribe();
        this.pendingUpdate$ = this.statefulHttpService.post<Quote.QuoteModel>(false, 'faresquote', {}, {
            params: {
                out: outDeparture,
                ...(returnDeparture && { return: returnDeparture }),
                ...cabinout,
                ...cabinret
            }
        }).subscribe(s => {
                    if (s.errors && s.errors.cabinNotAvailable) {
                this.quoteErrors.next(s)
            } else {
                this.mostRecent = s;
                this.updateBackendQuote(s)
            }
        });
    }

    public merge(fromBackend: Quote.QuoteModel, userQuoteData: QuoteChanged): Quote.QuoteModel {
        if (userQuoteData == null) { return fromBackend }
        if (!fromBackend) { return null }

        const resultQuoteModel = { ...fromBackend }
        const userQuoteModel = { ...userQuoteData }

        resultQuoteModel.outbound = this.mergeLeg(resultQuoteModel.outbound, userQuoteModel.outbound, userQuoteModel.type)
        resultQuoteModel.return = this.mergeLeg(resultQuoteModel.return, userQuoteModel.return, userQuoteModel.type)
        resultQuoteModel.final = this.mergeLeg(resultQuoteModel.final, userQuoteModel.final, userQuoteModel.type)
        const total = this.legPrice(resultQuoteModel.outbound) + this.legPrice(resultQuoteModel.return) + this.legPrice(resultQuoteModel.final)
        resultQuoteModel.totalPrice = total < 0 ? 0 : this.legPrice(resultQuoteModel.outbound) + this.legPrice(resultQuoteModel.return) + this.legPrice(resultQuoteModel.final)
        resultQuoteModel.deposit = userQuoteData.deposit && userQuoteData.deposit.deposit
        resultQuoteModel.remainingAmount = userQuoteData.deposit && userQuoteData.deposit.remainingAmount
        return resultQuoteModel
    }

    private legPrice(leg: Quote.QuoteDetail): number {
        if (leg == null) { return 0 }

        return flatMap(leg.priceSpecificationGroups, g => g.priceSpecifications)
            .map(p => this.calculateItemPrice(p.count, p.price))
            .reduce((a, b) => a + b, 0)
    }

    private mergeLeg(backendLeg: Quote.QuoteDetail, userQuoteDetails: UserQuoteDetails, type: PriceSpecificationType): Quote.QuoteDetail {
        if (userQuoteDetails == null) return backendLeg
        if (backendLeg == null || userQuoteDetails instanceof OneWayUserQuoteDetails) { return null }
        for (let group of backendLeg.priceSpecificationGroups.filter(g => g.type == type)) {
            group.priceSpecifications = userQuoteDetails.lines.map(e => new PriceSpecification(e.label, e.price, e.currencyCode, e.count, type))
        }
        backendLeg.routeName = userQuoteDetails.routeName || backendLeg.routeName
        backendLeg.routeDateTime = userQuoteDetails.departureTime || backendLeg.routeDateTime
        if (backendLeg.allCustomerCategories != null && userQuoteDetails.passengers != null) {
            for (let detailsKey in userQuoteDetails.passengers) {
                if (userQuoteDetails.passengers.hasOwnProperty(detailsKey)) {
                    let userPassenger: Quote.IUserPassenger = userQuoteDetails.passengers[detailsKey]
                    backendLeg.allCustomerCategories
                        .filter(a => a.category == userPassenger.category)
                        .forEach(p => p.count = userPassenger.count)
                }
            }
        }

        if (backendLeg == null) { return }
        if (type === PriceSpecificationType.Fares) {
            backendLeg.priceSpecificationGroups = backendLeg.priceSpecificationGroups.filter(x => x.type !== PriceSpecificationType.Product) // products must not be show on fares step
            if (backendLeg.priceSpecificationGroups.some(x => x.type === type)) {
                backendLeg.priceSpecificationGroups.find(x => x.type === type).priceSpecifications = userQuoteDetails.lines.map(e => new PriceSpecification(e.label, e.price, e.currencyCode, 0, type))
            } else {
                backendLeg.priceSpecificationGroups.push({ leg: backendLeg.leg, type: type, priceSpecifications: userQuoteDetails.lines.map(e => new PriceSpecification(e.label, e.price, e.currencyCode, 0, type)) })
            }
        }
        for (let group of backendLeg.priceSpecificationGroups.filter(g => g.type === type && type !== PriceSpecificationType.Fares)) {
            group.priceSpecifications = userQuoteDetails.lines.map(e => new PriceSpecification(e.label, e.price, e.currencyCode, e.count, type))
        }
        return backendLeg
    }

    private calculateItemPrice(count: number, price: number): number {
        let numberOfItems = (count == null || count === 0) ? 1 : count // when count == 0 then set to 1 is because that not all products have a count while they do have a price. This should be refactored when core delivers the delivers the quote data for sb web

        return numberOfItems * price
    }

    saveQuoteInSessionStorage(quote: IQuote) {
        this.storage.setItem('sbw_Quote', JSON.stringify(<IQuote>{ totalPrice: quote.totalPrice })) // only mapping totalPrice as it is the only property needed on passengers step for now
    }

    getQuoteFromSessionStorage(): IQuote {
        return <IQuote>{ totalPrice: JSON.parse(this.storage.getItem('sbw_Quote')).totalPrice }
    }
}

class PriceSpecification implements Quote.IPriceSpecification {
    leg: number
    type: number
    isVisible: boolean
    showPrice: boolean
    get displayCount(): number { return this.count }

    public constructor(
        public label: string,
        public price: number,
        public currencyCode: string,
        public count: number,
        public type2: PriceSpecificationType) {
    }
}

export interface IQuote {
    totalPrice: number
}
