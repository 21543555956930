import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule, Routes } from '@angular/router'
import { SharedModule } from '../shared/shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { AmendmentVehiclesComponent } from './amendment-vehicles/amendment-vehicles.component'
import { VehiclesSelectorComponent } from './vehicles-selector/vehicles-selector.component'
import { VehiclesDetailsRowComponent } from './vehicles-details-row/vehicles-details-row.component'
import { VehicleService } from 'app/vehicles/vehicle.service'
import { UIModule } from 'app/shared/UI.module'
import { VehiclesDetailsRowsComponent } from 'app/vehicles/vehicles-details-rows/vehicles-details-rows.component'
import { AuthGuard } from 'app/shared/guards/auth.guard'


const appRoutes: Routes = [
    { path: 'amendment/booking/:reservationCode/vehicles', component: AmendmentVehiclesComponent, canActivate: [AuthGuard] }
]

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(appRoutes),
        SharedModule,
        UIModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule
    ],
    providers: [
        VehicleService
    ],
    declarations: [
        AmendmentVehiclesComponent,
        VehiclesSelectorComponent,
        VehiclesDetailsRowComponent,
        VehiclesDetailsRowsComponent
    ],
    exports: [
        VehiclesSelectorComponent,
        VehiclesDetailsRowsComponent
    ]
})
export class VehiclesModule { }
