<sbw-layout [isAgent]="true" [backButtonVisible]="false" type="Amendment" [continueButtonVisible]="false">
  <div class="sbw_pagewbox">
    <div class="row uncollapse meals-container">
      <div class="row uncollapse login-container">
        <div class="column small-12 xlarge-4 login-box">
          <div class="row">
            <form (submit)="handleSubmit()" [formGroup]="agentForm">
              <h3>{{ 'AGENT_LOGIN_TITLE' | resource }}</h3>
              <div class="row">
                <div class="column small-12">
                  <div class="row">
                    <div class="column small-12 label">
                      <label for="LoginForm.AccountNumber">{{ 'AGENT_ACCOUNT_NUMBER' | resource }}</label>
                    </div>
                    <div class="column small-12 field">
                      <input
                        class="text-box single-line"
                        id="agentUsername"
                        type="text"
                        value=""
                        formControlName="abtaForm"
                        [(ngModel)]="username"
                      />
                      <span
                        class="field-validation-error"
                        *ngIf="validationError || (agentForm.controls.abtaForm?.errors && !agentAbtaValid)"
                        >{{ 'AGENT_EMPTY_ACCOUNT_FIELD' | resource }}</span
                      >
                      <span
                        *ngIf="errorCode == 2 && !agentForm.controls.abtaForm?.errors"
                        [ngClass]="{ 'field-validation-error': hasError, 'field-validation-valid': !hasError }"
                        >{{ 'AGENT_ACCOUNT_DOES_NOT_EXIST_IN_MARKET' | resource }}</span
                      >
                      <span
                        *ngIf="errorCode == 3 && !agentForm.controls.abtaForm?.errors"
                        [ngClass]="{ 'field-validation-error': hasError, 'field-validation-valid': !hasError }"
                        >{{ 'AGENT_ACCOUNT_NOT_WEB_ENABLED' | resource }}</span
                      >
                      <span
                        *ngIf="errorCode == 4 && !agentForm.controls.abtaForm?.errors"
                        [ngClass]="{ 'field-validation-error': hasError, 'field-validation-valid': !hasError }"
                        >{{ 'AGENT_ACCOUNT_BLOCKED' | resource }}</span
                      >
                      <span
                        *ngIf="errorCode == 1 && !agentForm.controls.abtaForm?.errors"
                        [ngClass]="{ 'field-validation-error': hasError, 'field-validation-valid': !hasError }"
                        >{{ 'AGENT_ACCOUNT_LOGIN_FAILED' | resource }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="column small-12">
                  <div class="row">
                    <div class="column small-12 label">
                      <label for="LoginForm_Password">{{ 'AGENT_PASSWORD' | resource }}</label>
                    </div>
                    <div class="column small-12 field">
                      <input
                        class="text-box single-line"
                        id="agentPassword"
                        type="password"
                        formControlName="passwordForm"
                        value=""
                        [(ngModel)]="password"
                      />
                      <span class="field-validation-error" *ngIf="agentForm.controls.passwordForm?.errors && !agentPasswordValid">{{
                        'AGENT_EMPTY_PASSWORD_FIELD' | resource
                      }}</span>
                      <span
                        *ngIf="errorCode == 5"
                        [ngClass]="{ 'field-validation-error': hasError, 'field-validation-valid': !hasError }"
                        >{{ 'AGENT_INVALID_PASSWORD_OR_USER_NAME' | resource }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="column small-12">
                  <div class="row">
                    <div class="column small-12 label">
                      <label for="LoginForm_AgentReference">{{ 'AGENT_AGENT_REFERENCE_TITLE' | resource }}</label>
                    </div>
                    <div class="column small-12 field">
                      <input
                        class="text-box single-line"
                        id="LoginForm.AgentReference"
                        formControlName="referenceForm"
                        type="text"
                        value=""
                        [(ngModel)]="agentReference"
                      />
                      <span class="field-validation-error" *ngIf="agentForm.controls.referenceForm?.errors && !agentNameValid">{{
                        'AGENT_EMPTY_NAME_FIELD' | resource
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="column small-12 field">
                  <input
                    type="checkbox"
                    id="LoginForm.StayLoggedIn"
                    [(ngModel)]="stayLoggedIn"
                    value="true"
                    formControlName="stayLoggedInForm"
                  />
                  <label for="LoginForm.StayLoggedIn">{{ 'AGENT_STAY_LOGGED_IN' | resource }}</label>
                </div>
                <div class="column small-12 field submit">
                  <button type="submit" class="preferred-action" [buttonState]="submitButtonState" [defaultState]="submitButtonState">
                    {{ 'AGENT_LOGIN' | resource }}
                  </button>
                </div>
              </div>
            </form>
            <form (submit)="handleResendPassword()">
              <div class="row">
                <div class="column small-12 field submit">
                  <button type="submit" class="previous-action" [buttonState]="resetPwdButtonState" [defaultState]="resetPwdButtonState">
                    {{ 'AGENT_RESET_PASSWORD' | resource }}
                  </button>

                  <span
                    [ngClass]="{ 'field-validation-error': wrongUsername, hide: !wrongUsername }"
                    data-valmsg-for="LoginForm.ForgotPassword"
                    data-valmsg-replace="true"
                    >{{ 'AGENT_ACCOUNT_NUMBER_INVALID' | resource }}</span
                  >
                  <span
                    [ngClass]="{ 'field-validation-error': passwordWasResentFailed, hide: !passwordWasResentFailed }"
                    data-valmsg-for="LoginForm.ForgotPassword"
                    data-valmsg-replace="true"
                    >{{ 'RESEND_PASSWORD_FAILED' | resource }}</span
                  >
                  <span
                    [ngClass]="{ 'field-validation-ok': passwordWasResent, hide: !passwordWasResent }"
                    data-valmsg-for="LoginForm.ForgotPassword"
                    data-valmsg-replace="true"
                    >{{ 'AGENT_SUCCESSFUL_PWD_RESEND_MESSAGE' | resource }}</span
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="column small-12 xlarge-8 register-box">
          <div class="row">
            <div class="sbw_plain" innerHtml="{{ 'AGENT_INTRO' | resource }}"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</sbw-layout>
