import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { LayoutState } from 'app/shared/layout/layout-state'
import { ResourceService } from 'app/shared/resources/resource.service'
import { TrackingService } from 'app/shared/tracking/tracking.service'
import { LoggingService } from 'app/shared/logging.service'
import { HybridService } from 'app/shared/hybrid/hybrid.service'

@Component({
  selector: 'sbw-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css'],
})
export class NotFoundComponent implements OnInit {
  public header: string
  public body: string
  public backButtonVisible = false

  constructor(
    private layoutState: LayoutState,
    private resourceService: ResourceService,
    private trackingService: TrackingService,
    private router: Router,
    private logger: LoggingService,
    private hybridService: HybridService
  ) {}

  ngOnInit() {
    let pathnameParts = this.router.url.toLowerCase().split('/')
    pathnameParts.shift() // remove the first entry which allways is an empty string

    if (pathnameParts.length === 1 && pathnameParts[0] === 'cabinfares') {
      // we still have a lot of entries in log on old cabin fares url in log
      this.router.navigate(['cabin-fares'])
      return
    }

    // check url (as it is) against route table after being lowercased
    const navigateUrl = this.getUrl(pathnameParts)
    if (navigateUrl) {
      this.router.navigate([navigateUrl])
      return
    }

    if (pathnameParts.length >= 3) {
      const pathnameArray = pathnameParts.filter((_, i) => i > 2) // removes the first 3 elements of url - da-dk/passagerfaerger/booking - which is locale, passengerfaerger (which is localized) and booking
      const navigateUrl = pathnameArray.length === 0 ? '' : this.getUrl(pathnameArray)
      if (navigateUrl) {
        this.router.navigate([navigateUrl])
        return
      }
    }

    let correlation = window['sbCorrelationId']
    if (correlation) {
      this.router.navigate(['error/sbw'], { queryParams: { correlation } })
      return
    }
    this.logger.logError({ message: 'Not found page', path: window.location.pathname, query: window.location.search }, null)
    const locale = this.resourceService.getLocale()
    this.resourceService.loadResourcesPromise('Default', locale, ['Common']).then(() => {
      this.layoutState.setIsContinueLoading(false)
      this.layoutState.setIsContentLoaded(true)
      this.backButtonVisible = true
      this.trackingService.trackNotFound()
    })
  }

  private getUrl = (pathnameParts: string[]): string => {
    const url = pathnameParts.join('/')
    const route = this.router.config.find((route) => route.path.toLocaleLowerCase() === url)
    return route ? route.path : null // allways use path from this.router.config as it is guaranteed to work
  }

  public goBack() {
    this.hybridService.changeWindowLocation('/')
  }
}
