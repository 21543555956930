import { Component, OnInit, Input } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { IFlowReservation } from 'app/shared/steps/step.model';

import { Pet } from '../pets.interface';

@Component({
    selector: 'sbw-pets-selector',
    templateUrl: './pets-selector.component.html',
    styleUrls: ['./pets-selector.component.css']
})
export class PetsSelectorComponent implements OnInit {

    @Input() reservation: IFlowReservation
    @Input() form: FormGroup

    ngOnInit() {
    }
}
