import { Component, Input, forwardRef } from '@angular/core';
import { NgForm, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { isPrimitiveValue } from 'app/shared/utils'

@Component({
    selector: 'sbw-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        }
    ]
})
export class SelectComponent implements ControlValueAccessor {
    private _items: any[]
    private valuesArePrimitive: boolean = false

    @Input() id: string
    @Input() dataId: string
    @Input() name: string
    @Input() label: string
    @Input() placeholder: string
    @Input() key: string = 'value'
    @Input() text: string = 'text'
    @Input() htmlClass = ''
    @Input() set items(obj: any[]) {
        if (obj.length) {
            this.valuesArePrimitive = isPrimitiveValue(obj[0][this.key])
            for (let item of obj) {
                if (isPrimitiveValue(item[this.key]) !== this.valuesArePrimitive) {
                    throw new Error('SelectComponent: All values in the list must be either primitive or complex')
                }
            }
        }
        this._items = obj
    }
    get items(): any[] { return this._items }

    gotFocus = false
    private _itemModel: any

    writeValue(obj: any): void {
        this._itemModel = obj
    }
    registerOnChange(fn: any): void { this.onChange = fn }
    registerOnTouched(fn: any): void { }
    setDisabledState(isDisabled: boolean): void { }

    onChange: any = s => { }

    set itemModel(i: any) {
        this._itemModel = i
        this.onChange(i)
    }
    get itemModel(): any { return this._itemModel }
}
