import { Component, Input } from '@angular/core'

@Component({
    selector: 'sbw-contact-details',
    templateUrl: './contact-details.component.html',
    styleUrls: ['./contact-details.component.css']
})
export class ContactDetailsComponent {
    @Input() inputContactDetails: IInputContactDetails
}

export interface IInputContactDetails {
    title: string
    firstName: string
    lastName: string
    addressLine1: string
    addressLine2: string
    city: string
    county: string
    country: string
    postalCode: string
    mobileNumber: string
    emailAddress: string
}
