import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ITravelPassengerRange } from 'app/travel/travel.interfaces';
import { FormGroup, FormArray } from '@angular/forms';
import { ChildrenAges } from 'app/travel/travel.models';
import { startWith } from 'rxjs/operators'
import { ResourceService } from 'app/shared/resources/resource.service';
import { of } from 'rxjs';
import { SelectItem } from 'app/shared/select-field/select.model';

@Component({
    selector: 'sbw-travel-passengers',
    templateUrl: './travel-passengers.component.html',
    styleUrls: ['./travel-passengers.component.css']
})
export class TravelPassengersComponent implements OnInit, OnChanges {

    private _showPets = false
    public childrenSelected: Array<number> = []
    public mobileViewport: boolean

    constructor(private resourceService: ResourceService) { this.attachMediaQueryHandler() }

    get adultCount() { return this.passengerSelections.get('adultCount') }
    get petCount() { return this.passengerSelections.get('petCount') }
    get childCount() { return this.passengerSelections.get('childCount') }
    get childrenAgeArray(): FormArray { return this.passengerSelections.get('childrenAges') as FormArray }

    @Input() giveAttentionChildAges = false
    @Input() passengerRanges: ITravelPassengerRange;
    @Input() passengerSelections: FormGroup;
    @Input() set showPets(value: boolean) {
        this._showPets = value
        this.resetPets()
    }
    get showPets(): boolean {
        return this._showPets
    }

    adultRange: SelectItem[]
    childRange: SelectItem[]
    childAgeRange: SelectItem[]
    petRange: SelectItem[]

    get hasInfants(): boolean { return this.ageModel().infants > 0 }
    callAttentionChildAges(i: number) { return this.giveAttentionChildAges && this.childrenAgeArray.controls[i].value == '' }
    private ageModel() { return ChildrenAges.fromStrings(this.childrenAgeArray.value, this.childCount.value) }

    ngOnInit() {
        this.adultRange = this.passengerRanges.adultPassengerRange.map(n => new SelectItem(n, n.toString()));
        this.childRange = this.passengerRanges.childPassengerRange.map(n => new SelectItem(n, n.toString()));
        this.petRange = this.passengerRanges.petPassengerRange.map(n => new SelectItem(n, n.toString()))

        // Set array 0-15 as child age range
        this.childAgeRange = [new SelectItem('', '-')]
            .concat(Array(16).fill(0).map((x, i) => new SelectItem(i.toString(), i.toString())))
        this.onChildCountChanged()
    }

    ngOnChanges(changes: any) {
        this.petRange = this.passengerRanges.petPassengerRange.map(n => new SelectItem(n, n.toString()))
        this.childRange = this.passengerRanges.childPassengerRange.map(n => new SelectItem(n, n.toString()))
    }

    private attachMediaQueryHandler() {
        if (window.matchMedia) {
            const mqMobile = window.matchMedia('(max-width: 40em)')
            this.mobileViewport = mqMobile.matches
            mqMobile.addListener(mq => this.mobileViewport = mq.matches)
        }
    }

    private resetPets() {
        if (!this._showPets) {
            this.petCount.setValue(0)
        }
    }

    private onChildCountChanged() {
        this.childCount.valueChanges.pipe(
            startWith(this.childCount.value))
            .subscribe(v => this.childrenSelected = new Array(parseInt(v)).fill(0).map((_, i) => i))
    }

    // Function that childAges selectField uses to determine which select field should have the correct children label, and which should be empty.
    // To solve the non-breaking-space issue, we gave it _ which will be replaced later by the $sbnp;
    getLabel(index) {
        switch (index) {
            case 0: return this.resourceService.get('CHILDREN_AGES')
            case 1: return of('_')
            case 2: return this.mobileViewport ? null : of('_')
            default: return null
        }
    }

}
