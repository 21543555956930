import { Component, OnInit, Input } from '@angular/core';
import { ResourceService } from 'app/shared/resources/resource.service'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { IFlowOrAmendment, FlowOrAmendment, ensureFlowOrAmendment } from 'app/shared/utils';

@Component({
    selector: 'sbw-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit, IFlowOrAmendment {
    @Input() mode: FlowOrAmendment
    @Input() model: IInputTermsAndCondition
    @Input() validationError: boolean

    linkText: string

    control = new FormControl(false, Validators.requiredTrue)

    constructor(private resourceService: ResourceService) { }

    ngOnInit() {
        ensureFlowOrAmendment(this.mode)
        this.resourceService.get('PASSENGERS_TERMS_AND_CONDITIONS_LINKS').subscribe(t => this.linkText = t)

        if (this.mode === 'Flow') {
            this.useFlow()
        } else if (this.mode === 'Amendment') {
            this.useAmendment()
        }
    }

    useFlow() {
        this.model.form.addControl('acceptTermsAndConditions', this.control)
    }
    useAmendment() {
        throw new Error('Method not implemented.')
    }

    changedEvent(checked: boolean) {
        this.control.setValue(checked)
        this.control.markAsDirty()
    }
}

export interface IInputTermsAndCondition {
    form: FormGroup
}
