import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule, Routes } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { SharedModule } from '../../shared/shared.module'
import { ActiveComponent } from './active/active.component'
import { InactiveComponent } from './inactive/inactive.component'
import { ContactDetailsComponent } from './contact-details/contact-details.component'
import { BookingInfoComponent } from './booking-info/booking-info.component'
import { PriceBreakdownComponent } from './price-breakdown/price-breakdown.component'
import { BookingComponent } from './booking/booking.component'
import { AmendmentLinksComponent } from './amendment-links/amendment-links.component'
import { UIModule } from 'app/shared/UI.module'
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from 'app/shared/guards/auth.guard'
import { BookingDetailsComponent } from './booking-details/booking-details.component'

const appRoutes: Routes = [
    { path: 'amendment/bookings', canActivate: [AuthGuard], children:[] },
    { path: 'amendment/booking/:reservationCode', component: BookingComponent, canActivate: [AuthGuard] },
]

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        UIModule,
        FormsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes)
    ],
    declarations: [
        ActiveComponent,
        InactiveComponent,
        ContactDetailsComponent,
        BookingInfoComponent,
        PriceBreakdownComponent,
        BookingComponent,
        AmendmentLinksComponent,
        BookingDetailsComponent
    ]
})
export class BookingsModule { }
