import { Component, NgZone, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { of, Observable, merge } from 'rxjs'
import { ResourceService } from 'app/shared/resources/resource.service'
import { StepService } from 'app/shared/steps/step.service'
import { IFlowReservation, LocalStorageStepData, SelectionTypeEnum } from 'app/shared/steps/step.model'
import { QuoteChanged } from 'app/shared/quote/quote.events'
import { SbEvent } from 'app/shared/sb-event.emitter'
import { ICabinDeparture, ICabinDepartureSelection, ICabinWire, ICabinSelection } from 'app/cabins/cabins.interfaces'
import { CabinsService } from 'app/cabins/cabins.service'
import { HybridService } from 'app/shared/hybrid/hybrid.service'
import { QuoteService } from 'app/shared/quote/quote.service'
import { LayoutState } from 'app/shared/layout/layout-state'
import { TrackingService } from 'app/shared/tracking/tracking.service'
import { TrackingFlowStep } from 'app/shared/tracking/tracking-wire.interfaces'
import { ContentfulClientApiFast } from 'app/shared/resources/contentfulClientApiFast'
import { AuthService } from 'app/auth/auth.service'
import { getPassengerRedirectUri } from 'app/auth/auth.config'

@Component({
  selector: 'sbw-flow-cabins',
  templateUrl: './flow-cabins.component.html',
  styleUrls: ['./flow-cabins.component.css'],
})
export class FlowCabinsComponent implements OnInit {
  private reservation: IFlowReservation
  public flowForm: FormGroup = new FormGroup({})
  public errorList = []
  public quoteData: Observable<QuoteChanged>
  public departures: ICabinDeparture[]
  public departureSelections: ICabinDepartureSelection[]
  public cabinWire: ICabinWire
  public isOneway = true
  public cabinsLoaded = false
  public flowCabinsContent?: any
  public isAgent: boolean

  get outboundDepartureGroup(): FormGroup {
    return this.flowForm.get('outbound') as FormGroup
  }

  get returnDepartureGroup(): FormGroup {
    return this.flowForm.get('return') as FormGroup
  }

  get outboundSelections(): FormGroup {
    return this.flowForm.get('outboundSelections') as FormGroup
  }

  get returnSelections(): FormGroup {
    return this.flowForm.get('returnSelections') as FormGroup
  }

  get noCabinsSelected(): boolean {
    let outboundHasSelections =
      this.flowForm.get('outboundSelections') !== null
        ? (<ICabinSelection[]>this.flowForm.get('outboundSelections').value).some((x) => x.count > 0)
        : false
    let returnHasSelections =
      this.flowForm.get('returnSelections') !== null
        ? (<ICabinSelection[]>this.flowForm.get('returnSelections').value).some((x) => x.count > 0)
        : false
    let hasSelections = outboundHasSelections || returnHasSelections
    return !hasSelections
  }

  constructor(
    private resourceService: ResourceService,
    private stepService: StepService,
    private hybridService: HybridService,
    private sbEvent: SbEvent,
    private fb: FormBuilder,
    private authService: AuthService,
    private zone: NgZone,
    private cabinsService: CabinsService,
    private title: Title,
    private quoteService: QuoteService,
    private layoutState: LayoutState,
    private trackingService: TrackingService,
    private contentful: ContentfulClientApiFast
  ) {}

  ngOnInit() {
    this.isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
    let locale = this.resourceService.getLocale()
    this.reservation = this.stepService.combineToReservation()
    this.resourceService
      .loadResourcesPromise(this.reservation.outboundRouteCode, locale, ['Common', 'Account', 'Quote', 'Cabin', 'Currency'])
      .then(() => {
        this.resourceService.get('CABIN_TITLE', false).subscribe((t) => this.title.setTitle(t))
        this.cabinsService.getCabinsFlow(this.reservation).subscribe((data) => {
          this.cabinWire = data
          this.buildForm()
          this.prepareQuoteData()
          this.cabinsLoaded = true
          this.layoutState.setIsContentLoaded(true)
        })
        this.contentful.getEntries<any>('flowCabin').subscribe((s) => (this.flowCabinsContent = s.items[0].fields))
        this.trackingService.trackFlow(TrackingFlowStep.CABIN, 'Select your cabin', this.reservation, false)
      })
  }

  public continue() {
    let departureSelections: ICabinDepartureSelection[] = []
    let outboundDepartureSelections = <ICabinSelection[]>this.flowForm.get('outboundSelections').value
    departureSelections.push({
      departureId: this.outboundDepartureGroup.value.departureId,
      selections: outboundDepartureSelections,
    })
    if (!this.isOneway) {
      let returnDepartureSelections = <ICabinSelection[]>this.flowForm.get('returnSelections').value
      departureSelections.push({
        departureId: this.returnDepartureGroup.value.departureId,
        selections: returnDepartureSelections,
      })
    }

    this.postChoices(departureSelections)
  }

  public postChoices(cabinSelections: ICabinDepartureSelection[]) {
    this.layoutState.setIsContinueLoading(true)
    this.cabinsService.postHybridCabins(cabinSelections).subscribe((data) => {
      if (data.errorList) {
        this.errorList = data.errorList.map((e) => e.message)
        this.layoutState.setIsContinueLoading(false)
        return
      }

      if (data.nextStepUrl === 'passengers' && !this.isAgent) {
        if (!this.authService.isLoggedIn()) {
          this.layoutState.setIsContinueLoading(false)
          this.authService.initiateLogin(getPassengerRedirectUri())
        } else {
          this.updateStepInLocalStorage(cabinSelections)
          this.quoteService.saveQuoteInSessionStorage(data.quote)
          this.trackingService.saveFlowTrackingData(data.trackingData)
          this.hybridService.changeWindowLocation(data.nextStepUrl)
          this.layoutState.setIsContentLoaded(false)
          this.layoutState.setIsContinueLoading(false)
        }
      } else {
        this.updateStepInLocalStorage(cabinSelections)
        this.quoteService.saveQuoteInSessionStorage(data.quote)
        this.trackingService.saveFlowTrackingData(data.trackingData)
        this.hybridService.changeWindowLocation(data.nextStepUrl)
        this.layoutState.setIsContentLoaded(false)
        this.layoutState.setIsContinueLoading(false)
      }
    })
  }

  private updateStepInLocalStorage(selections: ICabinDepartureSelection[]) {
    let step: any = {}
    step.cabinSelections = new LocalStorageStepData(SelectionTypeEnum.User, selections)
    this.stepService.saveStepData(step, window.location.pathname, false)
  }

  public navigateBack() {
    this.sbEvent.historyGoBack.next()
  }

  anySelectedValidator() {
    return (c: FormArray): { [key: string]: any } =>
      c.controls.map((t) => t.value.count).filter((t) => t > 0).length > 0 ? null : { anySelectedValidator: { valid: false } }
  }

  public buildForm(): void {
    if (this.reservation.cabinSelections) {
      this.cabinWire.departureSelections = this.reservation.cabinSelections
    }
    let { departures, departureSelections } = this.cabinWire
    this.departures = departures

    const formControlOutboundDeparture = new FormControl(this.departures[0])
    const formControlReturnDeparture = new FormControl(this.departures[1])
    const formControlOutboundSelections =
      departureSelections[0] && departureSelections[0].selections.map((s) => this.factorySelectionFormGroup(s))
    const formControlReturnSelections =
      departureSelections[1] && departureSelections[1].selections.map((s) => this.factorySelectionFormGroup(s))

    this.flowForm = this.fb.group({
      outbound: formControlOutboundDeparture,
      return: formControlReturnDeparture,
      outboundSelections: this.fb.array(formControlOutboundSelections || []),
      returnSelections: this.fb.array(formControlReturnSelections || []),
    })

    this.isOneway = departures.length === 1
    if (this.isOneway) {
      this.flowForm.get('returnSelections').disable()
    }

    merge(this.outboundSelections.valueChanges, this.returnSelections.valueChanges).subscribe(() => this.prepareQuoteData())
  }

  private factorySelectionFormGroup(selection: ICabinSelection) {
    return this.fb.group({
      id: [selection.id],
      count: [selection.count],
    })
  }

  private prepareQuoteData() {
    this.quoteData = of(
      this.cabinsService.getQuoteModel(
        this.outboundSelections.value,
        this.outboundDepartureGroup.value,
        this.returnSelections.value,
        this.returnDepartureGroup.value
      )
    )
  }
}
