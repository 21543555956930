export const getPassengerRedirectUri = () => {
  if (typeof window === 'undefined') return '/'
  if (window.location.hostname === 'localhost') return 'https://localhost:7001/passengers'
  return (
    window.location.origin +
    '/' +
    window.location.pathname.split('/')[1] +
    '/' +
    window.location.pathname.split('/')[2] +
    '/booking/passengers'
  )
}

export const getPathRedirectUri = (path: string) => {
  if (typeof window === 'undefined') return '/'
  if (window.location.hostname === 'localhost') return `https://localhost:7001/${path}`
  return (
    window.location.origin +
    '/' +
    window.location.pathname.split('/')[1] +
    '/' +
    window.location.pathname.split('/')[2] +
    `/booking/${path}`
  )
}

const getPostLogoutUri = () => {
  if (typeof window === 'undefined') return '/'
  if (window.location.hostname === 'localhost') return 'https://localhost:7001'
  return window.location.origin + '/' + window.location.pathname.split('/')[1] + '/' + window.location.pathname.split('/')[2] + '/booking/'
}

const getEnvironment = () => {
  if (typeof window === 'undefined') return 'dev'
  else if (window.location.hostname.includes('ferry.dfds.com')) return 'dev'
  else if (window.location.hostname === 'www.dfds.com') return 'prod'
  else if (window.location.hostname.includes('dev.dfds')) return 'dev'
  else if (window.location.hostname.includes('localhost')) return 'dev'
  else if (window.location.hostname.includes('test.dfds')) return 'test'
  else if (window.location.hostname.includes('accept.dfds')) return 'accept'
  else if (window.location.hostname.includes('uat.dfds')) return 'accept'
  else throw new Error('Unknown subdomain')
}

const getClientId = (env: string) => {
  if (typeof window === 'undefined') return 'dev'
  else if (window.location.hostname.includes('ferry.dfds.com')) return '91ef71ea-6e59-4283-8213-e9ded8fd25df'
  else if (env === 'dev') return '91ef71ea-6e59-4283-8213-e9ded8fd25df'
  else if (env === 'test') return '4cc97c5b-fd2b-42e8-9a5a-1c2620a9a07b'
  else if (env === 'accept') return '93946ba0-cfca-40c2-847e-f9dfe2d471cf'
  else if (env === 'prod') return '0022f598-b118-47b4-b4f0-c825d6890124'
  throw new Error('Unknown environment')
}

const env = getEnvironment()
const envWithOrWithoutDash = env === 'prod' ? '' : env + '-'
const clientId = getClientId(env)

export const msalAuthSettings = {
  config: {
    auth: {
      clientId: clientId,
      authority:
        env === 'prod'
          ? `https://dfdsb2c.b2clogin.com/id.dfds.com/b2c_1a_signin_react/`
          : `https://dfdsb2c${env}.b2clogin.com/${envWithOrWithoutDash}id.dfds.com/b2c_1a_signin_react/`,
      knownAuthorities: env === 'prod' ? ['https://dfdsb2c.b2clogin.com'] : [`https://dfdsb2c${env}.b2clogin.com`],
      postLogoutRedirectUri: getPostLogoutUri(),
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  },
  scopes: [
    `openid https://${envWithOrWithoutDash}id.dfds.com/userprofilestore/CIAM.LoyaltycardApi.Readcurrentuser.Swg https://${envWithOrWithoutDash}id.dfds.com/userprofilestore/CIAM.LoyaltycardApi.Writecurrentuser.Swg https://${envWithOrWithoutDash}id.dfds.com/userprofilestore/CR.LoyaltycardApi.Readcurrentuser.Swg https://${envWithOrWithoutDash}id.dfds.com/userprofilestore/CR.LoyaltycardApi.Writecurrentuser.Swg`,
  ],
}

// Known url for azure AD
// DEV : https://dfdsb2cdev.b2clogin.com/dev-id.dfds.com/b2c_1a_signin_react/v2.0/.well-known/openid-configuration
// TEST: https://dfdsb2ctest.b2clogin.com/test-id.dfds.com/b2c_1a_signin_react/v2.0/.well-known/openid-configuration
// UAT : https://dfdsb2caccept.b2clogin.com/accept-id.dfds.com/b2c_1a_signin_react/v2.0/.well-known/openid-configuration
// PROD: https://dfdsb2c.b2clogin.com/id.dfds.com/b2c_1a_signin_react/v2.0/.well-known/openid-configuration
