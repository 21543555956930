<div class="infoBlock infoBlock--white">
    <div class="collapse-expand-bar"  (click)="toggleCollapseExpand()" [ngClass]="{'expand': expanded, 'collapse': !expanded, 'expandable': expandable }">
        <img *ngIf="expandable" src='/_ng/booking/assets/chevron.svg'>
        <h3>{{InfoBlock.contentRef.title}}</h3>
    </div>
    <div class="infoBlock__textContent" *ngIf="expanded">
        <p [innerHtml]="InfoBlock.contentRef.text | markdown">
        </p>
    </div>
</div>
