import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule, Routes } from '@angular/router'
import { PaymentService } from './payment.service'
import { SharedModule } from '../shared/shared.module'
import { ConfirmationComponent } from './confirmation/confirmation.component'
import { UIModule } from 'app/shared/UI.module'
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service'
import { CreditCardsComponent } from 'app/payment/credit-cards/credit-cards.component'
import { RemainingComponent } from './remaining/remaining.component'
import { FinalizeReservationComponent } from './finalize-reservation/finalize-reservation.component'
import { HttpClientModule } from '@angular/common/http'
import { AuthGuard } from 'app/shared/guards/auth.guard'
import { FakePaymentComponent } from './fake-payment/fake-payment.component'

const appRoutes: Routes = [
    { path: 'payment/outstanding', component: RemainingComponent },
    { path: 'payment/outstanding/confirmation/:reservationCode', component: ConfirmationComponent, data: { mode: 'Amendment', outstanding: true } },
    { path: 'amendment/confirmation/:reservationCode', component: ConfirmationComponent, data: { mode: 'Amendment' } },
    { path: 'payment/amendment/:reservationCode', component: RemainingComponent },
    { path: 'payment/agentpayment/newbookingpayment', component: FinalizeReservationComponent, canActivate: [AuthGuard] },
    { path: 'confirmation/:reservationCode', component: ConfirmationComponent, data: { mode: 'Flow' } },
    { path: 'ecommerce/fake-payment', component: FakePaymentComponent },
]

@NgModule({
    imports: [SharedModule, UIModule, BrowserModule, FormsModule, HttpClientModule, RouterModule.forRoot(appRoutes), ReactiveFormsModule],
    declarations: [ConfirmationComponent, CreditCardsComponent, RemainingComponent, FinalizeReservationComponent, FakePaymentComponent],
    providers: [PaymentService, SalesChannelService],
    exports: [CreditCardsComponent]
})
export class PaymentModule { }
