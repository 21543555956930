import { Injectable } from '@angular/core'

import { DataLayerService } from './data-layer.service'
import { IRestaurantModel } from '../../meals/meals-selector-models'
import { IMealsRestaurantMenuTrack, IMealsChangedHourTrack } from './meals-tracking.interfaces'
import { IFlowReservation } from '../steps/step.model'

@Injectable()
export class MealsTrackingService {

    private restaurant: IRestaurantModel
    private flowReservation: IFlowReservation
    constructor(
        private dataLayerService: DataLayerService) {
    }

    public trackRestaurantMenuPopup(
        flowReservation: IFlowReservation,
        restaurant: IRestaurantModel
    ): void  {
        this.restaurant = restaurant
        this.flowReservation = flowReservation
        let restaurantMenuPopupTrack = this.createRestaurantMenuPopupTrack()
        this.dataLayerService.push(restaurantMenuPopupTrack)
    }

    private createRestaurantMenuPopupTrack(): IMealsRestaurantMenuTrack {
        let mealsRestaurantMenuTrack = <IMealsRestaurantMenuTrack> {
            event: 'meals-restaurant-menu',
            restaurantCode: this.restaurant.code,
            routeCode: this.flowReservation.routeCode
        }
        return mealsRestaurantMenuTrack
    }

    public trackChangedMealHour(event: string, selectedHour) {
        let mealsChangedMealHourTrack = <IMealsChangedHourTrack> {
            event: event,
            selectedHour: selectedHour
        }
        this.dataLayerService.push(mealsChangedMealHourTrack)
    }
}
