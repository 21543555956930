import { Injectable } from '@angular/core'

declare const dataLayer

@Injectable()
export class DataLayerService {

    public push(data) {
        // make a clone to get rid of the prototype info since we don't know how GTM reacts to that
        let pojo = JSON.parse(JSON.stringify(data))
        dataLayer.push(pojo)
    }

}
