import { Component, Input, OnInit, Output, EventEmitter, HostBinding } from '@angular/core'

@Component({
    selector: 'sbw-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

    private _state: ButtonStateType = 'activated'
    @HostBinding('class.is-loading') get isLoading(): boolean { return this.state == 'spinner' }
    @HostBinding('class.active') get active(): boolean { return this.state == 'spinner' }
    @Input() id: string
    @Input() onClickState: ButtonStateType = 'activated'
    @Input() forceLink: boolean
    @Input() name: string

    @Input() set state(s: ButtonStateType) {
        this._state = s
        this.stateChange.emit(this._state)
    }

    get state(): ButtonStateType {
        return this._state
    }

    @Input() link: string
    @Input() text: string
    @Input() type: ButtonType = 'default'
    @Output() clicked = new EventEmitter()
    @Output() stateChange = new EventEmitter<ButtonStateType>()
    public route: string
    public classes: string = ''

    constructor() { }

    ngOnInit() {
        if (this.link && this.isRoute(this.link)) {
            this.route = this.link;
            this.link = null;
        }

        switch (this.type) {
            case 'default':
                this.classes = 'button default-action'
                break;
            case 'preferred':
                this.classes = 'button preferred-action'
                break;
            case 'previous':
                this.classes = 'button previous-action'
                break;
            case 'secondary':
                this.classes = 'button secondary-action'
                break;
            case 'cancel':
                this.classes = 'button cancel-action'
                break;
        }
    }

    private isRoute(link: string) {
        return !this.forceLink && link.indexOf('http') !== 0
    }

    private clickedEvent(e: MouseEvent) {
        e.preventDefault()

        this.state = this.onClickState

        this.clicked.emit(e)
    }
}

export type ButtonType = 'default' | 'preferred' | 'previous' | 'secondary' | 'cancel'
export type ButtonStateType = 'activated' | 'disabled' | 'spinner'
