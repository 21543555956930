<div class="row">
    <div class="column small-12">
        <div class="flow-breadcrumb">
            <div class="row">
                <div class="column small-9 large-12">
                    <ul>
                        <li *ngFor="let item of breadcrumbItems; let i = index" class="hide-for-medium-down" [ngClass]="{'selected': item.IsCurrentStep}">
                            <a *ngIf="item.ShowAsLink" (click)="goBackLink(item.Url)">{{i+1}}. {{item.Title}}</a>
                            <span *ngIf="!item.ShowAsLink">{{i+1}}. {{item.Title}}</span>
                        </li>
                    </ul>
                </div>
                <div class="column small-3 hide-for-large-up">
                    <span class="right">[{{breadcrumbItems.length}}]</span>
                </div>
            </div>
        </div>
    </div>
</div>
