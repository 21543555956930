import { Injectable } from '@angular/core'

import { ContentfulSeabookWebService } from 'app/shared/resources/contentful-seabookweb.service';

@Injectable()
export class CabinDetailsService {

    private cabinDetailsList: CabinDetails[] = []

    constructor( private contentfulSeabookWebService: ContentfulSeabookWebService) {
    }

    findUncachedCabinTypeCodes(cabinTypeCodes: String[]): String[] {
        let missingCabinTypeCodes: String[] = []

        for (let cabinTypeCode of cabinTypeCodes) {
            let existingItem = this.cabinDetailsList.find(f => (f.cabinTypeCode == cabinTypeCode))

            if (!existingItem) {
                missingCabinTypeCodes.push(cabinTypeCode)
            }
        }
        return missingCabinTypeCodes
    }

    loadCabinDetailsForCabinTypeCodes(cabinTypeCodes: String[], route: string): Promise<CabinDetails[]> {

        let uncachedCabinTypeCodes = this.findUncachedCabinTypeCodes(cabinTypeCodes)

        if (uncachedCabinTypeCodes.length) {
            
            let loadCabinDetailsPromise = this.contentfulSeabookWebService.loadCabinDetailsForCabinTypeCodes(uncachedCabinTypeCodes, route).then(result => {
                for (let item of result.items) {
                    let cabinDetailsFields = item.fields
                    this.cabinDetailsList.push(<CabinDetails>{
                        cabinPhotos: cabinDetailsFields.cabinPhotos,
                        cabinTypeCode: cabinDetailsFields.cabinTypeCode,
                        description: cabinDetailsFields.description,
                        headerImage: cabinDetailsFields.headerImage,
                        headline: cabinDetailsFields.headline,
                        contentfulEntry: item
                    })
                }

                let missingCabinTypeCodes = this.findUncachedCabinTypeCodes(uncachedCabinTypeCodes)

                // add the ones that are missing, so it will not be requested again
                for (let cabinTypeCode of missingCabinTypeCodes)
                    this.cabinDetailsList.push(
                        <CabinDetails>{
                            cabinTypeCode: cabinTypeCode
                        })
                
                return this.cabinDetailsList
            })
            return loadCabinDetailsPromise
        }
        
        return Promise.resolve(this.cabinDetailsList)
    }

}

export class CabinDetails {
    cabinPhotos: any
    cabinTypeCode: string
    description: string
    headerImage: any
    headline: string
    contentfulEntry: any
}