import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Title, Country } from 'app/passengers/passengers.models'
import { EnvironmentService } from 'app/environment.service'
import { ResourceService } from 'app/shared/resources/resource.service'
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service'
import { IFlowPassengersWire, IFlowPassengersUserWire } from 'app/passengers/passengers.interfaces'
import { IPassenger } from 'app/shared/models/reservation.interfaces'
import { StepService } from 'app/shared/steps/step.service'
import { QuoteService } from 'app/shared/quote/quote.service'
import { IFlowReservation } from 'app/shared/steps/step.model'
import { IVehicleSelection } from 'app/vehicles/vehicles.interfaces'
import { IAdditionalPayments } from 'app/passengers/additional-payments/additional-payments.component'
import { toParamsObj } from 'app/shared/utils'
import { ITracking } from '../shared/tracking/tracking-wire.interfaces'
import { StatefulHttpService } from 'app/shared/stateful-http.service'
import { StatelessHttpService } from 'app/shared/stateless-http.service'

@Injectable()
export class PassengerService {
  constructor(
    private environmentService: EnvironmentService,
    private resourceService: ResourceService,
    private salesChannelService: SalesChannelService,
    private stepService: StepService,
    private quoteService: QuoteService,
    private statefulHttpService: StatefulHttpService,
    private statelessHttpService: StatelessHttpService
  ) {}

  public getTitles(salesOwnerId: string, locale: string): Observable<Title[]> {
    let params = {
      salesOwnerId: salesOwnerId,
      locale: locale,
    }

    return this.statelessHttpService.get<Title[]>(false, 'titles', { params: toParamsObj(params) })
  }

  public getCountries(locale: string): Observable<Country[]> {
    return this.statelessHttpService.get<Country[]>(false, 'countries', { params: toParamsObj({ locale: locale }) })
  }

  public getPassengersFlow(): Observable<IFlowPassengersWire> {
    const reservation: IFlowReservation = this.stepService.combineToReservation()
    const vtt = reservation.vehicleAndTrailerTypes && reservation.vehicleAndTrailerTypes[0]

    let params = {
      user: sessionStorage.getItem('pax_Sub'),
      salesOwner: this.resourceService.getSalesOwner(),
      locale: this.resourceService.getLocale(),
      salesChannel: this.salesChannelService.getCode(),
      currency: reservation.currencyCode,
      fullAmount: this.quoteService.getQuoteFromSessionStorage().totalPrice.toString(),
      firstEventDate: reservation.outboundDepartureDate,
      route: reservation.routeCode,
      vehicleType: vtt && vtt.vehicleType,
      trailerType: vtt && vtt.trailerType,
      products: reservation.productCode, // We only hand in the productCode (same for out and return) as we for some reason don't have a productCode for both out and return?
      productCode: reservation.productCode,
      hasAccommodation: reservation.hasAccommodation !== null && reservation.hasAccommodation ? true : false,
    }
    return this.statefulHttpService.post<IFlowPassengersWire>(true, 'initiate-passengers-flow', {}, { params: toParamsObj(params) })
  }

  public postHybrid(
    creditCardCode: string,
    additionalPayments: IAdditionalPayments,
    depositCode: string,
    passengers: IPassenger[],
    vehicles: IVehicleSelection[],
    userProfile: IFlowPassengersUserWire,
    agent: IAgentInfo,
    scrapingHints: { route; card }
  ): Observable<IHybridResult> {
    if (this.environmentService.getSbMockBackend()) {
    } //console.log('change url so that it points to getSandbox')}
    let postModel = this.mapModel(creditCardCode, additionalPayments, depositCode, passengers, vehicles, userProfile, agent, scrapingHints)
    return this.statefulHttpService.post<IHybridResult>(true, 'passengers-flow', postModel)
  }

  private mapModel(
    creditCardCode: string,
    additionalPayments: IAdditionalPayments,
    depositCode: string,
    passengers: IPassenger[],
    vehicles: IVehicleSelection[],
    userProfile: IFlowPassengersUserWire,
    agent: IAgentInfo,
    scrapingHints: { route; card }
  ) {
    if (userProfile) {
      userProfile.salesOwner = this.resourceService.getSalesOwner()
    } // only nessesary on post
    return <IHybridPost>{
      salesChannel: userProfile ? 'PIB' : '3PD',
      salesTeam: this.resourceService.getSalesTeam(),
      creditCardCode: creditCardCode,
      campaignCode: additionalPayments && additionalPayments.campaignCode,
      depositCode: depositCode,
      passengers: passengers.map(
        (p) =>
          <IPostPassenger>{
            category: p.customerCategory,
            titleCode: p.title,
            firstName: p.firstName,
            lastName: p.lastName,
            birthDate: p.dateOfBirth,
            nationalityCode: p.nationalityCode,
            passportNo: p.passportNumber,
          }
      ),
      vehicles,
      userProfile: userProfile,
      agent: agent,
      scrapingHints,
    }
  }
}

interface IHybridPost {
  salesChannel: string
  creditCardCode: string
  campaignCode: string
  passengers: IPostPassenger[]
  userProfile: IFlowPassengersUserWire
  agent: IAgentInfo
  vehicles: IVehicleSelection[]
}

interface IPostPassenger {
  category: string
  titleCode: string
  firstName: string
  lastName: string
  birthDate: string
  nationalityCode: string
  passportNo: string
}

export interface IAgentInfo {
  agentReference: string
  contactPhoneNumber: string
  contactPhoneNumberPrefix: string
}

export interface IHybridResult {
  nextStepUrl: string
  errorList: ErrorMessage[]
  quote: any
  reservationCode
  trackingData: ITracking
}

export interface ErrorMessage {
  message: string
  errorCode: number
}
