import { Injectable } from '@angular/core';
import { CookieService } from 'app/shared/cookie.service'

@Injectable()
export class SalesChannelService {

    constructor(private cookieService: CookieService) { }

    public getCode() {
        const authType = this.cookieService.getAuthType()

        return authType && authType === 'agent' ? '3PD' : 'PIB'
    }
}
