import { Injectable } from '@angular/core';
import { ContentfulGenericPage } from './contentful-generic-page';
import { ContentfulContentRef } from '../contentful-content-ref/contentful-content-ref';
import { ContentfulContentRow } from '../contentful-content-row/contentful-content-row';
import { ContentfulInfoBlock } from '../contentful-info-block/contentful-info-block';
import { ContentfulDfdsService } from '../../shared/resources/contentful-dfds.service';
import { ContentfulHeadlineBlock } from '../contentful-headline-block/contentful-headline-block';


@Injectable()
export class ContentfulGenericPageService {

    constructor(private contentfulDfdsService: ContentfulDfdsService) { }

    getGenericPage(urlSlugEntryTitle: string) : Promise<ContentfulGenericPage>
    {
        return new Promise((res, rej) => {

            this.contentfulDfdsService.getUrlSlugByEntryTitle(urlSlugEntryTitle).then(entries => {
                if (!entries.length)
                    return res(null)
                let firstEntry = entries[0]
                let wireGenericPage = firstEntry.fields.page
                let genericPage = this.mapContentPage(wireGenericPage)

                res(genericPage)
            })
        })
    }



    mapContentPage(wireGenericPage: any): ContentfulGenericPage {
        // Page structure:
        // ----------------
        // GenericPage
        //   ContentRow[]
        //     ContentEntry[]
        //       ContentRef.Text

        let genericPage = new ContentfulGenericPage()
        genericPage.heroBlock = Object.assign(wireGenericPage.fields.heroBlock.fields, new ContentfulHeadlineBlock())
        genericPage.contentRows = new Array()

        wireGenericPage.fields.contentRows.forEach(wireContentRow => {

            let contentRow = new ContentfulContentRow()

            genericPage.contentRows.push(contentRow)
            contentRow.entries = new Array()
            wireContentRow.fields.contentEntry.forEach(wireContentEntry => {

                let infoBlock: ContentfulInfoBlock = Object.assign(wireContentEntry.fields, new ContentfulContentRef())
                infoBlock.contentRef = Object.assign(wireContentEntry.fields.contentRef.fields, new ContentfulContentRef())

                contentRow.entries.push(infoBlock)
            })
        })

        return genericPage
    }

}
