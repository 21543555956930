import { Component, OnInit } from '@angular/core'
import { CookieService } from '../../shared/cookie.service'

@Component({
    selector: 'sbw-agent-menu',
    templateUrl: './agent-menu.component.html',
    styleUrls: ['./agent-menu.component.css']
})
export class AgentMenuComponent implements OnInit {

    public isAgent: boolean
    
    constructor(private cookieService: CookieService) {}

    ngOnInit() {                
        const authType = this.cookieService.getAuthType()
        this.isAgent = authType === 'agent'
    }
}
