import { Injectable } from "@angular/core";
import { StatelessHttpService } from "app/shared/stateless-http.service";

@Injectable()
export class AgentRegisterService {
    constructor(private statelessHttpService: StatelessHttpService) {}

    getSalesOwners() {
        return this.statelessHttpService.get<{ id, name }[]>(
            false,
            "agent/register"
        );
    }

    register(body: { salesOwner: string; abta: string; password: string, password2: string }) {
        return this.statelessHttpService.post<{ abtaError: string, error: string, intro: string }>(
            false,
            "agent/register",
            body
        );
    }
}
