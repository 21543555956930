<div class="column">
    <div class="row small-gutter show-for-large-up">
        <div class="gutter">
            <h4 class="column small-12 large-4 label">
                <label for="'vehicleRegistration_' + index">
                    {{vehicleName}}* ({{index + 1}})
                </label>
            </h4>
            <h4 *ngIf="trailerRegistrationNumber.enabled" class="column small-12 large-4 small end">
                <label [attr.for]="'trailerRegistration_' + index">
                    {{trailerName}}* ({{index + 1}})
                </label>
            </h4>
        </div>
        <div class="column small-12">
            <div class="row">
                <div class="column small-12 large-4">
                    <input type="text" data-hj-suppress [formControl]="vehicleRegistrationNumber" [id]="'vehicleRegistration_' + index"
                        placeholder="{{ 'PASSENGERS_VEHICLE_DETAILS_TYPE' | resource }} {{ 'PASSENGERS_VEHICLE_DETAILS_REGISTRATION' | resource }}" />
                    <span *ngIf="(validationError && vehicleRegistrationNumber.invalid) || (vehicleRegistrationNumber.invalid && vehicleRegistrationNumber.touched)"
                        class="field-validation-error">{{'VALIDATION_PASSENGERS_VEHICLE_DETAILS_REGISTRATION_NOT_EMPTY' | resource}}</span>
                </div>
                <div *ngIf="trailerRegistrationNumber.enabled" class="column small-12 large-4 end">
                    <input type="text" data-hj-suppress [formControl]="trailerRegistrationNumber"
                        [id]="'trailerRegistration_' + index"
                        placeholder="{{ 'PASSENGERS_VEHICLE_DETAILS_TYPE' | resource }} {{ 'PASSENGERS_VEHICLE_DETAILS_REGISTRATION' | resource }}" />
                    <span *ngIf="(validationError && trailerRegistrationNumber.invalid) || (trailerRegistrationNumber.invalid && trailerRegistrationNumber.touched)"
                        class="field-validation-error">{{'VALIDATION_PASSENGERS_VEHICLE_DETAILS_TRAILER_REGISTRATION_NOT_EMPTY' | resource}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row small-gutter show-for-medium-down">
        <div class="gutter vehicle-section">
            <h4 class="column small-12 quater-gutter label">
                <label [attr.for]="'vehicleRegistrationSmall_' + index">
                    {{vehicleName}} ({{index + 1}})
                </label>
            </h4>
            <div class="column small-12 quater-gutter">
                <input type="text" data-hj-suppress [formControl]="vehicleRegistrationNumber" [id]="'vehicleRegistrationSmall_' + index"
                    placeholder="{{ 'PASSENGERS_VEHICLE_DETAILS_TYPE' | resource }} {{ 'PASSENGERS_VEHICLE_DETAILS_REGISTRATION' | resource }}" />
                <span *ngIf="(validationError && vehicleRegistrationNumber.invalid) || (vehicleRegistrationNumber.invalid && vehicleRegistrationNumber.touched)"
                    class="field-validation-error">{{'VALIDATION_PASSENGERS_VEHICLE_DETAILS_REGISTRATION_NOT_EMPTY' | resource}}</span>
            </div>

            <h4 *ngIf="trailerRegistrationNumber.enabled" class="column small-12 quater-gutter label">
                <label for="'trailerRegistrationSmall_' + index">
                    {{trailerName}} ({{index + 1}})
                </label>
            </h4>
            <div *ngIf="trailerRegistrationNumber.enabled" class="column small-12 quater-gutter">
                <input type="text" data-hj-suppress [formControl]="trailerRegistrationNumber"
                    [id]="'trailerRegistrationSmall_' + index"
                    placeholder="{{ 'PASSENGERS_VEHICLE_DETAILS_TYPE' | resource }} {{ 'PASSENGERS_VEHICLE_DETAILS_REGISTRATION' | resource }}" />
                <span *ngIf="(validationError && trailerRegistrationNumber.invalid) || (trailerRegistrationNumber.invalid && trailerRegistrationNumber.touched)"
                    class="field-validation-error">{{'VALIDATION_PASSENGERS_VEHICLE_DETAILS_TRAILER_REGISTRATION_NOT_EMPTY' | resource}}</span>
            </div>
        </div>
    </div>
</div>
