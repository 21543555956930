import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'

import { MealWire } from 'app/meals/meals.interfaces'
import { IPassenger, IReservationErrorWire, IDepartureSelection } from 'app/shared/models/reservation.interfaces'
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service'
import { ITpaItemSelection } from 'app/tpa/tpa.interfaces'
import { IVehicleEditSelection } from 'app/vehicles/vehicles.interfaces'
import { ICabinDepartureSelection } from 'app/cabins/cabins.interfaces'
import { ICabinAllocationEditSelection } from 'app/cabin-allocations/cabin-allocations.interface'
import { ISnaphotDeparture } from '../snapshot.service';
import { Fare } from 'app/fares/fares.interfaces';
import { StatelessHttpService } from '../stateless-http.service'

@Injectable()
export class AmendService {

    constructor(
        private statelessHttpService: StatelessHttpService,
        private salesChannelService: SalesChannelService,
    ) { }

    public changeDepartures(reservationCode: string, departures: IDepartureSelection[], dryRun = true): Observable<IReservationErrorWire> {
        let model = <IEditReservation>{
            reservationCode,
            departures
        }
        return this.editReservation(model)
    }

    public getDepartureSelection(departures: Fare.IOutReturn<ISnaphotDeparture>): IDepartureSelection[] {
        if (!departures) return null;
        let fareSelection: IDepartureSelection[] = []
        if (departures.out) { fareSelection.push({ oldDepartureId: departures.out.oldId, newDepartureId: departures.out.id, }) }
        if (departures.return) { fareSelection.push({ oldDepartureId: departures.return.oldId, newDepartureId: departures.return.id, }) }
        return fareSelection
    }

    public changePassengers(reservationCode: string, passengers: IPassenger[], dryRun = true): Observable<IReservationErrorWire> {
        let model = <IEditReservation>{
            reservationCode,
            passengers
        }
        return this.editReservation(model)
    }

    public changeMeals(reservationCode: string, meals: MealWire[], dryRun = true): Observable<IReservationErrorWire> {
        let model = <IEditReservation>{
            reservationCode,
            meals
        }
        return this.editReservation(model)
    }

    public changeTpas(reservationCode: string, tpas: ITpaItemSelection[], dryRun = true): Observable<IReservationErrorWire> {
        let model = <IEditReservation>{
            reservationCode,
            salesChannelCode: this.salesChannelService.getCode(),
            tpas
        }
        return this.editReservation(model)
    }

    public changeVehicles(reservationCode: string, vehicles: IVehicleEditSelection, dryRun = true): Observable<IReservationErrorWire> {
        let model = <IEditReservation>{
            reservationCode,
            salesChannelCode: this.salesChannelService.getCode(),
            vehicles
        }
        return this.editReservation(model)
    }

    public changeCabins(reservationCode: string, cabins: ICabinDepartureSelection[], dryRun = true): Observable<IReservationErrorWire> {

        let model = <IEditReservation>{
            reservationCode,
            salesChannelCode: this.salesChannelService.getCode(),
            cabins
        }
        return this.editReservation(model)
    }

    public changeCabinAllocation(reservationCode: string, cabinAllocations: ICabinAllocationEditSelection[], dryRun = true) {
        let model = <IEditReservation>{
            reservationCode,
            salesChannelCode: this.salesChannelService.getCode(),
            cabinAllocations
        }
        return this.editReservation(model)
    }

    public patchReservation(reservationCode: string, isMiniCruise: boolean, snapDepartures: Fare.IOutReturn<ISnaphotDeparture>, meals: MealWire[], passengers: IPassenger[], tpas: ITpaItemSelection[], vehicles: IVehicleEditSelection, cabins: ICabinDepartureSelection[], cabinAllocations: ICabinAllocationEditSelection[]): Observable<IReservationErrorWire> {
        let model = <IEditReservation>{
            reservationCode,
            isMiniCruise,
            salesChannelCode: this.salesChannelService.getCode(),
            departures: this.getDepartureSelection(snapDepartures),
            passengers,
            meals,
            tpas,
            vehicles,
            cabins,
            cabinAllocations
        }
        return this.editReservation(model, false)
    }

    public cancelReservation(reservationCode: string): Observable<IReservationErrorWire> {
        return this.statelessHttpService.delete(`reservation/${reservationCode}`)
    }

    public getTotals(reservationCode: string, isMiniCruise: boolean, snapDepartures: Fare.IOutReturn<ISnaphotDeparture>, meals: MealWire[], passengers: IPassenger[], tpas: ITpaItemSelection[], vehicles: IVehicleEditSelection, cabins: ICabinDepartureSelection[], cabinAllocations: ICabinAllocationEditSelection[]): Observable<IReservationErrorWire> {
        let model = <IEditReservation>{
            reservationCode,
            isMiniCruise,
            departures: this.getDepartureSelection(snapDepartures),
            passengers,
            meals,
            tpas,
            vehicles,
            cabins,
            cabinAllocations
        }
        return this.editReservation(model, true)
    }

    private editReservation(editReservation: IEditReservation, dryRun = true): Observable<IReservationErrorWire> {

        let clearCache$ = of('')
        if (!dryRun) {
            // Since dryRun is false we know that this resource will soon change. After the patch is completed
            // We instruct the browser to fetch the reservation again which will clear the cached version.
            // The server only sends cache headers on confirmed requests so the browser will see that there is no
            // cache headers so this reservation will not be cached again until it is confirmed again
            clearCache$ = this.clearCachedReservation(editReservation.reservationCode)
        }
        let patch$ = this.statelessHttpService.patch<IReservationErrorWire>(`reservation/${editReservation.reservationCode}/patch?dryRun=${dryRun}`, editReservation)
        return new Observable(o => {
            patch$.subscribe(p => {
                clearCache$.subscribe(c => o.next(p), e => o.error(e))
            }, e => o.error(e))
        })
    }

    public clearCachedReservation(reservationCode: string): Observable<any> {
        let headers = { 'Cache-Control': 'max-age=0' }
        return this.statelessHttpService.get(true, `reservation/${reservationCode}/authorized`, { headers })
    }

    public clearCachedReservationWithToken(reservationCode: string, token: string): Observable<any> {
        return this.statelessHttpService.get(false, `reservation/${reservationCode}/authorized`, { headers: this.getClearCacheAndTokenHeaders(token) })
    }

    private getClearCacheAndTokenHeaders(token: string): any {
        return {
            'Cache-Control': 'max-age=0',
            'Authorization': `DFDSSB ${token}`
        }
    }
}

export interface IEditReservation {
    reservationCode: string
    isMiniCruise?: boolean
    salesChannelCode: string
    departures?: IDepartureSelection[]
    passengers?: IPassenger[]
    meals?: MealWire[]
    tpas?: ITpaItemSelection[]
    vehicles?: IVehicleEditSelection
    cabins?: ICabinDepartureSelection[]
    cabinAllocations?: ICabinAllocationEditSelection[]
}
