<ng-container *ngIf="cabinGroup">

    <div class="content">
        <p *ngIf="cabinGroup.legend" [innerHtml]="cabinGroup.legend | markdown">
        </p>
    </div>

    <div class="row carrousel">
        <div *ngFor="let img of carrouselWindow(); index as idx" class="box column small-6">
            <span *ngIf="idx == 0 && showArrows()" class="prevnext"><a (click)="prev()">&nbsp;</a>&nbsp;</span>
            <div class="imageContainer">
                <img class="carrouselCabinPhoto" [src]="img.fields.file.url">
                <br>{{img.fields.title}}
            </div>
            <span *ngIf="idx == 1 && showArrows()" class="prevnext next">&nbsp;<a (click)="next()">&nbsp;</a></span>
        </div>
    </div>

</ng-container>
