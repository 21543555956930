import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CommonModule } from '@angular/common'
import { AmendmentCabinsComponent } from './amendment-cabins/amendment-cabins.component'
import { CabinsSelectorComponent } from './cabins-selector/cabins-selector.component'
import { CabinsCategoryGroupComponent } from './cabins-category-group/cabins-category-group.component'
import { SharedModule } from '../shared/shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { CabinsService } from 'app/cabins/cabins.service'
import { CabinsRowComponent } from './cabins-row/cabins-row.component'
import { FlowCabinsComponent } from './flow-cabins/flow-cabins.component'
import { UIModule } from 'app/shared/UI.module'
import { FlowGuard } from 'app/shared/guards/flow.guard'
import { CabinDetailsComponent } from './cabin-details/cabin-details.component';
import { MarkdownModule } from 'ngx-markdown';
import { CabinGroupComponent } from './cabin-group/cabin-group.component';
import { AuthGuard } from 'app/shared/guards/auth.guard'

const appRoutes: Routes = [
    { path: 'cabins', component: FlowCabinsComponent, canActivate: [FlowGuard] },
    { path: 'amendment/booking/:reservationCode/cabins', component: AmendmentCabinsComponent, canActivate: [AuthGuard] }
]

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(appRoutes),
        SharedModule,
        UIModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        MarkdownModule.forRoot()
    ],
    declarations: [
        AmendmentCabinsComponent,
        CabinsSelectorComponent,
        CabinsCategoryGroupComponent,
        CabinsRowComponent,
        FlowCabinsComponent,
        CabinDetailsComponent,
        CabinGroupComponent
    ],
    exports: [
        CabinDetailsComponent,
        CabinGroupComponent
    ],
    providers: [CabinsService]
})
export class CabinsModule { }
