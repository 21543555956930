import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core'
import { QuoteService } from './quote.service'
import { Quote } from './quote.models'
import { QuoteChanged } from 'app/shared/quote/quote.events'
import { Observable, combineLatest, BehaviorSubject } from 'rxjs'
import { LayoutState } from 'app/shared/layout/layout-state'

@Component({
  selector: 'sbw-quote',
  styleUrls: ['./quote.component.css'],
  templateUrl: './quote.component.html',
})
export class QuoteComponent implements OnInit, OnChanges {
  @Input() step: string
  @Input() showDepartureTime = true
  @Input() showPrice = true
  @Input() show = true
  @Input() quote: Observable<QuoteChanged>
  @Input() useWithLayout = false // This can be removed when all steps with quote use the new layout

  private quote$ = new BehaviorSubject<QuoteChanged>(null)
  public model: Quote.QuoteModel

  _isLoading = true
  get isLoading() {
    return !this.useWithLayout ? !this.model || !this.show : this._isLoading || !this.model
  }
  set isLoading(value) {}

  constructor(private quoteService: QuoteService, private layoutState: LayoutState) {}

ngOnInit() {
    this.quoteService.initialize()

    this.layoutState.setIsQuoteLoading(true)
    this.layoutState.isQuoteLoading$.subscribe((x) => (this._isLoading = x))
    this.quote$ = new BehaviorSubject<QuoteChanged>(null)

    combineLatest([this.quoteService.getQuoteData(this.step), this.quote$]).subscribe(([backend, user]) => {
        this._isLoading = !!backend.isUpdating
        this.model = this.quoteService.merge(backend, user)
        this.layoutState.setIsQuoteLoading(this._isLoading)
    })
}

  ngOnChanges(changes: SimpleChanges): void {
    let { quote } = changes
    if (quote && quote.currentValue) quote.currentValue.subscribe((s) => this.quote$.next(s))
  }
}
