import { Component, Input, SimpleChanges, Output, EventEmitter, OnInit, OnChanges, AfterViewChecked } from '@angular/core'
import { QuoteChanged } from 'app/shared/quote/quote.events'
import { Observable, of } from 'rxjs'
import { LayoutState } from 'app/shared/layout/layout-state'
import { AbstractControl } from '@angular/forms'
import { FlowOrAmendment, isBrowserStorageSupported } from 'app/shared/utils'
import { Subscription } from 'rxjs'
import { LoggingService } from 'app/shared/logging.service'
import { TrackingService } from '../tracking/tracking.service';
import { debounceTime } from 'rxjs/operators';
import { CookieService } from '../cookie.service'

@Component({
  selector: 'sbw-layout',
  templateUrl: './layout.component.html',
  styleUrls: ["./layout.component.css"]
})

export class LayoutComponent implements OnInit, OnChanges, AfterViewChecked {

  private stickyElement: HTMLElement
  private buttonStackElement: HTMLElement
  private bodyElement: HTMLElement

  @Input() backButtonResource = 'BACK'
  @Input() backButtonVisible = false
  @Input() backButtonDisabled = false
  @Output() backEvent = new EventEmitter()

  @Input() continueButtonResource = 'CONTINUE'
  @Input() continueButtonVisible = true
  @Input() continueButtonDisabled = false
  @Input() continueButtonAlwaysEnabled = false
  @Output() continueEvent = new EventEmitter()
  @Output() validationEvent = new EventEmitter()

  @Input() footerVisible = true
  @Input() menuVisible = true

  @Input() form: AbstractControl
  @Input() isAgent = false
  @Input() type: FlowOrAmendment = 'Flow'
  @Input() hideDisclaimer = false
  @Input() amendmentPage = false

  @Input() quoteModel: Observable<QuoteChanged> = of<QuoteChanged>(null)
  @Input() quoteShowTotalPrice = false
  @Input() quoteShowDepartureTime = false
  @Input() quoteStep: string

  @Input() showPaddingAndBackground: boolean = true

  footerComponent = 'Common'
  isLoading = false
  isQuoteLoading = true
  isContinuing = false

  isFormValid = true
  isBrowserStorageSupported = false

  private sub: Subscription

  get continueButtonState() {
    let doneWithLoading = this.isQuoteLoading == false && this.isLoading == false

    let result = !doneWithLoading || this.continueButtonDisabled || !this.isBrowserStorageSupported ? 'disabled' : (!this.isFormValid && !this.continueButtonAlwaysEnabled ? 'disabled' : 'activated')
    if (this.isContinuing) { result = 'spinner' }

    return result
  }

  set continueButtonState(v) {

  }

  get backButtonState() {
    return this.isLoading || this.backButtonDisabled ? 'disabled' : ''
  }

  set backButtonState(v) { }

  constructor(private layoutState: LayoutState, private loggingService: LoggingService, private trackingService: TrackingService, private cookieService: CookieService) { }

  ngAfterViewChecked(): void {
    if (!this.stickyElement || !this.buttonStackElement) {
      this.loadStickyConfiguration()
    }
  }

  getContinueEvent() {
    if (!this.form) {
      this.continueEvent.emit()
      return
    } 
    if (this.form && !this.form.valid ) {
      this.validationEvent.emit()
      return
    }   
    this.continueEvent.emit()    
  }

  loadStickyConfiguration(): void {
    this.stickyElement = document.getElementById('layout-sticky-container')
    this.buttonStackElement = document.getElementById('layout-button-container')
    this.bodyElement = document.getElementsByTagName('body')[0]

    if (this.stickyElement && this.buttonStackElement) {
      // For some reason it works if I set some timeout
      setTimeout(() => {
      let bodyPosition: number = this.bodyElement.getBoundingClientRect().top
      let stickyElementPosition: number;
      let buttonStackPosition: number;

      document.addEventListener('scroll', (e) =>{
        stickyElementPosition = this.stickyElement.getBoundingClientRect().top - bodyPosition
        buttonStackPosition = this.buttonStackElement.getBoundingClientRect().top - bodyPosition

        if (stickyElementPosition - buttonStackPosition <= 0) {
          this.stickyElement.style.visibility = 'visible'
        }

        if (stickyElementPosition - buttonStackPosition > 0) {
          this.stickyElement.style.visibility = 'hidden'
        } 
      })
    }, 2000)
    }
  }

  ngOnInit(): void {
    this.footerComponent = this.isAgent || this.cookieService.getAuthType() == 'agent' ? 'Agent' : 'Common'
    document.documentElement.scrollTop = 0;

    this.isBrowserStorageSupported = isBrowserStorageSupported()

    if (!this.isBrowserStorageSupported) {
      this.loggingService.logInfo({ message: 'Storage in browser is not supported on this device' })
      this.trackingService.trackBrowserNotSupported();
    }

    this.layoutState.isLoading$.pipe(debounceTime(1)).subscribe(x => { this.isLoading = x })
    this.layoutState.isQuoteLoading$.pipe(debounceTime(1)).subscribe(x => this.isQuoteLoading = x)
    this.layoutState.isContinueLoading$.pipe(debounceTime(1)).subscribe(x => this.isContinuing = x)
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { form } = changes
    if (!form) { return }
    const formGroup = <AbstractControl>form.currentValue
    if (!formGroup && !(formGroup instanceof AbstractControl)) { return }
    if (this.sub) {
      this.sub.unsubscribe()
    }
    this.isFormValid = formGroup.valid
    this.sub = formGroup.statusChanges.subscribe(s => this.isFormValid = s === 'VALID')
  }
}
