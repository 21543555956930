<sbw-layout
    [backButtonVisible]="false"
    type="Amendment"
    [isAgent]="true"
    [continueButtonVisible]="false"
    [menuVisible]="false"
>
    <div class="sbw_pagewbox">
        <div class="row uncollapse agent-profile-container">
            <h1>{{ "REGISTER_NEW_AGENT_ACCOUNT" | resource }}</h1>
        </div>
        <div *ngIf="intro || error">
            <p *ngIf="intro" [innerHtml]="intro | resource"></p>
            <p class="field-validation-error" *ngIf="error" [innerHtml]="error | resource"></p>
        </div>
        <div class="row">
            <div class="small-12 column gutter">
                <div
                    class="agent-register-container"
                    [innerHtml]="'REGISTER_NEW_AGENT_MESSAGE' | resource"
                ></div>
                <h3 [innerHtml]="'REGISTER_NEW_AGENT' | resource"></h3>
                <div class="row spacer">
                    <div class="column small-12">
                        <h5>{{ "MARKET" | resource }}</h5>
                        <select [formControl]="group.controls.salesOwner">
                            <option value=""> -</option>
                            <option *ngFor="let so of salesOwners" [value]="so.id">{{ so.name }}</option>
                        </select>
                        <span *ngIf="group.controls.salesOwner.touched && group.controls.salesOwner.errors" class="field-validation-error">{{'VALIDATION_MARKET_EMPTY' | resource}}</span>
                    </div>
                </div>
                <div class="row spacer">
                    <div class="column small-12">
                        <h5>{{ "AGENT_ACCOUNT_NUMBER" | resource }}</h5>
                        <input type="text" [formControl]="group.controls.abta" />
                        <span *ngIf="group.controls.abta.touched && group.controls.abta.errors" class="field-validation-error">{{'VALIDATION_ACCOUNT_NUMBER_EMPTY' | resource}}</span>
                        <span *ngIf="abtaError" class="field-validation-error">{{abtaError | resource}}</span>
                    </div>
                </div>
                <div class="row spacer">
                    <div class="column small-12">
                        <h5>{{ "ACCOUNT_NEW_PASSWORD" | resource }}</h5>
                        <input type="password" [formControl]="group.controls.password" />
                        <ng-container *ngIf="group.controls.password.touched && group.controls.password.errors">
                            <span *ngIf="group.controls.password.errors.pattern" class="field-validation-error">{{'INVALID_PASSWORD_SIZE' | resource}}</span>
                            <span *ngIf="group.controls.password.errors.required" class="field-validation-error">{{'ACCOUNT_EMPTY_NEW_PASSWORD_FIELD' | resource}}</span>
                        </ng-container>
                    </div>
                </div>
                <div class="row spacer">
                    <div class="column small-12">
                        <h5>{{ "ACCOUNT_CONFIRM_PASSWORD" | resource }}</h5>
                        <input type="password" [formControl]="group.controls.password2" />
                        <span *ngIf="group.controls.password2.touched && group.errors" class="field-validation-error">{{'ACCOUNT_NOT_MATCHING_PASSWORD_FIELDS' | resource}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column small-6">
                        <sbw-button (clicked)="register()" [state]="(group.status === 'INVALID' || waiting) ? 'disabled' : null" type="preferred" [text]="'ACCOUNT_REGISTER' | resource"></sbw-button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</sbw-layout>
