<form [formGroup]="form">
    <div class="group-item" *ngIf="form">
        <div class="column small-7 medium-9 option-name">
            {{ form.value.pet.name }}

            <div class="price">
                +{{reservation.currencyCode | resource}}{{form.value.pet.price}}
            </div>
        </div>
        <div class="column small-5 medium-3 option-count selectFlex">
            <div class="selectFlexItem">
                <select formControlName="count">
                    <option *ngFor="let option of form.value.options" [ngValue]="option">
                        {{ option }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</form>