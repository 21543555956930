import { Injectable } from '@angular/core'

import { StorageService } from '../../storage.service'
import { DataLayerService } from '../data-layer.service'
import { IEnhancedEcommerceTrack, IECommerceTrack, IPurchaseTrack, IProductTrack, IPurchaseAction } from './ecommerce-tracking.interfaces'
import { IReservation } from 'app/shared/models/reservation.interfaces'
import { MarketService } from 'app/shared/market.service'
import { LocaleService } from 'app/shared/locale-service'
import { ResourceService } from 'app/shared/resources/resource.service'

@Injectable()
export class EcommerceAmendmentTrackingService {

    constructor(
        private dataLayerService: DataLayerService,
        private storageService: StorageService,
        private resourceService: ResourceService,
        private localeService: LocaleService,
        private marketService: MarketService) {
    }

    public track(reservation: IReservation): void {
        let enhancedEcommerceTrack = this.createEnhancedEcommerceTrack(reservation)
        this.dataLayerService.push(enhancedEcommerceTrack)
    }

    public saveTrackingData(data: IReservation) {
        this.storageService.setSbwAmendmentTracking(JSON.stringify(data))
    }

    private createEnhancedEcommerceTrack(reservation: IReservation): IEnhancedEcommerceTrack {
        const host = window.location.host.toLowerCase()
        const useUnifiedGTM = host.indexOf('.dfds.com') > 0 || host.indexOf('localhost') >= 0

        const payingPassengers = reservation.passengers.filter(x => x.customerCategory === 'ADULT' || x.customerCategory === 'CHILD').length
        const ret = reservation.departures[1]
        const outbound = reservation.departures[0]

        const enhancedEcommerceTrack = <IEnhancedEcommerceTrack>{
            event: 'purchase',
            businessArea: 'passenger',
            isReturnBooking: reservation.departures[1] ? 'Yes' : 'No',
            ecommerce: this.createEcommerce(reservation),
            countryCode: this.localeService.getUnifiedCountryCode(),
            languageCode: this.localeService.getUnifiedLanguageCode(),
            market: this.marketService.getMarket(),
            bookingEngine: useUnifiedGTM ? 'seabookAmendment' : 'seabook',

            numberAdults: '' + reservation.passengers.filter(x => x.customerCategory === 'ADULT').length,
            numberChildren: '' + reservation.passengers.filter(x => x.customerCategory === 'CHILD').length,
            numberInfants: '' + reservation.passengers.filter(x => x.customerCategory === 'INFANT').length,

            outboundPassengers: payingPassengers.toString(),
            returnPassengers: payingPassengers.toString(),
            outboundDepartureDate: outbound.departureDateTime && outbound.departureDateTime.toString().replace('T', ' '),
            returnDepartureDate: ret && ret.departureDateTime && ret.departureDateTime.toString().replace('T', ' '),
            fullRouteCode: reservation.routeCode,
            mealsIncluded: reservation.departures.some(d => d.rooms.length) ? 'Yes' : 'No',
            routeName: outbound.routeName,
            routeCode: reservation.routeCode,
            salesOwner: this.resourceService.getSalesOwner(),
            vehicleIncluded: outbound.vehicles.length ? 'Yes' : 'No',
            vehicleType: outbound.vehicles.length ? outbound.vehicles[0].description : '',
            arrivalPortName: outbound.routeName,
            departurePortName: ret && ret.routeName,
            bookingType: reservation.productCode && reservation.productCode[0],
            outwardRouteCode: outbound.routeCode,
            returnRouteCode: ret && ret.routeCode,
            productCode: reservation.productCode
        }
        return enhancedEcommerceTrack
    }

    private createEcommerce(reservation: IReservation): IECommerceTrack {
        let ecommerceTrack = <IECommerceTrack>{}
        ecommerceTrack.purchase = <IPurchaseTrack>{
            actionField: <IPurchaseAction>{
                affiliation: 'SeaBook',
                id: reservation.reservationCode,
                coupon: reservation.offerCode,
                currencyCode: reservation.currencyCode,
                totalPaid: (reservation.totalPaid).toFixed(2),
                revenue: (reservation.totalPrice).toFixed(2),
                agent: localStorage.getItem('sbw_AgentGenericId'),
                shipping: "0",
                tax: "0",
                deposit: false,
                paymentMethod: reservation.creditCard,
                passengersAdults: '' + reservation.passengers.filter(x => x.customerCategory === 'ADULT').length,
                passengersChild: '' + reservation.passengers.filter(x => x.customerCategory === 'CHILD').length,
                passengersInfant: '' + reservation.passengers.filter(x => x.customerCategory === 'INFANT').length,
                outboundDepartureDate: reservation.departures[0].departureDateTime && reservation.departures[0].departureDateTime.toString().replace('T', ' '),
                returnDepartureDate: reservation.departures[1] && reservation.departures[1].departureDateTime && reservation.departures[1].departureDateTime.toString().replace('T', ' ')
            },
            products: this.getProducts(reservation)
        }
        return ecommerceTrack
    }

    private getProducts(reservation: IReservation): IProductTrack[] {
        let products = this.getProduct(reservation)
            .concat(this.getMeals())
            .concat(this.getTpas(reservation))
            .concat(this.getVouchers(reservation))
            .concat(this.getVehicles(reservation))
            .concat(this.getCabins(reservation))
        return products
    }

    private getProduct(reservation: IReservation): IProductTrack[] {
        let products: IProductTrack[] = []
        let product = this.createProduct(reservation, reservation.productCode, reservation.productDescription, 0, 1, 'outbound')
        products.push(product)
        return products
    }

    private getMeals(): IProductTrack[] {
        const mealProducts = <IProductTrack[]>JSON.parse(this.storageService.getSbwAmendmentMealsTracking())
        return mealProducts ? mealProducts : []
    }

    private getTpas(reservation: IReservation): IProductTrack[] {
        let products: IProductTrack[] = []

        reservation.arrangements.forEach(arrangement => {
            arrangement.arrangementAsts.forEach(ast => {
                let price = this.getPriceFromPriceItems(reservation, ast.id)
                let product = this.createProduct(reservation, ast.id, ast.description, price, ast.count)
                products.push(product)
            })
        })
        return products
    }

    private getVouchers(reservation: IReservation): IProductTrack[] {
        let products: IProductTrack[] = []

        reservation.departures.forEach(departure => {
            departure.voucherArrangements.forEach(v => {
                v.voucherAsts.forEach(ast => {
                    let price = this.getPriceFromPriceItems(reservation, ast.id, departure.departureId)
                    let d1 = reservation.departures[0].departureId === departure.departureId ? 'outbound' : 'return'
                    let product = this.createProduct(reservation, ast.id, ast.description, price, ast.count, d1)
                    products.push(product)
                })
            })
        })
        return products
    }

    private getVehicles(reservation: IReservation): IProductTrack[] {
        let products: IProductTrack[] = []

        reservation.departures.forEach(departure => {
            departure.vehicles.forEach(v => {
                let price = this.getPriceFromPriceItems(reservation, v.id, departure.departureId)
                let d1 = reservation.departures[0].departureId === departure.departureId ? 'outbound' : 'return'
                let product = this.createProduct(reservation, v.id, v.description, price, v.count, d1)
                products.push(product)
            })
        })
        return products

    }

    private getCabins(reservation: IReservation): IProductTrack[] {
        let products: IProductTrack[] = []

        reservation.departures.forEach(departure => {
            departure.cabins.forEach(c => {
                let price = this.getPriceFromPriceItems(reservation, c.id, departure.departureId)
                let d1 = reservation.departures[0].departureId === departure.departureId ? 'outbound' : 'return'
                let product = this.createProduct(reservation, c.categoryCode, c.description, price, c.count, d1)
                products.push(product)
            })
        })

        return products
    }

    private createProduct(reservation: IReservation, id: string, name: string, price: number, count: number, d1?: string, d2?: string, d3?: string, d4?: string): IProductTrack {
        let product = <IProductTrack>{
            brand: 'SeaBook',
            name: name,
            id: id,
            price: price.toFixed(2),
            variant: reservation.productCode[0],
            category: this.getCategory(reservation),
            quantity: count,
            dimension1: d1,
            dimension2: d2,
            dimension3: d3,
            dimension4: d4
        }
        return product
    }

    private getPriceFromPriceItems(reservation: IReservation, id: string, departureId?: string): number {
        if (departureId) {
            let result = reservation.priceItems.find(x => x.departureId != null && x.departureId.toLowerCase() === departureId.toLowerCase() && x.id.toLowerCase() === id.toLowerCase())
            if (result) { return result.price }
        }
        let result2 = reservation.priceItems.find(x => x.id.toLowerCase() === id.toLowerCase())
        return result2 ? result2.price : 0
    }

    private getCategory(reservation: IReservation): string {
        if (!reservation.routeCode) { return '' }
        if (reservation.routeCode.length !== 4) { return reservation.routeCode }
        return `${reservation.routeCode}-${reservation.routeCode.substring(2)}${reservation.routeCode.substring(0, 2)}`
    }
}
