<sbw-layout [backButtonVisible]="true" type="Amendment" [form]="amendmentsForm"
    [continueButtonDisabled]="continueButtonDisabled" (continueEvent)="continue()"
    (backEvent)="navigateBack()">
    <div class="sbw_pagebox">
        <div class="row uncollapse vehicle-container">
            <h1>{{'VEHICLE_AMENDMENT_TITLE' | resource}}</h1>
            <div *ngIf="vehicles" class="sbw_legend" [innerHTML]="'VEHICLE_AMENDMENT_TEASER' | resource"></div>
            <div *ngIf="vehicles" class="row">
                <div class="column small-12 gutter">
                    <sbw-validation-summary [errorList]="errors"></sbw-validation-summary>

                    <div *ngIf="vehicles.isAmendmentCompatible" class="row">
                        <h2 class="column">{{'VEHICLE_TYPE' | resource}}</h2>
                        <sbw-vehicles-selector [formVehicleSelector]="selector" [vehicles]="vehicles.vehicles"
                            [supportMultiple]="vehicles.isMultipleVehiclesSupported"></sbw-vehicles-selector>
                    </div>
                    <div class="row" *ngIf="vehicles.isAmendmentCompatible && this.details.enabled">
                        <h2 class="column">{{'PASSENGERS_VEHICLE_DETAILS' | resource}}</h2>
                        <sbw-vehicles-details-rows mode="Amendment" [formControls]="details"
                            [vehicleName]="selectedVehicle().fullDescription" [trailerName]="selectedTrailer().fullDescription"></sbw-vehicles-details-rows>
                    </div>
                </div>
            </div>
        </div>
    </div>
</sbw-layout>
