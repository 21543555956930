import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms'

@Component({
    selector: 'sbw-vehicles-details-row',
    templateUrl: './vehicles-details-row.component.html',
    styleUrls: ['./vehicles-details-row.component.css']
})
export class VehiclesDetailsRowComponent {
    @Input() index: number
    @Input() vehicleName: string
    @Input() trailerName?: string
    @Input() form: FormGroup
    @Input() validationError: boolean

    get vehicleRegistrationNumber() {
        return this.form.get('vehicleRegistrationNumber')
    }

    get trailerRegistrationNumber() {
        return this.form.get('trailerRegistrationNumber')
    }

}
