import { Component, Input } from '@angular/core'
import { FormArray } from '@angular/forms'

@Component({
    selector: 'sbw-vehicles-details-rows',
    templateUrl: './vehicles-details-rows.component.html',
    styleUrls: ['./vehicles-details-rows.component.css']
})
export class VehiclesDetailsRowsComponent {
    @Input() formControls: FormArray
    @Input() vehicleName: string
    @Input() trailerName?: string
    @Input() validationError: boolean
}
