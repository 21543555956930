import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges, OnChanges } from '@angular/core'
import { DateOfBirthSettings, Title, Country } from 'app/passengers/passengers.models'
import { FormGroup, Validators } from '@angular/forms'
import { FlowOrAmendment, ensureFlowOrAmendment, IFlowOrAmendment, parseLocalDate } from 'app/shared/utils'

import moment from 'moment'
import { CustomerCategory, IPassenger } from 'app/shared/models/reservation.interfaces'
import { IFlowPassengersUserWire } from 'app/passengers/passengers.interfaces'
import { AbstractControl } from '@angular/forms'
import { FormBuilderTyped } from 'app/shared/form-builder-typed'
import { IEditDetaisChangedEvent } from 'app/passengers/edit-user-details/edit-user-details.component'
import { LocalDate, LocalDateTime } from 'js-joda';
import { DeviceDetectorService } from 'ngx-device-detector'

@Component({
    selector: 'sbw-passenger',
    templateUrl: './passenger.component.html',
    styleUrls: ['./passenger.component.css']
})
export class PassengerComponent implements OnInit, OnChanges, IFlowOrAmendment {
    public deviceInfo = null
    public oldSafari = false
    public isMobile = false
    public isSafari = false

    @Input() model: IInputPassenger
    @Input() mode: FlowOrAmendment
    @Input() validationError: boolean
    @Input() editModel: IEditDetaisChangedEvent
    @Output() created: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

    @Input() titles: Title[]
    @Input() nationalities: Country[]
    @Input() locale: string
    @Input() departureDate: Date
    @Input() routeCode

    id: string
    dateOfBirthSettings: DateOfBirthSettings
    form: FormGroup

    get formId() {
        return this.form ? this.form.get('id') : null
    }

    get formTitle() {
        return this.form ? this.form.get('title') : null
    }

    get formFirstName() {
        return this.form ? this.form.get('firstName') : null
    }

    get formLastName() {
        return this.form ? this.form.get('lastName') : null
    }

    get formDateOfBirth() {
        return this.form ? this.form.get('dateOfBirth') : null
    }

    get showPassportNumber() {
        if (!this.form || !this.form.get('nationalityCode')) {
            return false
        }
        const countryCode = this.form.get('nationalityCode').value
        return countryCode === '' || !'DNK SWE NOR FIN ISL GRL FRO'.split(' ').some(x => x === countryCode)
    }

    get showDobCountryPassport() {
        if (this.mode === 'Amendment') { return true }
        return 'CHOS OSCH FHOS OSFH'.split(' ').some(x => x === this.routeCode)
    }

    constructor(private fb: FormBuilderTyped, private deviceService: DeviceDetectorService) {
        this.detectDeviceSettings()
    }

    ngOnInit() {
        ensureFlowOrAmendment(this.mode)
        if (!this.model) { throw new Error('inputModel must be provided') }

        if (this.mode === 'Flow') {
            this.useFlow()
        } else if (this.mode === 'Amendment') {
            this.useAmendment()
        }

        this.dateOfBirthSettings = new DateOfBirthSettings(null, this.locale, this.minimumDateOfBirth, this.maximumDateOfBirth)
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { editModel } = changes
        if (!editModel || editModel.isFirstChange() || this.mode === 'Amendment') { return }
        const value = <IEditDetaisChangedEvent>editModel.currentValue
        this.form.get('title').setValue(value.title)
        this.formFirstName.setValue(value.firstName)
        this.formLastName.setValue(value.lastName)
    }

    private detectDeviceSettings() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        this.oldSafari = (Number(this.deviceInfo.browser_version.substring(0, 2)) < 14 && this.deviceInfo.browser === 'Safari')
        this.isSafari = this.deviceInfo.browser === 'Safari'
    }

    useFlow() {
        const { countryCode, passport, userProfile, customerCategory, passenger } = this.model
        let { titleCode, firstName, lastName, id } = userProfile || <any>{}
        id = passenger ? passenger.id : id
        titleCode = passenger ? passenger.title : titleCode?.toUpperCase() || this.titles[0].code
        firstName = passenger ? passenger.firstName : firstName
        lastName = passenger ? passenger.lastName : lastName

        this.setPassengerFormGroup(id, titleCode, firstName, lastName, countryCode, passenger && passenger.dateOfBirth, passport, customerCategory)
        this.created.emit(this.form)
    }
    useAmendment() {
        const { id, customerCategory, title, firstName, lastName, dateOfBirth, nationalityCode, passportNumber } = this.model.passenger || <any>{}
        this.setPassengerFormGroup(id, title, firstName, lastName, nationalityCode, dateOfBirth, passportNumber, customerCategory)
        this.created.emit(this.form)
    }

    setPassengerFormGroup(id: string, title: string, firstname: string, lastname: string, countryCode: string, dateOfBirth: LocalDate | LocalDateTime | string, passport: string, category: CustomerCategory) {
        this.form = this.fb.group<IPassenger>({
            'id': this.fb.control(id),
            'title': this.fb.control(title),
            'firstName': this.fb.control(firstname, [Validators.required, Validators.maxLength(50)]),
            'lastName': this.fb.control(lastname, [Validators.required, Validators.maxLength(50)]),
            'nationalityCode': this.fb.control(countryCode || this.nationalities[0].code),
            'dateOfBirth': this.fb.control(parseLocalDate(dateOfBirth)),
            'passportNumber': this.fb.control(passport),
            'customerCategory': this.fb.control(category)
        })
    }

    public transformChildAgeText(text: string) {
        return !this.model.passengerAge ? '' : text.replace(/%age%/i, this.model.passengerAge.toString())
    }

    public get minimumDateOfBirth(): Date {
        if (this.model.customerCategory !== 'ADULT' && this.model.passengerAge == undefined) {
            switch (this.model.customerCategory) {
                case 'CHILD':
                    return moment(this.departureDate).subtract(16, 'years').add(1, 'day').toDate()
                case 'INFANT':
                    return moment(this.departureDate).subtract(3, 'years').add(1, 'day').toDate()
            }
        }
        return this.model.customerCategory === 'ADULT'
            ? moment(this.departureDate).subtract(120, 'years').toDate()
            : moment(this.departureDate).subtract(this.model.passengerAge + 1, 'years').add(1, 'day').toDate()
    }

    public get maximumDateOfBirth(): Date {
        if (this.model.customerCategory !== 'ADULT' && this.model.passengerAge == undefined) {
            switch (this.model.customerCategory) {
                case 'CHILD':
                    return moment(this.departureDate).subtract(3, 'years').toDate()
                case 'INFANT':
                    return moment(this.departureDate).toDate()
            }
        }

        return this.model.customerCategory === 'ADULT'
            ? moment(this.departureDate).subtract(16, 'years').toDate()
            : moment(this.departureDate).subtract(this.model.passengerAge, 'years').toDate()
    }
}

export interface IInputPassenger {
    form: AbstractControl,
    passenger?: IPassenger // Edit
    userProfile?: IFlowPassengersUserWire // Flow
    customerCategory: CustomerCategory
    passengerAge?: number,
    countryCode?: string,
    passport?: string // Only flow (userProfile)
}
