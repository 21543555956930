import { NG_VALUE_ACCESSOR, FormControl, ControlValueAccessor } from "@angular/forms";
import { Component, Input, Output, EventEmitter, forwardRef, OnInit, OnChanges, ViewChild, SimpleChanges } from '@angular/core'
import { Datepicker } from 'app/shared/datepicker/datepicker.interfaces'
import { DatepickerView } from './datepicker.models'
import { Calendar } from "primeng/calendar"
import moment from 'moment'
import { LocalDateTime, nativeJs, LocalDate, convert } from "js-joda";
import { Subscription } from "rxjs";
import { sameDate } from "../utils";
import { PrimeNGConfig } from "primeng/api";

@Component({
    selector: 'sbw-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        }
    ]
})

export class DatepickerComponent implements ControlValueAccessor, OnInit, OnChanges {

    @Input() dateControl: FormControl
    @Input() viewModel: DatepickerView
    @ViewChild(Calendar, { static: true }) calenderChild: Calendar
    @Output() onDateChange: EventEmitter<Datepicker.IDatePickerDateChange> = new EventEmitter<Datepicker.IDatePickerDateChange>()

    public isReadonly = false
    private potentialValue: Date
    public isDatepickerActive = false
    private valueChanges: Subscription
    private statusChanges: Subscription

    constructor(private config: PrimeNGConfig) {}

    ngOnInit() {
        this.viewModel = this.viewModel || new DatepickerView()
        this.config.setTranslation(this.viewModel.calendarLocale)
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dateControl) {
            if(this.dateControl.value) this.value = convert(this.dateControl.value).toDate()
            if (this.valueChanges) this.valueChanges.unsubscribe()
            this.valueChanges = this.dateControl.valueChanges.subscribe((v: LocalDate) => {
                if (v && (!this.value || !sameDate(convert(v).toDate(), this.value))) this.value = convert(v).toDate()
            })
            if (this.statusChanges) this.statusChanges.unsubscribe()
            this.statusChanges = this.dateControl.statusChanges.subscribe(s => this.disabled = s == "DISABLED")
        }

        this.viewModel = this.viewModel || new DatepickerView()
    }

    onFocus(event) {
        this.isDatepickerActive = true
        this.potentialValue = null
    }

    onBlur(event) {
        this.isDatepickerActive = false
        if (this.potentialValue) this.value = this.turnValid(this.potentialValue)
    }

    onSelect(selectedDate: Date) {
        this.isDatepickerActive = false
        this.value = selectedDate

        let event: Datepicker.IDatePickerDateChange = {
            date: LocalDateTime.from(nativeJs(selectedDate))
        }
        this.onDateChange.emit(event)
    }

    mustHighlight = (date: { year: number, month: number, day: number}): boolean => {        
        const dateStr = LocalDate.of(date.year, date.month + 1, date.day).toString()
        const result = this.viewModel.highlightedDates.some(x => x === dateStr)
        return result
    }

    private turnValid(date: Date): Date {
        let m = moment(date)
        return this.isValidDateForConstraints(m) ? date : this.findValidDate(m)
    }

    private isValidDateForConstraints(m: moment.Moment): boolean {
        let r = this.viewModel
        return m && m.isBetween(r.minDate || new Date('0001-01-01'), r.maxDate || new Date('2999-01-01'), 'day', '[]')
            && !r.disabledDates.some(i => m.isSame(i, 'day'))
    }

    private scanDates(res: moment.Moment, target: Date, direction: number) {
        res = res.clone()
        let diff = Math.ceil(Math.abs(res.diff(target, 'days', true)))
        for (let i = 0; i < diff; i++) {
            res.add(direction, 'day')
            if (this.isValidDateForConstraints(res)) return res.toDate()
        }
    }

    private findValidDate(m: moment.Moment): Date {
        let r = this.viewModel
        return this.scanDates(m, r.minDate, -1) || this.scanDates(m, r.maxDate, 1)
    }

    public disabled = false
    protected _innerValue: Date
    private _registerOnChange: any = s => { }
    private _registerOnTouched: any = s => { }

    get value(): Date {
        return this.disabled ? null : this._innerValue
    }

    set value(value: Date) {
        if (sameDate(this._innerValue, value) && this.controlHasValue()) return
        this._innerValue = value
        this._registerOnChange(value)
        if (this.dateControl) this.dateControl.setValue(LocalDate.from(nativeJs(value)))
    }

    private controlHasValue(): boolean {
        return this.dateControl && this.dateControl.value
    }

    writeValue(obj: any): void {
        if (this.dateControl) this.dateControl.setValue(obj && LocalDate.from(nativeJs(obj)))
        if (sameDate(obj, this._innerValue)) { return }
        this._innerValue = obj
    }

    registerOnChange(fn: any): void {
        this._registerOnChange = fn
    }

    registerOnTouched(fn: any): void {
        this._registerOnTouched = fn
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    styleBorder() {
        return { 'error-border': this.dateControl && !this.dateControl.valid && this.dateControl.enabled }
    }

}
