import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ButtonStateDirective } from './directives/button-state-directive'
import { NumberOnlyDirective } from './directives/number.directive'
import { ButtonComponent } from './button/button.component'
import { SelectComponent } from 'app/shared/select/select.component'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CalendarModule } from 'primeng/calendar'
import { CheckboxComponent } from 'app/shared/checkbox/checkbox.component'
import { GridComponent, GridHeader, GridBody } from 'app/shared/grid/grid.component'
import { ValidationSummaryComponent } from 'app/shared/validation-summary/validation-summary.component'
import { DatepickerComponent } from 'app/shared/datepicker/datepicker.component'
import { PopupComponent } from 'app/shared/popup/popup.component'
import { InformationPopupComponent } from 'app/shared/info-popup/information-popup.component'
import { SpinnerDirective } from 'app/shared/directives/spinner-directive'
import { ValidateValidDirective } from 'app/shared/directives/validate-valid-directive'
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ContentfulGenericPageComponent } from '../contentful/contentful-generic-page/contentful-generic-page.component'
import { ContentfulContentRowComponent } from '../contentful/contentful-content-row/contentful-content-row.component'
import { ContentfulInfoBlockComponent } from '../contentful/contentful-info-block/contentful-info-block.component'
import { CabinGroupCache } from 'app/cabins/cabin-group/cabin-group.cache';
import { StuckDirective } from './directives/stuck.directive';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { NativeDatepickerComponent } from './native-datepicker/native-datepicker.component'
import { SelectFieldComponent } from 'app/shared/select-field/select.component'

@NgModule({
    imports: [
        CommonModule, RouterModule, DialogModule, DropdownModule, BrowserAnimationsModule, CalendarModule, FormsModule
    ],
    declarations: [
        ButtonStateDirective,
        NumberOnlyDirective,
        ButtonComponent,
        NativeDatepickerComponent,
        SelectComponent,
        CheckboxComponent,
        GridComponent,
        GridHeader,
        GridBody,
        ValidationSummaryComponent,
        DatepickerComponent,
        DateRangePickerComponent,
        PopupComponent,
        InformationPopupComponent,
        SpinnerDirective,
        ValidateValidDirective,
        StuckDirective,
        ContentfulGenericPageComponent,
        ContentfulContentRowComponent,
        ContentfulInfoBlockComponent,
        SelectFieldComponent
    ],
    exports: [
        ButtonStateDirective,
        NumberOnlyDirective,
        ButtonComponent,
        NativeDatepickerComponent,
        SelectComponent,
        CheckboxComponent,
        GridComponent,
        GridHeader,
        GridBody,
        ValidationSummaryComponent,
        DatepickerComponent,
        DateRangePickerComponent,
        PopupComponent,
        InformationPopupComponent,
        SpinnerDirective,
        ValidateValidDirective,
        StuckDirective,
        ContentfulGenericPageComponent,
        ContentfulContentRowComponent,
        ContentfulInfoBlockComponent,
        SelectFieldComponent
    ],
    providers: [CabinGroupCache],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UIModule {
}

