import { Injectable } from '@angular/core';
import { Entry, EntryCollection } from 'contentful';
import { ContentfulClientApiFast } from './contentfulClientApiFast';

export const CONFIG = {
    contentTypeIds: {
        resource: 'resource',
        mealsRestaurant: 'mealsRestaurant',
        flowMeals: 'flowMeals',
        cabinGroup: 'cabinGroup',
        flowCabinFares: 'flowCabinFares',
        cabinDetails: 'cabinDetails',
        flowTravel: 'flowTravel'
    },
    defaultRoute: {
        code: 'Default',
        id: '1hvL6LWlEGsykgAIwGag4s'
    }
}


const order = (entry: RouteEntry) => entry.fields.route.sys.id == CONFIG.defaultRoute.id ? 1000 : 0
export const putDefaultLast = (a: RouteEntry, b: RouteEntry) => order(a) - order(b)

function routeFilter(route: string) {
    if (!route) throw new Error('missing route code for route filter')
    return {
        'fields.route.fields.routeCodes[in]': [route, CONFIG.defaultRoute.code].join(','),
        'fields.route.sys.contentType.sys.id': 'route'
    }
}

export type ContentfulRestaurant = {
    defaultSeatingTime: string
    headline: string
    restaurantCode: string
    restaurantName: string
    teaser: string
    teaserImage: any
}

@Injectable()
export class ContentfulSeabookWebService {
/// note this service uses promises rather than Observables because contentful api uses promises
    constructor(private fastClient: ContentfulClientApiFast) { }

    public loadFlowTravel(): Promise<Entry<FlowTravel>> {
        return this.fastClient.getEntries<FlowTravel>(CONFIG.contentTypeIds.flowTravel, {}).toPromise().then(res => res.items[0]);
    }

    public loadFlowMeals(): Promise<Entry<any>> {
        return this.fastClient.getEntries(CONFIG.contentTypeIds.flowMeals, {}).toPromise().then(res => res.items[0]);
    }

    public loadMealsRestaurantDetailsForRestaurantCodes(restaurantCodes: string[], routeCode: string): Promise<ContentfulRestaurant[]> {
        return this.fastClient.getEntries<{ route: CFRef }>(CONFIG.contentTypeIds.mealsRestaurant, {
            'fields.restaurantCode[in]': restaurantCodes.join(','),
            ...routeFilter(routeCode)
        }).toPromise().then(r => {
            const m = {}
            for(let v of r.items) m[v.fields['restaurantCode']] = v.fields
            for(let v of r.items) if(v.fields.route.sys.id !== CONFIG.defaultRoute.id) m[v.fields['restaurantCode']] = v.fields
            return Object.values(m)
        })
    }

    public loadFlowCabinFaresForRouteOrDefault(routeCode: string): Promise<Entry<FlowCabinFares>> {
        routeCode = routeCode.toUpperCase()
        return this.fastClient.getEntries<FlowCabinFares>(CONFIG.contentTypeIds.flowCabinFares,
            routeFilter(routeCode)).toPromise().then(res => res.items.find(i => i.fields.route.sys.id != CONFIG.defaultRoute.id) || res.items[0]);
    }

    public loadCabinGroupsForRouteOrDefault(cabinGroupCodes: String[], routeCode: string): Promise<CabinGroup[]> {
        return this.fastClient.getEntries<CabinGroup>(CONFIG.contentTypeIds.cabinGroup,
            {
                'fields.cabinGroupCode[in]': cabinGroupCodes.join(','),
                ...routeFilter(routeCode)
            }).toPromise().then(res => cabinGroupCodes.map(c => res.items.find(i => i.fields.cabinGroupCode == c && i.fields.route.sys.id != CONFIG.defaultRoute.id)
                || res.items.find(i => i.fields.cabinGroupCode == c)).filter(c => c).map(c => c.fields));
    }

    public loadCabinDetails(cabinTypeCode: string): Promise<any> {
        return this.fastClient.getEntries(CONFIG.contentTypeIds.cabinDetails, {
            'fields.cabinTypeCode': cabinTypeCode
        }).toPromise().then(res => res.items[0] && res.items[0].fields);
    }

    public loadCabinDetailsForCabinTypeCodes(cabinTypeCodes: String[], route: string): Promise<EntryCollection<CabinDetails>> {
        return this.fastClient.getEntries<CabinDetails>(CONFIG.contentTypeIds.cabinDetails, {
            'fields.cabinTypeCode[in]': cabinTypeCodes.join(','),
            ...routeFilter(route && route.toUpperCase())
        }).toPromise().then(res => {
            res.items = cabinTypeCodes.map(c =>
                res.items.find(i => i.fields.cabinTypeCode == c && i.fields.route.sys.id != CONFIG.defaultRoute.id)
                || res.items.find(i => i.fields.cabinTypeCode == c))
                .filter(c => c)
            return res;
        });
    }

}

export interface CabinGroup {
    cabinGroupCode: string;
    route: CFRef;
    headline: string;
    legend: string;
}

export interface Sys {
    type: string;
    linkType: string;
    id: string;
}

export interface CFRef {
    sys: Sys;
}

export type RouteEntry = Entry<{ route: CFRef }>

export interface CabinDetails {
    cabinTypeCode: string;
    route: CFRef;
    headerImage: CFRef;
    headline: string;
    description: string;
    cabinPhotos: CFRef[];
}

export interface FlowCabinFares {
    route: CFRef;
}

export interface FlowTravel {
    petParameterRequiresVehicle: string;
    vehicleParameterRequiresTrailerType: string;
    selectedDateNotAvailable: string;
}

export interface MiniBook {
    offerCode: string
    offerCodeInfoText: string
}
