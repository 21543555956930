import { Component, NgZone, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { IReservation } from 'app/shared/models/reservation.interfaces'
import { ResourceService } from 'app/shared/resources/resource.service'
import { ReservationService } from 'app/shared/reservation/reservation.service'
import { CookieService } from 'app/shared/cookie.service'
import { Title } from '@angular/platform-browser'
import { StatelessHttpService } from 'app/shared/stateless-http.service'
import { zip } from 'rxjs'
import { AuthService } from 'app/auth/auth.service'
import { LayoutState } from 'app/shared/layout/layout-state'
import { ContentfulDfdsService } from 'app/shared/resources/contentful-dfds.service'

@Component({
  selector: 'sbw-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css'],
})
export class BookingComponent implements OnInit {
  public reservation: IReservation

  public reservationCode: string
  public loaded = false
  public errors: string[] = []
  public status = Status
  public isAgent: boolean
  public pathToMyBookings: string

  constructor(
    private route: ActivatedRoute,
    private resourceService: ResourceService,
    private reservationService: ReservationService,
    private cookieService: CookieService,
    private title: Title,
    private statelessHttpService: StatelessHttpService,
    private authService: AuthService,
    private zone: NgZone,
    private layoutState: LayoutState,
    private contentfulDfdsService: ContentfulDfdsService
  ) {}

  ngOnInit() {
    this.isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
    let authType = this.cookieService.getAuthType()

    this.contentfulDfdsService.getUrlSlugs(['5KCuKuA1SD3nEZyStlxP9u']).then((res) => {
      if (res && res.length === 1) this.pathToMyBookings = res[0].fields.slug
    })

    this.reservationCode = this.route.snapshot.params['reservationCode']

    if ((authType === 'agent' && this.isAgent) || this.authService.isLoggedIn()) {
      this.getData()
    }
  }

  get ReservationStatus(): Status {
    if (!this.reservation) {
      return Status.Unset
    }
    if (this.reservation.reservationStatus === 'Confirmed' && !this.reservation.isReadOnly) {
      return Status.Active
    }
    if (this.reservation.reservationStatus === 'Preliminary' || this.reservation.reservationStatus === 'Pending') {
      return Status.Preliminary
    }
    return Status.Inactive
  }

  printConfirmation = () => {
    zip(
      this.resourceService.get('ITINERARY_PRINT_PDF_FILE_NAME'),
      this.resourceService.get('ITINERARY_PRINT_PDF_FILE_NAME_CUSTOMER_COPY')
    ).subscribe((response) => {
      const [agentPdfFilename, customerPdfFilename] = response
      const pdfFileName = this.isAgent ? agentPdfFilename : customerPdfFilename
      this.statelessHttpService
        .get(true, `reservation/${this.reservation.reservationCode}/pdf`, { responseType: 'blob' })
        .subscribe((res: Blob) => {
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(res)
          link.download = pdfFileName.replace('%reservationid%', this.reservationCode)
          link.click()
        })
    })
  }

  private getData() {
    this.reservationService.getReservation(this.reservationCode).subscribe((reservation) => {
      this.cookieService.setCookie('sbRouteCode', reservation.routeCode)
      this.resourceService
        .get('TITLE_BOOKING_DETAILS', false)
        .subscribe((s) => s && this.title.setTitle(s.replace('%reservationId%', this.reservationCode)))
      this.resourceService.loadResources(
        reservation.routeCode,
        reservation.locale,
        ['Common', 'Account', 'Amendment', 'Menu', 'Meals', 'Currency', 'CabinAllocation', 'Passengers', 'Itinerary'].concat(
          this.isAgent ? ['Agent', 'MenuAgent'] : []
        )
      )

      this.reservation = reservation
      if (reservation.isReadOnly && reservation.reservationStatus !== 'Cancelled') {
        this.resourceService.get('AMENDMENT_READ_ONLY').subscribe((s) => s && this.errors.push(s))
      }
    })
  }
}

enum Status {
  Unset,
  Active,
  Preliminary,
  Inactive,
}
