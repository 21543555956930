import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { ICabinAllocationCabin, ICabinAllocationPassenger } from '../cabin-allocations.interface'
import { NgForm, FormGroup, FormBuilder, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms'
import { ResourceService } from "app/shared/resources/resource.service";

@Component({
    selector: 'sbw-cabin-allocation',
    templateUrl: './cabin-allocation.component.html',
    styleUrls: ['./cabin-allocation.component.css']
})
export class CabinAllocationComponent implements OnInit {

    @Input() cabin: ICabinAllocationCabin;
    @Input() formPassengerSelections: FormArray;

    get selectedPassengerCount(): number{
        return this.formPassengerSelections.controls.filter(p => p.value.allocatedTo === this.cabin.id && p.value.customerCategory != 'Infant').length
    }

    private bedsLeftResource: string;
    private bed: string;
    private beds: string;
    public noBedsLeftKey: string;
    
    get remainingCapacity(): number {
        return this.cabin.maxCapacity - this.selectedPassengerCount
    }

    constructor(
        private resourceService: ResourceService) {
    }

    ngOnInit() {
        this.resourceService.get('CABIN_ALLOCATIONS_BED').subscribe(s => this.bed = s);
        this.resourceService.get('CABIN_ALLOCATIONS_BEDS').subscribe(s => this.beds = s);

        if (this.formPassengerSelections) {
            let bedsLeftKey = this.hasInfants() ? 'CABIN_ALLOCATIONS_BEDS_LEFT_INFANT' : 'CABIN_ALLOCATIONS_BEDS_LEFT'
            this.noBedsLeftKey = this.hasInfants() ? 'CABIN_ALLOCATIONS_NO_BEDS_LEFT_INFANT' : 'CABIN_ALLOCATIONS_NO_BEDS_LEFT'
            this.resourceService.get(bedsLeftKey).subscribe(s => this.bedsLeftResource = s);
        }
    }

    public pickedPassengerEvent(checked: boolean, index: number) {
        let control = this.formPassengerSelections.controls[index]
        let patchValue = checked ? this.cabin.id : null
        //Split up into two or the side effects of patchValue will render selectedPassengerCount to give wrong result
        control.value.allocatedTo = patchValue
        control.get('allocatedTo').patchValue(patchValue)
    }

    public shouldPassengerBeDisabled(index: number) {
        let s = this.formPassengerSelections.controls[index]
        return s.get('customerCategory').value !== 'Infant' && this.selectedPassengerCount >= this.cabin.maxCapacity && this.cabin.id !== s.get('allocatedTo').value
    }

    public showPassenger(index: number) {
        let allocatedTo = this.formPassengerSelections.controls[index].get('allocatedTo').value
        return (this.cabin.id === allocatedTo || allocatedTo == null)
    }

    public bedsLeftFormatted() {
        let  resourceFormatted = this.remainingCapacity > 1 ? this.bedsLeftResource && this.bedsLeftResource.replace('%bedsLeft%', '' + this.remainingCapacity) : this.bedsLeftResource && this.bedsLeftResource.replace('%bedsLeft%', '' + this.remainingCapacity)
        return this.remainingCapacity > 1 ? resourceFormatted && resourceFormatted.replace('%beds%', this.beds) : resourceFormatted && resourceFormatted.replace('%beds%', this.bed)
    }

    public hasInfants() {
        return this.formPassengerSelections.controls.some(p => p.value.customerCategory === 'Infant')
    }

    public passengerAllocated(index: number) {
        let allocatedTo = this.formPassengerSelections.controls[index].get('allocatedTo').value
        return allocatedTo != null && this.cabin.id === allocatedTo;
    }

}
