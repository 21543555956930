import { Component, NgZone, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms'
import { zip } from 'rxjs'

import {
  ICabinAllocationWire,
  ICabinAllocationDeparture,
  ICabinAllocationCabin,
  ICabinAllocationPassenger,
  ICabinAllocationEditSelection,
} from '../cabin-allocations.interface'

import { IReservation } from 'app/shared/models/reservation.interfaces'
import { ReservationService } from 'app/shared/reservation/reservation.service'
import { ResourceService } from 'app/shared/resources/resource.service'
import { AmendService } from 'app/shared/reservation/amend.service'
import { SnapshotService } from 'app/shared/snapshot.service'

import { ButtonStateType } from 'app/shared/button/button.component'
import { CabinAllocationsService } from 'app/cabin-allocations/cabin-allocations.service'
import { hashObject } from 'app/shared/utils'
import { TrackingService } from '../../shared/tracking/tracking.service'
import { TrackingAmendmentStep } from '../../shared/tracking/tracking-wire.interfaces'
import { AuthService } from 'app/auth/auth.service'
import { LocaleService } from 'app/shared/locale-service'
import { CookieService } from 'app/shared/cookie.service'
import { LayoutState } from 'app/shared/layout/layout-state'

@Component({
  selector: 'sbw-amendment-allocations',
  templateUrl: './amendment-cabin-allocations.component.html',
  styleUrls: ['./amendment-cabin-allocations.component.css'],
})
export class AmendmentCabinAllocationsComponent implements OnInit {
  public reservationCode: string
  public reservation: IReservation
  public amendmentsForm: FormGroup = new FormGroup({})
  public buttonState: ButtonStateType = 'disabled'
  public errors: string[] = []
  public isOneway: boolean = true
  public cabinAllocationWire: ICabinAllocationWire
  public departures: ICabinAllocationDeparture[]
  private isAmendmentCompatible: boolean = false
  private initialHash: string
  private isAgent: boolean

  get outboundDepartureGroup(): FormGroup {
    return this.amendmentsForm.get('outbound') as FormGroup
  }

  get returnDepartureGroup(): FormGroup {
    const formGroup = this.amendmentsForm.get('return') as FormGroup

    if (formGroup == null) {
      return
    }

    return formGroup.value == null ? null : formGroup
  }

  constructor(
    private route: ActivatedRoute,
    private reservationService: ReservationService,
    private resourceService: ResourceService,
    private title: Title,
    private fb: FormBuilder,
    private amendService: AmendService,
    private snapshotService: SnapshotService,
    private router: Router,
    private trackingService: TrackingService,
    private cabinAllocationsService: CabinAllocationsService,
    private authService: AuthService,
    private zone: NgZone,
    private localeService: LocaleService,
    private cookieService: CookieService,
    private layoutState: LayoutState
  ) {}

  ngOnInit() {
    this.isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
    let authType = this.cookieService.getAuthType()

    if ((authType === 'agent' && this.isAgent) || this.authService.isLoggedIn()) {
      this.getData()
    }
  }

  public continue() {
    const dryRun = true
    let selections = this.getSelections()
    this.amendService.changeCabinAllocation(this.reservationCode, selections, dryRun).subscribe((response) => {
      if (response.errorMsgs && response.errorMsgs.length) {
        this.buttonState = 'activated'
        this.errors = response.errorMsgs.map((m) => m.message)
        return
      }
      this.snapshotService.setSnapshot(this.reservationCode, { cabinAllocations: selections })
      this.router.navigate(['/amendment/booking', this.reservationCode])
    })
  }

  private getHash() {
    return hashObject(this.getSelections())
  }

  private getSelections() {
    return this.makeLegSelection(
      this.amendmentsForm.controls.outbound.value,
      this.amendmentsForm.controls.outboundPassengerSelections.value
    ).concat(this.makeLegSelection(this.amendmentsForm.controls.return.value, this.amendmentsForm.controls.returnPassengerSelections.value))
  }

  private makeLegSelection(cabins: ICabinAllocationDeparture, passengers: ICabinAllocationPassenger[]): ICabinAllocationEditSelection[] {
    if (cabins == null) return []
    return cabins.cabins.map(
      (o) =>
        <ICabinAllocationEditSelection>{
          cabinId: o.id,
          passengers: passengers.filter((p) => p.allocatedTo === o.id).map((p) => p.id),
        }
    )
  }

  private passengerSelectedValidator() {
    return (c: FormArray): { [key: string]: any } =>
      c.controls.map((t) => t.value.allocatedTo).filter((t) => t === null).length > 0
        ? { passengerSelectedValidator: { valid: true } }
        : null
  }

  public buildForm(): void {
    let { departures } = this.cabinAllocationWire
    let selections = this.cabinAllocationWire.selections
    this.departures = departures

    const formControlOutboundDeparture = new FormControl(this.departures[0])
    const formControlReturnDeparture = this.departures.length === 1 ? null : new FormControl(this.departures[1])
    const formControlOutboundPassengersSelections = this.departures[0].passengers.map((p) => this.factorySelectionFormGroup(p))
    const formControlReturnPassengersSelections =
      this.departures[1] && this.departures[1].passengers.map((p) => this.factorySelectionFormGroup(p))

    if (selections) {
      this.allocateBasedOnSnapshot(formControlOutboundDeparture, formControlOutboundPassengersSelections)
      if (formControlReturnDeparture) {
        this.allocateBasedOnSnapshot(formControlReturnDeparture, formControlReturnPassengersSelections)
      }
    }

    this.amendmentsForm = this.fb.group({
      outbound: formControlOutboundDeparture,
      return: formControlReturnDeparture,
      outboundPassengerSelections: this.fb.array(formControlOutboundPassengersSelections, this.passengerSelectedValidator()),
      returnPassengerSelections: this.fb.array(formControlReturnPassengersSelections || [], this.passengerSelectedValidator()),
    })

    this.isOneway = departures.length === 1
    if (this.isOneway) {
      this.amendmentsForm.get('return').disable()
      this.amendmentsForm.get('returnPassengerSelections').disable()
    }
    this.initialHash = this.getHash()
  }

  private factorySelectionFormGroup(selection: ICabinAllocationPassenger) {
    return this.fb.group({
      id: [selection.id],
      firstName: [selection.firstName],
      lastName: [selection.lastName],
      allocatedTo: [selection.allocatedTo],
      customerCategory: [selection.customerCategory],
      title: [selection.title],
    })
  }

  private allocateBasedOnSnapshot(departure: any, selections: any) {
    departure.value.cabins.forEach((cabin: ICabinAllocationCabin) => {
      selections.forEach((group) => {
        this.cabinAllocationWire.selections
          .filter((s) => s.cabinId == cabin.id && s.passengers.some((p) => p == group.value.id))
          .forEach((selection) => {
            group.controls.allocatedTo.patchValue(selection.cabinId)
          })
      })
    })
  }

  private getData() {
    this.reservationCode = this.route.snapshot.params['reservationCode']

    let allocations$ = this.cabinAllocationsService.getCabinAllocations(this.reservationCode)
    let reservation$ = this.reservationService.getReservation(this.reservationCode)

    zip(allocations$, reservation$).subscribe((resArray) => {
      ;[this.cabinAllocationWire, this.reservation] = resArray

      this.trackingService.trackAmendment(TrackingAmendmentStep.CABINALLOCATION, false, this.reservation)

      this.resourceService
        .loadResourcesPromise(this.reservation.routeCode, this.reservation.locale, [
          'Common',
          'Account',
          'Amendment',
          'Currency',
          'Quote',
          'CabinFares',
          'Cabin',
          'CabinAllocation',
        ])
        .then(() => {
          this.resourceService.get('CABIN_ALLOCATIONS_TITLE', false).subscribe((s) => this.title.setTitle(s))

          let snapshot = this.snapshotService.getSnapshot(this.reservationCode)
          if (snapshot && snapshot.cabinAllocations) {
            this.cabinAllocationWire.selections = snapshot.cabinAllocations
          }

          if (snapshot && snapshot.cabins) {
            this.buttonState = 'disabled'
            this.resourceService.get('CABIN_ALLOCATIONS_ONGOING_CABIN_AMENDMENT').subscribe((s) => (this.errors = [s]))
            return
          }

          this.buildForm()
          this.amendmentsForm.valueChanges.subscribe((v) => {
            this.buttonState = this.amendmentsForm.valid && this.getHash() != this.initialHash ? 'activated' : 'disabled'
          })
        })
    })
  }
}
