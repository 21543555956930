<sbw-layout
  [backButtonVisible]="true"
  [quoteModel]="quoteData"
  [quoteShowTotalPrice]="true"
  [quoteShowDepartureTime]="true"
  quoteStep="Pets"
  [continueButtonDisabled]="!isValid"
  (continueEvent)="continue()"
  (backEvent)="navigateBack()"
>
  <div class="sbw_pagebox">
    <div class="row uncollapse pets-container">
      <div class="row gutter">
        <div class="column gutter">
          <div *ngIf="petsForm">
            <div class="pets-header">
              <h1>{{ 'PETS_HEADLINE' | resource }}</h1>
            </div>
            <sbw-validation-summary [errorList]="errorList"></sbw-validation-summary>
            <div class="group-panel">
              <div class="row uncollapse group-header">
                <div class="column pets-title">
                  {{ 'PET_CHARGE_TITLE' | resource }}
                </div>
              </div>

              <div *ngFor="let selection of petSelections.controls" class="row uncollapse">
                <sbw-pets-selector [reservation]="reservation" [form]="selection"> </sbw-pets-selector>
              </div>
            </div>
          </div>

          <div *ngIf="!petsForm" class="no-result">
            {{ 'NO_PETS_AVAILABLE' | resource }}
          </div>

          <div class="validation" *ngIf="!isValid">
            {{ petsValidationMessage }}
          </div>

          <sbw-information-popup [text]="'PET_POP_UP' | resource: 'Pets'" label="{{ 'PET_NOTE' | resource }}">
            {{ 'PET_POP_UP' | resource }}
          </sbw-information-popup>
        </div>
      </div>
    </div>
  </div>
</sbw-layout>
