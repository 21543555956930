import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ICabinAllocationWire } from 'app/cabin-allocations/cabin-allocations.interface';
import { EnvironmentService } from 'app/environment.service';
import { StatelessHttpService } from 'app/shared/stateless-http.service';

@Injectable()
export class CabinAllocationsService {

    constructor(private statelessHttpService: StatelessHttpService, private environmentService: EnvironmentService) { }

    public getCabinAllocations(reservationCode: string): Observable<ICabinAllocationWire> {
        const path = `cabin-allocations-amendment/${reservationCode}`
        return this.statelessHttpService.get<any>(true, path).pipe(map(j => {
            // the backend sends the passengers directly on the root object since they are identical for all legs.
            // For the frontend it is practical to have a copy on each leg so we copy it out for each leg.
            let passengersCopy = JSON.stringify(j.passengers)
            delete j.passengers
            for (let departure of j.departures) { departure.passengers = JSON.parse(passengersCopy) }
            return j
        }))
    }

}
