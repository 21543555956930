import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core'
import { AuthService } from 'app/auth/auth.service'
import { UserDetailsService, UserDetailsWire } from 'app/shared/user-details.service'
import { ResourceService } from 'app/shared/resources/resource.service'
import { CookieService } from 'app/shared/cookie.service'
import { ChangeDetectorRef } from '@angular/core'
import { Router } from '@angular/router'
import { FormControl } from '@angular/forms'
import { ContentfulDfdsService } from 'app/shared/resources/contentful-dfds.service'
import { HybridService } from 'app/shared/hybrid/hybrid.service'
import { StatelessHttpService } from 'app/shared/stateless-http.service'

@Component({
  selector: 'sbw-menu',
  styleUrls: ['./menu.component.css'],
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
  public userDetailsWire: UserDetailsWire
  public serviceLinks: CustomerService = {}
  public username: string
  public user: FormControl
  public linkTextMyBookings: string
  public linkToMyBookings: string

  public agentPage: boolean = window.location.pathname.includes('/agent/account/login')
  public pathToMyBookings: string
  public menuReady: boolean = false

  constructor(
    private userDetailsService: UserDetailsService,
    private resourceService: ResourceService,
    private hybridService: HybridService,
    private statelessHttpService: StatelessHttpService,
    private authService: AuthService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private cookieService: CookieService,
    private contentfulDfdsService: ContentfulDfdsService
  ) {}

  ngOnInit() {
    this.user = new FormControl(null)
    const urlSlugsTask = this.contentfulDfdsService.getUrlSlugs(['5KCuKuA1SD3nEZyStlxP9u'])
    const resourceTask = this.resourceService.loadResourcesPromise('Default', this.resourceService.getLocale(), ['Menu'])

    Promise.all([urlSlugsTask, resourceTask]).then((responses) => {
      if (responses[0] && responses[0].length === 1) this.pathToMyBookings = responses[0][0].fields.slug
      this.userDetailsService.getUserDetails().subscribe((data) => {
        this.userDetailsWire = data
      })

      this.resourceService.get('ONLINE_HELP_LINK', false, 'Menu').subscribe((resource) => {
        this.serviceLinks.onlineHelp = resource
      })

      this.resourceService.get('MY_BOOKINGS', true, 'Menu').subscribe((resource) => {
        const href = window.location.href
        const locale = href.split('/')[3]
        this.linkTextMyBookings = resource

        const origin = window.location.origin
        const bookingsUrl = `${origin}/${locale}/${this.pathToMyBookings}`
        this.linkToMyBookings = bookingsUrl
      })

      this.resourceService.get('SUPPORT_PHONE_NUMBER', false, 'Menu').subscribe((resource) => {
        if (/\d/.test(resource)) this.serviceLinks.contact = resource
      })
      this.menuReady = true
    })
  }

  getReturnUrl(): string {
    return encodeURIComponent(location.pathname + location.search)
  }

  getLoginLink() {
    return `/_mvc/booking/account/login?returnUrl=${this.getReturnUrl()}`
  }

  agentLogout() {
    console.log("Agent logout clicked")

    const salesOwnerId = this.cookieService.getCookie('sbw_SalesOwner')
    const locale = this.cookieService.getCookie('sbw_Locale')

    // Cleanup in MVC session
    this.statelessHttpService.post(false, 'agent/logout', {  }).subscribe(response => {
      console.log("Agent logged out on server")
      localStorage.removeItem('sbw_AgentGenericId')
      this.hybridService.changeWindowLocation(`agent/account/login?salesowner=${salesOwnerId}&locale=${locale}`)
    })
  }

  getLogoutLink() {
    if (this.userDetailsWire.isAgent) {
      const salesOwnerId = this.cookieService.getCookie('sbw_SalesOwner')
      const locale = this.cookieService.getCookie('sbw_Locale')
      return `/agent/account/logout?salesOwnerId=${salesOwnerId}&locale=${locale}`
    }
    let returnUrl = 'https://www.dfds.com'
    if (location.host.includes('ferry.dfds.com')) {
      returnUrl = location.origin
    } else if (location.pathname.split('/').length > 3) {
      const pathParts = location.pathname.split('/')
      returnUrl = `${location.origin}/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}`
    }
    return `/_mvc/booking/account/logout?returnUrl=${returnUrl}`
  }
}

export interface CustomerService {
  onlineHelp?: string
  contact?: string
}
