import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LocalDate } from "js-joda";
import { DatepickerView } from '../datepicker/datepicker.models';

@Component({
  selector: 'sbw-native-datepicker',
  templateUrl: './native-datepicker.component.html',
  styleUrls: ['./native-datepicker.component.css']
})
export class NativeDatepickerComponent implements OnInit {
  @Input() dateControl: FormControl
  @Input() viewModel: DatepickerView
  @Input() errorMessage: string

  public dateSelected
  public dateValid = true
  public maxDate
  public minDate
  
  public loaded = false

  ngOnInit(): void {
    if (this.dateControl.value) {
      const localDate: LocalDate = this.dateControl.value
      localDate.toString()
      this.dateSelected = new Date(localDate.toString())
      this.loaded = true
    }
    else this.loaded = true
  }

  public valueChanged(e: string) {
    if (e && e.length > 4) {
      const minLocalDate = LocalDate.parse(this.viewModel.minDate.toISOString().split('T')[0])
      const maxLocalDate = LocalDate.parse(this.viewModel.maxDate.toISOString().split('T')[0])
      const date = LocalDate.parse(e)

      this.dateValid = !date.isBefore(minLocalDate) && !date.isAfter(maxLocalDate)
      if (this.dateValid) this.dateControl.setValue(date)
    }
  }
}