<div class="row half-gutter" [attr.data-e2e-passenger-id]="formId && formId.value">
  <form [formGroup]="form" *ngIf="form">
    <div class="column small-12">
      <div class="row">
        <div class="column small-12 xlarge-12">
          <h5 class="small-12 field passenger-type">
            {{ 'PASSENGERS_' + model.customerCategory | resource }}
            {{transformChildAgeText('PASSENGER_AGE' | resource)}}
          </h5>
        </div>
        <div class="column small-12 medium-4 xlarge-4">
          <div class="small-12 field">
            <select formControlName="title">
              <option *ngFor="let title of titles" [value]="title.code">{{title.description}}</option>
            </select>
          </div>
        </div>
        <div class="column small-12 medium-8 large-8 xlarge-4">
          <div class="small-12 field">
            <input type="text" data-hj-suppress name="firstname" formControlName="firstName"
              [placeholder]="'PASSENGERS_FIRSTNAME' | resource" />
            <div *ngIf="validationError && formFirstName && formFirstName.invalid || formFirstName && formFirstName.invalid && (formFirstName.dirty || formFirstName.touched)">
              <span *ngIf="formFirstName.errors.required"
                class="field-validation-error xfirstname">{{'VALIDATION_PASSENGERS_FIRSTNAME_NOT_EMPTY' |
                resource}}</span>
            </div>
          </div>
        </div>
        <div class="column small-12 medium-12 large-12 xlarge-4">
          <div class="small-12 field">
            <input type="text" data-hj-suppress name="lastname" formControlName="lastName" required maxlength="50"
              [placeholder]="'PASSENGERS_LASTNAME' | resource" />
            <div *ngIf="validationError && formLastName && formLastName.invalid || formLastName && formLastName.invalid && (formLastName.dirty || formLastName.touched)">
              <span *ngIf="formLastName.errors.required"
                class="field-validation-error xlastname">{{'VALIDATION_PASSENGERS_LASTNAME_NOT_EMPTY' |
                resource}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="showDobCountryPassport">
        <div class="column small-12 medium-4 xlarge-4" data-hj-suppress>
          <div class="small-12 field" *ngIf="deviceInfo && isMobile && !oldSafari && dateOfBirthSettings">
            <sbw-native-datepicker errorMessage="{{'VALIDATION_PASSENGERS_BIRTHDATE_NOT_VALID' | resource}}" [viewModel]="dateOfBirthSettings.datepickerView" [dateControl]="formDateOfBirth">
            </sbw-native-datepicker>
            <div class="dob-label">
              {{'ADDITIONAL_INFORMATION_DATE_OF_BIRTH' | resource}}
            </div>
          </div>
          <div class="small-12 field data-hj-suppress"
            *ngIf="(deviceInfo && oldSafari || deviceInfo && !isMobile && dateOfBirthSettings)">
            <sbw-datepicker 
              [dateControl]="formDateOfBirth" 
              name="dateOfBirth"
              [viewModel]="dateOfBirthSettings.datepickerView" 
            ></sbw-datepicker>
            <div class="dob-label">
              {{'ADDITIONAL_INFORMATION_DATE_OF_BIRTH' | resource}}
            </div>
          </div>
        </div>
        <div class="column small-12 medium-8 large-8 xlarge-4 end">
          <div class="small-12 field">
            <select data-hj-suppress formControlName="nationalityCode" name="nationality">
              <option *ngFor="let nationality of nationalities" [value]="nationality.code">
                {{nationality.description}}</option>
            </select>
            <div class="dob-label">
              {{'PASSENGER_NATIONALITY' | resource}}
            </div>
          </div>
        </div>
        <div class="column small-12 medium-12 large-12 xlarge-4" *ngIf="showPassportNumber">
          <div class="small-12 field">
            <input formControlName="passportNumber" data-hj-suppress type="text" name="passportNumber"
              [placeholder]="'PASSENGER_PASSPORT_NO' | resource" />
          </div>
        </div>
      </div>
    </div>
  </form>
</div>