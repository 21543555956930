import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router'
import { AmendmentCabinAllocationsComponent } from './amendment-cabin-allocations/amendment-cabin-allocations.component';
import { CabinAllocationGroupComponent } from './cabin-allocation-group/cabin-allocation-group.component';
import { SharedModule } from '../shared/shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser';
import { CabinAllocationComponent } from './cabin-allocation/cabin-allocation.component'
import { CabinAllocationsService } from "app/cabin-allocations/cabin-allocations.service";
import { UIModule } from 'app/shared/UI.module';
import { AuthGuard } from 'app/shared/guards/auth.guard';

const appRoutes: Routes = [
    { path: 'amendment/booking/:reservationCode/cabin-allocations', component: AmendmentCabinAllocationsComponent, canActivate: [AuthGuard] }
]

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forRoot(appRoutes),
        FormsModule,
        UIModule,
        BrowserModule,
        ReactiveFormsModule
    ],
    declarations: [AmendmentCabinAllocationsComponent, CabinAllocationGroupComponent, CabinAllocationComponent],
    providers: [CabinAllocationsService]
})
export class CabinAllocationsModule { }
