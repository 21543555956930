import { Pipe, PipeTransform } from '@angular/core';
import { HeaderSplitterService } from './header-splitter.service';

@Pipe({ name: 'heading' })
export class HeadingPipe implements PipeTransform {
    constructor(private splitter: HeaderSplitterService) { }
    transform(value: any): any {
        const split = this.splitter.split(value)
        return split && split.heading
    }
}

@Pipe({ name: 'withoutHeading' })
export class WithoutHeadingPipe implements PipeTransform {
    constructor(private splitter: HeaderSplitterService) { }
    transform(value: any): any {
        const split = this.splitter.split(value)
        return split && split.rest
    }
}
