import { Injectable } from '@angular/core';
import { CookieService } from '../shared/cookie.service';

@Injectable()
export class LocaleService {
    constructor(private cookieService: CookieService) { }

    localeMappingsFromSeabookToUnified: any =
        {
            'de': 'de',
            'da': 'dk',
            'en': 'en',
            'et': 'et',
            'fi': 'fi',
            'fr': 'fr',
            'ga': 'en',
            'lt': 'lt',
            'lv': 'lv',
            'nl': 'nl',
            'no': 'nb',
            'pl': 'pl',
            'ru': 'ru',
            'sv': 'sv'
        }

    salesOwnerToCountryCodeMapping: any =
        {
            '1': 'SE',
            '4': 'NO',
            '7': 'DK',
            '8': 'DE',
            '11': 'EE',
            '13': 'NL',
            '14': 'GB',
            '15': 'LT',
            '16': 'FR',
            '17': 'INT',
            '18': 'LV',
            '20': 'RU',
            '21': 'FI',
            '19': 'GB',
            '23': 'GB',
            '24': 'FR'
        }

    public getLocale(): string {
        return this.cookieService.getCookie('sbw_Locale');
    }

    public getUnifiedLanguageCode(): string {
        let localeCode = this.localeMappingsFromSeabookToUnified[this.getLocale().toLowerCase()]
        if (!localeCode)
            console.warn('getUnifiedLanguageCode has no mapping for locale: ' + this.getLocale().toLowerCase())
        return localeCode
    }

    public getUnifiedCountryCode(): string {
        const countryCode = this.cookieService.getCookie('unifiedMarket');
        if (!countryCode)
            return 'INT'
        return countryCode.toUpperCase()
    }

    public getUnifiedLanguageCodeForSeabookLocale(): string {
        let localeCode = this.localeMappingsFromSeabookToUnified[this.getLocale().toLowerCase()]
        if (!localeCode)
            console.warn('getUnifiedLanguageCode has no mapping for locale: ' + this.getLocale().toLowerCase())
        return localeCode
    }

    public getCountryCodeForSalesOwner(salesOwner: string): string {
        let countryCode = this.salesOwnerToCountryCodeMapping[salesOwner]
        if (!countryCode)
            return 'INT'
        return countryCode
    }

}
