import { Injectable } from '@angular/core';
import { createClient, Entry, EntryCollection } from 'contentful';
import { EnvironmentService } from 'app/environment.service';
import { LocaleService } from '../locale-service';

const CONFIG = {
    space: 'mivicpf5zews',
    accessToken: '102b6ce0b5beb8e64d0139b604153c92f7476229ee4d2ed5fa3608f2b72640e4',

    contentTypeIds: {
        urlSlug: 'urlSlug',
        miniBook: 'miniBook',
        chat: 'focalscopeLiveChat',
        puzzelChat: 'puzzelLiveChat'
    }
}

@Injectable()
export class ContentfulDfdsService {

    private cdaClient = createClient({
        space: CONFIG.space,
        accessToken: CONFIG.accessToken,
        environment: this.environmentService.getDfdsContentfulEnvironment()
    });

    constructor( private environmentService: EnvironmentService, private localeService: LocaleService) { }

    getUrlSlugByEntryTitle(entryTitle: string): Promise<Entry<any>[]> {

        return this.getEntries({
            'content_type': CONFIG.contentTypeIds.urlSlug,
            'fields.entryTitle': entryTitle,
            'include': 10
        }, this.getContentfulLocale()).then(res => res.items);
    }

    getMinibookById(minibookId: string): Promise<Entry<any>> {

        return this.getEntries({
            'content_type': CONFIG.contentTypeIds.miniBook,
            'sys.id': minibookId,
            'include': 10
        }, this.getContentfulLocale()).then(res => res.items[0]);

    }

    getUrlSlugs(slugIds: string[]): Promise<Entry<any>[]> {

        let locale = window.location.pathname.split('/')[1]
        if (locale === 'ru-ru') locale = 'ru-RU'
        else locale = this.getContentfulLocale()
        return this.getEntries({
            'content_type': CONFIG.contentTypeIds.urlSlug,
            'include': 10,
            'sys.id[in]': slugIds.join(','),
        }, locale).then(res => res.items)
    }

    async getPuzzelChat() {
        const response = await this.getEntries<{
            customerKey: string,
            chatEnabledBookingFlow: boolean,
            configurationId: string,
            queueOverride: string[]
        }>({
            'content_type': CONFIG.contentTypeIds.puzzelChat,
            'include': 2
        }, this.getContentfulLocale())
        return response.items.length === 1 ? response.items[0] : null
    }

    private getContentfulLocale(): string
    {
        return this.mapSeabookLocaleToContentfulLocale( this.localeService.getLocale())
    }

    public getEntries<T>(query?: object, locale?: string): Promise<EntryCollection<T>> {
        return this.cdaClient.getEntries(Object.assign({
            locale: locale,
        }, query))
    }

    mapSeabookLocaleToContentfulLocale(seabookLocale: string)
    {
        switch (seabookLocale.toLowerCase())
        {
            case 'sv': return 'sv-SE'
            case 'da': return 'da-DK'
            case 'fr': return 'fr-FR'
            case 'de': return 'de-DE'
            case 'nl': return 'nl-NL'
            case 'no': return 'nb-NO'
            case 'ru': return 'ru-RU'
            case 'pl': return 'pl-PL'
            case 'lt': return 'lt-LT'
            case 'lv': return 'lv-LV'
            case 'fi': return 'fi-FI'
            case 'en': return 'en-GB'
            case 'et': return 'et-EE'
            default: return 'en'
        }
    }
}
