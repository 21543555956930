import { Injectable } from '@angular/core'
import { Observable, ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'

import { CookieService } from '../cookie.service'
import { SbEvent } from '../sb-event.emitter'
import { IResourceService } from './resource.service.interface'
import { LoggingService } from '../logging.service'
import { StatelessHttpService } from '../stateless-http.service'

@Injectable()
export class ResourceService implements IResourceService {
  public loadedLocale: string
  private loadedResources: { [key: string]: ResourceCollection } = {}
  private doneLoading = false

  constructor(
    private statelessHttpService: StatelessHttpService,
    private events: SbEvent,
    private cookieService: CookieService,
    private logger: LoggingService
  ) {}

  public loadResourcesPromise(route: string, locale: string, components: string[]): Promise<void> {
    return new Promise((res, rej) => {
      locale = locale || this.getLocale()
      this.loadedLocale = locale
      let count = 0
      this.doneLoading = false

      if (!route) {
        route = 'Default'
        this.logger.logWarn({
          message: 'Route was null or empty when requesting resource',
        })
      }

      for (let component of components) {
        const path = `resource/route/${route}/locale/${locale}/component/${component}`
        this.statelessHttpService.get(false, path).subscribe(
          (obj: any) => {
            for (let a in obj) {
              if (this.loadedResources[a]) {
                this.loadedResources[a].next(obj[a], component)
              } else {
                this.loadedResources[a] = ResourceCollection.withValue(obj[a], component)
              }
            }
          },
          () => {
            console.log('unable to load resource', path)
          },
          () => {
            count++
            if (count == components.length) {
              this.doneLoading = true
            }
            if (this.doneLoading) {
              this.triggerChangesForUnpopulated()
              res(null)
            }
          }
        )
      }
    })
  }

  public loadResources(route: string, locale: string, components: string[]): void {
    locale = locale || this.getLocale()
    this.loadedLocale = locale
    let count = 0
    this.doneLoading = false

    if (!route) {
      route = 'Default'
      this.logger.logWarn({ message: 'Route was null or empty when requesting resource' })
    }

    for (let component of components) {
      const path = `resource/route/${route}/locale/${locale}/component/${component}`
      this.statelessHttpService.get(false, path).subscribe(
        (obj: any) => {
          for (let a in obj) {
            if (this.loadedResources[a]) {
              this.loadedResources[a].next(obj[a], component)
            } else {
              this.loadedResources[a] = ResourceCollection.withValue(obj[a], component)
            }
          }
        },
        () => {
          console.log('unable to load resource', path)
        },
        () => {
          count++
          if (count == components.length) {
            this.doneLoading = true
          }
          if (this.doneLoading) {
            this.triggerChangesForUnpopulated()
          }
        }
      )
    }
  }

  public get(key: string, returnKeyIfNotFound = true, component: string = null): Observable<string> {
    if (!this.loadedResources[key]) {
      this.loadedResources[key] = new ResourceCollection(component)
    }
    let c = this.loadedResources[key].getOrCreate(component)
    if (this.doneLoading) {
      c.doneLoading()
    }
    let s = c.subject
    return returnKeyIfNotFound ? s.pipe(map((r) => (r == null && this.doneLoading ? key : r))) : s
  }

  public getLocale(): string {
    const locale = this.cookieService.getCookie('sbw_Locale')
    if (!locale) {
      this.logger.logWarn({ message: 'Locale was null or empty when requesting resource - defaulting to en' })
      return 'en'
    }
    return locale
  }

  public getSalesOwner(): string {
    return this.cookieService.getCookie('sbw_SalesOwner')
  }

  public getSalesTeam(): string {
    return this.cookieService.getCookie('sbw_SalesTeam')
  }

  private triggerChangesForUnpopulated() {
    for (let k in this.loadedResources) {
      // trigger a value change to signal enable the return of the key
      this.loadedResources[k].doneLoading()
    }
  }
}

class ResourceCollection {
  private unspecified: ResourceKeyContext
  private components: { [key: string]: ResourceKeyContext } = {}

  static withValue(value: string, component: string): ResourceCollection {
    let r = new ResourceCollection(component)
    r.next(value, component)
    return r
  }

  constructor(component: string) {
    if (component) {
      this.components[component] = new ResourceKeyContext()
    }
    this.unspecified = new ResourceKeyContext()
  }

  next(value: string, component: string) {
    let specific = this.components[component]
    if (!specific) {
      this.components[component] = specific = new ResourceKeyContext()
    }
    specific.next(value)
    this.unspecified.next(value)
  }

  getOrCreate(component: string): ResourceKeyContext {
    if (component == null) {
      return this.unspecified
    }
    let specific = this.components[component]
    if (!specific) {
      this.components[component] = specific = new ResourceKeyContext()
    }
    return specific
  }

  doneLoading() {
    this.unspecified.doneLoading()
    for (let k in this.components) {
      this.components[k].doneLoading()
    }
  }
}

class ResourceKeyContext {
  subject = new ReplaySubject<string>(1)
  hasValue = false

  static withValue(val: string) {
    let res = new ResourceKeyContext()
    res.next(val)
    return res
  }

  constructor() {}

  next(value: string) {
    this.hasValue = true
    this.subject.next(value)
  }

  doneLoading() {
    if (!this.hasValue) {
      this.next(null)
    }
  }
}
