import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { FormGroup } from '@angular/forms'

import { zip } from 'rxjs'

import { PassengerService } from 'app/passengers/passenger.service'
import { ReservationService } from 'app/shared/reservation/reservation.service'
import { SnapshotService } from 'app/shared/snapshot.service'
import { Passenger } from 'app/passengers/passengers.models'
import { AmendService } from 'app/shared/reservation/amend.service'
import { ResourceService } from 'app/shared/resources/resource.service'
import { hashObject } from 'app/shared/utils'
import { IPassenger } from 'app/shared/models/reservation.interfaces'
import { IInputPassengers } from 'app/passengers/passengers/passengers.component'
import { LayoutState } from 'app/shared/layout/layout-state'
import { TrackingService } from 'app/shared/tracking/tracking.service'
import { TrackingAmendmentStep } from 'app/shared/tracking/tracking-wire.interfaces'
import { AuthService } from 'app/auth/auth.service'
import { LocaleService } from 'app/shared/locale-service'
import { CookieService } from 'app/shared/cookie.service'

@Component({
  selector: 'sbw-amendment-passengers',
  templateUrl: './amendment-passengers.component.html',
  styleUrls: ['./amendment-passengers.component.css'],
})
export class AmendmentPassengersComponent implements OnInit {
  originalPassengers: Passenger[]
  reservationCode: string
  errors: string[] = []
  isAgent: boolean

  inputPassengers: IInputPassengers
  passengerForm: FormGroup
  public passengersChanged: boolean = false
  private initialMd5
  private selections

  constructor(
    private passengerService: PassengerService,
    private reservationService: ReservationService,
    private snapshotService: SnapshotService,
    private route: ActivatedRoute,
    private amendService: AmendService,
    private resourceService: ResourceService,
    private router: Router,
    private title: Title,
    private layoutState: LayoutState,
    private trackingService: TrackingService,
    private authService: AuthService,
    private zone: NgZone,
    private localeService: LocaleService,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
    let authType = this.cookieService.getAuthType()
    this.passengerForm = new FormGroup({})
    this.reservationCode = this.route.snapshot.params['reservationCode']

    if ((authType === 'agent' && this.isAgent) || this.authService.isLoggedIn()) {
      this.getData()
    } else {
      // Do something with azure ad
    }
  }

  public passengersChangedHandler() {
    this.selections = this.passengerForm.get('passengers').value
    this.passengersChanged = hashObject(this.selections) !== this.initialMd5
  }

  continue() {
    this.layoutState.setIsContinueLoading(true)
    const passengers = <IPassenger[]>this.passengerForm.get('passengers').value
    this.amendService.changePassengers(this.reservationCode, passengers).subscribe((response) => {
      this.layoutState.setIsContinueLoading(false)

      // If there are errors then halt & catch fire
      if (response.errorMsgs && response.errorMsgs.length) {
        this.errors = response.errorMsgs.map((m) => m.message)
        this.layoutState.setIsContentLoaded(true)
        this.layoutState.setIsContinueLoading(false)
        return
      }
      this.layoutState.setIsContentLoaded(false)

      this.snapshotService.setSnapshot(this.reservationCode, { passengers: response.reservation.passengers })
      this.navigateBack()
    })
  }
  navigateBack() {
    this.router.navigate(['/amendment/booking', this.reservationCode])
  }

  private getData() {
    this.reservationService.getReservation(this.reservationCode).subscribe((reservation) => {
      let { salesOwnerId, locale, routeCode } = reservation
      this.resourceService
        .loadResourcesPromise(
          routeCode,
          locale,
          ['Common', 'Account', 'Amendment', 'Passengers', 'AdditionalInformation'].concat(this.isAgent ? ['Agent', 'MenuAgent'] : [])
        )
        .then(() => {
          this.resourceService.get('PASSENGERS_TITLE', false).subscribe((s) => this.title.setTitle(s))
          zip(this.passengerService.getTitles('' + salesOwnerId, locale), this.passengerService.getCountries(locale)).subscribe((data) => {
            const [titles, countries] = data
            this.inputPassengers = {
              reservation: reservation,
              form: this.passengerForm,
              titles, // Move this to passenger component, and fetch by itself?
              countries, // Move this to passenger component, and fetch by itself?
            }
            this.layoutState.setIsContentLoaded(true)
          })
          // this.waitForFormCompletion()
          this.trackingService.trackAmendment(TrackingAmendmentStep.PASSENGERS, false, reservation)
        })
    })
  }
}
