<div class="column small-12" [ngClass]="{'half-gutter': i != inputPassengers.length -1 }" *ngFor="let inputPassenger of inputPassengers; index as i">
    <sbw-passenger *ngIf="i === 0"
        [model]="inputPassenger"
        [mode]="mode"
        [validationError]="validationError"
        [editModel]="editModel"
        (created)="created($event)"
        [titles]="titles"
        [nationalities]="countries"
        [locale]="locale"
        [departureDate]="departureDate"
        [routeCode]="routeCode">
    </sbw-passenger>
    <sbw-passenger *ngIf="i !== 0"
        [model]="inputPassenger"
        [mode]="mode"
        [validationError]="validationError"
        (created)="created($event)"
        [titles]="titles"
        [nationalities]="countries"
        [locale]="locale"
        [departureDate]="departureDate"
        [routeCode]="routeCode">
    </sbw-passenger>
</div>