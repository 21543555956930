<div *ngIf="formPassengerSelections" class="gutter">
    <sbw-grid [isCollapsable]="false">
        <sbw-grid-header [isOpen]="true">
            <div class="row">
                <div class="column small-12">
                    {{cabin.description}}
                </div>
            </div>
        </sbw-grid-header>
        <sbw-grid-body>
            <div class="half-gutter"></div>

            <div class="column small-12 gutter faded">
                <span *ngIf="remainingCapacity >= 1">{{bedsLeftFormatted()}}</span>
                <span *ngIf="remainingCapacity == 0">{{noBedsLeftKey | resource}}</span>
            </div>
            <div *ngFor="let passenger of formPassengerSelections.controls; let i = index">
                <div>
                    <div class="column small-12">
                        <div class="row">
                            <label>
                                <div class="column small-10 option-text" [class.option-text-disabled]="shouldPassengerBeDisabled(i) || !showPassenger(i)">
                                    {{passenger.controls.title.value}} {{passenger.controls.firstName.value}} {{passenger.controls.lastName.value}}
                                </div>
                                <div class="column small-2 option-checkbox right">
                                    <sbw-checkbox [label]="false" [disabled]="shouldPassengerBeDisabled(i) || !showPassenger(i)" [checked]="passengerAllocated(i)" (changed)="pickedPassengerEvent($event, i)"></sbw-checkbox>
                                </div>
                            </label>
                        </div>
                        <div class="small-gutter"></div>
                    </div>
                </div>
            </div>
        </sbw-grid-body>
    </sbw-grid>
</div>