import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ITpaCategory } from 'app/tpa/tpa.interfaces';

@Component({
    selector: 'sbw-tpa-selector',
    templateUrl: './tpa-selector.component.html',
    styleUrls: ['./tpa-selector.component.css']
})

export class TpaSelectorComponent {
    @Input() tpaCategories: ITpaCategory[]
    @Input() currency: string
    @Output() selectorChange = new EventEmitter()

    categoryChange() {
        this.selectorChange.emit()
    }
}
