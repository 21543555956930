<sbw-layout [backButtonVisible]="false" [type]="'Amendment'" [isAgent]="true" [continueButtonVisible]="false">
  <div class="sbw_pagewbox" *ngIf="resourcesLoaded">
    <div class="row uncollapse agent-profile-container">
      <h1>{{ 'AGENT_PROFILE_PAGE_TITLE' | resource }}</h1>
    </div>
    <div class="row">
      <div class="small-12 column gutter">
        <div class="agent-details-container">
          <div class="agent-details-row">
            <div class="bold">
              {{ 'AGENT_PROFILE_ACCOUNT' | resource }}
            </div>
            <div>
              {{ agentWire.agentDetails.travelAgentIdPart }}
            </div>
          </div>
          <div class="agent-details-row">
            <div class="bold">
              {{ 'AGENT_ADDRESS' | resource }}
            </div>
            <div>
              {{ agentWire.agentDetails.addressLine1 }}, {{ agentWire.agentDetails.addressLine2 }},
              {{ agentWire.agentDetails.postalCode }}
              {{ agentWire.agentDetails.city }}
            </div>
          </div>
          <div class="agent-details-row">
            <div class="bold">
              {{ 'AGENT_PROFILE_PHONE_NUMBER' | resource }}
            </div>
            <div>{{ agentWire.agentDetails.phone }}</div>
          </div>
          <div class="agent-details-row">
            <div class="bold">
              {{ 'AGENT_FAX' | resource }}
            </div>
            <div>{{ agentWire.agentDetails.fax }}</div>
          </div>
          <div class="agent-details-row">
            <div class="bold">
              {{ 'AGENT_CONFIRMATION_EMAIL' | resource }}
            </div>
            <div>{{ agentWire.agentDetails.email }}</div>
          </div>
        </div>
      </div>
      <div class="small-12 medium-5 column end">
        <div class="field">
          <span class="label">{{ 'AGENT_PREFERED_ROUTE' | resource }}</span>
          <select id="defaultRoute" [formControl]="preferedRoute">
            <option *ngFor="let route of agentWire.outboundRoutes" [value]="route.code">
              {{ route.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="small-12 medium-3 column gutter">
        <sbw-button
          id="SubmitContinue"
          type="preferred"
          [(state)]="submitButtonState"
          [text]="'AGENT_PROFILE_SAVE_CHANGES' | resource"
          (clicked)="savePreferedRoute()"
        ></sbw-button>
      </div>
    </div>
  </div>
</sbw-layout>
