<div *ngIf="isAgent">
    <div class="menu-wrapper">
        <nav class="row agent-menu">
            <ul>
                <li>
                    <a routerLink="/agent" routerLinkActive="active">{{
                        "MENU_AGENT_TOP_HOME" | resource: "MenuAgent"
                    }}</a>
                </li>
                <li>
                    <a routerLink="/agent/bookings" routerLinkActive="active">{{
                        "MENU_AGENT_TOP_BOOKINGS" | resource: "MenuAgent"
                    }}</a>
                </li>
                <li>
                    <a routerLink="/agent/profile" routerLinkActive="active">{{
                        "MENU_AGENT_TOP_PROFILE" | resource: "MenuAgent"
                    }}</a>
                </li>
            </ul>
        </nav>
    </div>
</div>