import moment from 'moment'
import { IRestaurant, IMealArrangement, ISeatingTimeAndCapacity, ReservationRule, IPassengersWithPrice } from './meals.interfaces'
import { IRoom, IRoomArrangement, IPassenger } from 'app/shared/models/reservation.interfaces'
import { flatMap, findNested, onlyUnique, singleOr0 } from 'app/shared/utils'
import { SelectItem } from 'app/shared/select/select.models'
import { IRestaurantModel, IMealArrangementModel, IMealTime, IRoomCodeWithTime } from 'app/meals/meals-selector-models'
import { LocalTime, ChronoUnit, DateTimeFormatter } from 'js-joda'
import { ContentfulRestaurant } from 'app/shared/resources/contentful-seabookweb.service'

export class MealsSelectorView {
    public restaurantsFullModel: IRestaurant[]
    public restaurants: IRestaurantModel[]
    private passengers: IPassenger[]
    private rooms: IRoom[]
    private childAges: number[]

    static allRoomCodes(r: IRestaurant) {
        return [].concat(...(r.mealArrangements ?? []).map(a => a.seatingTimeAndCapacity.map(sa => 
            sa.roomCode.match(/^[\D]*/g)[0].toUpperCase())))
    }

    public static restaurantCodes({outboundRestaurants, returnRestaurants}: {outboundRestaurants: IRestaurant[], returnRestaurants?: IRestaurant[]}) {
        const restaurants = (outboundRestaurants ?? []).concat(returnRestaurants ?? [])
        return [].concat(...restaurants.map(MealsSelectorView.allRoomCodes)).filter(onlyUnique)
    }

    constructor(restaurants: IRestaurant[], rooms: IRoom[], passengers: IPassenger[], childrenAges: number[], content: ContentfulRestaurant[] ) {
        this.restaurantsFullModel = restaurants
        this.passengers = passengers || []
        this.rooms = rooms || []
        this.childAges = childrenAges || []
        this.restaurants = restaurants ? restaurants.map(r => this.mapRestaurant(r, content)) : []
    }

    public mapRestaurant(restaurant: IRestaurant, restaurants: ContentfulRestaurant[]): IRestaurantModel {
        const code = singleOr0(MealsSelectorView.allRoomCodes(restaurant).filter(onlyUnique))
        const content = restaurants.find(c => c.restaurantCode === code)
        const res = <IRestaurantModel>{
            name: restaurant.name,
            mealArrangements: restaurant.mealArrangements && restaurant.mealArrangements.map(m => this.mapMealArrangement(m, content)),
            previousMealArrangements: restaurant.mealArrangements && restaurant.mealArrangements.map(m => this.mapMealArrangement(m, content)),
            content
        }
        res.displaySeatingTimeHeader = restaurant.mealArrangements && res.mealArrangements.some(m => !!m.mealTimes)
        res.hasCapacity = res.mealArrangements && res.mealArrangements.filter(ma => ma.hasCapacity).length > 0

        return res
    }

    public mapMealArrangement(mealArrangement: IMealArrangement, content: ContentfulRestaurant): IMealArrangementModel {
        let selectedTimeFromFlowOrSeabook = this.getSelectedTimeFromFlowOrSeabook(mealArrangement)
        let mealTimes = this.getMealTimes(mealArrangement.seatingTimeAndCapacity, selectedTimeFromFlowOrSeabook)

        let mealArrangementModel = <IMealArrangementModel>{
            id: mealArrangement.id,
            roomCodesWithTime: this.getRoomCodesWithTime(mealArrangement),
            description: mealArrangement.description,
            isReservationRule: mealArrangement.reservationRule === ReservationRule.Mandatory || mealArrangement.reservationRule === ReservationRule.MandatoryExclusive || mealArrangement.reservationRule === ReservationRule.MandatoryChoice,
            selected: selectedTimeFromFlowOrSeabook ? true : false,
            mealTimes: mealTimes,
            totalPrice: this.getTotalPrice(mealArrangement),
            pricePerAdult: this.getPricePerAdult(mealArrangement),
            hasCapacity: false
        }

        // If no rooms are provided (MealsFlow), set selected based on reservationRule
        if (this.rooms.length == 0) {
            mealArrangementModel.selected = mealArrangement.reservationRule == 1 ? true : false;
        }

        mealArrangementModel.selectedTime = this.getSelectedTime(selectedTimeFromFlowOrSeabook, mealArrangementModel.mealTimes, content?.defaultSeatingTime) // must do it this way otherwise it does not work when going back in flow
        mealArrangementModel.hasCapacity = this.getCapacityStatusForArrangement(mealArrangementModel, mealArrangement)
        return mealArrangementModel
    }

    private getRoomCodesWithTime(mealArrangement: IMealArrangement): IRoomCodeWithTime[] {
        return mealArrangement.seatingTimeAndCapacity.map(x => {
            return <IRoomCodeWithTime> {
                code: x.roomCode,
                time: x.time
            }
        })
    }

    private getCapacityStatusForArrangement(mealArrangementModel: IMealArrangementModel, mealArrangement: IMealArrangement): boolean {
        if (mealArrangementModel.mealTimes &&
            mealArrangementModel.mealTimes.length > 0) {
            return true
        }

        let mealWithoutTime = mealArrangementModel.mealTimes == null && mealArrangement.seatingTimeAndCapacity.length ? true : false
        if (mealWithoutTime) {
            if (mealArrangement.seatingTimeAndCapacity[0].availableCapacity >= this.passengers.length || mealArrangementModel.selected == true)
                return true
        }
        return false
    }

    private getSelectedTimeFromFlowOrSeabook(mealArrangement: IMealArrangement): LocalTime {
        let roomCode = this.getRoomCode(mealArrangement)
        let nested = findNested<IRoom, IRoomArrangement>(this.rooms.filter(x => x.roomCode === roomCode), x => x.roomArrangements, x => x.id.toLowerCase() === mealArrangement.id.toLowerCase())
        if (nested && nested.outer && !nested.outer.seatingTime) {
            nested.outer.seatingTime = LocalTime.of(0)
        }
        if (nested && nested.inner) { return nested.outer.seatingTime }
        return null
    }

    private getRoomCode(mealArrangement: IMealArrangement): string {
        let roomCodesFromWire = mealArrangement.seatingTimeAndCapacity.map(x => x.roomCode)

        let preloadedRoomCodes: string[] = []

        this.rooms.forEach(x => {
            if (x.roomArrangements.some(y => y.id.toLowerCase() === mealArrangement.id.toLowerCase())) { preloadedRoomCodes.push(x.roomCode)}
        })
        let resultList = roomCodesFromWire.filter(x => { return preloadedRoomCodes.indexOf(x) > -1 })
        if (resultList && resultList.length === 1) { return resultList[0] }
        return null
    }

    static parseSeatingTime (defaultSeatingTime: string | undefined) {
        if (!defaultSeatingTime) return null
        try {
            return LocalTime.parse(defaultSeatingTime)
        }catch {
            return null
        }
    }

    private getSelectedTime(selectedTime: LocalTime, mealTimes: IMealTime[], defaultSeatingTime: string | undefined): LocalTime {
        if (!mealTimes || mealTimes.length <= 0) { return null }
        const seatingTime = MealsSelectorView.parseSeatingTime(defaultSeatingTime) ?? LocalTime.of(19)
        const mealTimesConverted = mealTimes.map(x => <LocalTime>x.selectItem.value)
        const preSelectedTimeList = mealTimesConverted.filter(x => x.equals(selectedTime))
        if (preSelectedTimeList && preSelectedTimeList.length > 0) { return preSelectedTimeList[0]}

        const arrayWithElapsedMinutes = mealTimesConverted.map(x => { return { elapsedTime : Math.abs(x.until(seatingTime, ChronoUnit.MINUTES)), value: x } })
        const closestTo1900 = arrayWithElapsedMinutes.reduce(
            (acc, loc) =>
                acc.elapsedTime < loc.elapsedTime
                    ? acc
                    : loc
        )
        return closestTo1900.value
    }

    public getMealTimes(seatingTimeAndCapacities: ISeatingTimeAndCapacity[], selectedSeatingTime: LocalTime): IMealTime[] {
        if (!seatingTimeAndCapacities) { return null }

        if (!(seatingTimeAndCapacities.filter(stac => { return stac.time != null }).length > 0)) { return null }

        return seatingTimeAndCapacities.filter(x => x.availableCapacity >= this.passengers.length || x.time.equals(selectedSeatingTime)).map(stac => {
            const time = stac.time.format(DateTimeFormatter.ofPattern('HH:mm'))
            return <IMealTime> { avalilableCapacity: stac.availableCapacity, selectItem: new SelectItem(stac.time, time) }
        });
    }

    public getTotalPrice(mealArrangement: IMealArrangement): number {
        if (!mealArrangement.seatingTimeAndCapacity || !mealArrangement.seatingTimeAndCapacity.length) { return 0 }

        let masts = mealArrangement.seatingTimeAndCapacity[0].mealArrangementSubTypes

        if (!masts || !masts.length) { return 0 }

        let noOfPassengers
        let totalPrice = 0
        masts.forEach(mast => {
            // infants
            if (mast.forAge.from == null) {
                noOfPassengers = this.passengers.filter(p => p.customerCategory === 'INFANT').length
            }

            // generic range (children/teenagers)
            if (mast.forAge.from != null && mast.forAge.to != null) {
                noOfPassengers = this.childAges.filter(age => age >= mast.forAge.from && age <= mast.forAge.to).length
            }

            // adults
            if (mast.forAge.to == null) {
                noOfPassengers = this.passengers.filter(p => p.customerCategory === 'ADULT').length
            }

            totalPrice += noOfPassengers * mast.price
        })

        return totalPrice
    }

    getPricePerAdult(mealArrangement: IMealArrangement): number {
        if (!mealArrangement.seatingTimeAndCapacity || !mealArrangement.seatingTimeAndCapacity.length) { return 0 }

        let masts = mealArrangement.seatingTimeAndCapacity[0].mealArrangementSubTypes

        if (!masts || !masts.length) { return 0 }

        for (let mast of masts) {
            if (mast.forAge.to == null) {
                return mast.price
            }
        }

        return 0
    }

    public getPassengersWithPrices(mealArrangementId: string): IPassengersWithPrice[] {

        let masts = flatMap(this.restaurantsFullModel, r => r.mealArrangements.filter(m => m.id === mealArrangementId))[0].seatingTimeAndCapacity[0].mealArrangementSubTypes

        if (!masts || !masts.length) { return [] }

        let passengersWithPrices: IPassengersWithPrice[] = []

        let noOfPassengers
        let totalPrice = 0
        let description: string
        masts.forEach(mast => {
            // infants
            if (mast.forAge.from == null) {
                noOfPassengers = this.passengers.filter(p => p.customerCategory === 'INFANT').length
                totalPrice = mast.price
                description = mast.description
            }

            // generic range (children/teenagers)
            if (mast.forAge.from != null && mast.forAge.to != null) {
                noOfPassengers = this.childAges.filter(age => age >= mast.forAge.from && age <= mast.forAge.to).length
                totalPrice = mast.price
                description = mast.description
            }

            // adults
            if (mast.forAge.to == null) {
                noOfPassengers = this.passengers.filter(p => p.customerCategory === 'ADULT').length
                totalPrice = mast.price
                description = mast.description
            }

            if (noOfPassengers > 0) { passengersWithPrices.push(<IPassengersWithPrice>{ noOfPassengers, description, totalPrice }) }
        })
        return passengersWithPrices
    }

    public getSelectedMealArrangementSubTypes() {
        const mas = []
        for (let r of this.restaurants) {
            for (let ma of r.mealArrangements.filter(m => m.selected)) {
                mas.push({ id: ma.id, restaurantId: r.code, selected: ma.selected, selectedTime: ma.selectedTime })
            }
        }

        return mas
    }
}

