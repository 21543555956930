<sbw-popup [showPopup]="showPopup" [closable]="false" (showPopupChange)="close()">
    <div class="row uncollapse insurance-popup">
        <div class="sbw_legend">
            <h1>{{'INSURANCE_POPUP_HEADER' | resource}}</h1>
            <div class="content" [innerHTML]="text"></div>

            <div class="accept-button">
               <sbw-button [type]="'preferred'" [state]="'activated'" onClickState="'activated'" [text]="'ACCEPT' | resource" (clicked)="close()"></sbw-button>
            </div>
        </div>
    </div>
</sbw-popup>