import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { QueryModel } from './confirmation.models'
import { SbEvent } from 'app/shared/sb-event.emitter'
import { ResourceService } from 'app/shared/resources/resource.service'
import { CookieService } from 'app/shared/cookie.service'
import { SnapshotService } from 'app/shared/snapshot.service'
import { AmendService } from 'app/shared/reservation/amend.service'
import { LayoutState } from 'app/shared/layout/layout-state'
import { StepService } from 'app/shared/steps/step.service'
import { FlowOrAmendment, IFlowOrAmendment, ensureFlowOrAmendment } from 'app/shared/utils'
import { TrackingService } from 'app/shared/tracking/tracking.service'
import { TrackingFlowStep, TrackingAmendmentStep } from 'app/shared/tracking/tracking-wire.interfaces'
import { Title } from '@angular/platform-browser'
import { fromEvent, Subscription } from 'rxjs'

@Component({
  selector: 'sbw-outstanding-confirmation',
  templateUrl: './confirmation.component.html',
})
export class ConfirmationComponent implements OnInit, IFlowOrAmendment {
  private reservationCode: string
  private queryParams: QueryModel
  private cookieData: IDictionary

  public isAgent: boolean
  public mode: FlowOrAmendment
  public outstanding: boolean
  subscription: Subscription

  public concatTexts: IDictionary = {}

  constructor(
    private resourceService: ResourceService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private events: SbEvent,
    private snapshotService: SnapshotService,
    private amendservice: AmendService,
    private layoutState: LayoutState,
    private stepService: StepService,
    private trackingService: TrackingService,
    private title: Title
  ) {}

  ngOnInit() {
    this.resourceService.get('TITLE', false).subscribe((s) => this.title.setTitle(s))
    this.isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
    this.queryParams = this.getQueryParams()
    this.cookieData = this.getCookies()
    this.mode = this.route.snapshot.data['mode'] || 'Flow'
    this.reservationCode = this.queryParams.reservationCode

    ensureFlowOrAmendment(this.mode)
    if (this.mode === 'Flow') {
      this.useFlow()
    } else if (this.mode === 'Amendment') {
      this.useAmendment()
    }

    history.pushState(null, null, location.href)
    this.subscription = fromEvent(window, 'popstate').subscribe((_) => {
      history.pushState(null, null, location.href)
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  useFlow() {
    const flowRes = this.stepService.combineToReservation()
    flowRes.code = this.reservationCode

    let routeCode: string
    let hostIncludedDevFerry = location.host.includes('devferry.dfds.')

    // ******
    // TO BE REMOVED WHEN ALTAPAY HAS ADDED PORTS IN THEIR END
    if (hostIncludedDevFerry && flowRes.routeCode == null) {
      routeCode = 'default'
    } else {
      routeCode = flowRes.outboundRouteCode
    }
    // ******

    this.resourceService
      .loadResourcesPromise(
        routeCode,
        this.cookieData['locale'],
        ['Common', 'Account', 'Confirmation', 'Tracking', 'Quote', 'Currency'].concat(this.isAgent ? ['Agent', 'MenuAgent'] : [])
      )
      .then(() => {
        this.resourceService.get('BOOKING_CONFIRMATION').subscribe((s) => {
          this.concatTexts['headline'] = this.getHeadlineText(this.reservationCode, s)
        })
        this.layoutState.setIsContentLoaded(true)

        // ******
        // TO BE REMOVED WHEN ALTAPAY HAS ADDED PORTS IN THEIR END
        if (!hostIncludedDevFerry && flowRes.routeCode != null) {
          this.trackingService.trackFlow(TrackingFlowStep.CONFIRMATION, 'Booking confirmation', flowRes, true)
        }
        // ******
        // TO BE ENABLED WHEN ALTAPAY HAS ADDED PORTS IN THEIR END
        // this.flowTrackingService.track(TrackingFlowStep.CONFIRMATION, flowRes, true)

        this.ejectCookies()
      })
  }

  useAmendment() {
    this.snapshotService.clearSnapshot(this.reservationCode)
    this.resourceService
      .loadResourcesPromise(
        this.cookieData['routeCode'],
        this.cookieData['locale'],
        ['Common', 'Confirmation'].concat(this.isAgent ? ['Agent', 'MenuAgent'] : [])
      )
      .then(() => {
        this.resourceService.get('BOOKING_CONFIRMATION').subscribe((s) => {
          this.concatTexts['headline'] = this.getHeadlineText(this.reservationCode, s)

          this.layoutState.setIsContentLoaded(true)
        })
        const token = this.cookieService.getReservationToken()
        if (!token && !sessionStorage.getItem('pax_Token')) return // user must have presset f5
        // Force the browser to reload a reservation that has no cache headers to clear the cached version
        const getNonCachedReservation$ = token
          ? this.amendservice.clearCachedReservationWithToken(this.reservationCode, token)
          : this.amendservice.clearCachedReservation(this.reservationCode)
        getNonCachedReservation$.subscribe(
          (reservation) => {
            this.trackingService.trackAmendment(
              this.route.snapshot.data.outstanding
                ? TrackingAmendmentStep.OUTSTANDINGCONFIRMATION
                : TrackingAmendmentStep.AMENDMENTCONFIRMATION,
              true,
              reservation
            ) // must take reservation from maybe sessionStorage
            this.ejectCookies()
          },
          (error) => {
            console.log('Error happended:', error), this.layoutState.setIsContentLoaded(true)
          }
        ) // We add an error check to prevent confirmation step to redicrect to the error page when reservation is not found, due to not being processed fast enough
      })
  }

  private getQueryParams(): QueryModel {
    let reservationCode = this.route.snapshot.params['reservationCode']
    if (typeof reservationCode === 'undefined') {
      return null
    }

    return new QueryModel(reservationCode)
  }

  private getHeadlineText(reservationCode: string, resource: string): string {
    return resource.replace(/\%BOOKINGREF\%/gi, reservationCode)
  }

  private getCookies(): IDictionary {
    const cookieData: IDictionary = {}

    cookieData['routeCode'] = this.cookieService.getCookie('sbRouteCode')
    cookieData['locale'] = this.cookieService.getCookie('sbw_Locale')

    return cookieData
  }

  private ejectCookies() {
    this.cookieService.deleteReservationToken() // to be removed when orca handles authorization - also in cookieService
    this.cookieService.deleteOutstandingAuthToken()
    this.cookieService.deleteAuthorizationToken()
    this.cookieService.deleteOutstandingConfirmPage()
    this.cookieService.deleteAmendmentConfirmPage()
  }
}

interface IDictionary {
  [text: string]: string
}
