<div id="EditCustomerDetailsContainer" class="row" *ngIf="isInEditMode">
    <form [formGroup]="form">
        <div class="column small-12 gutter">
            {{'PASSENGERS_INTRO' | resource:'Passengers'}}
        </div>
        <div class="customer-fields-container">
            <div class="column small-12 medium-4 large-3">
                <div class="row">
                    <div class="column small-12 label">
                        <label>{{'PASSENGERS_PASSENGERTITLE' | resource:'Passengers'}}</label>
                    </div>
                    <div class="column small-12 field">
                        <select formControlName="titleCode">
                            <option *ngFor="let title of model.titles" [value]="title.code">{{title.description}}</option>
                        </select>
                        <div *ngIf="validationError && formTitle.invalid || (formTitle.invalid && (formTitle.dirty || formTitle.touched))">
                            <span class="field-validation-error">{{'VALIDATION_PASSENGERS_TITLE_NOT_EMPTY' | resource:'Passengers'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column small-12 medium-8 large-4">
                <div class="row">
                    <div class="column small-12 label">
                        <label>{{'PASSENGERS_FIRSTNAME' | resource:'Passengers'}}</label>
                    </div>
                    <div class="column small-12 field">
                        <input type="text" formControlName="firstName">
                        <div *ngIf="validationError && formFirstName.invalid || (formFirstName.invalid && (formFirstName.dirty || formFirstName.touched))">
                            <span class="field-validation-error">{{'VALIDATION_PASSENGERS_FIRSTNAME_NOT_EMPTY' | resource:'Passengers'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column small-12 large-5">
                <div class="row">
                    <div class="column small-12 label">
                        <label>{{'PASSENGERS_LASTNAME' | resource:'Passengers'}}</label>
                    </div>
                    <div class="column small-12 field">
                        <input type="text" formControlName="lastName">
                        <div *ngIf="validationError && formLastName.invalid || (formLastName.invalid && (formLastName.dirty || formLastName.touched))">
                            <span class="field-validation-error">{{'VALIDATION_PASSENGERS_LASTNAME_NOT_EMPTY' | resource:'Passengers'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column small-12">
                <div class="row">
                    <div class="column small-12 label">
                        <label>{{'PASSENGERS_COUNTRY' | resource:'Passengers'}}</label>
                    </div>
                    <div class="column small-12 field" id="RegisterCountryCode">
                        <select formControlName="countryCode">
                            <option *ngFor="let country of model.countries" [value]="country.code">{{country.description}}</option>
                        </select>
                        <div *ngIf="validationError && formCountry.invalid || (formCountry.invalid && (formCountry.dirty || formCountry.touched))">
                            <span class="field-validation-error">{{'VALIDATION_PASSENGERS_COUNTRY_NOT_EMPTY' | resource:'Passengers'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column small-12">
                <div class="row collapse">
                    <div class="column small-12 label">
                        <label>{{'PASSENGERS_POSTALCODE' | resource:'Passengers'}}</label>
                    </div>
                    <div class="column field find-address-input" [ngClass]="(formCountry.value === 'GBR')?'small-6 medium-8':'small-12'">
                        <input type="text" formControlName="postalCode">
                        <div *ngIf="validationError && formPostalCode.invalid || formPostalCode.invalid && (formPostalCode.dirty || formPostalCode.touched)">
                            <span class="field-validation-error">{{'VALIDATION_PASSENGERS_POSTALCODE_NOT_EMPTY' | resource:'Passengers'}}</span>
                        </div>
                    </div>
                    <div *ngIf="formCountry.value === 'GBR'" class="column small-6 medium-4 label find-address-button">
                        <sbw-button type="secondary" onClickState="activated" (clicked)="findAddresses()" [text]="'SEARCH_FOR_ADDRESS' | resource:'Passengers'"></sbw-button>
                        <sbw-find-address *ngIf="showAddresses" [showAddresses]="showAddresses" [model]="inputFindAddressModel" (backButtonClicked)="showAddresses=false" (addressSelected)="addressChanged($event)"></sbw-find-address>
                    </div>
                </div>
            </div>

            <div class="address-fields-container">
                <div class="column small-12">
                    <div class="row">
                        <div class="column small-12 label">
                            <label>{{'PASSENGERS_CITY' | resource:'Passengers'}}</label>
                        </div>
                        <div class="column small-12 field">
                            <input type="text" formControlName="city">
                            <div *ngIf="validationError && formCity.invalid || formCity.invalid && (formCity.dirty || formCity.touched)">
                                <span class="field-validation-error">{{'VALIDATION_PASSENGERS_CITY_NOT_EMPTY' | resource:'Passengers'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column small-12">
                    <div class="row">
                        <div class="column small-12 label">
                            <label>{{'PASSENGERS_ADDRESS_LINE_1' | resource:'Passengers'}}</label>
                        </div>
                        <div class="column small-12 field">
                            <input type="text" formControlName="addressLine1">
                            <div *ngIf="validationError && formAddressLine1.invalid || formAddressLine1.invalid && (formAddressLine1.dirty || formAddressLine1.touched)">
                                <span class="field-validation-error">{{'VALIDATION_PASSENGERS_ADDRESS_LINE_1_NOT_EMPTY' | resource:'Passengers'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column small-12">
                    <div class="row">
                        <div class="column small-12 label">
                            <label>{{'PASSENGERS_ADDRESS_LINE_2' | resource:'Passengers'}}</label>
                        </div>
                        <div class="column small-12 field">
                            <input type="text" formControlName="addressLine2">
                        </div>
                    </div>
                </div>
            </div>

            <div class="column small-12">
                <div class="row">
                    <div class="column small-12 label">
                        <sbw-information-popup [text]="'PASSENGERS_PHONE_NUMBER_NOTE' | resource"></sbw-information-popup>
                        <label>{{'PASSENGERS_MOBILE_NUMBER' | resource:'Passengers'}}</label>
                    </div>
                    <div class="column small-12">
                        <div class="row collapse">
                            <div class="column small-5 medium-3 field">
                                <select formControlName="phonePrefix">
                                    <option *ngFor="let phonePrefix of model.phonePrefixes" [value]="phonePrefix">{{phonePrefix}}</option>
                                </select>
                            </div>
                            <div class="column small-7 medium-9 field">
                                <input type="text" formControlName="phone"/>
                                <div *ngIf="validationError && formPhone.invalid || formPhone.invalid && (formPhone.dirty || formPhone.touched)">
                                    <span class="field-validation-error">{{'VALIDATION_PASSENGERS_PHONE_NOT_EMPTY' | resource:'Passengers'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column small-12">
                <div class="row">
                    <div class="column small-12 label">
                        <sbw-information-popup [text]="'PASSENGERS_EMAIL_ADDRESS_NOTE' | resource"></sbw-information-popup>
                        <label>{{'PASSENGERS_EMAIL_ADDRESS' | resource:'Passengers'}}</label>
                    </div>
                    <div class="column small-12 field">
                        <input type="text" formControlName="email">
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
