import { Injectable } from '@angular/core'
import { Observable, ReplaySubject } from 'rxjs'
import { StatefulHttpService } from 'app/shared/stateful-http.service'

export type ICorrelationId = {
    CorrelationId: string
}

@Injectable()
export class LoggingService {

    constructor(private statefulHttpService: StatefulHttpService) { }

    private apiKey
    private error = new Deduplicator()
    private info = new Deduplicator()
    private warn = new Deduplicator()

    logError(payload: Payload, correlationId): Observable<ICorrelationId> {
        let correlationHeader = correlationId && { 'X-Correlation-Id': correlationId }
        return this.error.log(this.enrich(payload), p => this.statefulHttpService.put(`errors`, p, { headers: { ...correlationHeader, ...this.apiKey } }))
    }

    logInfo(payload: Payload): Observable<ICorrelationId> {
        return this.info.log(this.enrich(payload), p => this.statefulHttpService.put(`info`, p, { headers: this.apiKey }))
    }

    logWarn(payload: Payload): Observable<ICorrelationId> {
        return this.warn.log(this.enrich(payload), p => this.statefulHttpService.put(`warn`, p, { headers: this.apiKey }))
    }

    public setApiKey(key) {
        this.apiKey = { 'X-Api-Key': key }
    }

    private enrich(p: Payload) {
        return {
            userAgent: navigator.userAgent,
            path: window.location.pathname,
            query: window.location.search,
            ...p
        }
    }

}

export class Deduplicator {
    lastMessage: Payload
    lastResult: Observable<any>

    log(payload: Payload, action: (payload: Payload) => Observable<any>) {
        if (this.lastMessage && JSON.stringify(this.lastMessage) == JSON.stringify(payload)) {
            return this.lastResult
        }
        const s = new ReplaySubject(1)
        action(payload).subscribe(o => s.next(o), e => console.error(e), () => s.complete())
        this.lastMessage = payload
        return this.lastResult = s
    }
}

export interface Payload {
    message: string
    path?: string
    query?: string
    stacktrace?: string
    params?: any[]
    steps?: string
}
