<div class="surrounding-container">
    <div class="booking-info" *ngIf="departureInfo && content">
        <div class="row">
            <div class="column small-12 medium-4 large-3">
                {{ 'AMENDMENT_TABLE_HEADER_OUTBOUND_DATE' | resource }}
            </div>
            <div class="column small-12 medium-8 large-9">
                <ul>
                    <li id="departure-out" [ngClass]="{ 'state-new': departureDetails.state == 'New', 'state-old': departureDetails.state == 'Old' }" *ngFor="let departureDetails of departureInfo.out">
                        {{ departureDetails.route }}, {{ departureDetails.departureTime | jodaDateFormatter:content.dateFormat }}, {{ departureDetails.departureTime | jodaTimeFormatter:content.timeFormat }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="row" *ngIf="departureInfo.return">
            <div class="column small-12 medium-4 large-3">
                {{ 'AMENDMENT_TABLE_HEADER_RETURN_DATE' | resource }}
            </div>
            <div class="column small-12 medium-8 large-9">
                <ul>
                    <li id="departure-return" [ngClass]="{ 'state-new': departureDetails.state == 'New', 'state-old': departureDetails.state == 'Old' }" *ngFor="let departureDetails of departureInfo.return">
                        {{ departureDetails.route }}, {{ departureDetails.departureTime | jodaDateFormatter:content.dateFormat }}, {{ departureDetails.departureTime | jodaTimeFormatter:content.timeFormat }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="column small-12 medium-4 large-3">
                {{ 'AMENDMENT_TABLE_HEADER_NUMBER_OF_PASSENGERS' | resource }}
            </div>
            <div class="column small-12 medium-8 large-9">
                <ul>
                    <li id="passenger" data-hj-suppress [ngClass]="{ 'state-new': passenger.state == 'New', 'state-old': passenger.state == 'Old' }" *ngFor="let passenger of passengerInfoList"
                        [attr.data-e2e-passenger-id]="passenger.id">
                        {{passenger.title}} {{passenger.firstName}} {{passenger.lastName}} ({{ passenger.category | resource }}{{ getDateOfBirth(passenger.dateOfBirth,
                        'MOMENT_DATE_OF_BIRTH_FORMAT') }}{{ getBlankIfNull(passenger.nationality) }}{{ getBlankIfNull(passenger.passportNumber)
                        }})
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="column small-12 medium-4 large-3" *ngIf="vehicleInfoList">
                {{ 'VEHICLES_LABEL' | resource }}
            </div>
            <div class="column small-12 medium-8 large-9">
                <ul>
                    <li id="vehicle" data-hj-suppress [ngClass]="{ 'state-new': vehicle.state == 'New', 'state-old': vehicle.state == 'Old' }" *ngFor="let vehicle of vehicleInfoList">
                        {{vehicle.registrationNumber}} {{vehicle.trailerRegistrationNumber}}
                    </li>
                </ul>
            </div>
        </div>
        <div class="row" *ngIf="pets">
            <div class="column small-12 medium-4 large-3">
                {{ 'PETS_LABEL' | resource }}
            </div>
            <div class="column small-12 medium-8 large-9">
                <span id="pets">{{ pets }}</span>
            </div>
        </div>
        <div class="row">
            <div class="column small-12 medium-4 large-3">
                {{ 'BOOKED_ON' | resource }}
            </div>
            <div id="booked" class="column small-12 medium-8 large-9">
                {{ bookedOn | resourceDateformatNew:'MOMENT_SHORT_DATE_TEMPLATE' }}
            </div>
        </div>
        <div class="row">
            <div class="column small-12 medium-4 large-3">
                {{ 'MODIFIED_ON' | resource }}
            </div>
            <div id="modified" class="column small-12 medium-8 large-9">
                {{ modified | resourceDateformatNew:'MOMENT_SHORT_DATE_TEMPLATE' }}
            </div>
        </div>
        <div class="row">
            <div class="column small-12 medium-4 large-3">
                {{ 'PAYMENT_METHOD' | resource }}
            </div>
            <div id="payment-method" class="column small-12 medium-8 large-9">
                {{ creditCard }}
            </div>
        </div>
    </div>
</div>
