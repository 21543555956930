<div *ngIf="model && !isMiniCruiseReturnLeg()" class="row leg-container"
    [ngClass]="{'multiple-departures': hasMultipleDepartures()}">
    <div *ngFor="let departure of departuresOrEmpty(); let i = index"
        class="column leg-container-data no-padding-mobile"
        [ngClass]="{'departure-folded': hasMultipleDepartures() && !departure.expanded, 'gutter': i == departuresOrEmpty().length - 1 || departure.expanded}">
        <div class="leg-header">
            <div class="padding-mobile">
                <h2 class="route-header" *ngIf="i == 0">{{routeDescription()}}</h2>
                <p *ngIf="!departure.cabinGroups" class="noDepartures">
                    {{content.noDepartures}}
                </p>
                <span *ngIf="departure.ferryName && !hasMultipleDepartures()"
                    class="icon-ship leg-ferry">{{departure.ferryName}}</span>
            </div>
            <div *ngIf="i == 0 && model.dates" [ngClass]="hasMultipleDepartures() ? 'grid-container-multiple' : 'grid-container'">
                <div class="grid-earlier-later padding-mobile">
                    <div class="earlier" [ngClass]="hideArrow ? 'earlier_hidden' : 'earlier'">
                        <sbw-prev-next [mode]="modeEnum.Prev" [dateControl]="departureDate" [minDate]="model.dates.min"
                            [maxDate]="model.dates.max" [disabledDates]="model.dates.disabled" [textVisible]="false">
                        </sbw-prev-next>
                    </div>
                    <div class="date-selector">
                        <sbw-datepicker 
                            [dateControl]="departureDate" 
                            name="departureDate"
                            [viewModel]="cabinFaresDateSettings.datepickerView" 
                        ></sbw-datepicker>
                    </div>
                    <div class="later" [ngClass]="hideArrow ? 'later_hidden' : 'later'">
                        <sbw-prev-next [mode]="modeEnum.Next" [dateControl]="departureDate" [minDate]="model.dates.min"
                            [maxDate]="model.dates.max" [disabledDates]="model.dates.disabled" [textVisible]="false">
                        </sbw-prev-next>
                    </div>
                </div>
            </div>
            <div *ngIf="hasMultipleDepartures()" class="multiple" (click)="departure.expanded = !departure.expanded">
                <h5>{{departure.departureTimeFormatted}}</h5>
                <span *ngIf="departure.ferryName" class="icon-ship leg-ferry">{{departure.ferryName}}</span>
                <img class="arrow" src="/_ng/booking/assets/chevron_right.svg">
            </div>
        </div>
        <div *ngFor="let cabinGroup of departure.cabinGroups" class="group-panel cabin-panel">
            <div class="row uncollapse group-header">
                <div class="column small-8 large-9">
                    <div class="cabin-type">
                      <sbw-information-popup
                      *ngIf="!cabinGroup.cabinGroupDetailsEntryFromContentful || !cabinGroup.cabinGroupDetailsEntryFromContentful.legend"
                      [text]="'CABIN_GROUP_' + cabinGroup.cabinGroupCode | resource:'Cabin':false">
                  </sbw-information-popup>
                  <sbw-information-popup
                      [heading]="cabinGroup.cabinGroupDetailsEntryFromContentful.headline"
                      *ngIf="cabinGroup.cabinGroupDetailsEntryFromContentful && cabinGroup.cabinGroupDetailsEntryFromContentful.legend">
                      <sbw-cabin-group [cabinGroup]="cabinGroup.cabinGroupDetailsEntryFromContentful">
                      </sbw-cabin-group>
                  </sbw-information-popup>
                        <h5>{{cabinGroup.cabinGroupDescription}}</h5>
                        <span
                            *ngIf="cabinGroup.cabinGroupDetailsEntryFromContentful && cabinGroup.cabinGroupDetailsEntryFromContentful.note"
                            class="note">{{cabinGroup.cabinGroupDetailsEntryFromContentful.note}}</span>
                    </div>
                </div>
                <div class="column small-4 large-3">
                    <div class="time">
                        <h5>
                            {{departure.departureTimeFormatted}}
                        </h5>
                    </div>
                </div>
            </div>

            <div *ngFor="let inputCabinFaresSelector of cabinGroup.inputCabinFaresSelectors">
                <sbw-cabin-fares-selector
                    [form]="form.get(['cabins', departure.departureId, inputCabinFaresSelector.cabinTypeCode])"
                    [model]="inputCabinFaresSelector" [reservation]="reservation"></sbw-cabin-fares-selector>
            </div>
        </div>
    </div>
</div>
