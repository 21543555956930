<div class="datepicker">
    <p-calendar 
        [selectionMode]="rangeMode ? 'range' : 'single'"
        [(ngModel)]="rangeMode ? rangeDates : rangeDates[0]" 
        (onSelect)="onSelect($event)" 
        [disabledDates]="disabledDates"
        [minDate]="minDate" 
        [maxDate]="maxDate" 
        [numberOfMonths]="isSmallerThan1280() ? 1 : 2"
        [showOtherMonths]="false" 
        (onClickOutside)="onClickOutside()"
        appendTo="body"
        showIcon="true"
        readonlyInput="true">
        <ng-template pTemplate="date" let-date>
            <span [ngClass]="{ 'highlight-date': mustHighlight(date), 'can-select-date': date.selectable }">{{ date.day }}</span>
        </ng-template>
    </p-calendar>
</div>