import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FlowFaresComponent } from './flow-fares/flow-fares.component'
import { FaresSelectorComponent } from './fares-selector/fares-selector.component'
import { Routes, RouterModule } from '@angular/router'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { SharedModule } from '../shared/shared.module'
import { FaresService } from 'app/fares/fares.service'
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service'
import { UIModule } from 'app/shared/UI.module'
import { FlowGuard } from 'app/shared/guards/flow.guard'
import { AmendmentFaresComponent } from './amendment-fares/amendment-fares.component';
import { AuthGuard } from 'app/shared/guards/auth.guard'

const appRoutes: Routes = [
    { path: 'faresnew', component: FlowFaresComponent, canActivate: [FlowGuard] },
    { path: 'fares', component: FlowFaresComponent, canActivate: [FlowGuard] },
    { path: 'amendment/booking/:reservationCode/fares', component: AmendmentFaresComponent, canActivate: [AuthGuard] }
]

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(appRoutes),
        SharedModule,
        UIModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        AmendmentFaresComponent,
        FlowFaresComponent,
        FaresSelectorComponent,
    ],
    providers: [
        SalesChannelService,
        FaresService
    ]
})
export class FaresModule { }
