<ng-container *ngIf="restaurant">
    <div class="teaserImage" *ngIf="restaurant.teaserImage" [ngStyle]="{'background-image':'url(' + restaurant.teaserImage.fields.file.url + ')'}">

        <div *ngIf="restaurant.bannerLine1 || restaurant.bannerLine2Small || restaurant.bannerLine2Large || restaurant.bannerLine3"
            class="banner">

            <div class="bannerContent">
                <div class="bannerLine1">{{restaurant.bannerLine1}}</div>

                <div class="bannerLine2">
                    <span class="bannerLine2Small">{{restaurant.bannerLine2Small}}</span>
                    <span class="bannerLine2Large">{{restaurant.bannerLine2Large}}</span>
                </div>

                <div class="bannerLine3">{{restaurant.bannerLine3}}</div>

            </div>
            <div class="bannerOverlayPolygon">

            </div>
        </div>
    </div>

    <div class="content">
        <p [innerHtml]="restaurant.teaser | markdown">
        </p>

        <div class="menuCardDownloads menuCardDownloadsDesktop" *ngIf="restaurant.menuCard">
            <p>
                <a *ngIf="restaurant.menuCard" class="genericLink  genericLink--rightAlignIcon" href="{{restaurant.menuCard.fields.file.url}}"
                    rel="noopener noreferrer" target="_blank">
                    <div class="genericLink__textWrapper">{{restaurant.menuCardTitle}}</div>
                    <div class="genericLink__iconWrapper">
                        <svg class="svg-icon external-link genericLink__icon genericLink__icon--right" width="16px" height="16px" viewBox="0 0 16 16">
                            <!-- <use xlink:href="#external-link"></use> -->
                            <title>
                                {{restaurant.menuCardTitle}}
                            </title>
                            <path d="M14.2 3.2L7.5 10 6 8.5l6.7-6.7H7.5V0H16v8.5h-1.8V3.2zM12 15.1v.9H0V4h6v1.8H1.8v8.4h8.4V10H12v5.1z"></path>

                        </svg>
                    </div>
                </a>
            </p>

            <p>
                <a *ngIf="restaurant.menuCardForChildren" class="genericLink  genericLink--rightAlignIcon" href="{{restaurant.menuCardForChildren.fields.file.url}}"
                    rel="noopener noreferrer" target="_blank">
                    <div class="genericLink__textWrapper">{{restaurant.menuCardForChildrenTitle}}</div>
                    <div class="genericLink__iconWrapper">
                        <svg class="svg-icon external-link genericLink__icon genericLink__icon--right" width="16px" height="16px" viewBox="0 0 16 16">
                            <!-- <use xlink:href="#external-link"></use> -->
                            <title>
                                {{restaurant.menuCardForChildrenTitle}}
                            </title>
                            <path d="M14.2 3.2L7.5 10 6 8.5l6.7-6.7H7.5V0H16v8.5h-1.8V3.2zM12 15.1v.9H0V4h6v1.8H1.8v8.4h8.4V10H12v5.1z"></path>

                        </svg>
                    </div>
                </a>
            </p>
        </div>
        <div class="contentRows">
            <div class="contentRow" *ngIf="menuStartersInfoBlock">
                <sbw-contentful-info-block [InfoBlock]="menuStartersInfoBlock" [Expanded]="menuStartersExpanded" (onToggleExpand)="onToggleExpandInfoBlock($event)" [InfoBlockBehaviour]="infoBlockBehaviour"></sbw-contentful-info-block>
            </div>
            <div class="contentRow" *ngIf="menuMainCoursesInfoBlock">
                <sbw-contentful-info-block [InfoBlock]="menuMainCoursesInfoBlock" [Expanded]="menuMainCoursesExpanded" (onToggleExpand)="onToggleExpandInfoBlock($event)" [InfoBlockBehaviour]="infoBlockBehaviour"></sbw-contentful-info-block>
            </div>
            <div class="contentRow" *ngIf="menuMainCourseExtrasInfoBlock">
                <sbw-contentful-info-block [InfoBlock]="menuMainCourseExtrasInfoBlock" (onToggleExpand)="onToggleExpandInfoBlock($event)"
                    [InfoBlockBehaviour]="infoBlockBehaviour"></sbw-contentful-info-block>
            </div>
            <div class="contentRow" *ngIf="menuDessertsInfoBlock">
                <sbw-contentful-info-block [InfoBlock]="menuDessertsInfoBlock" (onToggleExpand)="onToggleExpandInfoBlock($event)" [InfoBlockBehaviour]="infoBlockBehaviour"></sbw-contentful-info-block>
            </div>
            <div class="contentRow" *ngIf="menuBeveragesInfoBlock">
                <sbw-contentful-info-block [InfoBlock]="menuBeveragesInfoBlock" (onToggleExpand)="onToggleExpandInfoBlock($event)" [InfoBlockBehaviour]="infoBlockBehaviour"></sbw-contentful-info-block>
            </div>
            <div class="contentRow" *ngIf="menuForChildrenInfoBlock">
                <sbw-contentful-info-block [InfoBlock]="menuForChildrenInfoBlock" (onToggleExpand)="onToggleExpandInfoBlock($event)" [InfoBlockBehaviour]="infoBlockBehaviour"></sbw-contentful-info-block>
            </div>
        </div>

        <div class="menuCardDownloads menuCardDownloadsMobile" *ngIf="restaurant.menuCard">
            <p>
                <a *ngIf="restaurant.menuCard" class="genericLink  genericLink--rightAlignIcon" href="{{restaurant.menuCard.fields.file.url}}"
                    rel="noopener noreferrer" target="_blank">
                    <div class="genericLink__textWrapper">{{restaurant.menuCardTitle}}</div>
                    <div class="genericLink__iconWrapper">
                        <svg class="svg-icon external-link genericLink__icon genericLink__icon--right" width="16px" height="16px" viewBox="0 0 16 16">
                            <!-- <use xlink:href="#external-link"></use> -->
                            <title>
                                {{restaurant.menuCardTitle}}
                            </title>
                            <path d="M14.2 3.2L7.5 10 6 8.5l6.7-6.7H7.5V0H16v8.5h-1.8V3.2zM12 15.1v.9H0V4h6v1.8H1.8v8.4h8.4V10H12v5.1z"></path>

                        </svg>
                    </div>
                </a>
            </p>

            <p>
                <a *ngIf="restaurant.menuCardForChildren" class="genericLink  genericLink--rightAlignIcon" href="{{restaurant.menuCardForChildren.fields.file.url}}"
                    rel="noopener noreferrer" target="_blank">
                    <div class="genericLink__textWrapper">{{restaurant.menuCardForChildrenTitle}}</div>
                    <div class="genericLink__iconWrapper">
                        <svg class="svg-icon external-link genericLink__icon genericLink__icon--right" width="16px" height="16px" viewBox="0 0 16 16">
                            <!-- <use xlink:href="#external-link"></use> -->
                            <title>
                                {{restaurant.menuCardForChildrenTitle}}
                            </title>
                            <path d="M14.2 3.2L7.5 10 6 8.5l6.7-6.7H7.5V0H16v8.5h-1.8V3.2zM12 15.1v.9H0V4h6v1.8H1.8v8.4h8.4V10H12v5.1z"></path>

                        </svg>
                    </div>
                </a>
            </p>
        </div>

    </div>
</ng-container>
