<div class="puzzle-chat">
  <div id="startchat" (click)="onLoadPuzzle()" [ngStyle]="{display: (show ? 'block' : 'none')}">
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M19 4a2 2 0 012 2v10a2 2 0 01-2 2h-6l-4 4v-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14zm-6.2 6h-1.6a.2.2 0 00-.2.2v1.6c0 .11.09.2.2.2h1.6a.2.2 0 00.2-.2v-1.6a.2.2 0 00-.2-.2zm3 0h-1.6a.2.2 0 00-.2.2v1.6c0 .11.09.2.2.2h1.6a.2.2 0 00.2-.2v-1.6a.2.2 0 00-.2-.2zm-6 0H8.2a.2.2 0 00-.2.2v1.6c0 .11.09.2.2.2h1.6a.2.2 0 00.2-.2v-1.6a.2.2 0 00-.2-.2z"
        />
      </svg>
    </div>
  <div id="chathost"></div>
</div>