import { ITpaItemSelection } from 'app/tpa/tpa.interfaces'
import { IMealsWire } from 'app/meals/meals.interfaces'
import { Fare } from 'app/fares/fares.interfaces'
import { VehicleAndTrailerType } from 'app/travel/travel.service'
import { ICabinDepartureSelection } from 'app/cabins/cabins.interfaces'
import { IPassenger } from 'app/shared/models/reservation.interfaces'
import { IVehicleSelection } from 'app/vehicles/vehicles.interfaces'
import { IFlowPassengersUserWire } from 'app/passengers/passengers.interfaces'
import { IAgentInfo } from 'app/passengers/passenger.service'
import { IAdditionalPayments } from 'app/passengers/additional-payments/additional-payments.component'
import { LocalDateTime } from 'js-joda'
import { IWireLegSelections } from 'app/cabin-fares/cabin-fares-wire.interfaces';

export class LocalStorageStepData {
    constructor(
        public selectionType: SelectionTypeEnum,
        public value: any
    ) { }
}

export enum SelectionTypeEnum {
    User = 1,
    Auto = 2
}

export interface IFlowReservation {
    flowActivated: boolean
    code: string
    adults: number
    children: number
    infants: number
    outboundDepartureDate: LocalDateTime
    outboundDepartureId: string
    outboundRouteName: string
    outboundRouteCode: string
    outboundRouteId: string
    passengerTypes: string[]
    passengerInfos: IPassenger[]
    payingPassengers: number
    pets: number
    productCode: string
    offerCode: string
    returnDepartureDate: LocalDateTime
    returnDepartureId: string
    returnRouteName: string
    returnRouteCode: string
    returnRouteId: string
    routeCode: string
    cabinSelections: ICabinDepartureSelection[]
    cabinFaresLegSelections: IWireLegSelections
    tpaSelections?: ITpaItemSelection[]
    passengers?: IPassenger[]
    mealsSelections?: IMealsWire
    currencyCode: string,
    fareSelections?: Fare.IOutReturn<Fare.ISelectedDeparture>
    vehicleAndTrailerTypes: VehicleAndTrailerType[]
    childrenAges: number[]
    campaignCode: string
    creditCardCode: string,
    flowPassengers: IFlowReservationPassengers
    hasAccommodation: boolean
}

export interface IFlowReservationPassengers {
    creditCardCode: string
    additionalPayments: IAdditionalPayments
    vehicles: IVehicleSelection[]
    passengers: IPassenger[]
    userProfile: IFlowPassengersUserWire
    agentInfo: IAgentInfo
    reservationCode: string
}

export function vehiclesParameters(reservation: IFlowReservation): { vehicleType?, trailerType?, vehicleCount?} {
    let firstVehicle = reservation && reservation.vehicleAndTrailerTypes && reservation.vehicleAndTrailerTypes[0]
    return {
        vehicleType: firstVehicle && firstVehicle.vehicleType,
        trailerType: firstVehicle && firstVehicle.trailerType,
        vehicleCount: firstVehicle && firstVehicle.vehicleType && firstVehicle.count || undefined
    }

}
