import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntryCollection } from 'contentful';
import { EnvironmentService } from 'app/environment.service';
import { CookieService } from '../cookie.service';
import resolveResponse from 'contentful-resolve-response'

@Injectable({
    providedIn: 'root'
})
//This client is faster than the client from contentful which puts authorization in a header.
//This authorization header causes the request to be considered non-simple which causes the browser to perform a OPTIONS request also
//By using this client, the number of requests to contentful is cut in half.
export class ContentfulClientApiFast {

    private host: string
    private token: string
    private spaceId: string
    private contentfulEnv: string

    constructor(private http: HttpClient, environmentService: EnvironmentService, private cookieService: CookieService) {
        let preview = document.URL.match(/[?&]previewmode/i) || environmentService.getSbContentfulUsePreview()
        this.host = preview ? 'preview.contentful.com' : 'cdn.contentful.com'
        this.token = preview ? environmentService.getSbContentfulPreviewAccessToken() : environmentService.getSbContentfulAccessToken()
        this.spaceId = environmentService.getSbContentfulSpaceId();
        this.contentfulEnv = environmentService.getDfdsContentfulEnvironment();
    }

    getEntries<T>(contentTypeId: string, filters?: any): Observable<EntryCollection<T>> {
        let params = {
            content_type: contentTypeId,
            locale: this.getLocale(),
            ...filters,
            access_token: this.token
        }

        return new Observable(s => {
            let that = this
            let retries = 5
            function getContent() {
                that.http.get<EntryCollection<T>>(`https://${that.host}/spaces/${that.spaceId}/environments/${that.contentfulEnv}/entries`, {
                    params: params
                }).subscribe(h => {
                    that.wrapEntryCollection(h, { resolveLinks: true, removeUnresolved: true })
                    s.next(h)
                    s.complete()
                }, e => {
                    if (e.status == 429 && retries--) setTimeout(getContent, 1000) // Too many requests. Try again later
                    else s.error(e)
                })
            }
            getContent()
        })
    }

    getLocale(): string {
        return this.mapLocale(this.cookieService.getLocale())
    }

    mapLocale(locale: string): string {
        switch (locale) {
            case 'sv': return 'sv-SE'
            case 'da': return 'da-DK'
            case 'fr': return 'fr-FR'
            case 'de': return 'de-DE'
            case 'nl': return 'nl-NL'
            case 'no': return 'nb-NO'
            case 'ru': return 'ru-RU'
            case 'pl': return 'pl-PL'
            case 'lt': return 'lt-LT'
            case 'lv': return 'lv-LV'
            case 'fi': return 'fi-FI'
            case 'en': return 'en-GB'
            case 'et': return 'et-EE'
            default: return 'en'
        }
    }

    wrapEntryCollection(data, { resolveLinks, removeUnresolved }) {
        if (resolveLinks) {
            data.items = resolveResponse(data, { removeUnresolved, itemEntryPoints: ['fields'] })
        }
    }

}
