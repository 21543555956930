import { Injectable } from '@angular/core'

import { StorageService } from '../../storage.service'
import { DataLayerService } from '../data-layer.service'
import { IFlowReservation } from '../../steps/step.model'
import {
  IEnhancedEcommerceTrack,
  IECommerceTrack,
  IPurchaseTrack,
  ICheckoutTrack,
  IActionField,
  IProductTrack,
  IPurchaseAction,
} from './ecommerce-tracking.interfaces'
import { ITracking, TrackingFlowStep } from '../tracking-wire.interfaces'
import { ResourceService } from 'app/shared/resources/resource.service'
import { LocaleService } from 'app/shared/locale-service'
import { reviveUtcDate } from 'app/shared/utils'
import { MarketService } from 'app/shared/market.service'
import { CookieService } from 'app/shared/cookie.service'

@Injectable()
export class EcommerceFlowTrackingService {
  private reservation: IFlowReservation
  private step: TrackingFlowStep
  private tracking: ITracking

  constructor(
    private dataLayerService: DataLayerService,
    private storageService: StorageService,
    private resourceService: ResourceService,
    private cookieService: CookieService,
    private localeService: LocaleService,
    private marketService: MarketService
  ) {}

  public track(step: TrackingFlowStep, reservation: IFlowReservation): void {
    let trackingStorageItem = this.storageService.getSbwTracking()
    if (trackingStorageItem) {
      this.reservation = reservation
      this.step = step
      this.tracking = <ITracking>JSON.parse(trackingStorageItem, (key: any, value: any) => reviveUtcDate(key, value))
      let enhancedEcommerceTrack = this.createEnhancedEcommerceTrack()
      this.dataLayerService.push(enhancedEcommerceTrack)
    }
  }

  public saveTrackingData(data: ITracking) {
    this.storageService.setSbwTracking(JSON.stringify(data))
  }

  public clearTracking(): void {
    this.storageService.removeSbwTracking()
  }

  private createEnhancedEcommerceTrack(): IEnhancedEcommerceTrack {
    let host = window.location.host.toLowerCase()
    let useUnifiedGTM = host.indexOf('.dfds.com') > 0 || host.indexOf('localhost') >= 0

    let enhancedEcommerceTrack = <IEnhancedEcommerceTrack>{
      isAgent: this.cookieService.isAgent(),
      event: this.step === TrackingFlowStep.CONFIRMATION ? 'purchase' : 'checkout',
      businessArea: 'passenger',
      isReturnBooking: this.reservation.returnDepartureId ? 'Yes' : 'No',
      ecommerce: this.createEcommerce(),
      countryCode: this.localeService.getUnifiedCountryCode(),
      languageCode: this.localeService.getUnifiedLanguageCode(),
      market: this.marketService.getMarket(),
      bookingEngine: useUnifiedGTM ? 'seabookFlow' : 'seabook',

      // old tracking values, may be removed
      passengersAdults: '' + this.reservation.adults,
      passengersChild: '' + this.reservation.children,
      passengersInfant: '' + this.reservation.infants,
      // new tracking values
      numberAdults: '' + this.reservation.adults,
      numberChildren: '' + this.reservation.children,
      numberInfants: '' + this.reservation.infants,

      outboundPassengers: this.reservation.payingPassengers.toString(),
      returnPassengers: this.reservation.payingPassengers.toString(),
      outboundDepartureDate: this.reservation.outboundDepartureDate && this.reservation.outboundDepartureDate.toString().replace('T', ' '),
      returnDepartureDate: this.reservation.returnDepartureDate && this.reservation.returnDepartureDate.toString().replace('T', ' '),
      fullRouteCode: this.reservation.routeCode,
      mealsIncluded: this.reservation.mealsSelections && this.reservation.mealsSelections.out.selectedMeals.length ? 'Yes' : 'No',
      routeName: this.reservation.outboundRouteName,
      routeCode: this.reservation.routeCode,
      salesOwner: this.resourceService.getSalesOwner(),
      vehicleIncluded: this.reservation.vehicleAndTrailerTypes && this.reservation.vehicleAndTrailerTypes.length ? 'Yes' : 'No',
      vehicleType:
        this.reservation.vehicleAndTrailerTypes && this.reservation.vehicleAndTrailerTypes.length
          ? this.reservation.vehicleAndTrailerTypes[0]
          : '',
      arrivalPortName: this.reservation.outboundRouteName,
      departurePortName: this.reservation.returnRouteName,
      bookingType: this.reservation.productCode && this.reservation.productCode[0],
      outwardRouteCode: this.reservation.outboundRouteCode,
      returnRouteCode: this.reservation.returnRouteCode,
      productCode: this.reservation.productCode,
      userID: sessionStorage.getItem('pax_Sub'),
    }
    return enhancedEcommerceTrack
  }

  private createEcommerce(): IECommerceTrack {
    let ecommerceTrack = <IECommerceTrack>{}
    if (this.step === TrackingFlowStep.CONFIRMATION) {
      ecommerceTrack.purchase = <IPurchaseTrack>{
        actionField: <IPurchaseAction>{
          affiliation: 'SeaBook',
          id: this.reservation.code,
          coupon: this.reservation.offerCode,
          currencyCode: this.reservation.currencyCode,
          revenue: this.tracking.price.toFixed(2),
          totalPaid: (this.tracking.depositAmount || this.tracking.price).toFixed(2),
          agent: localStorage.getItem('sbw_AgentGenericId'),
          shipping: this.tracking.creditCardFee.toFixed(2),
          tax: '0',
          deposit: !!this.tracking.depositAmount,
          paymentMethod: this.reservation.creditCardCode,
          passengersAdults: '' + this.reservation.adults,
          passengersChild: '' + this.reservation.children,
          passengersInfant: '' + this.reservation.infants,
          outboundDepartureDate: this.reservation.outboundDepartureDate.toString().replace('T', ' '),
          returnDepartureDate: this.reservation.returnDepartureDate && this.reservation.returnDepartureDate.toString().replace('T', ' '),
        },
        products: this.getProducts(),
      }
    } else {
      ecommerceTrack.checkout = <ICheckoutTrack>{
        actionField: <IActionField>{
          step: this.step,
          option: this.reservation.outboundRouteCode && this.reservation.outboundRouteCode.match(/^DV|DV$/i) ? 'short' : 'long',
          revenue: this.tracking && this.tracking.price ? this.tracking.price.toFixed(2) : null,
        },
        products: this.getProducts(),
      }
    }
    return ecommerceTrack
  }

  private getProducts(): IProductTrack[] {
    return (
      this.tracking &&
      this.tracking.products.map(
        (p) =>
          <IProductTrack>{
            id: p.id,
            brand: 'SeaBook',
            name: p.name ? p.name : p.id,
            price: p.price.toFixed(2),
            variant: this.reservation.productCode[0],
            category: [this.reservation.outboundRouteCode, this.reservation.returnRouteCode].filter((c) => c).join('-'),
            quantity: p.quantity,
            dimension1: p.dimensions && p.dimensions.d1,
            dimension2: p.dimensions && p.dimensions.d2,
            dimension3: p.dimensions && p.dimensions.d3,
            dimension4: p.dimensions && p.dimensions.d4,
          }
      )
    )
  }
}
