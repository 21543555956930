import { Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { FormGroup, FormControl } from '@angular/forms'
import { Observable, of } from 'rxjs'
import { ResourceService } from 'app/shared/resources/resource.service'
import { StepService } from 'app/shared/steps/step.service'
import { IFlowReservation, LocalStorageStepData, SelectionTypeEnum } from 'app/shared/steps/step.model'
import { Fare, IFaresContentModel } from 'app/fares/fares.interfaces'
import { FaresService } from 'app/fares/fares.service'
import { QuoteChanged } from 'app/shared/quote/quote.events'
import { SbEvent } from 'app/shared/sb-event.emitter'
import { HybridService } from 'app/shared/hybrid/hybrid.service'
import { findNested, flatMap, distinctUntilChangedDeep } from 'app/shared/utils'
import { QuoteService } from 'app/shared/quote/quote.service'
import { LayoutState } from 'app/shared/layout/layout-state'
import { LocalDateTime, ChronoUnit, LocalTime, LocalDate } from 'js-joda'
import { TrackingService } from 'app/shared/tracking/tracking.service'
import { TrackingFlowStep } from 'app/shared/tracking/tracking-wire.interfaces'
import { ContentfulClientApiFast } from 'app/shared/resources/contentfulClientApiFast'
import { map, skip } from 'rxjs/operators'
import { ContentfulDfdsService } from 'app/shared/resources/contentful-dfds.service'
import { StorageService } from 'app/shared/storage.service'

@Component({
  selector: 'sbw-flow-fares',
  templateUrl: './flow-fares.component.html',
  styleUrls: ['./flow-fares.component.css'],
})
export class FlowFaresComponent implements OnInit, AfterViewChecked {
  private reservation: IFlowReservation
  public errorList = []
  public data: Fare.IWire
  public faresForm: FormGroup
  public quoteData: Observable<QuoteChanged>
  public isLoading = false
  public resourceLoaded = false
  public productNotActive: boolean
  public currency: string
  public isAgent: boolean
  public content: IFaresContentModel
  public datePickerInfoLabel: string

  selectedOutDeparture: Fare.ISelectedDeparture
  selectedReturnDeparture: Fare.ISelectedDeparture
  daysDuration: number

  get outboundDepartureDateControl(): FormControl {
    return this.faresForm.get('departureDate') as FormControl
  }
  get returnDepartureDateControl(): FormControl {
    return this.faresForm.get('returnDate') as FormControl
  }

  constructor(
    private resourceService: ResourceService,
    private stepService: StepService,
    private hybridService: HybridService,
    private titleService: Title,
    private faresService: FaresService,
    private cdRef: ChangeDetectorRef,
    private sbEvent: SbEvent,
    private quoteService: QuoteService,
    private layoutState: LayoutState,
    private trackingService: TrackingService,
    private contentful: ContentfulClientApiFast,
    private contentfulDfdsService: ContentfulDfdsService,
    private storage: StorageService
  ) {}

  ngOnInit() {
    this.isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
    let locale = this.resourceService.getLocale()
    this.reservation = this.stepService.combineToReservation()
    this.resourceService
      .loadResourcesPromise(
        this.reservation.outboundRouteCode,
        locale,
        ['Common', 'Account', 'Fares', 'Quote', 'Currency', 'Tracking'].concat(this.isAgent ? ['Agent', 'MenuAgent'] : [])
      )
      .then(() => {
        this.resourceService.get('FARES_TITLE', false).subscribe((s) => this.titleService.setTitle(s))
        this.faresForm = new FormGroup(
          {
            departureDate: new FormControl({}),
            returnDate: new FormControl({}),
          },
          (c) => {
            let ret = c.get('return')
            if (!ret) return undefined
            if (!ret.value) return { noDepartures: true }
            let out = c.get('out')
            if (!out.value) return { noDepartures: true }
          }
        )
        this.currency = this.reservation.currencyCode
        this.contentful.getEntries<IFaresContentModel>('flowFares').subscribe((s) => (this.content = s.items[0].fields))

        this.loadData(this.reservation.outboundDepartureDate, this.reservation.returnDepartureDate, true)

        this.setOfferTextInDatePicker()
      })
  }

  private loadData(outboundDate: LocalDateTime, returnDate: LocalDateTime, initialLoad: boolean) {
    this.layoutState.setIsLoading(true)
    this.reservation = this.stepService.combineToReservation()

    this.faresService.getFaresFlow(outboundDate, returnDate, this.reservation).subscribe((data) => {
      this.productNotActive = !(data && (data.out.dates.max || data.out.dates.min))
      this.setSelections(data)
      this.data = data
      let outDefault = this.getInitalDataForQuote(data.out, 'out')
      let returnDefault = data.return && data.return.selectedDeparture ? this.getInitalDataForQuote(data.return, 'return') : null
      let changed: Fare.IFareChangedMessage = { currency: this.reservation.currencyCode, out: outDefault, return: returnDefault }
      if (!this.reservation.returnDepartureDate) this.faresForm.get('returnDate').disable()
      if (outDefault) {
        this.quoteData = of(this.faresService.GetQuoteModel(changed))
      }

      if (this.productNotActive) {
        this.stepService.clearAllSteps()
        this.trackingService.clearFlowTracking()
      }
      this.updateForm()
      this.layoutState.setIsLoading(false)
      this.layoutState.setIsContentLoaded(true)
      this.resourceLoaded = true
      if (initialLoad) {
        this.updateInitialForm()
        this.trackingService.trackFlow(TrackingFlowStep.FARES, 'Select sailing', this.reservation, true)
      }
    })
  }

  private updateInitialForm() {
    let rDate = this.reservation.returnDepartureDate
    if (rDate) {
      this.faresForm.patchValue({ departureDate: this.data.out.dateOfDeparture, returnDate: this.data.return.dateOfDeparture })
    }
    if (!rDate) {
      this.faresForm.patchValue({ departureDate: this.data.out.dateOfDeparture })
    }

    this.faresForm.valueChanges
      .pipe(map((val) => ({ outbound: val.departureDate, return: this.checkReturnDate(val.departureDate, val.returnDate, this.data?.daysDuration ?? null) })))
      .pipe(distinctUntilChangedDeep())
      .pipe(skip(1))
      .subscribe((s) => {
        this.loadData(s.outbound, s.return, false)
      })
  }

  setOfferTextInDatePicker() {
    let miniBookId = this.storage.getItem('sbw_MiniBookId')
    if (miniBookId) {
      this.contentfulDfdsService.getMinibookById(miniBookId).then((miniBookEntry) => {
        this.datePickerInfoLabel = miniBookEntry && miniBookEntry.fields.offerCodeInfoText
      })
    }
  }

  private checkReturnDate(oDate: LocalDate, rDate: LocalDate, daysDuration: number | null): any {
    if (oDate > rDate) {
      return oDate
    }
    if(oDate && daysDuration != null && rDate?.minusDays(daysDuration) > oDate) { 
        return oDate.plusDays(daysDuration)
    }
    return rDate
  }

  private updateForm() {
    let rDate = this.reservation.returnDepartureDate
    if (rDate) {
      this.faresForm.patchValue({ departureDate: this.data.out.dateOfDeparture, returnDate: this.data.return.dateOfDeparture })
    }
    if (!rDate) {
      this.faresForm.patchValue({ departureDate: this.data.out.dateOfDeparture })
    }
    this.returnDepartureDateControl.markAsTouched()
  }

  private getInitalDataForQuote(departure: Fare.IDeparture, direction: Fare.Direction): Fare.IRowItemChanged {
    let productCode = departure.selectedDeparture
      ? departure.selectedDeparture.productCode.toLowerCase()
      : this.reservation.productCode.toLowerCase()
    let selectedProductName = this.data.products.find((x) => x.productCode.toLowerCase() === productCode).description
    let selectedDepartureRow =
      !departure.rows || departure.rows.length === 0 || !departure.selectedDeparture
        ? null
        : departure.rows.find((x) => x.departureId === departure.selectedDeparture.departureId)
    let selectedDeparturePrice = selectedDepartureRow
      ? selectedDepartureRow.items.find((x) => x.productCode.toLowerCase() === departure.selectedDeparture.productCode.toLowerCase()).price
      : 0
    let departureTime = selectedDepartureRow ? selectedDepartureRow.departureTime : departure.dateOfDeparture.atTime(LocalTime.MIN)
    let selectedRoute = selectedDepartureRow
      ? selectedDepartureRow.route
      : direction === 'out'
      ? this.reservation.outboundRouteName
      : this.reservation.returnRouteName

    return {
      route: selectedRoute,
      product: selectedProductName,
      direction: direction,
      departureTime: departureTime,
      price: selectedDeparturePrice,
    }
  }

  public ngAfterViewChecked(): void {
    this.cdRef.detectChanges()
  }

  public postChoices() {
    this.layoutState.setIsContinueLoading(true)
    let outDeparture = this.faresForm.get('out') ? this.faresForm.get('out').value : null
    let returnDeparture = this.faresForm.get('return') ? this.faresForm.get('return').value : null
    let postModel = <Fare.IFaresPostRequest>{
      out: <Fare.IPostDeparture>{
        departureId: outDeparture.departureId,
        expectedPrice: outDeparture.price,
        productCode: outDeparture.productCode,
      },
      return: returnDeparture
        ? <Fare.IPostDeparture>{
            departureId: returnDeparture.departureId,
            expectedPrice: returnDeparture.price,
            productCode: returnDeparture.productCode,
          }
        : null,
    }
    this.faresService.postHybridFares(postModel).subscribe((data) => {
      if (data.errorList) {
        this.errorList = data.errorList.map((e) => e.message)
        this.layoutState.setIsContentLoaded(true)
        this.layoutState.setIsContinueLoading(false)
        return
      }

      let fareSelections: Fare.IFaresWire = { out: outDeparture, return: returnDeparture }
      this.updateStepInSessionStorage(data.step, fareSelections)
      this.quoteService.saveQuoteInSessionStorage(data.quote)
      this.hybridService.changeWindowLocation(data.nextStepUrl)
      this.trackingService.saveFlowTrackingData(data.trackingData)
      this.layoutState.setIsContentLoaded(false)
      this.layoutState.setIsContinueLoading(false)
    })
  }

  public fareChangedHandler(changed: Fare.IFareChangedMessage) {
    this.quoteData = of(this.faresService.GetQuoteModel(changed))
  }

  public navigateBack() {
    this.sbEvent.historyGoBack.next()
  }

  private updateStepInSessionStorage(stepData: any, selections: Fare.IFaresWire) {
    stepData.step.fareSelections = new LocalStorageStepData(SelectionTypeEnum.User, selections)
    this.stepService.saveStepData(stepData.step, window.location.pathname, false)
  }

  private setSelections(data: Fare.IWire) {
    let selectedDepartureOut = this.findRowForSelectedDepartureOut(data)
    let defaultDepartureOut = this.findRowForDefaultDepartureOut(data)
    let departureIdFromSessionStorageOut = this.reservation.fareSelections
      ? this.getSelectedDepartureFromSessionStorage(this.reservation.fareSelections.out, data.out)
      : null

    if (!(selectedDepartureOut && selectedDepartureOut.outer)) {
      this.selectedOutDeparture = null
    }
    this.setSelectionOnLeg(departureIdFromSessionStorageOut, defaultDepartureOut, this.selectedOutDeparture, data.out)

    let defaultDepartureReturn = null

    this.ensureDeparturesAreClearedIfAllDeparturesAreSoldOut(data)

    if (data.out.rows && data.return) {
      let outDeparture = this.selectedOutDeparture
        ? this.selectedOutDeparture
        : departureIdFromSessionStorageOut
        ? departureIdFromSessionStorageOut
        : defaultDepartureOut
      const arrivalTimeOut = this.findArrivalTimeForOutDeparture(data, outDeparture)
      defaultDepartureReturn = this.findRowForDefaultDepartureReturn(data, arrivalTimeOut)

      let selectedDepartureReturn = this.findRowForSelectedDepartureReturn(data)
      if (!(selectedDepartureReturn && selectedDepartureReturn.outer)) {
        this.selectedReturnDeparture = null
      }

      let departureIdFromSessionStorageReturn = this.findReturnDepartureFromSessionStorage(data)
      this.setSelectionOnLeg(departureIdFromSessionStorageReturn, defaultDepartureReturn, this.selectedReturnDeparture, data.return)
    }
  }

  private ensureDeparturesAreClearedIfAllDeparturesAreSoldOut(data: Fare.IWire): void {
    let allSoldOutOnOut =
      data.out &&
      data.out.rows &&
      flatMap(data.out.rows, (x) => x.items.map((y) => y.status)).every((x) => x === Fare.RowItemStatus.SoldOut)
    if (allSoldOutOnOut) {
      data.out.rows = null
    }
    let allSoldOutOnReturn =
      data.return &&
      data.return.rows &&
      flatMap(data.return.rows, (x) => x.items.map((y) => y.status)).every((x) => x === Fare.RowItemStatus.SoldOut)
    if (allSoldOutOnReturn) {
      data.return.rows = null
    }
  }

  private findReturnDepartureFromSessionStorage(data: Fare.IWire) {
    return this.reservation.fareSelections && data.return
      ? this.getSelectedDepartureFromSessionStorage(this.reservation.fareSelections.return, data.return)
      : null
  }

  private findRowForSelectedDepartureReturn(data: Fare.IWire) {
    return this.selectedReturnDeparture
      ? findNested<Fare.IRow, Fare.IRowItem>(
          data.return.rows.filter((x) => x.departureId === this.selectedReturnDeparture.departureId),
          (x) => x.items,
          (x) => x.productCode.toLowerCase() === this.selectedReturnDeparture.productCode.toLowerCase()
        )
      : null
  }

  private findRowForDefaultDepartureReturn(data: Fare.IWire, arrivalTimeOut: LocalDateTime): any {
    return this.getDepartureWithCheapestPriceClosestToNoon(data.return.rows, this.reservation.returnRouteCode, arrivalTimeOut)
  }

  private findArrivalTimeForOutDeparture(data: Fare.IWire, outDeparture: Fare.ISelectedDeparture): LocalDateTime {
    return (
      outDeparture &&
      findNested<Fare.IRow, Fare.IRowItem>(
        data.out.rows.filter((x) => x.departureId === outDeparture.departureId),
        (x) => x.items,
        (x) => x.productCode.toLowerCase() === outDeparture.productCode.toLowerCase()
      ).outer.arrivalTime
    )
  }

  private findRowForDefaultDepartureOut(data: Fare.IWire) {
    return this.getDepartureWithCheapestPriceClosestToNoon(data.out.rows, this.reservation.outboundRouteCode)
  }

  private findRowForSelectedDepartureOut(data: Fare.IWire) {
    return this.selectedOutDeparture
      ? findNested<Fare.IRow, Fare.IRowItem>(
          data.out.rows.filter((x) => x.departureId === this.selectedOutDeparture.departureId),
          (x) => x.items,
          (x) => x.productCode.toLowerCase() === this.selectedOutDeparture.productCode.toLowerCase()
        )
      : null
  }

  private setSelectionOnLeg(
    departureFromSessionStorage: Fare.ISelectedDeparture,
    defaultDeparture: Fare.ISelectedDeparture,
    selectedDeparture: Fare.ISelectedDeparture,
    departure: Fare.IDeparture
  ) {
    if (selectedDeparture) {
      departure.selectedDeparture = selectedDeparture
    } else if (departureFromSessionStorage) {
      departure.selectedDeparture = departureFromSessionStorage
    } else {
      departure.selectedDeparture = defaultDeparture
    }
  }

  private getSelectedDepartureFromSessionStorage(
    selectedDepartureFromSessionStorage: Fare.ISelectedDeparture,
    departure: Fare.IDeparture
  ): Fare.ISelectedDeparture {
    const foundDeparture = departure.rows && departure.rows.length > 0 && departure.rows.find((x) => x.departureId === selectedDepartureFromSessionStorage.departureId)
    return foundDeparture && foundDeparture.items.some((x) => x.productCode === selectedDepartureFromSessionStorage.productCode)
      ? selectedDepartureFromSessionStorage
      : null // must remove previously selected id from session storage as prev, next or new date in datepicker was selected
  }

  private getDepartureWithCheapestPriceClosestToNoon(
    rows: Fare.IRow[],
    routeCode: string,
    arrivalTime?: LocalDateTime
  ): Fare.ISelectedDeparture {
    if (!rows) {
      return null
    }
    const defaultProductCode = this.reservation.productCode
    let departuresReduced = rows
      .filter((x) => x.routeCode.toLowerCase() === routeCode.toLowerCase())
      .reduce((result, element) => {
        if (element.items.find((x) => x.productCode.toLowerCase() === defaultProductCode.toLowerCase() && x.status === 'Available')) {
          result.push(element)
        }
        return result
      }, [])
      .map((row) => {
        let rowItem = row.items.find((x) => x.productCode.toLowerCase() === defaultProductCode.toLowerCase())
        if (rowItem) {
          return {
            departureId: row.departureId,
            departureTime: row.departureTime,
            price: rowItem.price,
          }
        }
      })

    if (arrivalTime) {
      // Only if return leg
      departuresReduced = departuresReduced.filter((x) => x.departureTime.isAfter(arrivalTime))
    }

    if (departuresReduced.length === 0) {
      return null
    }

    const lowestPrice = departuresReduced.reduce((min, current) => (current.price < min ? current.price : min), departuresReduced[0].price)

    const departuresWithLowestPrice = departuresReduced.filter((x) => x.price === lowestPrice)
    if (rows.length === 0) {
      return null
    }

    const noon = LocalDateTime.parse(rows[0].departureTime.toLocalDate() + 'T12:00:00')

    let departureClosestToNoon: any
    let indicator = Number.MAX_VALUE

    departuresWithLowestPrice.forEach((departure) => {
      const timeUntilNoon = Math.abs(departure.departureTime.until(noon, ChronoUnit.SECONDS))
      if (timeUntilNoon < indicator) {
        indicator = timeUntilNoon
        departureClosestToNoon = departure
      }
    })

    return <Fare.ISelectedDeparture>{ departureId: departureClosestToNoon.departureId, productCode: defaultProductCode }
  }
}
