<div *ngIf="showPopup" class="popup" #popupContainer>
    <div class="popup-content active" [ngClass]="{'allow-xl': allowXL, 'disallow-xl': !allowXL}">
        <div class="outer">
            <div class="inner">
                <div *ngIf="closable" class="close-button" (click)="closePopup()">
                    <img src='/_ng/booking/assets/popup-close.svg'>
                </div>
                <div *ngIf="header" class="heading">{{header}}</div>
                <div [ngClass]="{'inner-padding': padding}">
                    <div [ngClass]="{'data': padding}">
                        <ng-content></ng-content>
                    </div>
                </div>

                <div *ngIf="actionButtonText && !isLoading" class="action-buttons">
                    <button class="preferred-action" (click)="performActionButtonClick()">{{actionButtonText}}</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="closable" class="overlay active-full" (click)="closePopup()"></div>
    <div *ngIf="!closable" class="overlay active-full"></div>
</div>
