<sbw-layout
    [backButtonVisible]="false"
    [continueButtonVisible]="false"
    [quoteModel]="quoteData"
    [quoteShowTotalPrice]="true"
    [quoteShowDepartureTime]="true"
    [menuVisible]="false"
    [footerVisible]="false"
    [form]="paymentForm"
    quoteStep="Payment"
    (backEvent)="navigateBack()"
>
    <div class="sbw_pagebox">
        <div id="fakePayment" class="row uncollapse payment-container">
            <div class="column small-12 label">
                <h1>FAKE PAYMENT PAGE SeabookWeb E2E at DFDS A/S</h1>
            </div>
            <div class="row">
                <div class="row payment">
                    <div class="column medium-4">
                        <div class="column small-12 label">
                            <label>Total price to pay:</label>
                        </div>
                    </div>
                    <div class="column medium-6 left field">
                        <div class="column small-12 label">
                            <label style="font-weight: bold;"><span>{{currency | resource}}</span>{{amountToPay | resourceformat:'CURRENCY_FORMAT'}}</label>
                        </div>
                    </div>
                </div>
                <div class="row payment">
                    <div class="column medium-4">
                        <div class="column small-12 label">
                            <label>Payment type:</label>
                        </div>
                    </div>
                    <div class="column medium-6 left field">
                        <div class="column small-12 label">
                            <label style="font-weight: bold;">{{paymentType | uppercase}}</label>
                        </div>
                    </div>
                </div>
                <div class="row payment">
                    <div class="column medium-4">
                        <div class="column small-12 label">
                            <label>Card number</label>
                        </div>
                    </div>
                    <div class="column medium-6 left field">
                        <div class="column small-12">
                            <input type="text" id="creditCardNumberInput">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="column medium-4">
                        <div class="column small-12 label">
                            <label>Expiry date</label>
                        </div>
                    </div>
                    <div class="column medium-6 left field">
                        <div class="column small-12">
                            <select>
                                <option *ngFor="let month of months" name="month" id="emonth" [value]="month">{{month}}</option>
                            </select>
                            <select>
                                <option *ngFor="let year of years" name="year" id="eyear" [value]="year">{{year}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="column medium-4">
                        <div class="column small-12 label">
                            <label>CVC / CVV2:</label>
                        </div>
                    </div>
                    <div class="column medium-6 left field">
                        <div class="column small-12">
                            <input type="text" id="cvcInput">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row sb_payment_actions">
            <div class="column small-12">
                <div class="column medium-3 left" style="padding-left:0;">
                    <sbw-button id="back" type="previous" text="BACK" (clicked)="navigateBack()"></sbw-button>
                </div>
                <div class="column medium-3 right" style="padding-right:0;">
                    <sbw-button id="confirm" type="preferred" text="COMPLETE BOOKING" (clicked)="completeBooking()"></sbw-button>
                </div>
            </div>
        </div>
    </div>
</sbw-layout>