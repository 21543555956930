import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[stuck]'
})
export class StuckDirective {

    @Input() stuck: string

    constructor(private element: ElementRef) { }

    ngDoCheck() {
        let q = this.element.nativeElement
        let stuck = q.getBoundingClientRect().top - +window.getComputedStyle(q).top.replace(/px$/, '') < 1
        if(stuck) q.classList.add('stuck')
        else q.classList.remove('stuck')
    }

}
