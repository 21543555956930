
import { LocalTime } from 'js-joda'
import { ITracking } from '../shared/tracking/tracking-wire.interfaces'

export interface IMealsModel {
    outboundRestaurants: IRestaurant[]
    returnRestaurants: IRestaurant[]
    currency: string
    routeCode: string
    locale: string
}

export interface IForAge {
    from?: number;
    to?: number;
}

export interface IMealArrangementSubType {
    price: number;
    forAge: IForAge;
    componentGenericId: string
    description: string
}

export interface ISeatingTimeAndCapacity {
    time: LocalTime
    availableCapacity: number;
    mealArrangementSubTypes: IMealArrangementSubType[];
    roomCode: string
}

export interface IMealArrangement {
    id: string;
    description: string;
    seatingTimeAndCapacity: ISeatingTimeAndCapacity[];
    reservationRule: ReservationRule;
}

export interface IRestaurant {
    name: string;
    mealArrangements: IMealArrangement[];
}

export interface IRestaurants {
    restaurants: IRestaurant[];
}

export enum ReservationRule {
    NotSet = 0,
    Mandatory = 1,
    MandatoryChoice = 2,
    MandatoryExclusive = 3,
    Optional = 4,
    OptionalExclusive = 5,
}

export interface MealWire {
    departureId: string,
    selectedMeals: SelectedMealWire[],
    childrenAges: number[] // todo remove and let backend handle when all active reservations have ages
}

export interface SelectedMealWire {
    mealId: string
    selectedTime: string
    roomCode: string
}

export interface IMealsWire {
    salesOwner: string,
    out: MealWire,
    return: MealWire,
    childrenAges: number[]
}

export interface IMealFlowResult {
    nextStepUrl: string
    trackingData: ITracking
    quote: any
}

export interface IPassengersWithPrice {
    noOfPassengers: number,
    totalPrice: number,
    description: string
}

export interface IPopupResources {
    noMeals: boolean
    oneLeg: boolean
}

export type MealPopups = 'MEALS_POPUP_NO_MEALS' | 'MEALS_POPUP_ONE_LEG'
