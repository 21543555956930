import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'

import { LocalDate, LocalDateTime } from 'js-joda'

import { IInputCabinFaresSelector } from '../cabin-fares-selector/cabin-fares-selector.component'
import { Mode } from 'app/shared/prev-next/prev-next.component'
import { IFlowReservation } from 'app/shared/steps/step.model';
import { IDepartureChangeDateForDirection, Direction, IDepartureCabinSelections, IDepartureCabins, findSelectedDeparture, flatSelections, ILegDates } from '../cabin-fares.interfaces';
import { toObj, flatMap, entries } from 'app/shared/utils';
import { QuoteService } from 'app/shared/quote/quote.service';
import { Quote } from 'app/shared/quote/quote.models';
import { DatepickerLocaleType, DatepickerView } from 'app/shared/datepicker/datepicker.models'

@Component({
    selector: 'sbw-cabin-fares-group',
    templateUrl: './cabin-fares-group.component.html',
    styleUrls: ['./cabin-fares-group.component.css']
})
export class CabinFaresGroupComponent implements OnChanges {

    @Input() model: IInputCabinFaresGroup
    @Input() reservation: IFlowReservation
    @Input() form: FormGroup
    @Input() content: any
    @Input() hideArrow: boolean = false
    @Input() datePickerInfoLabel: string
    @Output() prevNext: EventEmitter<IDepartureChangeDateForDirection> = new EventEmitter<IDepartureChangeDateForDirection>()

    cabinFaresDateSettings: CabinFaresDateSettings

    modeEnum = Mode
    private quote: Quote.QuoteModel


    get departureDate(): FormControl {
        return this.form.get('departureDate') as FormControl
    }

    constructor(quoteService: QuoteService) {
        quoteService.quoteUpdates.subscribe(s => this.quote = s)
    }

    private getStorageLegSelection(departureId: string) {
        let cabinSelections = this.reservation && this.reservation.cabinFaresLegSelections
        let legSelection = cabinSelections && (this.model.direction == Direction.out ? cabinSelections.out : cabinSelections.return)
        return legSelection && legSelection.departure == departureId && legSelection.cabins
    }

    private getFromStorage(cabinTypeCode: string, departureId: string) {
        let legSelection = this.getStorageLegSelection(departureId)
        let selected = legSelection && legSelection.find(c => c.cabinCode == cabinTypeCode)
        return selected && selected.selection && selected.selection
    }

    private getCabinSelectionsForCabin(departureId: string, cabinTypeCode: string, changes: SimpleChanges): number {
        let prevDate = this.model.departures.length == 1 && findSelectedDeparture(this.form.value)
        return (prevDate && prevDate.cabins[cabinTypeCode]) || (this.isFirstReservationChange(changes) && this.getFromStorage(cabinTypeCode, departureId)) || 0
    }

    private isFirstReservationChange(changes: SimpleChanges) {
        return changes.reservation && changes.reservation.isFirstChange()
    }

    deselectOtherDeparture(parent: FormGroup, departureId: string) {
        let pv: IDepartureCabinSelections = parent.value
        delete pv[departureId]
        entries(pv).forEach(dep => { for (let c in dep.value) dep.value[c] = 0 })
        parent.patchValue(pv)
    }

    public getDateIcon() {
        return this.model.direction === 0 ? '/_ng/booking/assets/datepicker_outbound_blue.svg' : '/_ng/booking/assets/datepicker_return_blue.svg'
    }

    public getLabel() {
        return this.model.direction === 0 ? this.content.departureDate : this.content.returnDate
    }

    ngOnInit() {
        let val = this.form.value as IDepartureCabins
        if (!val || !val.cabins || !this.model.departures) return
        flatMap(entries(val.cabins), c => entries(c.value).filter(s => s.value > 0)
            .flatMap(_ => this.model.departures.filter(d => d.departureId == c.key)))
            .forEach(dep => dep.expanded = true)

        if (this.model.dates && (!this.model.dates.max || !this.model.dates.min)) 
        {   
              this.model.dates.max = this.model.departureDate
              this.model.dates.min = this.model.departureDate
        }

        let disabledDates = []
        let offerDates = []

        if (this.model){
            if (this.model.dates?.disabled?.length > 0){
                disabledDates = this.model.dates.disabled.map(x => new Date(x.toString()))
            }
            if (this.model.dates?.offer?.length > 0){
                offerDates = this.model.dates.offer.map(x => x.toString() )
            }
        }
        this.cabinFaresDateSettings = new CabinFaresDateSettings(
            new Date(this.model.dates.min.toString()), 
            new Date(this.model.dates.max.toString()), 
            disabledDates, this.model.locale,
            offerDates
        )
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes['model']) return
        this.form.setControl('cabins',
            new FormGroup(toObj(this.model.departures, dep => dep.departureId, dep => {
                let controls = this.isMiniCruiseReturnLeg() ? {}
                    : toObj(flatMap(dep.cabinGroups,
                        g => g.inputCabinFaresSelectors), k => k.cabinTypeCode, v => new FormControl(this.getCabinSelectionsForCabin(dep.departureId, v.cabinTypeCode, changes)))

                let group = new FormGroup(controls)
                group.valueChanges.subscribe(_ => this.deselectOtherDeparture(group.parent as FormGroup, dep.departureId))
                return group
            }), control => {
                if (this.isMiniCruiseReturnLeg()) return
                let val = control.value as IDepartureCabinSelections
                let flat = flatSelections(val, this.model.departures)
                let totalSelectedCapacity = flat.map(f => f.selectedCapacity).reduce((a, b) => a + b, 0)
                if (totalSelectedCapacity < this.reservation.payingPassengers) return { notEnoughCapacity: true }
                if (Object.keys(toObj(flat.filter(f => f.selectedCapacity), k => k.departure, () => null)).length > 1) return { multipleDeparturesSelected: true }
            }))

        let userClickedBack = this.reservation.cabinFaresLegSelections
        if (this.form.pristine && !userClickedBack) {
            let mapped = flatMap(this.model.departures, d => flatMap(d.cabinGroups, cg => cg.inputCabinFaresSelectors)
                .map(selector => ({ selector, form: this.form.get(['cabins', d.departureId, selector.cabinTypeCode]) })))
            for (let m of mapped) m.form.setValue(m.selector.numberOfSuggested)
        }
    }

    public showOrHide(showCalendar: boolean) {
        this.hideArrow = showCalendar
    }

    public isMiniCruiseReturnLeg() {
        return this.model.direction == Direction.return && this.model.isMiniCruise
    }

    routeDescription() {
        if (!this.model) return
        if (this.model.routeDescription) return this.model.routeDescription
        if (!this.quote) return
        return (this.model.direction == Direction.out ? this.quote.outbound : this.quote.return).routeName
    }

    hasMultipleDepartures() {
        return this.model.departures && this.model.departures.length > 1
    }

    empty = [{}]
    departuresOrEmpty() {
        let hasDeparturesAndCabins = this.model.departures && this.model.departures.some(d => d.cabinGroups && d.cabinGroups.length > 0)
        return hasDeparturesAndCabins ? this.model.departures : this.empty
    }

}

export interface IInputCabinFaresGroup {
    locale: string
    routeCode: string
    routeDescription: string
    isMiniCruise: boolean
    departureDate: LocalDate
    departureDateNative: Date
    direction: Direction
    dates: ILegDates

    // from contentful
    navigationDateFormat?: string

    departures: ICabinFaresGroupDeparture[]
}

export interface ICabinFaresGroupDeparture {
    departureTime: LocalDateTime
    departureTimeFormatted?: string
    ferryCode: string
    ferryName: string
    departureId: string
    expanded: boolean
    cabinGroups: ICabinGroup[]
}

export interface ICabinGroup {
    cabinGroupDescription: string
    cabinGroupCode: string
    inputCabinFaresSelectors: IInputCabinFaresSelector[]

    // enriched model
    cabinGroupDetailsEntryFromContentful?: any;
}

export interface IDatePeriod {
    from: LocalDate
    to: LocalDate
}
export class CabinFaresDateSettings {
    private _datepickerView: DatepickerView

    constructor(private minDate: Date, private maxDate: Date, private disableDates: Date[], private locale: string, private highlightedDates?: string[]) {
        this._datepickerView = new DatepickerView(<DatepickerLocaleType>this.locale)
        this._datepickerView.defaultDate = this.minDate
        this._datepickerView.minDate = this.minDate
        this._datepickerView.maxDate = this.maxDate
        this._datepickerView.highlightedDates = this.highlightedDates
        this.setYearSelectorRange()
    }

    public get datepickerView(): DatepickerView {
        return this._datepickerView
    }

    private setYearSelectorRange() {
        this._datepickerView.minDate.getFullYear()
        this._datepickerView.maxDate.getFullYear()
        this._datepickerView.disabledDates = this.disableDates
    }
}