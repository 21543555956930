import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { SbEvent } from '../sb-event.emitter';

@Directive({
    selector: '[buttonState]'
})

export class ButtonStateDirective {
    private _defaultState = ButtonStateEnum.Activated;

    constructor(private el: ElementRef, private sbEvent: SbEvent) { }

    @Input() set defaultState(buttonState: ButtonStateEnum) {
        this._defaultState = buttonState || this._defaultState;

        const element: Element = this.el.nativeElement;
        this.setState(this._defaultState, element);
    }

    @Input('buttonState') buttonState: ButtonStateEnum;

    @HostListener('document:submit') onSubmit() {
        const element: Element = this.el.nativeElement;

        this.clearElement(element);
        this.setState(this.buttonState, element);
    }

    @HostListener('window:keydown', ['$event']) onKeyDown(event: any) {
        if (event.keyCode == 27) {
            this.sbEvent.escapePressed.next()
            this.clearElement(this.el.nativeElement)
        }
    }

    private setState(buttonState: ButtonStateEnum, element: Element) {
        switch (buttonState) {
            case ButtonStateEnum.Spinner:
                element.classList.add('is-loading');
                element.classList.add('active');
            case ButtonStateEnum.Disabled:
                element.setAttribute('disabled', 'disabled');
                break;
            default:
                this.clearElement(element);
        }
    }

    private clearElement(element: Element) {
        element.removeAttribute('disabled');
        element.classList.remove('is-loading');
        element.classList.remove('active');
    }
}

export enum ButtonStateEnum {
    Activated = 1,
    Disabled = 2,
    Spinner = 3
}

