import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { LocalDateTime, DateTimeFormatter, LocalDate } from 'js-joda';
import { LoggingService } from '../logging.service';

@Injectable()
class Transform {
    transformDefault(logger: LoggingService, input: LocalDateTime, key: string, defaultKey: string) {
        if (input) {
            try {
                return input.format(DateTimeFormatter.ofPattern(key))
            }
            catch (Exception) {
                let out = input.format(DateTimeFormatter.ofPattern(defaultKey))
                logger.logWarn({ message: `invalid time format ${key}` })
                return out
            }
        }
        logger.logWarn({ message: `Input is null ${input}` })
        return null
    }
}

@Pipe({ name: 'jodaTimeFormatter' })
@Injectable()
export class JodaTimeFormatterPipe extends Transform implements PipeTransform {

    constructor(private logger: LoggingService) {
        super()
    }
    transform(input: LocalDateTime, key: string) {
        return this.transformDefault(this.logger, input, key, "HH:mm")
    }
}

@Pipe({ name: 'jodaDateFormatter' })
@Injectable()
export class JodaDateFormatterPipe extends Transform implements PipeTransform {

    constructor(private logger: LoggingService) {
        super()
    }
    transform(input: LocalDateTime, key: string) {
        return this.transformDefault(this.logger, input, key, "dd.MM.yyyy")
    }
}

@Pipe({ name: 'jodaDateTimeFormatter' })
@Injectable()
export class JodaDateTimeFormatterPipe extends Transform implements PipeTransform {

    constructor(private logger: LoggingService) {
        super()
    }
    transform(input: LocalDateTime, key: string) {
        return this.transformDefault(this.logger, input, key, "dd.MM.yyyy - HH:mm")
    }
}
