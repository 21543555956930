import { Component, OnInit, Input, ContentChild } from '@angular/core'
import { trigger, state, transition, style, AUTO_STYLE, animate } from '@angular/animations'

// GridComponent
@Component({
    selector: 'sbw-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.css']
})

export class GridComponent implements OnInit {

    private header: GridHeader
    private body: GridBody

    @Input() border = true
    @Input() isCollapsable = false
    public isOpen = true

    constructor() { }

    ngOnInit() {
    }

    addHeader(header: GridHeader) {
        this.header = header
    }

    addBody(body: GridBody) {
        this.body = body
    }

    toggleState(gridHeader: GridHeader): void {
        this.isOpen = gridHeader.isOpen
        this.body.pnlState = gridHeader.isOpen ? 'expanded' : 'collapsed'
    }
}


// GridHeader
@Component({
    selector: 'sbw-grid-header',
    templateUrl: './grid-header.component.html'
})

export class GridHeader {
    private _isOpen = true
    private _grid: GridComponent

    @Input() noPadding = false
    @Input()
    set isOpen(value: boolean) {
        this._isOpen = value
        this._grid.toggleState(this)
    }

    get isOpen() {
        return this._isOpen
    }

    constructor(private grid: GridComponent) {
        this._grid = grid
        grid.addHeader(this)
    }

    toggleState(gridHeader: GridHeader): void {
        if (!this._grid.isCollapsable) { return }
        this.isOpen = !this.isOpen
        this._grid.toggleState(this)
    }
}


// GridBody
@Component({
    selector: 'sbw-grid-body',
    templateUrl: './grid-body.component.html',
    animations: [
        trigger('panelState', [
            state('collapsed', style({ height: '0px', 'padding-top': '0px' })),
            state('expanded', style({ height: AUTO_STYLE })),
            transition('collapsed <=> expanded', animate('200ms'))
        ])
    ]
})

export class GridBody {
    public pnlState = 'expanded'

    constructor(private grid: GridComponent) {
        grid.addBody(this)
    }
}
