import { Component, Input } from '@angular/core'
import { AnimationOptions } from 'ngx-lottie'

@Component({
  selector: 'sbw-ship-loader',
  templateUrl: './ship-loader.component.html',
  styleUrls: ['./ship-loader.component.css'],
})
export class ShipLoaderComponent {
  @Input() label: string = '&nbsp;'

  options: AnimationOptions = {
    path: 'https://www.dfds.com/_ng/booking/assets/ship-loader-final.json',
  }
}
