import { Injectable } from '@angular/core'

import { IFlowReservation } from '../steps/step.model'
import { EcommerceFlowTrackingService } from './ecommerce-tracking/ecommerce-flow-tracking.service'
import { TrackingFlowStep, TrackingAmendmentStep, ITracking } from './tracking-wire.interfaces'
import { VirtualPageViewTrackingService } from './virtual-page-view-tracking/virtual-page-view-tracking.service'
import { IReservation } from '../models/reservation.interfaces'
import { EcommerceAmendmentTrackingService } from './ecommerce-tracking/ecommerce-amendment-tracking.service'
import { BrowserNotSupportedTrackingService } from './browser-not-supported-tracking.service'
import { StorageService } from '../storage.service'
import { reviveUtcDate } from '../utils'

@Injectable()
export class TrackingService {
  constructor(
    private ecommerceFlowTrackingService: EcommerceFlowTrackingService,
    private ecommerceAmendmentTrackingService: EcommerceAmendmentTrackingService,
    private virtualPageViewTracking: VirtualPageViewTrackingService,
    private browserNotSupportedTrackingService: BrowserNotSupportedTrackingService,
    private storageService: StorageService
  ) {}

  public trackFlow(step: TrackingFlowStep, pageTitle: string, reservation: IFlowReservation, trackEnhancedEcommerce: boolean): void {
    this.trackFeaturesFromStorage()
    this.virtualPageViewTracking.track(pageTitle, reservation)
    if (trackEnhancedEcommerce) {
      this.ecommerceFlowTrackingService.track(step, reservation)
    }
  }

  private trackFeaturesFromStorage() {
    let trackingStorageItem = this.storageService.getSbwTracking()
    if (!trackingStorageItem) return

    let tracking = <ITracking>JSON.parse(trackingStorageItem, (key: any, value: any) => reviveUtcDate(key, value))
  }

  public saveFlowTrackingData(tracking: ITracking) {
    this.ecommerceFlowTrackingService.saveTrackingData(tracking)
  }

  public clearFlowTracking(): void {
    this.ecommerceFlowTrackingService.clearTracking()
  }

  public trackAmendment(step: TrackingAmendmentStep, trackEnhancedEcommerce = false, reservation?: IReservation) {
    this.virtualPageViewTracking.trackAmendment(step, reservation)
    if (trackEnhancedEcommerce) {
      this.ecommerceAmendmentTrackingService.track(reservation)
    }
  }

  public saveAmendmentTrackingData(reservation: IReservation) {
    this.ecommerceAmendmentTrackingService.saveTrackingData(reservation)
  }

  public trackNotFound(): void {
    this.virtualPageViewTracking.trackNotFound()
  }

  public trackError(): void {
    this.virtualPageViewTracking.trackError()
  }

  public trackBrowserNotSupported() {
    this.browserNotSupportedTrackingService.trackBrowserNotSupported()
  }
}
