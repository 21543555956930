import { BreadcrumbItemModel, BreadcrumbModel } from './breadcrumb.models'
import { Component, OnInit } from '@angular/core'
import { BreadcrumbService } from './breadcrumb.service'
import { SbEvent } from '../sb-event.emitter'
import { HybridService } from '../hybrid/hybrid.service'
import { LayoutState } from '../layout/layout-state'
import { CookieService } from '../cookie.service'
import { Location } from '@angular/common'

@Component({
    selector: 'sb-breadcrumb',
    styleUrls: ['./breadcrumb.component.css'],
    templateUrl: './breadcrumb.component.html'
})

export class BreadcrumbComponent implements OnInit {
    public breadcrumb: BreadcrumbModel = new BreadcrumbModel()
    public breadcrumbItems: Array<BreadcrumbItemModel> = []

    constructor(private breadcrumbService: BreadcrumbService,
        private layoutState: LayoutState,
        private sbEvent: SbEvent,
        private hybridService: HybridService,
        private cookieService: CookieService,
        private location: Location) { }

    ngOnInit() {
        this.sbEvent.historyGoBack.subscribe(() => this.goBack())
        this.breadcrumbService.loadBreadcrumb().subscribe(data => {
            this.breadcrumb = data
            this.breadcrumbItems = data.Items.filter(i => i.Title)
            this.setLinkProperties()
        })
    }

    private setLinkProperties() {
        let compareUrl: string = location.pathname
        compareUrl = compareUrl.replace('payment/agentpayment/newbookingpayment', 'payment')
        compareUrl = compareUrl.replace('payment/scraping', 'payment')
        this.breadcrumb.Items.forEach(b => b.ExternalUrl = this.location.prepareExternalUrl(b.Url))
        let currentIndex = this.breadcrumb.Items.map(d => d.ExternalUrl).indexOf(compareUrl)

        if (currentIndex === -1) { return }

        for (let i = 0; i < currentIndex; i++) {
            this.breadcrumb.Items[i].ShowAsLink = true
        }
        this.breadcrumb.Items[currentIndex].IsCurrentStep = true
    }

    private goBack() {
        let url = location.pathname
        let i = this.breadcrumb.Items.map(d => d.ExternalUrl).indexOf(url)
        if (i > 0) {
            let previous = this.breadcrumb.Items[i - 1]
            if (previous) {
                this.layoutState.setIsContentLoaded(false)
                this.hybridService.changeWindowLocation(previous.Url)
            }
        }
    }

    public goBackLink(url: string) {
        if (this.cookieService.isAgent()) url = 'agent'
        this.layoutState.setIsContentLoaded(false)
        this.hybridService.changeWindowLocation(url)
    }
}
