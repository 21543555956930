import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router'
import { AuthService } from 'app/auth/auth.service'
import { Observable } from 'rxjs'
import { UserDetailsService, UserDetailsWire } from '../user-details.service'
import { getPathRedirectUri } from 'app/auth/auth.config'

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private userDetailsService: UserDetailsService
  ) {}

  canActivate(snapshot: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable<boolean>((obs) => {
      const redirectStartPage = snapshot.routeConfig?.path.includes(':') ? null : snapshot.routeConfig?.path

      this.authService.waitForLogin().then(() => {
        // Agent
        const isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
        if (isAgent) {
          obs.next(true)
          return
        }

        // If user logged in with Azure
        if (this.authService.isLoggedIn()) {
          // Check if we have token in localstorage
          const token = sessionStorage.getItem('pax_Token')
          const sub = sessionStorage.getItem('pax_Sub')

          if (!token || !sub) {
            this.authService.setAccessToken(redirectStartPage).then(() => {
              obs.next(true)
            })
          } else {
            // Verify token validity
            this.userDetailsService.getUserDetails().subscribe((result: UserDetailsWire) => {
              if (!result.isAuthenticated) {
                this.authService
                  .setAccessToken(redirectStartPage)
                  .then(() => {
                    obs.next(true)
                  })
                  .catch(() => {
                    sessionStorage.removeItem('pax_Token')
                    redirectStartPage ? this.authService.initiateLogin(getPathRedirectUri(redirectStartPage)) : this.authService.initiateLogin()
                  })
              } else {
                obs.next(true)
              }
            })
          }
        } else {
          redirectStartPage ? this.authService.initiateLogin(getPathRedirectUri(redirectStartPage)) : this.authService.initiateLogin()
        }
      })
    })
  }
}
