<sbw-layout [backButtonVisible]="true" [quoteModel]="quoteData" [quoteShowTotalPrice]="true"
    [quoteShowDepartureTime]="true" quoteStep="ThirdPartyArrangements" (continueEvent)="continue()"
    (backEvent)="navigateBack()">
    <div class="sbw_pagebox">
        <div class="row uncollapse tpa-container">
            <form action="/tpa" id="sbw_arrangement_options_form" method="post" novalidate="novalidate">
                <div class="row">
                    <div class="column small-12 half-gutter">
                        <div id="legend" class="sbw_legend" [innerHTML]="'ARRANGEMENT_OPTIONS_LEGEND' | resource"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="column small-12 gutter">
                        <sbw-validation-summary [errorList]="errorList"></sbw-validation-summary>
                        <div class="row">
                            <div *ngIf="tpaCategories">
                                <sbw-tpa-selector [tpaCategories]="tpaCategories" (selectorChange)="modelChange()"
                                    [currency]="currency"></sbw-tpa-selector>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</sbw-layout>
<sbw-tpa-popup [tpaCategories]="tpaCategories" [selections]="selections"></sbw-tpa-popup>
