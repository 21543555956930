import { Component, OnInit } from '@angular/core'
import { ResourceService } from 'app/shared/resources/resource.service'
import { IFlowReservation } from 'app/shared/steps/step.model'
import { StepService } from 'app/shared/steps/step.service'
import { Observable, of } from 'rxjs'
import { QuoteChanged } from 'app/shared/quote/quote.events'
import { SbEvent } from 'app/shared/sb-event.emitter'
import { Title } from '@angular/platform-browser'
import { ButtonStateType } from 'app/shared/button/button.component'
import { LayoutState } from 'app/shared/layout/layout-state'
import { PaymentService } from 'app/payment/payment.service'
import { HybridService } from 'app/shared/hybrid/hybrid.service'

@Component({
  selector: 'sbw-finalize-reservation',
  templateUrl: './finalize-reservation.component.html',
  styleUrls: ['./finalize-reservation.component.css'],
})
export class FinalizeReservationComponent implements OnInit {
  agentReference: string
  agentAccountNumber: string
  buttonState: ButtonStateType = 'disabled'
  reservation: IFlowReservation

  public quoteData: Observable<QuoteChanged>

  constructor(
    private resourceService: ResourceService,
    private stepService: StepService,
    private sbEvent: SbEvent,
    private titleService: Title,
    private layoutState: LayoutState,
    private paymentService: PaymentService,
    private hybridService: HybridService
  ) {}

  ngOnInit() {
    this.reservation = this.stepService.combineToReservation()
    if (this.reservation.flowActivated) {
      this.resourceService
        .loadResourcesPromise(this.reservation.outboundRouteCode, this.resourceService.getLocale(), [
          'Common',
          'Payment',
          'Quote',
          'Currency',
          'Agent',
        ])
        .then(() => {
          this.resourceService.get('PAYMENT_TITLE', false).subscribe((t) => this.titleService.setTitle(t))

          this.paymentService.getAgentFinalizeFlow().subscribe((res) => {
            this.agentReference = res.emailAddress
            this.agentAccountNumber = res.accountNumber

            this.layoutState.setIsContentLoaded(true)
          })

          this.quoteData = of(new QuoteChanged(null, null, null, null))
          this.buttonState = 'activated'
        })
    } else {
      this.hybridService.changeWindowLocation('/')
    }
  }

  public navigateBack() {
    window.history.back()
  }

  public continue() {
    this.layoutState.setIsContentLoaded(false)
    this.paymentService.postAgentFinalizeFlow(this.reservation.flowPassengers.reservationCode).subscribe((res) => {
      this.hybridService.changeWindowLocation(`${res.nextStepUrl}/${this.reservation.flowPassengers.reservationCode}`)
    })
  }
}
