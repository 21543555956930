<sbw-layout [backButtonVisible]="false" [continueButtonVisible]="false" type="Amendment"
    [showPaddingAndBackground]="false" [hideDisclaimer]="true" [amendmentPage]="true">
    <div class="row uncollapse bookings-container">
        <div
            *ngIf="ReservationStatus!==status.Active && (ReservationStatus!==status.Inactive && ReservationStatus!==status.Preliminary)">
            <div class="column booking-details-container">
                <div class="column small-12 large-8 half-gutter">
                    <h1 data-e2e="header-booking">{{ 'AMENDMENT_TABLE_HEADER_RESERVATION_ID' | resource }} {{ reservationCode }}</h1>
                </div>
            </div>
        </div>
        <sbw-active *ngIf="ReservationStatus===status.Active" [reservation]="reservation"
            [printConfCallback]="printConfirmation" (loadingDone)="loadingDone()" [pathToMyBookings]="pathToMyBookings">
        </sbw-active>
        <sbw-inactive *ngIf="ReservationStatus===status.Inactive || ReservationStatus===status.Preliminary"
            [reservation]="reservation" [printConfCallback]="printConfirmation" (loadingDone)="loadingDone()"
            [errors]="errors" [pathToMyBookings]="pathToMyBookings">
        </sbw-inactive>
    </div>
</sbw-layout>
