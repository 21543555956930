import { Quote } from './quote.models'
import { SbEvent } from '../sb-event.emitter'

export class QuoteChanged {
    public return: UserQuoteDetails

    public constructor(
        public type: PriceSpecificationType,
        public outbound: UserQuoteDetails,
        return_: UserQuoteDetails,
        public final: UserQuoteDetails,
        public deposit: QuoteDeposit = null) {
        this.return = return_
    }
}

export class QuoteDeposit {
    public constructor(
        public deposit: number,
        public remainingAmount: number
    ) {
    }
}

export class QuoteLine {
    public constructor(
        public label: string,
        public price: number,
        public currencyCode: string,
        public count: number) {
    }
}

export class UserQuoteDetails {
    constructor(public lines: QuoteLine[], public passengers?: Quote.IUserPassengers, public routeName?: string, public departureTime?: string) {

    }
}

export class OneWayUserQuoteDetails extends UserQuoteDetails {
    constructor() {
        super([])
    }
}

export enum PriceSpecificationType {
    Product = 0,
    Vehicles = 1,
    Cabins = 2,
    Pets = 3,
    Meals = 4,
    Hotels = 5,
    Tpa = 6,
    FuelFees = 7,
    AdministrativeFees = 8,
    FareDiscounts = 9,
    CampaignDiscounts = 10,
    Discounts = 11,
    CreditCardFees = 12,
    Fares = 13,
    Travel = 14,
}
