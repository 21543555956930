<div *ngIf="!tpaCategories.length" class="column tpa-category-container no-padding-mobile">
    <div [innerHTML]="'TPA_NONE_AVAILABLE' | resource">
    </div>
</div>

<div *ngFor="let category of tpaCategories" class="column tpa-category-container no-padding-mobile">
    <sbw-grid [isCollapsable]="true" [border]="false">
        <sbw-grid-header [isOpen]="category.isOpen">
            <div class="row">
                <div class="column small-12 medium-6"><h5>{{category.description}}</h5></div>
                <div class="column small-2 hide-for-small-down">&nbsp;</div>
                <div class="column small-2 hide-for-small-down"><h5>{{'ARRANGEMENT_OPTIONS_PRICE' | resource}}</h5></div>
                <div class="column small-2 hide-for-small-down"><h5>{{'ARRANGEMENT_OPTIONS_COUNT' | resource}}</h5></div>
            </div>
        </sbw-grid-header>
        <sbw-grid-body>
            <div *ngFor="let groupItem of category.tpaGroupItems; let i = index; let last = last;" class="tpa-group" [ngClass]="{'last':last}">
                <sbw-tpa-category-group [tpaGroupItem]="groupItem" (groupChange)="categoryChange()" [currency]="currency"></sbw-tpa-category-group>
            </div>
        </sbw-grid-body>
    </sbw-grid>
</div>