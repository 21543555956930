import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'
import { IAmendmentVehicleWire } from 'app/vehicles/vehicles.interfaces'
import { StatelessHttpService } from 'app/shared/stateless-http.service'

@Injectable()
export class VehicleService {

    constructor(
        private statelessHttpService: StatelessHttpService)
    { }

    public getVehiclesAmendment(reservationCode: string): Observable<IAmendmentVehicleWire> {
        return this.statelessHttpService.get(true, 'vehicles-amendment', { params: { reservationCode: reservationCode } })
    }
}
