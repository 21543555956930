import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HeaderSplitterService {
    private parser = new DOMParser()
    private headers = { H1: 1, H2: 1, H3: 1, B: 1 }

    trySplit(html: string, header: string): { heading: string, rest: string } {
        return header ? { heading: header, rest: html } : this.split(html) || { heading: null, rest: html }
    }

    split(html: string): { heading: string, rest: string } {
        if (!html) return null
        var res = this.parser.parseFromString(html, 'text/html')
        const first = res.body.firstChild as Element
        if (first && this.headers[first.tagName] && first.innerHTML.length <= 100) {
            res.body.removeChild(first)
            return { heading: first.innerHTML, rest: res.body.innerHTML }
        }
        return { heading: null, rest: html }
    }

}
