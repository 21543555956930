<div class="column">
    <div class="row">
        <div class="column small-12 large-5">
            <sbw-select-field 
                name="vehicleType" 
                [control]="vehicleControl" 
                label="{{'VEHICLE' | resource}}"
                [items]="vehicleItems" 
            ></sbw-select-field> 
        </div>
        <div class="column small-12 large-4 end" *ngIf="trailerItems.length">
            <sbw-select-field
                name="trailerType" 
                [control]="trailerControl" 
                label="{{'TRAILER' | resource}}"
                [items]="trailerItems" 
            ></sbw-select-field>
        </div>
        <div *ngIf="supportMultiple && isVehicleSelected" class="column small-12 large-3 end">
            <sbw-select-field
                name="vehicleCount" 
                [control]="vehicleCountControl" 
                label="{{'VEHICLE_COUNT' | resource}}"
                [items]="vehicleCountItems"
            ></sbw-select-field>
        </div>
    </div>
</div>
