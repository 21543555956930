import { Injectable } from '@angular/core'

import { DataLayerService } from './data-layer.service'
import { IBrowserNotSupportedTrack } from './browser-not-supported-tracking.interfaces';

@Injectable()
export class BrowserNotSupportedTrackingService {

    constructor(
        private dataLayerService: DataLayerService) {
    }

    public trackBrowserNotSupported(        
    ): void  {
        let browserNotSupportedTrack = this.createBrowserNotSupportedTrack()
        this.dataLayerService.push(browserNotSupportedTrack)
    }

    private createBrowserNotSupportedTrack(): IBrowserNotSupportedTrack {
        let browserNotSupportedTrack = <IBrowserNotSupportedTrack> {
            event: 'browserNotSupported',
        }
        return browserNotSupportedTrack
    }
}
