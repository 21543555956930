<form [formGroup]="cabinSelection" novalidate>
    <div class="row uncollapse group-item cabin-item">
        <div class="column small-8 large-9 cabin-name">
            <span class="name">{{cabin.description}}</span>
            <div class="sbw_info_box"></div>
            <span class="users icon-user">x<i>{{cabin.maxCapacity}}</i></span>
        </div>
        <div class="column small-4 large-3 cabin-prices" [attr.data-e2e-cabin-capacity]="cabin.maxCapacity">
            <label *ngIf="cabin.hasCapacity" class="small-12 large-12 selected-column" [ngClass]="{'selected-cell' : checkForSelectedRow()}">
                <span class="display-cabin-price" [ngClass]="{'selected-price' : checkForSelectedRow()}">{{cabin.price.currencySymbol}} {{cabin.price.currentPrice}}</span>
                <sbw-select formControlName="count" [items]="items"></sbw-select>
            </label>
             <label *ngIf="!cabin.hasCapacity" class="small-12 large-12 selected-column">
                <span class="display-cabin-price" [ngClass]="{'selected-price' : checkForSelectedRow()}">{{'UNAVAILABLE' | resource}}</span>
            </label>
        </div>
    </div>
</form>
