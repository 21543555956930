import { Injectable, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

import { ResourceService } from './resource.service';

@Injectable()
@Pipe({ name: 'resourceDateformat', pure: false })

export class ResourceDateformatPipe implements PipeTransform {

    private cachedResult: string = null;
    private cachedDate: Date;

    constructor(public resourceService: ResourceService) { }

    transform(input: string | Date, key: string, locale: string = null) {
        if(!input) return

        const dateInput = new Date(<any>input)
        if (isNaN(dateInput.getTime())) return

        if (!this.cachedDate || dateInput.getTime() !== this.cachedDate.getTime()) {
            this.cachedDate = dateInput;
            const momentInstance = moment(dateInput).locale(locale !== null ? locale : this.resourceService.getLocale())
            this.resourceService.get(key).subscribe(resource => {
                this.cachedResult = key === resource ? key : momentInstance.format(resource);
            });
        }
        return this.cachedResult;
    }

}
