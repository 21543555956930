import { Injectable } from '@angular/core'
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { Observable, ReplaySubject } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { AuthService } from 'app/auth/auth.service'

//This is used to control apis from mvc backend which does Set-Cookie for asp.net session id. If all the api calls are performed concurrently
//they will all create a new session on the server and we can lose important state causing the flow to restart.
///All communication to asp.net mvc (at the edge of the application) must go through these to ensure we don't have duplicate session ids
///This ensure the first request will complete before we start number two
@Injectable()
export class StatefulHttpService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  private root = '/sbwapi/booking'

  firstInstance: ReplaySubject<number>

  public post<T>(secure: boolean, path: string, body?: any, options?: IHttpOptions): Observable<T> {
    const token = sessionStorage.getItem('pax_Token')
    const agent = localStorage.getItem('sbw_AgentGenericId')

    if (secure) {
      // Verify that user is logged into msal
      if (!agent && !this.authService.isLoggedIn()) {
        this.authService.initiateLogin()
        return
      }

      options = this.setAuth(token, options)
    }

    if (token && !secure) {
      // with new sso setup claims needs to be resolved in ORCA so if token is present we send it
      options = this.addAuth(token, options)
    }

    if (!this.firstInstance) {
      this.firstInstance = new ReplaySubject(1)
      this.http.post(`${this.root}/session-init`, {}).subscribe((_) => this.firstInstance.next(1))
    }
    return this.firstInstance.pipe(switchMap((_) => this.http.post<T>(`${this.root}/${path}`, body, options)))
  }

  public put<T>(path: string, body: any, options?: IHttpOptions): Observable<T> {
    if (!this.firstInstance) {
      this.firstInstance = new ReplaySubject(1)
      this.http.post(`${this.root}/session-init`, {}).subscribe((_) => this.firstInstance.next(1))
    }
    return this.firstInstance.pipe(switchMap((_) => this.http.put<T>(`${this.root}/${path}`, body, options)))
  }

  private setAuth(token: string, options?: IHttpOptions): object {
    if (!token) {
      throw new Error('No token for auth')
    }
    return this.addAuth(token, options)
  }

  private addAuth(token: string, options?: IHttpOptions): object {
    let headers = { ...((options && options.headers) || {}), Authorization: `Bearer ${token}` }
    return { ...options, headers }
  }
}

export interface IHttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[]
      }
  params?:
    | HttpParams
    | {
        [param: string]: string | string[]
      }
  reportProgress?: boolean
  responseType?: 'json'
  withCredentials?: boolean
}
