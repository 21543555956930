<div id="ShowCustomerDetailsContainer" class="">
    <div class="row">
        <div class="column small-12">
            {{titleDescription}} {{userDetails.firstName}} {{userDetails.lastName}}
            <br>{{userDetails.addressLine1}}
            <br>{{userDetails.addressLine2}}
            <br>{{userDetails.city}}
            <br>{{userDetails.postalCode}}
            <br>{{countryDescription}}
            <br>{{userDetails.phonePrefix}} {{userDetails.phone}}
            <br>{{userDetails.email}}
        </div>
        <div class="column small-12 medium-4 large-3 xlarge-2 end field submit">
            <sbw-button onClickState="activated" type="secondary" (clicked)="change()" [text]="'EDIT' | resource:'Common'"></sbw-button>
        </div>
    </div>
</div>