import { FormControl } from "@angular/forms"
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, SimpleChanges } from '@angular/core'
import { LocaleData } from '../locale-settings'
import { nativeJs, LocalDate, convert } from "js-joda"
import { Calendar } from "primeng/calendar"
import { ITravelDepartureDatepicker } from "app/travel/travel.interfaces"
import { PrimeNGConfig } from "primeng/api"

@Component({
    selector: 'sbw-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.css'],
})

export class DateRangePickerComponent implements OnInit {

    @Input() locale: string
    @Input() rangeMode: boolean
    @Input() departureDateControl: FormControl
    @Input() returnDateControl: FormControl
    @Input() oneWayControl: FormControl
    @Input() outbound: ITravelDepartureDatepicker
    @Input() homebound: ITravelDepartureDatepicker

    @ViewChild(Calendar, { static: true }) calenderChild: Calendar
    @Output() loadReturnDates: EventEmitter<void> = new EventEmitter<void>()

    constructor(private config: PrimeNGConfig) { }

    previousRangeDates: Date[] = []
    rangeDates: Date[] = []
    disabledDates: Date[] = []
    highlightedDates: LocalDate[] = []
    minDate: Date
    maxDate: Date

    ngOnInit() {
        const localeData = new LocaleData(this.locale)
        this.addRangeDates()
        this.previousRangeDates = this.rangeDates
        this.disabledDates = this.outbound.disabledDates.map(d => convert(d).toDate())
        this.highlightedDates = this.outbound.offerEnabledDates
        this.minDate = convert(this.outbound.minDate).toDate()
        this.maxDate = convert(this.outbound.maxDate).toDate()
        this.config.setTranslation(localeData.settings)
    }

    ngOnChanges(changes: SimpleChanges) {
        const outboundDate = this.rangeDates.length > 0 ? LocalDate.from(nativeJs(this.rangeDates[0])) : null
        const homeboundDate = this.rangeDates.length > 1 ? LocalDate.from(nativeJs(this.rangeDates[1])) : null
        if (this.rangeMode) {
            if(!outboundDate && !homeboundDate) {
                this.addRangeDates()
            }

            if (outboundDate && !outboundDate.equals(this.departureDateControl.value) && !homeboundDate?.equals(this.returnDateControl.value)) {
                this.addRangeDates()
            }
            if(this.outbound.disabledDates.some(d => d.equals(outboundDate))) {
                this.addRangeDates()            
            }
            if(this.homebound.disabledDates.some(d => d.equals(homeboundDate))) {
                this.addRangeDates()            
            }
            if (changes.rangeMode?.currentValue === true && homeboundDate === null) {
                this.addRangeDates()            
            }
            if (homeboundDate === null){
                this.minDate = convert(this.homebound.minDate).toDate()
                this.maxDate = convert(this.homebound.maxDate).toDate()
                this.disabledDates = this.homebound.disabledDates.map(d => convert(d).toDate())
                this.highlightedDates = this.homebound.offerEnabledDates
            }
            else {
                this.minDate = convert(this.outbound.minDate).toDate()
                this.maxDate = convert(this.outbound.maxDate).toDate()
                this.disabledDates = this.outbound.disabledDates.map(d => convert(d).toDate())
                this.highlightedDates = this.outbound.offerEnabledDates
            }
        }
        else {
            if (outboundDate && !outboundDate.equals(this.departureDateControl.value)) {
                this.rangeDates = [ convert(this.departureDateControl.value).toDate()]
            }
            if(this.outbound.disabledDates.some(d => d.equals(outboundDate))) {
                this.rangeDates = [ convert(this.departureDateControl.value).toDate()]
                this.previousRangeDates = this.rangeDates
            }
            this.disabledDates = this.outbound.disabledDates.map(d => convert(d).toDate())
            this.highlightedDates = this.outbound.offerEnabledDates
        }
    }

    private addRangeDates = () => {
        if (this.departureDateControl.value && !this.rangeMode){
            this.rangeDates = [ convert(this.departureDateControl.value).toDate()] 
        } else if (this.departureDateControl.value && this.returnDateControl.value && this.rangeMode) {
            this.rangeDates = [ convert(this.departureDateControl.value).toDate(), convert(this.returnDateControl.value).toDate()]
        }
        this.previousRangeDates = this.rangeDates
    }

    onSelect(selectedDate: Date) {
        if (this.rangeMode) {
            if (this.rangeDates.length === 2) {
                this.rangeDates = []
            } else if (this.rangeDates.length === 1 && this.rangeDates[0] > selectedDate){
                this.rangeDates = []
            } 
            this.rangeDates.push(selectedDate)
            if (this.rangeDates.length === 1) {
                this.departureDateControl.setValue(LocalDate.from(nativeJs(this.rangeDates[0])))
                this.minDate = convert(this.homebound.minDate).toDate()
                this.maxDate = convert(this.homebound.maxDate).toDate()
                this.disabledDates = this.homebound.disabledDates.map(d => convert(d).toDate())
                this.highlightedDates = this.homebound.offerEnabledDates
            } else if (this.rangeDates.length === 2) {
                this.returnDateControl.setValue(LocalDate.from(nativeJs(this.rangeDates[1])))
                this.minDate = convert(this.outbound.minDate).toDate()
                this.maxDate = convert(this.outbound.maxDate).toDate()
                this.disabledDates = this.outbound.disabledDates.map(d => convert(d).toDate())
                this.highlightedDates = this.outbound.offerEnabledDates
                this.calenderChild.overlayVisible=false;
                this.previousRangeDates = this.rangeDates
            }
        } else {
            this.rangeDates = [selectedDate]
            this.departureDateControl.setValue(LocalDate.from(nativeJs(this.rangeDates[0])))
            this.calenderChild.overlayVisible=false;
        }
    }

    onClickOutside() {
        this.rangeDates = this.previousRangeDates
    }

    mustHighlight = (date: { year: number, month: number, day: number}): boolean => {        
        const dateStr = LocalDate.of(date.year, date.month + 1, date.day)?.toString()
        const result = this.highlightedDates.some(x => x?.toString() === dateStr)
        return result
    }

    isSmallerThan1280 = () => window.innerWidth < 1280
}