import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { HybridService } from 'app/shared/hybrid/hybrid.service';

@Component({
    selector: 'agent-logout',
    template: `
        <div></div>
    `
})

export class AgentLogoutComponent implements OnInit {

    constructor(private hybridService: HybridService, private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            let salesowner = params['salesowner']

            // Remove saved agent data and redirect to agent login
            localStorage.removeItem('sbw_AgentGenericId')

            // Redirect to agent login
            this.hybridService.changeWindowLocation(`agent/account/login?salesowner=${salesowner}`)
        })
    }

}
