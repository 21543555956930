import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { ContentfulInfoBlock } from '../../contentful/contentful-info-block/contentful-info-block';
import { ContentfulContentRef } from '../../contentful/contentful-content-ref/contentful-content-ref';
import { InfoBlockBehaviour } from '../../contentful/info-block-behaviour';
import { ContentfulInfoBlockComponent } from '../../contentful/contentful-info-block/contentful-info-block.component';

@Component({
    selector: 'sbw-restaurant-menu',
    templateUrl: './restaurant-menu.component.html',
    styleUrls: ['./restaurant-menu.component.css']
})
export class RestaurantMenuComponent implements OnInit {
    @Input() restaurant: any

    menuStartersInfoBlock: ContentfulInfoBlock
    menuMainCoursesInfoBlock: ContentfulInfoBlock
    menuMainCourseExtrasInfoBlock: ContentfulInfoBlock
    menuDessertsInfoBlock: ContentfulInfoBlock
    menuBeveragesInfoBlock: ContentfulInfoBlock
    menuForChildrenInfoBlock: ContentfulInfoBlock

    menuStartersExpanded: boolean
    menuMainCoursesExpanded: boolean
    infoBlockBehaviour: InfoBlockBehaviour = InfoBlockBehaviour.Accordion
    @ViewChildren(ContentfulInfoBlockComponent) infoBlocks: QueryList<ContentfulInfoBlockComponent>

    ngOnInit() {
            if (this.restaurant.menuSectionsNoCollapse)
                this.infoBlockBehaviour = InfoBlockBehaviour.NoCollapse
            this.menuStartersInfoBlock = this.mapMenuSectionToInfoBlock(this.restaurant.menuStartersHeadline, this.restaurant.menuStarters)
            this.menuMainCoursesInfoBlock = this.mapMenuSectionToInfoBlock(this.restaurant.menuMainCoursesHeadline, this.restaurant.menuMainCourses)
            this.menuMainCourseExtrasInfoBlock = this.mapMenuSectionToInfoBlock(this.restaurant.menuMainCourseExtrasHeadline, this.restaurant.menuMainCourseExtras)
            this.menuDessertsInfoBlock = this.mapMenuSectionToInfoBlock(this.restaurant.menuDessertsHeadline, this.restaurant.menuDesserts)
            this.menuBeveragesInfoBlock = this.mapMenuSectionToInfoBlock(this.restaurant.menuBeveragesHeadline, this.restaurant.menuBeverages)
            this.menuForChildrenInfoBlock = this.mapMenuSectionToInfoBlock(this.restaurant.menuForChildrenHeadline, this.restaurant.menuForChildren)

            this.menuStartersExpanded = this.restaurant.menuStartersExpanded
            this.menuMainCoursesExpanded = this.restaurant.menuMainCoursesExpanded

    }

    private mapMenuSectionToInfoBlock(sectionHeadline: string, sectionText: string): ContentfulInfoBlock {
        let infoBlock: ContentfulInfoBlock = null
        if (sectionText)
            infoBlock = <ContentfulInfoBlock>{
                contentRef: <ContentfulContentRef>{
                    title: sectionHeadline,
                    text: sectionText
                }
            }
        return infoBlock
    }

    public onToggleExpandInfoBlock(event: any) {
        this.collapseAllInfoBlocks(event.target);
    }

    public collapseAllInfoBlocks(excludeInfoBlock: any) {
        this.infoBlocks.forEach(infoBlock => {
            if (infoBlock != excludeInfoBlock) {
                infoBlock.collapse()
            }
        });
    }

}
