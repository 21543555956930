import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule, Routes } from '@angular/router'
import { MealsSelectorComponent } from './meals-selector/meals-selector.component'
import { AmendmentMealsComponent } from './amendment-meals/amendment-meals.component'
import { SharedModule } from '../shared/shared.module'
import { MealsService } from './meals.service'
import { FormsModule } from '@angular/forms'
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service'
import { FlowMealsComponent } from './flow-meals/flow-meals.component'
import { UIModule } from 'app/shared/UI.module'
import { FlowGuard } from 'app/shared/guards/flow.guard'
import { MealsTrackingService } from '../shared/tracking/meals-tracking.service'
import { RestaurantMenuComponent } from './restaurant-menu/restaurant-menu.component'
import { MarkdownModule } from 'ngx-markdown'
import { AuthGuard } from 'app/shared/guards/auth.guard'

const appRoutes: Routes = [
  { path: 'meals', component: FlowMealsComponent, canActivate: [FlowGuard] },
  { path: 'amendment/booking/:reservationCode/meals', component: AmendmentMealsComponent, canActivate: [AuthGuard] },
]

@NgModule({
  exports: [MealsSelectorComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes),
    SharedModule,
    UIModule,
    MarkdownModule,
    FormsModule,
  ],
  bootstrap: [],
  declarations: [MealsSelectorComponent, AmendmentMealsComponent, FlowMealsComponent, RestaurantMenuComponent],
  providers: [SalesChannelService, MealsService, MealsTrackingService],
})
export class MealsModule {}
