<sbw-layout [backButtonVisible]="true" [quoteModel]="quoteData" [quoteShowTotalPrice]="true"
    [quoteShowDepartureTime]="true" quoteStep="Meals" (continueEvent)="continue()" (backEvent)="navigateBack()">
    <div class="sbw_pagebox">
        <div class="row uncollapse meals-container">
            <sbw-popup [header]="popup?.textKey | resource | heading" [showPopup]="popup.show" [closable]="false">
                <span [innerHTML]="popup.textKey | resource | withoutHeading"></span>
                <div class="row no-column-margin">
                    <div class="column small-12 medium-6 field submit">
                        <sbw-button type="preferred" onClickState="activated" name="meals-stay-button"
                            [text]="popup.stayKey | resource" (clicked)="continueOrderMeals()"></sbw-button>
                    </div>
                    <div class="column small-12 medium-6 field submit">
                        <sbw-button type="default" name="continue-button" [text]="popup.continueKey | resource"
                            (clicked)="closePopupAndPost()"></sbw-button>
                    </div>
                </div>
            </sbw-popup>
            <form action="/meals" id="sbw_meals_form" method="post" novalidate="novalidate">
                <div class="row">
                    <div class="column small-12">
                        <div class="sbw_legend" [innerHTML]="'MEALS_TEASER' | resource"></div>
                    </div>
                </div>

                <div class="row">
                    <div class="column small-12 gutter no-padding-mobile">
                        <sbw-validation-summary [errorList]="errorList"></sbw-validation-summary>
                        <div class="row no-margin-mobile">
                            <div *ngIf="outboundRestaurants">
                                <div class="column gutter leg-container no-padding-mobile">
                                    <div class="meals-header">
                                        <h2 class="padding-mobile">{{reservation.outboundRouteName}}
                                            <span>{{reservation.outboundDepartureDate |
                                                resourceDateformat:'MOMENT_DEPARTURE_HEADER_DATE_FORMAT'}}</span>
                                        </h2>
                                    </div>
                                    <sbw-meals-selector *ngIf="outboundRestaurants" [restaurants]="outboundRestaurants"
                                        [routeCode]="reservation.outboundRouteCode"
                                        [currencyCode]="reservation.currencyCode"
                                        (mealsChanged)="mealsChangedHandler()"></sbw-meals-selector>
                                    <div *ngIf="outboundRestaurants && outboundRestaurants.length == 0">
                                        {{mealsContent.noMealsAvailable}}</div>
                                </div>

                                <div *ngIf="reservation.returnRouteName"
                                    class="column gutter leg-container no-padding-mobile">
                                    <div class="meals-header">
                                        <h2 class="padding-mobile">{{reservation.returnRouteName}}
                                            <span>{{reservation.returnDepartureDate |
                                                resourceDateformat:'MOMENT_DEPARTURE_HEADER_DATE_FORMAT'}}</span>
                                        </h2>
                                    </div>
                                    <sbw-meals-selector *ngIf="returnRestaurants" [restaurants]="returnRestaurants"
                                        [routeCode]="reservation.outboundRouteCode"
                                        [currencyCode]="reservation.currencyCode"
                                        (mealsChanged)="mealsChangedHandler()"></sbw-meals-selector>
                                    <div *ngIf="returnRestaurants && returnRestaurants.length == 0">
                                        {{mealsContent.noMealsAvailable}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</sbw-layout>