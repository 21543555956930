import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ContentfulSeabookWebService } from 'app/shared/resources/contentful-seabookweb.service';

@Component({
    selector: 'sbw-cabin-group',
    templateUrl: './cabin-group.component.html',
    styleUrls: ['./cabin-group.component.css']
})
export class CabinGroupComponent implements OnInit, OnChanges {
    @Input() cabinGroupCode: string
    @Input() cabinGroup: any
    @Input() routeCode: string
    public carrouselOffset = 0

    constructor(private contentfulSeabookWebService: ContentfulSeabookWebService) { }

    ngOnInit() {
        if (!this.cabinGroup) {
            this.contentfulSeabookWebService.loadCabinGroupsForRouteOrDefault([this.cabinGroupCode], this.routeCode).then(cabinGroup => {
                this.cabinGroup = cabinGroup
            })
        }
    }

    ngOnChanges() {
        this.carrouselOffset = this.photos().length * 100000
    }

    private photos() { return this.cabinGroup && this.cabinGroup.cabinPhotos && this.cabinGroup.cabinPhotos.filter(f => f.fields.file && f.fields.file.url) || [] }

    public showArrows() { return this.photos().length > 2 }

    public carrouselWindow(): any[] {
        let p = this.photos()
        if (p.length == 0) return []
        if (p.length == 1) return [p[this.carrouselOffset % p.length]]
        return [p[this.carrouselOffset % p.length], p[(this.carrouselOffset + 1) % p.length]]
    }

    public next() { this.carrouselOffset++ }

    public prev() { this.carrouselOffset-- }

}
