import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { AddressService, IAddressSearchResult, IConfirmAddressResult } from 'app/passengers/find-address/address.service'
import { FormGroup, FormControl } from '@angular/forms'

@Component({
    selector: 'sbw-find-address',
    templateUrl: './find-address.component.html',
    styleUrls: ['./find-address.component.css']
})
export class FindAddressComponent implements OnInit {
    findAddressForm: FormGroup
    @Input() model: IInputFindAddressModel
    @Input() showAddresses: boolean
    @Output() addressSelected: EventEmitter<IConfirmAddressResult> = new EventEmitter<IConfirmAddressResult>()
    @Output() backButtonClicked = new EventEmitter()

    addressSearchResult: IAddressSearchResult[]

    constructor(private addressService: AddressService) { }

    ngOnInit() {
        this.findAddressForm = new FormGroup({
            'address': new FormControl(this.model.addresses[0].moniker)
        })
    }

    continue() {
        this.addressService.confirmAddress(this.findAddressForm.value.address).subscribe(response => {
            this.addressSelected.emit(response)
        })
    }

    close() {
        this.backButtonClicked.emit()
    }
}

export interface IInputFindAddressModel {
    addresses: IAddressSearchResult[]
}
