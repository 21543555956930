export class LocaleSettings {
  constructor(
      public firstDayOfWeek: number,
      public monthNames: string[],
      public monthNamesShort: string[],
      public dayNames: string[],
      public dayNamesShort: string[],
      public dayNamesMin: string[],
      public dateFormat: string,
      public placeholderFormat: string) { }
}

export class LocaleData {

  constructor(private locale: string) { }

  public get settings(): LocaleSettings {
      const s = <LocaleSettings>_settings[this.locale.toLocaleLowerCase()]
      if (window.location.pathname.includes('/passengers') || window.location.pathname.includes('/agent/bookings')) s.dateFormat = 'dd-mm-yy'
      else s.dateFormat = 'D dd/mm'
      if (!s) { return <LocaleSettings>_settings['en'] }
      return s
  }
}

const _settings = {
  da: new LocaleSettings(
      1,
      ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'],
      ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
      ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
      ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
      ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
      'D dd/mm', 'dd-mm-yyyy'),
  nl: new LocaleSettings(
      1,
      ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
      ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
      ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
      ['zon', 'maa', 'din', 'woe', 'don', 'vri', 'zat'],
      ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
      'D dd/mm', 'dd-mm-yyyy'),
  fr: new LocaleSettings(
      0,
      ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
      ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
      'D dd/mm', 'dd/mm/yyyy'),
  de: new LocaleSettings(
      1,
      ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      'D dd/mm', 'dd.mm.yyyy'),
  sv: new LocaleSettings(
      1,
      ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
      ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
      ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
      ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
      ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'],
      'D dd/mm', 'yyyy-mm-dd'),
  lt: new LocaleSettings(
      1,
      ['Sausis', 'Vasaris', 'Kovas', 'Balandis', 'Gegužė', 'Birželis', 'Liepa', 'Rugpjūtis', 'Rugsėjis', 'Spalis', 'Lapkritis', 'Gruodis'],
      ['Sau', 'Vas', 'Kov', 'Bal', 'Geg', 'Bir', 'Lie', 'Rugp', 'Rugs', 'Spa', 'Lap', 'Gru'],
      ['sekmadienis', 'pirmadienis', 'antradienis', 'trečiadienis', 'ketvirtadienis', 'penktadienis', 'šeštadienis'],
      ['sek', 'pir', 'ant', 'tre', 'ket', 'pen', 'šeš'],
      ['Se', 'Pr', 'An', 'Tr', 'Ke', 'Pe', 'Še'],
      'D dd/mm', 'yyyy-mm-dd'),
  no: new LocaleSettings(
      1,
      ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'],
      ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
      ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
      ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
      ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
      'D dd/mm', 'dd.mm.yyyy'),
  ru: new LocaleSettings(
      1,
      ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
      ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
      ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
      ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      'D dd/mm', 'dd.mm.yyyy'),
  pl: new LocaleSettings(
      1,
      ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
      ['Sty', 'Lu', 'Mar', 'Kw', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Pa', 'Lis', 'Gru'],
      ['Niedziela', 'Poniedzialek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
      ['Nie', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'So'],
      ['N', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
      'D dd/mm', 'yyyy-mm-dd'),
  lv: new LocaleSettings(
      1,
      ['Janvāris', 'Februāris', 'Marts', 'Aprīlis', 'Maijs', 'Jūnijs', 'Jūlijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decembris'],
      ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jūn', 'Jūl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
      ['svētdiena', 'pirmdiena', 'otrdiena', 'trešdiena', 'ceturtdiena', 'piektdiena', 'sestdiena'],
      ['svt', 'prm', 'otr', 'tre', 'ctr', 'pkt', 'sst'],
      ['Sv', 'Pr', 'Ot', 'Tr', 'Ct', 'Pk', 'Ss'],
      'D dd/mm', 'dd.mm.yyyy'),
  et: new LocaleSettings(
      1,
      ['Jaanuar', 'Veebruar', 'Märts', 'Aprill', 'Mai', 'Juuni', 'Juuli', 'August', 'September', 'Oktoober', 'November', 'Detsember'],
      ['Jaan', 'Veebr', 'Märts', 'Apr', 'Mai', 'Juuni', 'Juuli', 'Aug', 'Sept', 'Okt', 'Nov', 'Dets'],
      ['Pühapäev', 'Esmaspäev', 'Teisipäev', 'Kolmapäev', 'Neljapäev', 'Reede', 'Laupäev'],
      ['Pühap', 'Esmasp', 'Teisip', 'Kolmap', 'Neljap', 'Reede', 'Laup'],
      ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
      'D dd/mm', 'dd.mm.yyyy'),
  en: new LocaleSettings(
      1,
      ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      'D dd/mm', 'dd/mm/yyyy'),
  ga: new LocaleSettings(
      1,
      ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      'D dd/mm', 'dd/mm/yyyy'),
  fi: new LocaleSettings(
      1,
      ["Tammikuu", "Helmikuu", "Maaliskuu", "Huhtikuu", "Toukokuu", "Kesäkuu", "Heinäkuu", "Elokuu", "Syyskuu", "Lokakuu", "Marraskuu", "Joulukuu"],
      ["Tammi", "Helmi", "Maalis", "Huhti", "Touko", "Kesä", "Heinä", "Elo", "Syys", "Loka", "Marras", "Joulu"],
      ["Sunnuntai", "Maanantai", "Tiistai", "Keskiviikko", "Torstai", "Perjantai", "Lauantai"],
      ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"],
      ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"],
      'D dd/mm', 'dd.mm.yyyy')
}
