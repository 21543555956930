// enums are exported due to: https://github.com/angular/angular-cli/issues/2034
export enum TpaType {
    Tpa = 1,
    Voucher = 2
}

export enum TpaLeg {
    Out = 1,
    Return = 2,
    Extra = 3
}
