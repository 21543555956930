import { Injectable, Pipe, PipeTransform, OnDestroy } from '@angular/core'
import { ResourceService } from './resource.service'
import { Subscription } from 'rxjs'

@Pipe({ name: 'resource', pure: false })
@Injectable()
export class ResourcePipe implements PipeTransform, OnDestroy {

    value: string
    lastKey: string
    subscription: Subscription

    constructor(private resourceService: ResourceService) {
    }

    transform(key: string, component: string, returnKeyIfNotFound: boolean = true) {
        if (key != this.lastKey) {
            this.value = null
            this.lastKey = key
            if (this.subscription) this.subscription.unsubscribe()

            this.subscription = this.resourceService.get(key, false, component)
                .subscribe(result => returnKeyIfNotFound ? this.value = result != null ? result : key : this.value = result)
        }
        return this.value;
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe()
    }
}
