import { Injectable } from '@angular/core'

import { DataLayerService } from './data-layer.service'
import { INoCapacityOnSelectedDateTrack } from './travel-tracking.interfaces';

@Injectable()
export class TravelTrackingService {

    constructor(
        private dataLayerService: DataLayerService) {
    }

    public trackNoCapacityOnSelectedDate(
    ): void  {
        let browserNotSupportedTrack = this.createNoCapacityOnSelectedDateTrack()
        this.dataLayerService.push(browserNotSupportedTrack)
    }

    private createNoCapacityOnSelectedDateTrack(): INoCapacityOnSelectedDateTrack {
        let noCapacityTrack = <INoCapacityOnSelectedDateTrack> {
            event: 'noCapacityOnSelectedDate',
        }
        return noCapacityTrack
    }
}
