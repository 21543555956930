import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ITpaGroupItem, ITpaItem } from 'app/tpa/tpa.interfaces';
import { SelectItem } from 'app/shared/select/select.models'

@Component({
    selector: 'sbw-tpa-category-group',
    templateUrl: './tpa-category-group.component.html',
    styleUrls: ['./tpa-category-group.component.css']
})
export class TpaCategoryGroupComponent implements OnInit {

    @Input() tpaGroupItem: ITpaGroupItem
    @Input() currency: string
    @Input() categoryCode: string
    @Output() groupChange = new EventEmitter();

    changed() { this.groupChange.emit() }

    public hasOptions(tpaItem: ITpaItem): boolean {
        return tpaItem.options && tpaItem.options.length !== 0
    }

    ngOnInit() {
        this.populateSelections()
    }

    public populateSelections() {
        this.tpaGroupItem.tpaItems.forEach(item => {
            this.populateCountDropDownValues(item)
        });
    }

    private populateCountDropDownValues(tpaItem: ITpaItem): void {
        tpaItem.selections = []
        if (tpaItem.minNoOrder > tpaItem.maxNoOrder) {
            throw new Error('Min > max')
        }
        for (let i = tpaItem.minNoOrder || 0; i <= tpaItem.maxNoOrder; i++) {
            tpaItem.selections.push(new SelectItem(i.toString(), i.toString()))
        }
    }
}
