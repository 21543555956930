<sbw-layout [backButtonVisible]="true" [quoteModel]="quoteData" [quoteShowTotalPrice]="true"
    [quoteShowDepartureTime]="true" [form]="cabinFaresForm" continueButtonResource="CONTINUE"
    (continueEvent)="continue()" (backEvent)="navigateBack()">
    <sbw-popup *ngIf="flowCabinFaresContentfulEntry && flowCabinFaresContentfulEntry.fields && flowCabinFaresContentfulEntry.fields.notAvailablePopupText" [showPopup]="refreshNeededModal && flowCabinFaresContentfulEntry" [header]="flowCabinFaresContentfulEntry?.fields?.notAvailablePopupHeading">
        <p>{{ flowCabinFaresContentfulEntry.fields.notAvailablePopupText }}</p>
        <div class="row">
            <div class="column small-12 medium-6 medium-offset-6">
                <sbw-button type="preferred" [text]="flowCabinFaresContentfulEntry.fields.notAvailablePopupReload" (clicked)="reload()"></sbw-button>
            </div>
        </div>
    </sbw-popup>

    <div class="sbw_pagebox">
        <div class="uncollapse cabin-fares-container">
            <sbw-popup [showPopup]="popup" [header]="popup?.title" >
                <span [innerHTML]="popup?.contentText"></span>
                <div class="row">
                    <div class="column small-12 medium-6 field submit">
                        <sbw-button type="default" onClickState="activated" name="cabinfares-stay-button"
                            [text]="popup?.stayButtonText" (clicked)="cancelPopup()"></sbw-button>
                    </div>
                    <div class="column small-12 medium-6 field submit">
                        <sbw-button type="preferred" name="continue-button" [text]="popup?.continueButtonText"
                            (clicked)="closePopupAndPost()"></sbw-button>
                    </div>
                </div>
            </sbw-popup>
            <div class="header" *ngIf="flowCabinFaresContent">
                <div class="row">
                    <div class="column medium-3 xlarge-2 hide-for-small-down">
                        <img src="/_ng/booking/assets/cabin-seaview.svg">
                    </div>
                    <div class="column medium-9 xlarge-10">
                        <h1>{{flowCabinFaresContent.headline}}</h1>
                    </div>
                    <div class="column medium-9 xlarge-10">
                        <p *ngIf="flowCabinFaresContent?.legend" [innerHTML]="flowCabinFaresContent?.legend | markdown">
                        </p>
                    </div>
                </div>
            </div>
            <sbw-validation-summary [errorList]="errorList"></sbw-validation-summary>
            <sbw-cabin-fares-group *ngIf="cabinFaresModel?.outbound"
                [form]="outboundFormGroup" [model]="cabinFaresModel.outbound" [reservation]="reservation"
                [content]="flowCabinFaresContent" [datePickerInfoLabel]="datePickerInfoLabel"></sbw-cabin-fares-group>
            <sbw-cabin-fares-group *ngIf="cabinFaresModel?.return"
                [form]="returnFormGroup" [model]="cabinFaresModel.return" [reservation]="reservation"
                [content]="flowCabinFaresContent" [datePickerInfoLabel]="datePickerInfoLabel"></sbw-cabin-fares-group>
        </div>
    </div>

</sbw-layout>
