import { Component, OnInit } from '@angular/core';
import { LayoutState } from 'app/shared/layout/layout-state';

@Component({
    selector: 'test-error',
    template: `
    <sbw-button class="unhandled" type="preferred" (click)="unhandled()" text="Unhandled"></sbw-button>
    &nbsp;
    <sbw-button class="throw" type="preferred" (click)="throw()" text="Throw"></sbw-button>`
})
export class TestErrorComponent implements OnInit {

    constructor(private layout: LayoutState) { }

    ngOnInit() {
        this.layout.setIsContentLoaded(true, 1)
    }

    unhandled() {
        const a: string = null
        a.match(/something/)
    }

    throw() {
        throw new Error('error')
    }

}
