<sbw-layout
    [backButtonVisible]="true"
    [type]="'Amendment'"
    [continueButtonDisabled]="!this.tpasChanged"
    (continueEvent)="continue()"
    (backEvent)="navigateBack()"
    >
    <div class="sbw_pagebox">
        <div class="row uncollapse tpa-container">
            <h1 [innerHTML]="'TPA_AMENDMENT_TITLE' | resource"></h1>
            <div class="sbw_legend" [innerHTML]="'TPA_AMENDMENT_TEASER' | resource"></div>
            <form *ngIf="true" action="/tpas" id="sbw_tpas_form" method="post">
                <div class="row">
                    <div class="column small-12 gutter">
                        <sbw-validation-summary [errorList]="errors"></sbw-validation-summary>
                        <div class="row">
                            <div *ngIf="tpaCategories">
                                <sbw-tpa-selector [tpaCategories]="tpaCategories" [currency]="currencyCode" (selectorChange)="tpasChangedHandler()"></sbw-tpa-selector>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <sbw-tpa-popup [tpaCategories]="tpaCategories" [selections]="selections"></sbw-tpa-popup>
</sbw-layout>
