import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { LocalDateTime, ZonedDateTime, LocalDate, LocalTime } from 'js-joda'

// https://github.com/angular/angular/blob/master/packages/common/http/src/xhr.ts#L18
const XSSI_PREFIX = /^\)\]\}',?\n/

/**
 * Provide custom json parsing capabilities for api requests.
 * @export
 * @class JsonInterceptor
 */
@Injectable()
export class JsonInterceptor implements HttpInterceptor {

    /**
     * Custom http request interceptor
     * @public
     * @param {HttpRequest<any>} req
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     * @memberof JsonInterceptor
     */
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!req.url.includes('fares-flow') &&
            !req.url.includes('fares-amendment') &&
            !req.url.includes('travel-flow') &&
            !req.url.includes('/reservations') &&
            !req.url.includes('/reservation/') &&
            !req.url.includes('meals-flow') &&
            !req.url.includes('meals-amendment')) {
            return next.handle(req);
        }

        if (req.responseType !== 'json') {
            return next.handle(req)
        }
        // convert to responseType of text to skip angular parsing
        req = req.clone({
            responseType: 'text'
        })

        return next.handle(req).pipe(map(event => {
            // Pass through everything except for the final response.
            if (!(event instanceof HttpResponse)) {
                return event
            }
            return this.processJsonResponse(event)
        }))
    }

    /**
     * Parse the json body using custom revivers.
     * @private
     * @param {HttpResponse<string>} res
     * @returns{HttpResponse<any>}
     * @memberof JsonInterceptor
     */
    private processJsonResponse(res: HttpResponse<string>): HttpResponse<any> {
        let body = res.body
        if (typeof body === 'string') {
            const originalBody = body
            body = body.replace(XSSI_PREFIX, '')
            try {
                body = body !== '' ? JSON.parse(body, (key: any, value: any) => this.reviveUtcDate(key, value)) : null
            } catch (error) {
                // match https://github.com/angular/angular/blob/master/packages/common/http/src/xhr.ts#L221
                throw new HttpErrorResponse({
                    error: { error, text: originalBody },
                    headers: res.headers,
                    status: res.status,
                    statusText: res.statusText,
                    url: res.url || undefined,
                })
            }
        }
        return res.clone({ body })
    }

    /**
     * Detect a date string and convert it to a date object.
     * @private
     * @param {*} key json property key.
     * @param {*} value json property value.
     * @returns {*} original value or the parsed date.
     * @memberof JsonInterceptor
     */
    private reviveUtcDate(key: any, value: any): any {
        if (typeof value !== 'string') {
            return value
        }

        if (value === '0001-01-01T00:00:00') {
            return null
        }

        const matchDateTimeWithTimeZone = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)([+\-])(\d{2}):(\d{2})$/.exec(value)
        if (matchDateTimeWithTimeZone) {
            // when the time includes offset "0001-01-01T00:00:00+01:00", convert it to local time by ignoring timezone information from server
            return ZonedDateTime.parse(value).toLocalDateTime()
        }

        const matchDateTimeWithoutTimeZone = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/.exec(value)
        if (matchDateTimeWithoutTimeZone) {
            // when the time zone is not included, i.e. in the format "0001-01-01T00:00:00", treat it as local time
            return LocalDateTime.parse(value)
        }

        const matchTime = /^(?:[01]?\d|2[0-3]):[0-5]\d:[0-5]\d$/.exec(value)
        if (matchTime) {
            return LocalTime.parse(value)
        }

        const matchDate = /^(\d{4})-(\d{2})-(\d{2})$/.exec(value)
        if (matchDate) {
            // when it is just a date parse it to a localdate
            return LocalDate.parse(value)
        }

        // it is not a date or datetime so we just return it
        return value
    }
}
