<div id="AgentDetailsContainer" class="row">
    <form [formGroup]="form">
        <div class="column small-12 half-gutter">
            <div class="row">
                <div class="column small-12">
                    {{inputModel.agentContact.company}}
                    <br>{{inputModel.agentContact.addressLine1}}
                    <br>{{inputModel.agentContact.addressLine2}}
                    <br>{{inputModel.agentContact.city}}
                    <br>{{inputModel.agentContact.postalCode}}
                    <br>{{countryDescription}}
                    <br>{{inputModel.agentContact.phonePrefix}} {{inputModel.agentContact.phoneNumber}}
                </div>
            </div>
        </div>
        <div class="column small-12 xlarge-6 half-gutter">
            <div class="row">
                <div class="column small-12 label">
                    <label>{{'AGENT_CONTACT' | resource:'Agent'}}</label>
                </div>
                <div class="column small-12">
                    <input formControlName="agentReference" type="text">
                    <br>
                    <span class="field-validation-valid"></span>
                </div>
            </div>
        </div>

        <div class="column small-12">
            <div class="row">
                <div class="column small-12 label">
                    <label>{{'AGENT_CUSTOMER_PHONE_NUMBER' | resource:'Agent'}}</label>
                </div>
                <div class="column small-12 xlarge-6 end">
                    <div class="row collapse inline-right">
                        <div class="column small-5 medium-3">
                            <select formControlName="contactPhonePrefix">
                                <option *ngFor="let phonePrefix of inputModel.phonePrefixes" [value]="phonePrefix">{{phonePrefix}}</option>
                            </select>
                        </div>
                        <div class="column small-7 medium-9 field">
                            <input formControlName="contactPhoneNumber" type="text">
                            <br>
                            <span class="field-validation-valid"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
