import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { LayoutState } from 'app/shared/layout/layout-state'
import { ResourceService } from 'app/shared/resources/resource.service'
import { SbEvent } from 'app/shared/sb-event.emitter'
import { zip } from 'rxjs'
import { AgentRegisterService } from './agent-register.service'

@Component({
  selector: 'sbw-register',
  templateUrl: './agent-register.component.html',
  styleUrls: ['./agent-register.component.css'],
})
export class AgentRegisterComponent implements OnInit {
  constructor(
    private layoutState: LayoutState,
    private resourceService: ResourceService,
    private events: SbEvent,
    private title: Title,
    private registerService: AgentRegisterService,
    private route: ActivatedRoute
  ) {}
  waiting = false
  error = ''
  abtaError = ''
  intro = ''
  group = new FormGroup(
    {
      salesOwner: new FormControl('', Validators.required),
      abta: new FormControl('', Validators.required),
      password: new FormControl('', [Validators.required, Validators.pattern(/^.{6,15}$/)]),
      password2: new FormControl(''),
    },
    repeatPasswordValidator
  )

  salesOwners: { id; name }[] = []

  async ngOnInit() {
    const { salesowner, abta } = this.route.snapshot.queryParams
    this.group.patchValue({ salesOwner: salesowner, abta })

    this.resourceService.loadResourcesPromise('Default', null, ['Agent', 'Account']).then(async () => {
      this.resourceService.get('REGISTER_NEW_AGENT_ACCOUNT').subscribe((s) => this.title.setTitle(s))
      const [s] = await zip(this.registerService.getSalesOwners()).toPromise()
      this.salesOwners = s
      this.layoutState.setIsContentLoaded(true)
    })
  }

  async register() {
    this.waiting = true
    this.abtaError = this.error = this.intro = null
    const { abtaError, error, intro } = await this.registerService.register(this.group.value as { salesOwner: string; abta: string; password: string; password2: string; }).toPromise()
    this.waiting = false
    this.abtaError = abtaError
    this.error = error
    this.intro = intro
  }
}

const repeatPasswordValidator = (group: FormGroup) =>
  group.controls.password.value === group.controls.password2.value ? null : { passwordsNotEqual: true }
