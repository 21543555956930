import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { LayoutState } from '../shared/layout/layout-state'
import { ResourceService } from '../shared/resources/resource.service'
import { TrackingService } from '../shared/tracking/tracking.service'

@Component({
  selector: 'sbw-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css'],
})
export class ErrorPageComponent implements OnInit {
  public correlationId: string
  public backButtonVisible = true

  constructor(
    private layoutState: LayoutState,
    private resourceService: ResourceService,
    private route: ActivatedRoute,
    private tracking: TrackingService
  ) {}

  ngOnInit() {
    const locale = this.resourceService.getLocale()
    this.resourceService.loadResourcesPromise('Default', locale || 'en', ['Common']).then(() => {
      this.correlationId = this.route.snapshot.queryParams['correlation']
      this.tracking.trackError()
      this.layoutState.setIsContinueLoading(false)
      this.layoutState.setIsContentLoaded(true)
    })
  }
  public goBack() {
    window.history.back()
  }
}
