import { LocalDate } from "js-joda";
import { IInputCabinFaresGroup, ICabinFaresGroupDeparture } from "./cabin-fares-group/cabin-fares-group.component";
import { entries, flatMap, range } from "app/shared/utils";
import { LoggingService } from 'app/shared/logging.service';

export enum Direction {
    out,
    return
}

export interface IDepartureChangeDateForDirection {
    direction: Direction
    date: LocalDate
}

export interface ICabinFares {
    outbound: IInputCabinFaresGroup
    return: IInputCabinFaresGroup

    flowCabinFaresContent?: any;
    flowCabinFaresContentfulEntry?: any;
}

export class CabinFaresModelCache {
    cabinFaresModel: ICabinFares
    outboundDepartureDate: LocalDate
    returnDepartureDate: LocalDate
}

export interface ILegDates {
    min: LocalDate,
    max: LocalDate,
    disabled: LocalDate[]
    offer: LocalDate[]
}

/* Selected departure represented as departureId and productCode */
export interface ISelectedDeparture {
    departureId: string
    productCode: string
}

export type IDepartureCabinSelections = { [departureId: string]: ICabinSelections }

export type ICabinSelections = { [cabinCode: string]: number }

export interface ILegSelections {
    out: ILegSelection
    return?: ILegSelection
}

export interface ILegSelection {
    cabins: ICabinSelections
    departure: string
}

export interface IDepartureCabins {
    cabins: IDepartureCabinSelections
}

export interface ILegDepartureSelections {
    out: IDepartureCabins
    return?: IDepartureCabins
}

export interface IFlatSelections {
    availableCapacity: number
    numberOfOccupiableBerths: number
    selected: number
    selectedCapacity: number
    departure: string
}

export function findSelectedDeparture(leg: IDepartureCabins): ILegSelection {
    if (!leg) return
    if (!leg.cabins) return { cabins: {}, departure: undefined }
    let ent = entries(leg.cabins)
    let departuresWithSelections = ent.filter(dep => entries(dep.value).some(cab => cab.value > 0))
    if (departuresWithSelections.length > 1) throw new Error('cabins selected on multiple departures')
    let selected = departuresWithSelections[0] || ent[0]
    return selected && {
        cabins: selected.value,
        departure: selected.key,
    }
}

export function getSelectedDeparture(legs: ILegDepartureSelections, model: ICabinFares): ILegSelections {
    let out = findSelectedDeparture(legs.out)
    let ret = findSelectedDeparture(legs.return)
    if (model && model.outbound.isMiniCruise) {
        if (!ret || !out) return null
        ret.departure = getMiniCruiseReturnDep(out, model)
    }
    return legs && {
        out: out,
        return: ret
    }
}

export function getMiniCruiseReturnDep(out: ILegSelection, model: ICabinFares, loggingService?: LoggingService) {
    if (!out) return null
    let outDep = model.outbound.departures.find(o => o.departureId == out.departure)
    if (!outDep && loggingService) loggingService.logWarn({ params: [out.departure], message: "Selected {departure} no longer available in outbound departures" })
    if (!outDep || !model.return) return null
    let retDep = model.return.departures.find(r => r.ferryCode == outDep.ferryCode)
    return retDep.departureId
}

export function flatSelections(selections: IDepartureCabinSelections, model: ICabinFaresGroupDeparture[]): IFlatSelections[] {
    return flatMap(model, d => flatMap(d.cabinGroups, group => group.inputCabinFaresSelectors.map(cabin => ({
        availableCapacity: cabin.availableCapacity,
        numberOfOccupiableBerths: cabin.numberOfOccupiableBerths,
        selected: selections[d.departureId][cabin.cabinTypeCode],
        selectedCapacity: selections[d.departureId][cabin.cabinTypeCode] * cabin.numberOfOccupiableBerths,
        departure: d.departureId
    }))));
}

export function expandedSelections(flat: IFlatSelections[]): number[] {
    return flatMap(flat, f => range(f.selected).map(_ => f.numberOfOccupiableBerths))
}
