import { MarketService } from './market.service'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ResourcePipe } from './resources/resource.pipe'
import { ResourceFormatPipe } from './resources/resourceformat.pipe'
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component'
import { DisclaimerComponent } from './disclaimer/disclaimer.component'
import { QuoteComponent } from './quote/quote.component'
import { HybridService } from './hybrid/hybrid.service'
import { QuoteService } from './quote/quote.service'
import { QuotedetailComponent } from './quote/quotedetail/quotedetail.component'
import { ResourceService } from './resources/resource.service'
import { LocaleService } from './locale-service'
import { CookieService } from './cookie.service'
import { AmendService } from './reservation/amend.service'
import { BreadcrumbService } from './breadcrumb/breadcrumb.service'
import { ResourceDateformatPipe } from './resources/resourcedateformat.pipe'
import { ResourceDateformatNewPipe } from './resources/resourcedateformatnew.pipe'
import { JodaTimeFormatterPipe, JodaDateFormatterPipe, JodaDateTimeFormatterPipe } from './resources/jodadateformat.pipe'
import { ReservationService } from './reservation/reservation.service'
import { StorageService } from './storage.service'
import { SnapshotService } from './snapshot.service'
import { FormsModule } from '@angular/forms'
import { SalesChannelService } from './salesChannel/sales-channel.service'
import { LayoutComponent } from 'app/shared/layout/layout.component'
import { UIModule } from 'app/shared/UI.module'
import { LayoutState } from 'app/shared/layout/layout-state'
import { FooterComponent } from '../footer'
import { AgentMenuComponent } from '../agent/agent-menu/agent-menu.component'
import { AuthGuard } from 'app/shared/guards/auth.guard'
import { LoggingService } from 'app/shared/logging.service'
import { FlowGuard } from 'app/shared/guards/flow.guard'
import { ContentfulSeabookWebService } from './resources/contentful-seabookweb.service'
import { ContentfulDfdsService } from './resources/contentful-dfds.service'
import { ContentfulGenericPageService } from '../contentful/contentful-generic-page/contentful-generic-page.service'
import { TrackingService } from './tracking/tracking.service'
import { EcommerceFlowTrackingService } from './tracking/ecommerce-tracking/ecommerce-flow-tracking.service'
import { EcommerceAmendmentTrackingService } from './tracking/ecommerce-tracking/ecommerce-amendment-tracking.service'
import { VirtualPageViewTrackingService } from './tracking/virtual-page-view-tracking/virtual-page-view-tracking.service'
import { DataLayerService } from './tracking/data-layer.service'
import { PrevNextComponent } from './prev-next/prev-next.component'
import { WebSessionCounterService } from './tracking/web-session-counter.service'
import { BrowserNotSupportedTrackingService } from './tracking/browser-not-supported-tracking.service'
import { MenuComponent } from 'app/menu/menu.component'
import { HeadingPipe, WithoutHeadingPipe } from './splitter.pipe'
import { ChatComponent } from './chat/chat.component'
import { EcommerceTrackingMealsPreparerService } from './tracking/ecommerce-tracking/ecommerce-amendment/meals-preparer.service'
import { StatelessHttpService } from './stateless-http.service'
import { StatefulHttpService } from './stateful-http.service'
import { AgentAuthGuard } from './guards/agent-auth.guard'
import { UserDetailsService } from './user-details.service'
import { TopBoxComponent } from './top-box/top-box.component'
import { SpinnerComponent } from './spinner/spinner.component'

@NgModule({
  imports: [CommonModule, RouterModule, BrowserAnimationsModule, FormsModule, UIModule],
  declarations: [
    ResourcePipe,
    ResourceFormatPipe,
    ResourceDateformatPipe,
    ResourceDateformatNewPipe,
    HeadingPipe,
    WithoutHeadingPipe,
    BreadcrumbComponent,
    DisclaimerComponent,
    QuoteComponent,
    QuotedetailComponent,
    LayoutComponent,
    SpinnerComponent,
    FooterComponent,
    ChatComponent,
    MenuComponent,
    AgentMenuComponent,
    PrevNextComponent,
    TopBoxComponent,
    JodaTimeFormatterPipe,
    JodaDateFormatterPipe,
    JodaDateTimeFormatterPipe,
  ],
  exports: [
    ResourcePipe,
    ResourceFormatPipe,
    ResourceDateformatPipe,
    ResourceDateformatNewPipe,
    HeadingPipe,
    WithoutHeadingPipe,
    BreadcrumbComponent,
    DisclaimerComponent,
    QuoteComponent,
    QuotedetailComponent,
    LayoutComponent,
    SpinnerComponent,
    FooterComponent,
    ChatComponent,
    MenuComponent,
    AgentMenuComponent,
    PrevNextComponent,
    TopBoxComponent,
    JodaTimeFormatterPipe,
    JodaDateFormatterPipe,
    JodaDateTimeFormatterPipe,
  ],
  providers: [
    QuoteService,
    ResourceService,
    ContentfulDfdsService,
    ContentfulSeabookWebService,
    ContentfulGenericPageService,
    HybridService,
    LocaleService,
    MarketService,
    WebSessionCounterService,
    CookieService,
    BreadcrumbService,
    ReservationService,
    StorageService,
    AmendService,
    SalesChannelService,
    SnapshotService,
    LoggingService,
    AuthGuard,
    FlowGuard,
    AgentAuthGuard,
    LayoutState,
    TrackingService,
    EcommerceFlowTrackingService,
    EcommerceAmendmentTrackingService,
    EcommerceTrackingMealsPreparerService,
    BrowserNotSupportedTrackingService,
    VirtualPageViewTrackingService,
    DataLayerService,
    StatelessHttpService,
    StatefulHttpService,
    UserDetailsService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
