<sbw-layout [backButtonVisible]="false" [continueButtonVisible]="false" [type]="'Amendment'">
    <div class="sbw_pagebox">
        <div *ngIf="reservation && inputCreditCard">
            <form [formGroup]="remainingForm">
                <div *ngIf="!reservation" class="is-loading active"></div>

                <div *ngIf="reservation">
                    <div class="row">
                        <div class="column small-12">
                            <h1>{{ 'OUTSTANDING_PAYMENT_BOOKING_DETAILS' | resource }}</h1>
                        </div>
                    </div>

                    <div class="row">
                        <div class="column small-12 large-6 gutter booking-details">
                            <div class="row">
                                <div class="column small-12 medium-6 label">
                                    {{ 'OUTSTANDING_PAYMENT_BOOKING_NUMBER' | resource }}</div>
                                <div id="bookingNumber" class="column small-12 medium-6">{{reservation.code}}</div>
                            </div>
                            <div class="row">
                                <div class="column small-12 medium-6 label">{{ 'PASSENGERS_FULLNAME' | resource }}
                                </div>
                                <div class="column small-12 medium-6">{{reservation.customerName}}</div>
                            </div>
                            <div class="row">
                                <div class="column small-12 medium-6 label">
                                    {{ 'OUTSTANDING_PAYMENT_OUTBOUND_ROUTE' | resource }}</div>
                                <div class="column small-12 medium-6">{{reservation.outboundLeg.description}}</div>
                            </div>
                            <div class="row">
                                <div class="column small-12 medium-6 label">
                                    {{ 'OUTSTANDING_PAYMENT_OUTBOUND_DATE' | resource }}</div>
                                <div class="column small-12 medium-6">
                                    {{reservation.outboundLeg.departureDate | jodaDateFormatter : dateFormat}}</div>
                            </div>
                            <div *ngIf="reservation.returnLeg">
                                <div class="row">
                                    <div class="column small-12 medium-6 label">
                                        {{ 'OUTSTANDING_PAYMENT_RETURN_ROUTE' | resource }}</div>
                                    <div class="column small-12 medium-6">{{reservation.returnLeg.description}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column small-12 medium-6 label">
                                        {{ 'OUTSTANDING_PAYMENT_RETURN_DATE' | resource }}</div>
                                    <div class="column small-12 medium-6">
                                        {{reservation.returnLeg.departureDate | jodaDateFormatter : dateFormat }}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="column small-12 medium-6 label">
                                    {{ 'OUTSTANDING_PAYMENT_AMOUNT' | resource }}</div>
                                <div class="column small-12 medium-6">{{reservation.currencyCode | resource}}
                                    <span
                                        id="paymentAmount">{{reservation.remainingAmount | resourceformat:'CURRENCY_FORMAT' }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="reservation.remainingAmount > 0" class="gutter">
                        {{ 'OUTSTANDING_PAYMENT_LEGEND' | resource }}</div>
                    <div id="paymentLegend" class="gutter" *ngIf="reservation.remainingAmount <= 0">
                        {{ 'OUTSTANDING_PAYMENT_PAID_LEGEND' | resource }}</div>

                    <div *ngIf="reservation.remainingAmount > 0">
                        <sbw-credit-cards [model]="inputCreditCard"></sbw-credit-cards>
                        <div class="gutter"></div>
                        <div class="row">
                            <div class="column small-12">
                                <div class="row">
                                    <div class="column small-12 medium-6 xlarge-4 gutter">
                                        <sbw-button [id]="'payment'" [name]="'payment'"
                                            [state]="remainingForm.valid ? 'activated' : 'disabled'"
                                            (clicked)="postForm()" [type]="'preferred'"
                                            [text]="'OUTSTANDING_PAYMENT_BUTTON_PAY' | resource"></sbw-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <sbw-disclaimer *ngIf="reservation.remainingAmount > 0"
                        [resourceKey]="'OUTSTANDING_DISCLAIMER_FOOTER'"></sbw-disclaimer>
                </div>
            </form>
        </div>
    </div>
</sbw-layout>
