import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ResourceService } from '../../shared/resources/resource.service'
import { QueryModel } from '../login/agent-login.models'
import { CookieService } from '../../shared/cookie.service'
import { SbEvent } from '../../shared/sb-event.emitter'
import { LayoutState } from 'app/shared/layout/layout-state'
import { FormControl } from '@angular/forms'
import { AgentProfileService, AgentProfileWire } from './agent-profile.service'
import { ButtonStateType } from 'app/shared/button/button.component'

@Component({
  selector: 'agent-profile',
  templateUrl: './agent-profile.component.html',
  styleUrls: ['./agent-profile.component.css'],
})
export class AgentProfileComponent implements OnInit {
  private queryModel: QueryModel
  public preferedRoute: FormControl
  public isAgent: boolean = true
  public agentWire: AgentProfileWire
  public resourcesLoaded: boolean = false
  submitButtonState: ButtonStateType = 'activated'

  constructor(
    private resourceService: ResourceService,
    private route: ActivatedRoute,
    private layoutState: LayoutState,
    private cookieService: CookieService,
    private events: SbEvent,
    private agentService: AgentProfileService
  ) {}

  ngOnInit() {
    this.agentService.getAgentDetails(this.isAgent).subscribe((data) => {
      this.agentWire = data
      this.preferedRoute = new FormControl(this.agentWire.agentDetails.preferredRouteCode || this.agentWire.outboundRoutes[0].code)
      this.queryModel = this.getQueryModel()
      this.resourceService
        .loadResourcesPromise('Default', this.queryModel.locale, ['Agent', 'MenuAgent', 'AgentSearch', 'AgentProduct', 'AgentHome'])
        .then(() => {
          this.resourcesLoaded = true
          this.layoutState.setIsContentLoaded(true)
        })
    })
  }

  private getQueryModel(): QueryModel {
    let queryParams = this.route.snapshot.queryParams
    let salesOwner = this.getSalesOwner(queryParams)
    let locale = this.getLocale(queryParams)
    return new QueryModel(salesOwner, locale)
  }

  private getSalesOwner(queryParams): string {
    let salesOwner = queryParams['salesowner']

    if (typeof salesOwner === 'undefined') {
      salesOwner = this.cookieService.getCookie('sbw_SalesOwner')
    }
    return salesOwner
  }

  private getLocale(queryParams): string {
    let locale = queryParams['locale']

    if (typeof locale === 'undefined') {
      locale = this.cookieService.getCookie('sbw_Locale')
    }
    return locale
  }

  savePreferedRoute(): void {
    this.submitButtonState = 'spinner'
    this.agentService.savePreferedRoute(this.preferedRoute.value).subscribe((res) => {
      this.submitButtonState = 'activated'
    })
  }
}
