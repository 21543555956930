export class QueryModel {
    constructor(
        public salesOwner: string,
        public locale: string
    ) { }
}

export class LoginResult {
    constructor(public bearerToken: string, public agentCode: string) { }
}

export class LoginError {
    get sbwError(): number { return parseInt(this.error) }
    constructor(public error: string) { }
}

export interface ILoginClassicWireModel {
    username: string,
    password: string,
    reference: string,
    stayLoggedIn: boolean,
    salesOwnerId: string
}

export interface IIdentificationResult{
    travelAgentCode: string
}
