import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { CookieService } from 'app/shared/cookie.service'
import { HybridService } from 'app/shared/hybrid/hybrid.service'

@Injectable()
export class AgentAuthGuard implements CanActivate {
  constructor(
    private cookieService: CookieService,
    private hybridService: HybridService
  ) {}

  canActivate(_: ActivatedRouteSnapshot): boolean {
    if (!this.cookieService.getAuthType() || !sessionStorage.getItem('pax_Token')) {
      this.cookieService.deleteCookie(this.cookieService.sbwAuthType)
      localStorage.removeItem('sbw_AgentGenericId')
      this.hybridService.agentLogin()
      return false
    }
    return true
  }
}
