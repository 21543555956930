import { Component, NgZone, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { Subject, zip } from 'rxjs'

import { MealWire, IMealsModel } from '../meals.interfaces'
import { IRestaurantModel } from '../meals-selector-models'
import { MealsService } from '../meals.service'

import { MealsSelectorView } from '../meals-selector-view'
import { IReservation } from 'app/shared/models/reservation.interfaces'
import { ResourceService } from 'app/shared/resources/resource.service'
import { ReservationService } from 'app/shared/reservation/reservation.service'
import { SnapshotService, ISnapshotMeals } from 'app/shared/snapshot.service'
import { AmendService } from 'app/shared/reservation/amend.service'

import { hashObject } from 'app/shared/utils'
import { LayoutState } from 'app/shared/layout/layout-state'
import { convert as JsJodaConvert } from 'js-joda'
import { TrackingService } from 'app/shared/tracking/tracking.service'
import { TrackingAmendmentStep } from 'app/shared/tracking/tracking-wire.interfaces'
import { AuthService } from 'app/auth/auth.service'
import { LocaleService } from 'app/shared/locale-service'
import { CookieService } from 'app/shared/cookie.service'
import { ContentfulSeabookWebService } from 'app/shared/resources/contentful-seabookweb.service'

@Component({
  selector: 'sbw-amendment-meals',
  templateUrl: './amendment-meals.component.html',
  styleUrls: ['./amendment-meals.component.css'],
})
export class AmendmentMealsComponent implements OnInit {
  public showMeals: boolean
  public outboundDepartureDate: Date
  public returnDepartureDate: Date
  public outboundRoute: string
  public returnRoute: string
  public currencyCode: string
  private numberOfChildren: number
  public reservation: IReservation
  private onChildAges = new Subject<number[]>()
  private childrenAges: number[]
  public errors: string[] = []
  public mealsChanged: boolean
  public outboundRestaurants: IRestaurantModel[]
  public returnRestaurants: IRestaurantModel[]
  private mealsSelectorViewOutbound: MealsSelectorView
  private mealsSelectorViewReturn: MealsSelectorView
  private initialMd5
  public reservationCode: string
  public isAgent: boolean

  private mealsModel: IMealsModel

  constructor(
    private titleService: Title,
    private resourceService: ResourceService,
    private reservationService: ReservationService,
    private snapshotService: SnapshotService,
    private mealsService: MealsService,
    private route: ActivatedRoute,
    private router: Router,
    private amendService: AmendService,
    private trackingService: TrackingService,
    private layoutState: LayoutState,
    private authService: AuthService,
    private zone: NgZone,
    private localeService: LocaleService,
    private cookieService: CookieService,
    private contentful: ContentfulSeabookWebService
  ) {}

  ngOnInit() {
    this.isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
    let authType = this.cookieService.getAuthType()

    this.reservationCode = this.route.snapshot.params['reservationCode']

    if ((authType === 'agent' && this.isAgent) || this.authService.isLoggedIn()) {
      this.getData()
    } else {
      this.layoutState.setIsContentLoaded(true)
      // Do something with azure ad
    }
  }

  private enrichReservation() {
    let snapshot = this.snapshotService.getSnapshot(this.reservation.reservationCode)
    if (snapshot && snapshot.meals) {
      this.reservation.departures[0].rooms = snapshot.meals.get.outbound
      if (this.reservation.departures.length > 1) {
        this.reservation.departures[1].rooms = snapshot.meals.get.return
      }
    }

    this.outboundDepartureDate = JsJodaConvert(this.reservation.departures[0].departureDateTime).toDate()
    this.returnDepartureDate =
      this.reservation.departures.length === 2 ? JsJodaConvert(this.reservation.departures[1].departureDateTime).toDate() : null
    this.outboundRoute = this.reservation.departures[0].routeName
    this.returnRoute = this.reservation.departures.length === 2 ? this.reservation.departures[1].routeName : null

    this.currencyCode = this.reservation.currencyCode
    this.numberOfChildren = this.reservation.passengers.filter((p) => p.customerCategory === 'CHILD').length
    const restaurants$ = this.contentful.loadMealsRestaurantDetailsForRestaurantCodes(
      MealsSelectorView.restaurantCodes(this.mealsModel),
      this.reservation.departures[0].routeCode
    )

    this.onChildAges.subscribe((childrenAges) => {
      restaurants$.then((r) => {
        this.showMeals = true
        this.mealsSelectorViewOutbound = new MealsSelectorView(
          this.mealsModel.outboundRestaurants,
          this.reservation.departures[0].rooms,
          this.reservation.passengers,
          childrenAges,
          r
        )
        this.outboundRestaurants = this.mealsSelectorViewOutbound.restaurants

        if (this.mealsModel.returnRestaurants && this.mealsModel.returnRestaurants.length && this.reservation.departures.length > 1) {
          this.mealsSelectorViewReturn = new MealsSelectorView(
            this.mealsModel.returnRestaurants,
            this.reservation.departures[1].rooms,
            this.reservation.passengers,
            childrenAges,
            r
          )
          this.returnRestaurants = this.mealsSelectorViewReturn.restaurants
        }

        this.initialMd5 = this.getMd5()
      })
    })

    this.trackingService.trackAmendment(TrackingAmendmentStep.MEALS, false, this.reservation)

    if (this.reservation.childrenAges && this.reservation.childrenAges.length > 0) {
      this.onChildAges.next(this.reservation.childrenAges)
    } else {
      if (this.numberOfChildren > 0) {
        throw new Error('meals: missing children ages')
      } else {
        this.onChildAges.next([])
      }
    }
  }

  mealsChangedHandler() {
    this.mealsChanged = this.getMd5() !== this.initialMd5
    this.errors = []
  }

  public continue() {
    this.layoutState.setIsContinueLoading(true)
    let outboundMeals: MealWire = this.mealsService.getMealsWire(
      this.reservation.departures[0].departureId,
      this.mealsSelectorViewOutbound,
      this.childrenAges
    )
    let returnMeals: MealWire =
      this.reservation.departures[1] &&
      this.mealsService.getMealsWire(this.reservation.departures[1].departureId, this.mealsSelectorViewReturn, this.childrenAges)
    const dryRun = true

    this.amendService.changeMeals(this.reservation.reservationCode, [outboundMeals, returnMeals], dryRun).subscribe((response) => {
      this.layoutState.setIsContinueLoading(false)

      if (response.errorMsgs && response.errorMsgs.length) {
        this.errors = response.errorMsgs.map((m) => m.message)
        this.layoutState.setIsContentLoaded(true)
        return
      }
      let reservation = response.reservation

      let snapshotMeals: ISnapshotMeals = {
        get: {
          childrenAges: this.childrenAges,
          outbound: reservation.departures[0].rooms,
          return: reservation.departures[1] && reservation.departures[1].rooms,
        },
        patch: [outboundMeals, returnMeals],
      }
      this.snapshotService.setSnapshot(this.reservation.reservationCode, { meals: snapshotMeals })
      this.navigateBack()
    })
  }

  navigateBack() {
    this.router.navigate(['/amendment/booking', this.reservation.reservationCode])
  }

  private getMd5() {
    return hashObject({
      o: this.mealsSelectorViewOutbound.getSelectedMealArrangementSubTypes(),
      r: this.mealsSelectorViewReturn && this.mealsSelectorViewReturn.getSelectedMealArrangementSubTypes(),
    })
  }

  private getData() {
    let reservation$ = this.reservationService.getReservation(this.reservationCode)
    let meals$ = this.mealsService.getMealsAmendment(this.reservationCode)

    zip(meals$, reservation$).subscribe(([meals, reservation]) => {
      this.resourceService
        .loadResourcesPromise(
          reservation.routeCode,
          reservation.locale,
          ['Common', 'Account', 'Amendment', 'Meals', 'Menu', 'Currency', 'Notification'].concat(this.isAgent ? ['Agent', 'MenuAgent'] : [])
        )
        .then(() => {
          this.resourceService.get('MEALS_TITLE', false).subscribe((s) => this.titleService.setTitle(s))
          this.mealsModel = meals
          this.reservation = reservation
          this.enrichReservation()
          this.layoutState.setIsContentLoaded(true)
        })
    })
  }
}
