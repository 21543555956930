import { Router, NavigationEnd, NavigationStart } from '@angular/router'
import { Component, OnInit, AfterViewInit } from '@angular/core'
import { CookieService } from 'app/shared/cookie.service'
import { SbEvent } from 'app/shared/sb-event.emitter'
import moment from 'moment'
import { filter, skip, map } from 'rxjs/operators'
import { LayoutState } from './shared/layout/layout-state'
import { Location } from '@angular/common'
import { StorageService } from './shared/storage.service'
import { ContentfulClientApiFast } from './shared/resources/contentfulClientApiFast'
import { Observable } from 'rxjs'
import { LoggingService } from './shared/logging.service'
import { ResourceService } from './shared/resources/resource.service'

declare const ga: Function
declare const sbSalesOwner: string
declare const sbLocale: string
@Component({
  selector: 'sb-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {
  isLoading: boolean = true
  isContinuing: boolean = true
  contentLoaded: boolean = false
  isIE: boolean = false
  logoHref: string = '..'
  content: Observable<any>
  loadResource: string
  

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private resourceService: ResourceService,
    private sbEvents: SbEvent,
    private layoutState: LayoutState,
    private location: Location,
    private storage: StorageService,
    private contentful: ContentfulClientApiFast,
    private logger: LoggingService
  ) {
    if (typeof ga === 'function') {
      this.router.events
        .pipe(filter((s) => s instanceof NavigationEnd))
        .pipe(skip(1)) // until we have a true SPA we also track page views on page load.
        .subscribe(() => this.track())
    }
  }

  getLoaderResource() {
    return this.loadResource
  }

  ngOnInit() {
    const isAgent = window.localStorage['sbw_AgentGenericId'] != null
    if (isAgent) this.logoHref = '/agent'

    this.isIE = typeof navigator !== 'undefined' && (navigator.userAgent.includes('MSIE ') || navigator.userAgent.includes('Trident/'))

    // We load loaderResource from index window variable because resources loaded from contentful are slow.
    this.resourceService.get('LOADER_TEXT').subscribe((s) => {
      this.loadResource = s
    })

    allowLocalTimeForJsonDates()
    this.clearCookieFromDifferentBase()
    this.copyQueryParamsToCookie()
    this.setDefaultIfMissing()
    this.setDefaultMomentLocale()
    this.setBaseHrefCookie()
    try {
      this.cleanupTokens()
    } catch {
      // If LocalStorage is not supported, in Private Mode in Safari on iOS 10, which has a bug, we get this error: "The quota has been exceeded".
      // If Safari->Preferences->Privacy->Block All Cookies is enabled, we get the error: "The operation is insecure"
      alert('This website does not support browsing in Private Mode, or when cookies are blocked')
    }

    this.content = this.contentful.getEntries<any>('app').pipe(map((o) => o.items[0].fields))

    this.setRouterEvents()

    this.layoutState.isContentLoaded$.subscribe((x) => (this.contentLoaded = x))

    this.router.events.subscribe((x) => {
      if (x instanceof NavigationStart) {
        this.layoutState.setIsContentLoaded(false)
      }
    })
    this.router.events.pipe(filter((x) => x instanceof NavigationStart)).subscribe((x: NavigationStart) => {
      const parts = x.url && x.url.split('?')
      this.logger.logInfo({
        message: 'navigation {NavigationId} {Trigger}',
        params: [x.id, x.navigationTrigger],
        path: parts[0],
        query: parts && parts[1],
      })
    })
  }

  ngAfterViewInit() {}

  //backend needs to know if it needs to do a 302 for e.g. RequireItinerary and after payment
  private setBaseHrefCookie() {
    this.cookieService.setCookie('sbw_Base', this.location.prepareExternalUrl(''))
  }

  private clearCookieFromDifferentBase() {
    if (this.location.prepareExternalUrl('') !== this.cookieService.getCookie('sbw_Base')) {
      //If user has been to /da-dk/booking/ and changes url to /nl-nl/booking/ we want the site in dutch
      this.cookieService.deleteCookie('sbw_Locale')
      this.cookieService.deleteCookie('sbw_SalesOwner')
      this.cookieService.deleteCookie('sbw_Market')
      this.cookieService.deleteCookie('sbw_SalesTeam')
    }
  }

  private cleanupTokens(): void {
    if (!this.cookieService.getAuthType()) {
      sessionStorage.removeItem('pax_Token')
      sessionStorage.removeItem('pax_Sub')
    }
  }

  setDefaultMomentLocale() {
    let locale = this.cookieService.getCookie('sbw_Locale')
    let map = { no: 'nb', ga: 'en' }
    moment.locale(map[locale] || locale)
  }

  private copyQueryParamsToCookie(): void {
    let queryParams = this.getQueryParams()
    const validatedLocale = this.validatedLocale(queryParams['locale'])
    if (validatedLocale != null) {
      this.cookieService.setCookie('sbw_Locale', validatedLocale)
    }
    if (queryParams['salesowner'] != null) {
      this.cookieService.setCookie('sbw_SalesOwner', queryParams['salesowner'])
    }
    if (queryParams['market'] != null) {
      this.cookieService.setMarket(queryParams['market'].toLowerCase())
    }
    if (queryParams['salesteam'] != null) {
      this.cookieService.setCookie('sbw_SalesTeam', queryParams['salesteam'])
    }
    if (queryParams['e2emock'] != null) {
      this.storage.setItem('e2emock', 'true')
    }
  }

  private validatedLocale(from: string): string {
    const seabookLocales = {
      da: 1,
      no: 1,
      sv: 1,
      de: 1,
      nl: 1,
      fr: 1,
      pl: 1,
      lt: 1,
      et: 1,
      lv: 1,
      ru: 1,
      fi: 1,
      ga: 1,
      en: 1,
    }
    const locale = from && from.toLowerCase()
    return seabookLocales[locale] ? locale : null
  }

  private setDefaultIfMissing(): void {
    if (!this.cookieService.getCookie('sbw_SalesOwner')) {
      this.cookieService.setCookie('sbw_SalesOwner', sbSalesOwner)
    }
    if (!this.cookieService.getCookie('sbw_Locale')) {
      this.cookieService.setCookie('sbw_Locale', sbLocale)
    }
  }

  private track() {
    ga('set', 'page', window.location.pathname)
    ga('send', 'pageview')
  }

  private getQueryParams() {
    let query = window.location.search.substr(1)
    let result = {}
    query.split('&').forEach(function (part) {
      let item = part.split('=')
      result[item[0].toLowerCase()] = decodeURIComponent(item[1])
    })
    return result
  }

  private setRouterEvents() {
    this.router.events
      .pipe(filter((s) => s instanceof NavigationEnd))
      .subscribe(() => this.sbEvents.resourceSetChanging.next('route changed'))
  }
}

function allowLocalTimeForJsonDates() {
  Date.prototype.toJSON = function () {
    return isNaN(this) ? null : moment(this).format()
  }
}
