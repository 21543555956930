<div class="row" *ngIf="content">

    <div *ngIf="model.out.departure !== null" class="column small-12 gutter outbound-grid no-padding-mobile" [ngClass]="{'xlarge-6': !isOneWay, 'xlarge-12': isOneWay}">
        <h2 class="padding-mobile">{{ 'OUTBOUND_JOURNEY' | resource }}</h2>
        <div *ngIf="isLoading" class="is-loading active" style="min-height: 0"></div>
        <div class="fares-grid-container">
            <div [ngClass]="popupOpen ? '' : activeOut ? 'focus sticky' : 'nonFocus sticky'" [stuck] class="row uncollapse grid-header" >
                <div class="column small-12">
                    <div class="fares-direction-header-container">
                        <h5>{{model.out.departure.route}}</h5>
                        <div [ngClass]="useNarrow() ? 'grid-earlier-later-narrow' : 'grid-earlier-later'">
                            <div class="earlier" [ngClass]="hideArrow ? 'earlier_hidden' : (useNarrow() ? 'earlier_narrow' : 'earlier')">
                                <sbw-prev-next [mode]="modeEnum.Prev" [dateControl]="departureDate"
                                    [minDate]="model.out.dates.min" [maxDate]="model.out.dates.max" [disabledDates]="model.out.dates.disabled" [textVisible]="false" allowSameDay="true">
                                </sbw-prev-next>
                            </div>
                            <div class="date-selector">
                                <sbw-datepicker 
                                    [dateControl]="departureDate" 
                                    name="departureDate"
                                    [viewModel]="faresOutDateSettings.datepickerView" 
                                ></sbw-datepicker>
                            </div>
                            <div class="later" [ngClass]="hideArrow ? 'later_hidden' : (useNarrow() ? 'later_narrow' : 'later')">
                                <sbw-prev-next [mode]="modeEnum.Next" [dateControl]="departureDate"
                                    [minDate]="model.out.dates.min" [maxDate]="model.out.dates.max" [disabledDates]="model.out.dates.disabled" [textVisible]="false" allowSameDay="true" isReturn="true">
                                </sbw-prev-next>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="data-table" *ngIf="!model.out.departure.rows || model.out.departure.rows.length === 0">
                <div class="row uncollapse data-options">
                    <div class="column small-12">
                        {{ 'NO_DEPARTURES_FOR_LEG' | resource }}
                    </div>
                </div>
            </div>

            <div *ngIf="model.out.departure.rows && model.out.departure.rows.length > 0">
                <div class="data-table ">
                    <div [stuck] class="data-panel" [ngClass]="popupOpen ? '' : useNarrow() ? 'sticky_narrow' : 'sticky'">
                        <div class="row uncollapse data-options" style="padding-left: 0; padding-right: 0">
                            <div class="data-item">
                                <div  class="data-content hide-prices-for-medium-down">
                                    <div  class="data-content-column small-12">
                                        <div *ngFor="let product of productsFor(model.out); index as i" class="column small-{{12 / productsFor(model.out).length}} product-name" style="padding-left: 0.4rem; padding-right: 0.4rem">
                                            <div class="matrix-{{i}} price fares-popup-container">
                                                <sbw-information-popup (isOpen)="popupChanged($event)" [label]="getTextFromCombinedResourceKey('FARE_CATEGORY_TITLE', product.productCode)" [text]="getTextFromCombinedResourceKey('FARE_CATEGORY_INFOBOX', product.productCode)"></sbw-information-popup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let fareRow of model.out.departure.rows; index as i" class="data-panel">
                        <div class="row uncollapse data-items">
                            <div class="data-item">
                                <div class="data-content hide-prices-for-medium-down">
                                    <div class="data-content-column small-12 time">{{fareRow.departureTime | resourceDateformatNew: 'MOMENT_DEPARTURE_TIME_FORMAT'}} - {{fareRow.route}}</div>
                                    <div class="data-content-column small-12">
                                        <div *ngFor="let fareRowItem of fareRow.items; let i = index" class="column small-{{12 / fareRow.items.length}} price-container">
                                            <span *ngIf="fareRowItem.status===rowItemStatus.SoldOut" class="price soldout">{{ 'NOT_AVAILABLE' | resource }}</span>
                                            <span *ngIf="fareRowItem.status===rowItemStatus.Disabled" class="price disabled">{{currency | resource}}{{fareRowItem.price | resourceformat:'CURRENCY_FORMAT'}}</span>
                                            <label *ngIf="fareRowItem.status===rowItemStatus.Available" class="matrix-{{i}} price" [ngClass]="setSelected(fareRow, fareRowItem, model.out.departure.selectedDeparture) ? 'selected' : ''"
                                                (click)="selectDepartureProduct('out', fareRowItem, fareRow, i)" attr.data-selected-fare-outbound-e2e="{{fareRow.departureTime | resourceDateformatNew: 'MOMENT_DEPARTURE_TIME_FORMAT'}} - {{fareRow.route}}" attr.data.productCode="{{fareRowItem.productCode}}">
                                                <span>{{currency | resource}}{{fareRowItem.price | resourceformat:'CURRENCY_FORMAT'}}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div *ngIf="!isOneWay && model.return.departure !== null" class="column small-12 xlarge-6 gutter return-grid no-padding-mobile">
        <h2 class="padding-mobile">{{ 'RETURN_JOURNEY' | resource }}</h2>
        <div *ngIf="isLoading" class="is-loading active" style="min-height: 0"></div>
        <div class="fares-grid-container">
            <div [ngClass]="popupOpen ? '' : activeOut ? 'focus sticky' : 'nonFocus sticky'" [stuck] class="row uncollapse grid-header" >
                <div class="column small-12">
                    <div class="fares-direction-header-container">
                        <h5>{{model.return.departure.route}}</h5>

                        <div [ngClass]="useNarrow() ? 'grid-earlier-later-narrow' : 'grid-earlier-later'">
                                <div class="earlier" [ngClass]="hideArrow ? 'earlier_hidden' : (useNarrow() ? 'earlier_narrow' : 'earlier')">
                                <sbw-prev-next [mode]="modeEnum.Prev" [dateControl]="returnDate"
                                    [minDate]="model.return.dates.min" [maxDate]="model.return.dates.max" [disabledDates]="model.return.dates.disabled" [textVisible]="false" allowSameDay="true">
                                </sbw-prev-next>
                            </div>
                            <div class="date-selector">
                                <sbw-datepicker 
                                    [dateControl]="returnDate" 
                                    name="returnDate"
                                    [viewModel]="faresReturnDateSettings.datepickerView" 
                                ></sbw-datepicker>
                            </div>
                            <div class="later" [ngClass]="hideArrow ? 'later_hidden' : (useNarrow() ? 'later_narrow' : 'later')">
                                <sbw-prev-next [mode]="modeEnum.Next" [dateControl]="returnDate"
                                    [minDate]="model.return.dates.min" [maxDate]="model.return.dates.max" [disabledDates]="model.return.dates.disabled" [textVisible]="false" allowSameDay="true">
                                </sbw-prev-next>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="data-table" *ngIf="!model.return.departure.rows || model.return.departure.rows.length === 0">
                <div class="row uncollapse data-options">
                    <div class="column small-12">
                        {{ 'NO_DEPARTURES_FOR_LEG' | resource }}
                    </div>
                </div>
            </div>

            <div *ngIf="model.return.departure.rows && model.return.departure.rows.length > 0">
                <div class="data-table">
                    <div [stuck] class="data-panel" [ngClass]="popupOpen ? '' : useNarrow() ? 'sticky_narrow' : 'sticky'">
                        <div class="row uncollapse data-options" style="padding-left: 0; padding-right: 0">
                            <div class="data-item">
                                <div class="data-content hide-prices-for-medium-down">
                                    <div class="data-content-column small-12">
                                        <div *ngFor="let product of productsFor(model.return); index as i" class="column small-{{12 / productsFor(model.return).length}} product-name" style="padding-left: 0.4rem; padding-right: 0.4rem">
                                            <div class="matrix-{{i}} price fares-popup-container">
                                                <sbw-information-popup (isOpen)="popupChanged($event)" [label]="getTextFromCombinedResourceKey('FARE_CATEGORY_TITLE', product.productCode)" [text]="getTextFromCombinedResourceKey('FARE_CATEGORY_INFOBOX', product.productCode)"></sbw-information-popup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let fareRow of model.return.departure.rows; index as i" class="data-panel">
                        <div class="row uncollapse data-items">
                            <div class="data-item">
                                <div class="data-content hide-prices-for-medium-down">
                                    <div class="data-content-column small-12 time">{{fareRow.departureTime | resourceDateformatNew: 'MOMENT_DEPARTURE_TIME_FORMAT'}} - {{fareRow.route}}</div>
                                    <div class="data-content-column small-12">
                                        <div *ngFor="let fareRowItem of fareRow.items; let i = index" class="column small-{{12 / fareRow.items.length}} end price-container">
                                            <span *ngIf="fareRowItem.status===rowItemStatus.SoldOut" class="price soldout">{{ 'NOT_AVAILABLE' | resource }}</span>
                                            <span *ngIf="fareRowItem.status===rowItemStatus.Disabled" class="price disabled">{{currency | resource}}{{fareRowItem.price | resourceformat:'CURRENCY_FORMAT'}}</span>
                                            <label *ngIf="fareRowItem.status===rowItemStatus.Available" class="matrix-{{i}} price" [ngClass]="setSelected(fareRow, fareRowItem, model.return.departure.selectedDeparture) ? 'selected' : ''"
                                                (click)="selectDepartureProduct('return', fareRowItem, fareRow, i)" attr.data.productCode="{{fareRowItem.productCode}}">
                                                <span>{{currency | resource}}{{fareRowItem.price | resourceformat:'CURRENCY_FORMAT'}}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
