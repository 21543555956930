<sbw-layout [backButtonVisible]="true" [type]="'Amendment'" [form]="passengerForm" (continueEvent)="continue()"
    (backEvent)="navigateBack()">
    <div class="sbw_pagebox">
        <h1 [innerHTML]="'PASSENGERS_AMENDMENT_TITLE' | resource"></h1>
        <form [formGroup]="passengerForm">
            <div class="sbw_legend gutter" [innerHTML]="'PASSENGERS_AMENDMENT_TEASER' | resource"></div>
            <div class="row">
                <div class="column small-12">
                    <sbw-validation-summary [errorList]="errors"></sbw-validation-summary>
                </div>
            </div>
            <div class="row">
                <sbw-passengers *ngIf="inputPassengers" [mode]="'Amendment'" [model]="inputPassengers"></sbw-passengers>
            </div>
        </form>
    </div>
</sbw-layout>