import { Component, NgZone, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { zip } from 'rxjs'
import { ReservationService } from 'app/shared/reservation/reservation.service'
import { SnapshotService } from 'app/shared/snapshot.service'
import { ResourceService } from 'app/shared/resources/resource.service'
import { TpaService } from 'app/tpa/tpa.service'
import { ITpaCategory, ITpaItemSelection } from 'app/tpa/tpa.interfaces'
import { AmendService } from 'app/shared/reservation/amend.service'
import { hashObject } from 'app/shared/utils'
import { LayoutState } from '../../shared/layout/layout-state'
import { TrackingAmendmentStep } from '../../shared/tracking/tracking-wire.interfaces'
import { TrackingService } from '../../shared/tracking/tracking.service'
import { AuthService } from 'app/auth/auth.service'
import { LocaleService } from 'app/shared/locale-service'
import { CookieService } from 'app/shared/cookie.service'

@Component({
  selector: 'sbw-amendment-tpa',
  templateUrl: './amendment-tpa.component.html',
  styleUrls: ['./amendment-tpa.component.css'],
})
export class AmendmentTpaComponent implements OnInit {
  public reservationCode: string
  public currencyCode: string
  public tpaCategories: ITpaCategory[]
  public selections: ITpaItemSelection[] = []
  public errors: string[] = []
  tpasChanged = false
  private initialMd5
  public isAgent: boolean

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private reservationService: ReservationService,
    private snapshotService: SnapshotService,
    private resourceService: ResourceService,
    private tpaService: TpaService,
    private amendService: AmendService,
    private title: Title,
    private trackingService: TrackingService,
    private layoutState: LayoutState,
    private authService: AuthService,
    private zone: NgZone,
    private localeService: LocaleService,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
    let authType = this.cookieService.getAuthType()

    this.reservationCode = this.route.snapshot.params['reservationCode']

    if ((authType === 'agent' && this.isAgent) || this.authService.isLoggedIn()) {
      this.getData()
    } else {
      this.layoutState.setIsContentLoaded(true)
    }
  }

  public tpasChangedHandler() {
    this.selections = this.tpaService.getSelections(this.tpaCategories)
    this.tpasChanged = hashObject(this.selections) !== this.initialMd5
  }

  private enrichReservation() {
    let snapshot = this.snapshotService.getSnapshot(this.reservationCode)
    if (snapshot && snapshot.tpas) {
      this.selections = snapshot.tpas
      this.tpaService.setSelectionsOnViewModel(this.tpaCategories, this.selections)
      this.tpaService.updateIsOpen(this.tpaCategories)
    }
  }

  public continue() {
    this.layoutState.setIsContinueLoading(true)
    const dryRun = true
    this.amendService.changeTpas(this.reservationCode, this.selections, dryRun).subscribe((response) => {
      this.layoutState.setIsContinueLoading(false)

      if (response.errorMsgs && response.errorMsgs.length) {
        this.errors = response.errorMsgs.map((m) => m.message)
        this.layoutState.setIsContentLoaded(true)
        return
      }
      this.snapshotService.setSnapshot(this.reservationCode, { tpas: this.selections })
      this.navigateBack()
    })
  }

  navigateBack() {
    this.router.navigate(['/amendment/booking', this.reservationCode])
  }

  private getData() {
    const locale = this.localeService.getLocale()

    let tpa$ = this.tpaService.getTpaAmendment(this.reservationCode)
    let reservation$ = this.reservationService.getReservation(this.reservationCode)
    zip(tpa$, reservation$).subscribe((arr) => {
      let [tpa, reservation] = arr
      this.resourceService
        .loadResourcesPromise(
          'Default',
          locale,
          ['Common', 'Account', 'Amendment', 'ArrangementOptions', 'Currency', 'Quote'].concat(this.isAgent ? ['Agent', 'MenuAgent'] : [])
        )
        .then(() => {
          this.currencyCode = tpa.currency
          this.tpaCategories = tpa.categories
          this.tpaService.setSelectionsOnViewModel(this.tpaCategories, tpa.selections)
          this.selections = this.tpaService.getSelections(this.tpaCategories)
          this.enrichReservation()
          this.initialMd5 = hashObject(this.selections)
          this.trackingService.trackAmendment(TrackingAmendmentStep.TPA, false, reservation)
          this.layoutState.setIsContentLoaded(true)
        })
    })
  }
}
