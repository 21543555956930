import {
    Component,
    Input,
    OnInit,
    SimpleChanges,
} from "@angular/core";
import { FormControl } from '@angular/forms';
import { SelectItem } from "./select.model";

@Component({
    selector: "sbw-select-field",
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.css']
  })
export class SelectFieldComponent implements OnInit{
    @Input() name: string;
    @Input() label: string;
    @Input() control: FormControl;
    @Input() items: SelectItem[];

    selectedItem: SelectItem | undefined

    ngOnInit() {
        if(this.items.length > 0) {
            this.selectedItem = this.items.filter(x => x.code?.toString() === this.control.value?.toString())?.[0]
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if(this.items.length > 0) {
            this.selectedItem = this.items.filter(x => x.code?.toString() === this.control.value?.toString())?.[0]
        }
    }


    onInputChange(event: SimpleChanges) {
        event && this.control.setValue(event?.code)
    }
}
