import { Component, OnInit, Input } from '@angular/core';
import { HybridService } from 'app/shared/hybrid/hybrid.service';
import { ContentfulClientApiFast } from 'app/shared/resources/contentfulClientApiFast';
import { LoggingService } from 'app/shared/logging.service';

export class PriceChangedPopupModel {
    rejectUrl: string
    constructor(public expected: number, public actual: number, public currencyCode: string, public acceptUrl: string, routeCode: string) {
        this.rejectUrl = routeCode.match(/^dv|dv$/i) ? `${window.location.href.replace('/passengers', '/fares')}` : `${window.location.href.replace('/passengers', '/cabinfares')}`
    }

    priceDiff(): number {
        let diff = this.actual - this.expected
        return diff > 0.01 ? diff : 0
    }

}

@Component({
    selector: 'sbw-price-changed-popup',
    templateUrl: './price-changed-popup.component.html',
    styleUrls: ['./price-changed-popup.component.css']
})
export class PriceChangedPopupComponent implements OnInit {
    @Input() model: PriceChangedPopupModel
    content: IContent
    beforePrice: string
    afterPrice: string

    constructor(private hybridService: HybridService, private contentfulClient: ContentfulClientApiFast, private loggerService: LoggingService) { }

    ngOnInit() {
        this.contentfulClient.getEntries<IContent>('priceChangedPopup').subscribe(s => this.handleContent(s.items[0].fields)) 
        this.loggerService.logWarn({
            message: 'Price was changed on passengers step expected {before} but is {now} with a difference of {diff}',
            params: [this.model.expected, this.model.actual, this.model.priceDiff()]
        })
    }

    accept() {
        this.content = null
        this.hybridService.changeWindowLocation(this.model.acceptUrl)
    }

    reject() {
        this.content = null
        this.hybridService.changeWindowLocation(this.model.rejectUrl)
    }

    handleContent(content: IContent) {
        let split = content.text.split('{diff}')
        this.beforePrice = split[0]
        this.afterPrice = split[1]
        this.content = content
    }

}

interface IContent {
    header: string
    text: string
    goBack: string
    acceptNewPrice: string
}
