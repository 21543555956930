import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { MealWire, IMealFlowResult, IMealsWire, IMealsModel, SelectedMealWire } from './meals.interfaces'
import { ResourceService } from '../shared/resources/resource.service'
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service'
import { LocalStorageStepData, SelectionTypeEnum } from 'app/shared/steps/step.model'
import { StepService } from 'app/shared/steps/step.service'
import { QuoteChanged, PriceSpecificationType, QuoteLine, UserQuoteDetails } from 'app/shared/quote/quote.events'
import { flatMap, toParamsObj, withSeconds } from 'app/shared/utils'
import moment from 'moment'
import { MealsSelectorView } from 'app/meals/meals-selector-view'
import { LocalTime } from 'js-joda'
import { StatelessHttpService } from 'app/shared/stateless-http.service'

@Injectable()
export class MealsService {
  constructor(
    private statelessHttpService: StatelessHttpService,
    private resourceService: ResourceService,
    private salesChannelService: SalesChannelService,
    private stepService: StepService
  ) {}

  public getMealsAmendment(reservationCode: string): Observable<IMealsModel> {
    let params = { reservationCode: reservationCode }
    return this.statelessHttpService.get<IMealsModel>(true, 'meals-amendment', { params: toParamsObj(params) })
  }

  public getMealsFlow(ages: number[]): Observable<IMealsModel> {
    const reservation: any = this.stepService.combineToReservation()
    let params = {
      salesOwner: this.resourceService.getSalesOwner(),
      locale: this.resourceService.getLocale(),
      outboundDepartureId: reservation.outboundDepartureId,
      returnDepartureId: reservation.returnDepartureId,
      productCode: reservation.productCode,
      currency: reservation.currencyCode,
      salesChannelCode: this.salesChannelService.getCode(),
      ages: ages,
    }
    return this.statelessHttpService.get<IMealsModel>(false, 'meals-flow', { params: toParamsObj(params) })
  }

  public postHybridMeals(outMealWire: MealWire, retMealWire: MealWire): Observable<IMealFlowResult> {
    let mealsSelections: IMealsWire = {
      salesOwner: this.resourceService.getSalesOwner(),
      out: outMealWire,
      return: retMealWire,
      childrenAges: outMealWire.childrenAges,
    }
    this.updateStepInLocalStorage(mealsSelections)
    return this.statelessHttpService.post<IMealFlowResult>(false, 'meals-flow-new', mealsSelections)
  }

  private updateStepInLocalStorage(mealsSelections: IMealsWire) {
    let step: any = {}
    step.mealsSelections = new LocalStorageStepData(SelectionTypeEnum.User, mealsSelections)
    this.stepService.saveStepData(step, window.location.pathname, false)
  }

  public getQuoteModel(
    mealSelectorViewOut: MealsSelectorView,
    mealSelectorViewReturn: MealsSelectorView,
    currencyCode: string,
    seatingTimeFormat: string
  ): QuoteChanged {
    return new QuoteChanged(
      PriceSpecificationType.Meals,
      this.getQuoteData(mealSelectorViewOut, currencyCode, seatingTimeFormat),
      this.getQuoteData(mealSelectorViewReturn, currencyCode, seatingTimeFormat),
      null
    )
  }

  private getQuoteData(mealSelectorView: MealsSelectorView, currencyCode: string, seatingTimeFormat: string): UserQuoteDetails {
    if (mealSelectorView == null) {
      return null
    }
    let selectedMealArrangements = flatMap(mealSelectorView.restaurants, (x) => x.mealArrangements.filter((m) => m.selected === true))
    return new UserQuoteDetails(
      flatMap(selectedMealArrangements, (m) =>
        mealSelectorView
          .getPassengersWithPrices(m.id)
          .map(
            (x) =>
              new QuoteLine(
                this.getDescription(x.description, m.selectedTime, seatingTimeFormat),
                x.totalPrice,
                currencyCode,
                x.noOfPassengers
              )
          )
      ).reduce((a, b) => a.concat(b), [])
    )
  }

  private getDescription(description: string, seationTime: LocalTime, seatingTimeFormat: string): string {
    return seationTime != null
      ? description + ' - ' + moment(seationTime.toString(), seatingTimeFormat).format(seatingTimeFormat)
      : description
  }

  public getMealsWire(departureId: string, mealSelectorViews: MealsSelectorView, childrenAges: number[]): MealWire {
    let selectedMeals: SelectedMealWire[] = []
    if (mealSelectorViews && mealSelectorViews.restaurants && mealSelectorViews.restaurants.length) {
      for (const r of mealSelectorViews.restaurants) {
        if (r.mealArrangements && r.mealArrangements.length) {
          for (const ma of r.mealArrangements.filter((x) => x.selected === true)) {
            let roomCodeWithTime = ma.selectedTime
              ? ma.roomCodesWithTime.find((x) => x.time.equals(ma.selectedTime))
              : ma.roomCodesWithTime[0]
            selectedMeals.push({
              mealId: ma.id.toLocaleUpperCase(),
              roomCode: roomCodeWithTime ? roomCodeWithTime.code : null,
              selectedTime: ma.selectedTime ? withSeconds(ma.selectedTime) : null,
            }) // If seconds is 00 js-joda will remove them. Must use moment to format as backend needs the seconds to be there and js-joda formatting are locale sensitive
          }
        }
      }
    }
    return <MealWire>{ departureId: departureId, selectedMeals: selectedMeals, childrenAges: childrenAges }
  }
}
