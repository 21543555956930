import { LocaleData, LocaleSettings } from "../locale-settings"

export class DatepickerView {
    public placeholder: string;
    public calendarLocale: LocaleSettings
    public dateFormat: string

    constructor(
        public locale: DatepickerLocaleType = 'en',
        public minDate: Date = null,
        public maxDate: Date = null,
        public disabledDates: Date[] = [],
        public highlightedDates: string[] = [],
        placeholder: string = null,
        public yearSelectorRange = '',
        public readonly = true,
        public defaultDate: Date = null,
        public dateOfBirth: Date = null
    ) {
        const localeData = new LocaleData(this.locale)
        this.calendarLocale = localeData.settings
        this.dateFormat = localeData.settings.dateFormat
        this.placeholder = placeholder == null ? localeData.settings.placeholderFormat : placeholder
    }

    public get showYearSelector(): boolean {
        return this.yearSelectorRange ? true : false
    }
}

export type DatepickerLocaleType = 'da' | 'nl' | 'de' | 'sv' | 'no' | 'lt' | 'et' | 'lv' | 'pl' | 'fr' | 'ru' | 'en' | 'ga' | 'fi'
