<h1 class="middle">{{heading}}</h1>
<div class="row">
  <div class="collapse inline-right secondary-action-form">
    <div class="column small-12 medium-5">
      <input 
        type="text"
        [readonly]="codeValue !== null && codeStatus === 'IsValidProductCode'"
        class="input-text"
        [(ngModel)]="codeFieldValue"
        [ngClass]="{ 'input-error': errorText }"
        name="suppliedCode"
      />
      <small class="assistiveText" *ngIf="assistiveText">{{assistiveText}}</small>
      <small class="errorText" *ngIf="errorText">{{errorText}}</small>
    </div>
    <div class="column small-12 medium-3 label end verify-button-container">
      <sbw-button
        *ngIf="!hasValidOfferOrProductCode()"
        id="sbw_update_product"
        onClickState="activated" 
        type="secondary" 
        [text]="'OFFER_VERIFY' | resource" 
        (clicked)="verifyCode()"
      ></sbw-button>
      <sbw-button
        *ngIf="hasValidOfferOrProductCode()"
        id="sbw_remove_product"
        onClickState="activated"
        type="secondary" 
        [text]="'OFFER_REMOVE' | resource" 
        (clicked)="removeCode()"
      ></sbw-button>
    </div>
  </div>
</div>
