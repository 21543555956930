import { ActivatedRoute } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class WebSessionCounterService {
    
    constructor(private route: ActivatedRoute) {
        this.update()
    }
    
    get sessionCount():number {        
        return Number(window.localStorage.getItem('user_web_session_count'));        
    }

    set sessionCount(val:number) {
        window.localStorage.setItem('user_web_session_count', val.toString());
    }
    
    update() {    
        let count = this.sessionCount

        if (count === 0 || this.isNewSession()) {                        
            if (this.isNewSession())
            {
                
                //    console.log('new session')
                this.sessionPageViews = 0
                this.sessionCount = count + 1
            }
            this.lastActive = new Date()

            if (this.currentUtmCampaign)
                this.lastUtmCampaign = this.currentUtmCampaign;
        }        
    }

    incrementPageViews() {    
        this.sessionPageViews++   
           
        // console.log('pageViews', this.sessionPageViews)
        // console.log('sessionCount', this.sessionCount)
    }

    isNewSession():boolean {
        // use definition from https://support.google.com/analytics/answer/2731565?hl=en

        const time = this.lastActive,
              now = new Date();

        let diff = now.valueOf() - time.valueOf()

        if (this.currentUtmCampaign && this.lastUtmCampaign != this.currentUtmCampaign)
        {
            //console.log('campaign changed', this.lastUtmCampaign, this.currentUtmCampaign)
            return true
        }
        if (diff/1000/60 > 30)
        {
            //console.log('session expired after 30 minutes')
            return true
        }
        if (now.toDateString() !== time.toDateString())
        {
            //console.log('session date changed', now, time)
            return true
        }

        return false
    }

    get lastActive():Date {
        const time = window.localStorage.getItem('user_web_session_last_active');

        if (time) {
            return new Date(time);
        }else{
            return new Date();
        }
    }

    set lastActive(time: Date) {
        window.localStorage.setItem('user_web_session_last_active', time.toISOString());
    }

    get sessionPageViews():number {
        const pageViews = window.localStorage.getItem('user_web_session_page_views');

        if (pageViews) {
            return Number.parseInt(pageViews);
        }else{
            return 0;
        }
    }

    set sessionPageViews(pageViews: number) {
        window.localStorage.setItem('user_web_session_page_views', pageViews.toString());
    }

    get lastUtmCampaign(): string {
        return window.localStorage.getItem('user_web_session_utm_campaign');
    }

    set lastUtmCampaign(val: string) {
        window.localStorage.setItem('user_web_session_utm_campaign', val);
    }

    get currentUtmCampaign():string {
        
        let queryParams = this.route.snapshot.queryParams
        return queryParams['utm_campaign']        
    }

}
 