import { Injectable } from '@angular/core'
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service'
import { Fare } from 'app/fares/fares.interfaces'
import { Observable } from 'rxjs'
import { ResourceService } from 'app/shared/resources/resource.service'
import { QuoteChanged, PriceSpecificationType, QuoteLine, UserQuoteDetails } from 'app/shared/quote/quote.events'
import { IFlowReservation } from 'app/shared/steps/step.model'
import { toParamsObj } from 'app/shared/utils'
import { LocalDateTime } from 'js-joda'
import { StatelessHttpService } from 'app/shared/stateless-http.service'

@Injectable()
export class FaresService {

    constructor(
        private statelessHttpService: StatelessHttpService,
        private salesChannelService: SalesChannelService,
        private resourceService: ResourceService) {}

    public getFaresAmendment(outboundDepartureDate: LocalDateTime, returnDepartureDate: LocalDateTime, reservationCode: string): Observable<any> {
        let params = {
            'outboundDepartureDate': outboundDepartureDate,
            'returnDepartureDate': returnDepartureDate,
            'reservationCode': reservationCode
        }
        return this.statelessHttpService.get<Fare.IWire>(true, 'fares-amendment', { params: toParamsObj(params) })
    }

    public getFaresFlow(oDate: LocalDateTime, rDate: LocalDateTime, reservation: IFlowReservation): Observable<Fare.IWire> {
        let params = {
            outboundDepartureDate: oDate,
            returnDepartureDate: rDate,
            outboundRouteCode: reservation.outboundRouteId,
            returnRouteCode: reservation.returnRouteId && reservation.returnRouteId.toLowerCase() === 'one_way' ? null : reservation.returnRouteId,
            salesOwnerId: this.resourceService.getSalesOwner(),
            localeCode: this.resourceService.getLocale(),
            salesChannelCode: this.salesChannelService.getCode(),
            adults: reservation.adults,
            children: reservation.children,
            childAges: reservation.childrenAges,
            infants: reservation.infants,
            pets: reservation.pets,
            isAmendment: 'false',
            productCode: reservation.productCode,
            offer: reservation.offerCode,
            agentGenericId: localStorage.getItem('sbw_AgentGenericId'),
        }

        if ((<any>reservation).vehicleAndTrailerTypes.length !== 0) {
            params['vehicleType'] = (<any>reservation).vehicleAndTrailerTypes[0].vehicleType
            params['trailerType'] = (<any>reservation).vehicleAndTrailerTypes[0].trailerType
        }

        return this.statelessHttpService.get<Fare.IWire>(false, 'fares-flow', { params: toParamsObj(params) })
    }

    public GetQuoteModel(msg: Fare.IFareChangedMessage): QuoteChanged {
        let out = msg.out && new UserQuoteDetails([new QuoteLine(msg.out.product, msg.out.price, msg.currency, 1)],
            null, msg.out.route, msg.out.departureTime.toString())

        let ret = msg.return && new UserQuoteDetails([new QuoteLine(msg.return.product, msg.return.price, msg.currency, 1)],
            null, msg.return.route, msg.return.departureTime.toString())

        return new QuoteChanged(PriceSpecificationType.Fares, out, ret, null)
    }

    public postHybridFares(postModel: Fare.IFaresPostRequest): Observable<Fare.IFaresFlowResult> {
        return this.statelessHttpService.post<Fare.IFaresFlowResult>(false, 'fares-flow', postModel)
    }

}
