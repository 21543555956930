<sbw-layout
  [backButtonVisible]="true"
  type="Amendment"
  [form]="amendmentsForm"
  [continueButtonDisabled]="!this.canAmend || (this.canAmend && !this.fareChanged)"
  (continueEvent)="continue()"
  (backEvent)="navigateBack()"
>
  <div class="sbw_pagebox" *ngIf="reservation && resourceLoaded">
    <div class="row uncollapse fares-container">
      <h1>{{ amendmentFaresContent.title }}</h1>
      <div class="row">
        <div class="column small-12 large-10 sbw_legend gutter">{{ amendmentFaresContent.legend }}</div>
      </div>
      <div class="row" id="booking-details">
        <div class="column small-12">
          <h5>{{ amendmentFaresContent.defaultBooking }}</h5>
        </div>
        <div class="column small-12">{{ amendmentFaresContent.bookingNumber }}: {{ reservationCode }}</div>
        <div class="column small-12">
          {{ amendmentFaresContent.outboundTravel }}: {{ outboundViewModel.route }}, {{ outboundViewModel.day }}
          {{ outboundViewModel.date }} {{ outboundViewModel.time }}
        </div>
        <div *ngIf="returnRoute" class="column small-12">
          {{ amendmentFaresContent.returnTravel }}: {{ returnViewModel.route }}, {{ returnViewModel.day }} {{ returnViewModel.date }}
          {{ returnViewModel.time }}
        </div>
        <div class="column small-12 gutter"></div>
      </div>
      <div class="row">
        <div class="column small-12">
          <sbw-validation-summary [errorList]="errors"></sbw-validation-summary>
          <div class="row">
            <div class="column small-12 medium-12" [class.large-6]="!returnRoute" [class.large-12]="returnRoute">
              <sbw-fares-selector
                [content]="amendmentFaresContent"
                *ngIf="data"
                [data]="data"
                [faresForm]="amendmentsForm"
                [currency]="reservation.currencyCode"
                (fareChanged)="departureChanged()"
                [isLoading]="loadDepartures"
                (prevNext)="prevNext($event)"
                [filterProducts]="true"
                [isQuotePresent]="false"
              ></sbw-fares-selector>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</sbw-layout>
