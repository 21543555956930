import { Injectable, Pipe, PipeTransform } from '@angular/core'
import { ResourceService } from './resource.service'
import { LocalDateTime } from 'js-joda'
import moment from 'moment'
import { LoggingService } from '../logging.service';

@Injectable()
@Pipe({ name: 'resourceDateformatNew', pure: false })
export class ResourceDateformatNewPipe implements PipeTransform {

    private cachedResult: string = null
    private cachedDate: LocalDateTime

    constructor(private logger: LoggingService, public resourceService: ResourceService) {
    }

    transform(input: LocalDateTime, key: string) {
        if (!input || !moment(input.toString()).isValid()) {
            this.logger.logWarn({ message: `invalid input ${input}` })
            return
        }
        if (!this.cachedDate || !input.isEqual(this.cachedDate)) {
            this.cachedDate = input
            let locale = this.resourceService.getLocale()
            this.resourceService.get(key).subscribe(resource => {
                if (key === resource) {
                    this.logger.logWarn({ message: `Format do not exist ${key}` })
                    this.cachedResult = moment(input.toString()).locale(locale).format("DD/MM/YYYY, [at] HH:mm")
                } else {
                    try {
                        this.cachedResult = moment(input.toString()).locale(locale).format(resource)
                    } catch (error) {
                        this.logger.logWarn({ message: `Invalid format ${resource}` })
                        this.cachedResult = moment(input.toString()).locale(locale).format("DD/MM/YYYY, [at] HH:mm")
                    }
                }
            })
        }
        return this.cachedResult
    }
}
