<div class="row">
    <div class="column small-12 medium-6">
        <div class="miniBook__container" id="departureType">
            <div class="miniBook miniBook__return"
                [ngClass]="{ miniBook__grey: isOneWayOnly(), miniBook__selected: !oneWay.value }"
                (click)="changeDepartureType('return')">
                {{content.fields.return}}
            </div>
            <div class="miniBook miniBook__oneWay"
                [ngClass]="{ miniBook__grey: isMiniCruise(), miniBook__selected: oneWay.value }"
                (click)="changeDepartureType('oneway')">
                {{content.fields.oneway}}
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="column small-12 medium-6">
        <div class="row">
            <div class="column small-12">
                <div class="field">
                    <sbw-select-field name="route" [control]="outboundRouteCode"
                        [items]="routes">
                    </sbw-select-field>
                </div>
            </div>
        </div>
    </div>

    <div class="column small-12 medium-6">
        <div class="row">
            <div class="column small-12 end">
                <sbw-date-range-picker
                    [locale]="locale"
                    [departureDateControl]="outboundDepartureDate"
                    [returnDateControl]="returnDepartureDate"
                    [oneWayControl]="oneWay"
                    [outbound]="outboundRoute.date"
                    [homebound]="returnRoute.date"
                    [rangeMode]="!oneWay.value">
                </sbw-date-range-picker>
            </div>
        </div>
    </div>
</div>
