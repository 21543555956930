import { Component, OnInit, Input } from '@angular/core'
import { IFlowPassengersAgentWire } from 'app/passengers/passengers.interfaces'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { Country } from 'app/passengers/passengers.models'

@Component({
    selector: 'sbw-agent-details',
    templateUrl: './agent-details.component.html',
    styleUrls: ['./agent-details.component.css']
})
export class AgentDetailsComponent implements OnInit {

    @Input() inputModel: IInputAgentDetails
    form: FormGroup

    constructor(private fb: FormBuilder) { }

    ngOnInit() {
        const { agentReference, phonePrefix, contactPhoneNumberPrefix, contactPhoneNumber } = this.inputModel.agentContact
        this.form = this.fb.group({
            'agentReference': this.fb.control(agentReference, [Validators.required, Validators.maxLength(50)]),
            'contactPhoneNumber': this.fb.control(contactPhoneNumber, [Validators.maxLength(25)]),
            'contactPhonePrefix': this.fb.control(phonePrefix || this.inputModel.phonePrefixes[0]) // defaults to same as agents prefix
        })
        this.inputModel.form.addControl('agentDetails', this.form)
    }

    public get countryDescription(): string {
        const country = this.inputModel.countries.find(x => x.code === this.inputModel.agentContact.countryCode)
        return country ? country.description : ''
    }
}

export interface IInputAgentDetails {
    agentContact?: IFlowPassengersAgentWire
    countries: Country[],
    phonePrefixes: string[],
    form: FormGroup
}
