import { CookieService } from 'app/shared/cookie.service'
import { Injectable } from '@angular/core'

import { LocaleService } from 'app/shared/locale-service'
import { IFlowReservation } from 'app/shared/steps/step.model'
import { IFlowTrack, ILegTrack, IBaseTrack, IAmendmentTrack } from './virtual-page-view-tracking.interfaces'
import { IReservation } from 'app/shared/models/reservation.interfaces'
import { TrackingAmendmentStep, AmendmentTrackingReservation } from '../tracking-wire.interfaces'
import { WebSessionCounterService } from '../web-session-counter.service'
import { MarketService } from 'app/shared/market.service'
import { SessionPushService } from '../session-push.service'
import { tryParseLocalDate } from 'app/shared/utils'

@Injectable()
export class VirtualPageViewTrackingService {
  private reservation: IFlowReservation

  constructor(
    private sessionPush: SessionPushService,
    private localeService: LocaleService,
    private marketService: MarketService,
    private webSessionCounter: WebSessionCounterService,
    private cookieService: CookieService
  ) {}

  public track(pageTitle: string, reservation: IFlowReservation): void {
    this.reservation = reservation
    let flowTrack = this.getFlowTrack(pageTitle)
    this.sessionPush.push(flowTrack)
  }

  public trackAmendment(step: TrackingAmendmentStep, reservation?: IReservation | AmendmentTrackingReservation): void {
    if (reservation) {
      this.sessionPush.push(this.GetAmendmentTrackReservation(step, reservation))
    } else {
      this.sessionPush.push(this.GetAmendmentTrack(step))
    }
  }

  private getFlowTrack(pageTitle: string): IFlowTrack {
    this.webSessionCounter.incrementPageViews()

    return <IFlowTrack>{
      event: 'vpv',
      businessArea: 'passenger',
      isAgent: this.cookieService.isAgent(),
      statusCode: 200,
      sessionCount: this.webSessionCounter.sessionCount,
      pagesViewed: this.webSessionCounter.sessionPageViews,
      bookingEngine: 'seabookFlow',
      localeCode: this.localeService.getLocale(),
      routeCode: this.reservation.routeCode,
      bookingType: this.reservation.productCode && this.reservation.productCode[0],
      productCode: this.reservation.productCode,
      outbound:
        this.reservation.outboundDepartureDate && <ILegTrack>{ departureDate: this.reservation.outboundDepartureDate.toLocalDate() },
      return: this.reservation.returnDepartureDate && <ILegTrack>{ departureDate: this.reservation.returnDepartureDate.toLocalDate() },
      countryCode: this.localeService.getUnifiedCountryCode(),
      pagePath: location.pathname,
      pageTitle: pageTitle,
      languageCode: this.localeService.getUnifiedLanguageCode(),
      market: this.marketService.getMarket(),
      targeting: this.reservation.outboundRouteCode && this.reservation.outboundRouteCode.match(/^DV|DV$/i) ? 'shortsea' : 'longsea',
      pageQuerySource: this.getParameterByName('utm_source'),
      pageQueryMedium: this.getParameterByName('utm_medium'),
      pageQueryCampaign: this.getParameterByName('utm_campaign'),
      userID: sessionStorage.getItem('pax_Sub'),
    }
  }

  private GetAmendmentTrack(step: TrackingAmendmentStep): IAmendmentTrack {
    this.webSessionCounter.incrementPageViews()

    return <IAmendmentTrack>{
      event: 'vpv',
      businessArea: 'passenger',
      statusCode: 200,
      sessionCount: this.webSessionCounter.sessionCount,
      pagesViewed: this.webSessionCounter.sessionPageViews,
      bookingEngine: 'seabookAmendment',
      localeCode: this.localeService.getLocale(),
      languageCode: this.localeService.getUnifiedLanguageCode(),
      countryCode: this.localeService.getUnifiedCountryCode(),
      market: this.marketService.getMarket(),
      pagePath: location.pathname,
      pageTitle: document.title,
      pageName: TrackingAmendmentStep[step],
      userID: sessionStorage.getItem('pax_Sub'),
    }
  }

  private GetAmendmentTrackReservation(
    step: TrackingAmendmentStep,
    reservation: IReservation | AmendmentTrackingReservation
  ): IAmendmentTrack {
    let eventData = this.GetAmendmentTrack(step)
    eventData.routeCode = reservation.routeCode
    eventData.bookingType = reservation.productCode[0]
    eventData.productCode = reservation.productCode
    eventData.outbound = <ILegTrack>{ departureDate: tryParseLocalDate(reservation.departures[0].departureDateTime) }
    eventData.return =
      reservation.departures[1] && <ILegTrack>{ departureDate: tryParseLocalDate(reservation.departures[1].departureDateTime) }
    eventData.localeCode = reservation.locale
    return eventData
  }

  public trackNotFound(): void {
    let notFoundTrack = this.getBaseTrack(404)
    this.sessionPush.push(notFoundTrack)
  }

  public trackError(): void {
    let errorTrack = this.getBaseTrack(500)
    this.sessionPush.push(errorTrack)
  }

  private getBaseTrack(statusCode: number): IBaseTrack {
    return <IBaseTrack>{
      event: 'vpv',
      businessArea: 'passenger',
      statusCode: statusCode,
      bookingEngine: 'seabook',
      countryCode: this.localeService.getUnifiedCountryCode(),
      localeCode: this.localeService.getLocale(),
      market: this.marketService.getMarket(),
      pagePath: location.pathname + location.search,
      userID: sessionStorage.getItem('pax_Sub'),
    }
  }

  private getParameterByName(name) {
    let match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search)
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
  }
}
