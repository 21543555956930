import { Injectable } from '@angular/core'

import { StorageService } from '../../../storage.service'
import { IProductTrack } from '../ecommerce-tracking.interfaces'
import { IReservation, IRoomAst, IRoomArrangement, IRoom } from 'app/shared/models/reservation.interfaces'

@Injectable()
export class EcommerceTrackingMealsPreparerService {

    constructor(
        private storageService: StorageService
    ) {}

    public prepareMealsForAmendmentTracking(before: IReservation, after: IReservation){
        const meals = this.prepareMeals(before, after)
        this.storageService.removeSbwAmendmentMealsTracking()
        if (meals && meals.length > 0) {
            this.storageService.setSbwAmendmentMealsTracking(JSON.stringify(meals))
        }
    }

    public getPreparedMealsForAmendmentTracking(before: IReservation, after: IReservation): IProductTrack[] {
        const meals = this.prepareMeals(before, after)
        return meals
    }

    private prepareMeals(before: IReservation, after: IReservation): IProductTrack[] {
        let products: IProductTrack[] = []

        after.departures.forEach(departure => {
            const departureBefore = before.departures.find(x => x.departureId === departure.departureId)
            departure.rooms.forEach(r => {
                r.roomArrangements.forEach(ra => {
                    if (this.roomArrangementExists(ra, departureBefore && departureBefore.rooms)) { // updated item
                        if (this.trackingNamechanged(r, ra, departureBefore && departureBefore.rooms)) {
                            let name = this.name(r.description, ra.description)
                            let dimension5 = 'Amended - Adjusted'
                            let product = this.createMealProduct(after, ra.id, name, 0, 0, dimension5) // Price and quantity set to 0 in agreement with Richard James Dennis from Dentsu Aegis Network
                            products.push(product)
                        }
                    } else { // new item
                        let price = this.getMealsPrice({ reservation: after, roomArrangements: ra.roomASTs, departureId: departure.departureId })
                        let name = this.name(r.description, ra.description)
                        let count = this.count(ra) // count is wrong for added product(s) - bug originating from mapping behind patch method in webfacade https://dfds.visualstudio.com/SeaBook/_git/Seabook.WebFacade?path=%2FDfds.SeaBook.WebFacade.Web%2FAreas%2FReservation%2FReservationController.cs&_a=contents&version=GBmaster
                        let d5 = 'Amended - Added'
                        let product = this.createMealProduct(after, ra.id, name, price, count, d5)
                        products.push(product)
                    }
                })
            })
        })
        before.departures.forEach(departure => {
            const departureAfter = after.departures.find(x => x.departureId === departure.departureId)
            departure.rooms.forEach(r => {
                r.roomArrangements.forEach(ra => {
                    if (!this.roomArrangementExists(ra, departureAfter && departureAfter.rooms)) { // removed item
                        let price = -this.getMealsPrice({ reservation: before, roomArrangements: ra.roomASTs, departureId: departure.departureId })
                        let name = this.name(r.description, ra.description)
                        let count = -this.count(ra) // Quantity must be negative for removals in agreement with Richard James Dennis from Dentsu Aegis Network
                        let d5 = 'Amended - Removed'
                        let product = this.createMealProduct(before, ra.id, name, price, count, d5)
                        products.push(product)
                    }
                })
            })
        })
        return products
    }

    private name = (roomDescription: string, roomArrangementDescription: string): string => `${roomDescription} - ${roomArrangementDescription}`
    private count = (roomArrangement: IRoomArrangement): number => roomArrangement.roomASTs.map(x => x.count).reduce((a, b) => a + b, 0) // summed up for all ast's

    private roomArrangementExists = (roomArrangement: IRoomArrangement, rooms: IRoom[]): boolean => {
        if (!rooms || rooms.length < 1) return false
        let result: boolean = false
        rooms.forEach(r => {
            r.roomArrangements.forEach(ra => {
                if (ra.id === roomArrangement.id) {
                    result = true
                }
            })
        })
        return result
    }

    private trackingNamechanged = (room: IRoom, roomArrangement: IRoomArrangement, rooms: IRoom[]): boolean => {
        if (!rooms || rooms.length < 1) return false
        let result: boolean = false
        rooms.forEach(r => {
            r.roomArrangements.forEach(ra => {
                if (ra.id === roomArrangement.id) {
                    const nameFirst = this.name(r.description, ra.description)
                    const nameOther = this.name(room.description, roomArrangement.description)
                    if (nameFirst !== nameOther) {
                        result = true
                    }
                }
            })
        })
        return result
    }

    private getMealsPrice({ reservation, roomArrangements, departureId }: { reservation: IReservation; roomArrangements: IRoomAst[]; departureId: string }): number {
        let price = 0
        roomArrangements.forEach(ra => {
            let priceItem = reservation.priceItems.find(pi => pi.departureId != null && pi.departureId.toLowerCase() === departureId.toLowerCase() && pi.id.toLowerCase() === ra.generalComponentGenericID.toLowerCase())
            price += priceItem && priceItem.price
        })
        return price ? price : 0
    }

    private createMealProduct(reservation: IReservation, id: string, name: string, price: number, count: number, dimension5: string): IProductTrack {
        let product = <IProductTrack>{
            id: id,
            name: name,
            brand: 'SeaBook',
            category: this.getCategory(reservation),
            variant: reservation.productCode[0],
            price:  price.toFixed(2),
            quantity: count,
            dimension5: dimension5
        }
        return product
    }

    private getCategory(reservation: IReservation): string {
        if (!reservation.routeCode) { return '' }
        if (reservation.routeCode.length !== 4) { return reservation.routeCode }
        return `${reservation.routeCode}-${reservation.routeCode.substring(2)}${reservation.routeCode.substring(0, 2)}`
    }

}
