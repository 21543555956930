import { Injectable } from '@angular/core'
import { Observable, of as observableOf } from 'rxjs';
import { ResourceService } from 'app/shared/resources/resource.service';
import { ITravelWire, IHybridResult, IAgentProductList, TravelForm } from 'app/travel/travel.interfaces';
import { toParamsObj, tryParseLocalDate } from 'app/shared/utils'
import { Params } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ChildrenAges } from './travel.models';
import { LocalDate } from 'js-joda';
import { IVehicleTypes } from 'app/vehicles/vehicles.interfaces';
import { StatefulHttpService } from 'app/shared/stateful-http.service'
import { StatelessHttpService } from 'app/shared/stateless-http.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'app/shared/cookie.service';

export interface ITravelParams {
    departureRoute: string
    code: string
    departureDate: LocalDate
    returnDate?: LocalDate
    numberOfPassengers: number
    childAges?: number[]
    vehicleType?: string
}

@Injectable()
export class TravelService {

    constructor(
        private statelessHttpService: StatelessHttpService, 
        private statefulHttpService: StatefulHttpService,
        private cookieService: CookieService,
        private httpClient: HttpClient,
        private resourceService: ResourceService) 
    { }

    public getTravelFlow(salesChannel: string, p: ITravelParams): Observable<ITravelWire> {
        let params = {
            salesOwner: this.resourceService.getSalesOwner(),
            locale: this.resourceService.getLocale(),
            salesChannel,
            ...p
        }
        return this.statelessHttpService.get<ITravelWire>(false, 'travel-flow', { params: toParamsObj(params) })
    }

    public ensureAgentAuth() {
      if (this.cookieService.getAuthType() !== 'agent') {
        // this.statelessHttpService.ensureAuthTokenPresent()
        this.agentReferenceId()
      }  
    }

    private agentReferenceId() {
        let r = localStorage.getItem('sbw_AgentGenericId')
        if (!r) { throw new Error('agent id missing') }
        return r
    }

    public getAgentProducts(routeCode: string): Observable<IAgentProductList> {
        let params = {
            'salesOwner': this.resourceService.getSalesOwner(),
            'locale': this.resourceService.getLocale(),
            'agentGenericId': this.agentReferenceId(),
            'routeCode': routeCode
        }
        return this.statelessHttpService.get<IAgentProductList>(true, 'agent/products', { params: toParamsObj(params) })
    }

    private getTravelSearchBaseApi()
    {
        let result: string = ''
        if (window.location.host === 'www.dfds.com')
            result = 'https://travel-search-prod.dfds-pax-web.com/api'
        else if (window.location.host === 'www.dev.dfds.com')
            result = 'https://travel-search-dev.dfds-pax-web.com/api'
        else if (window.location.host === 'www.test.dfds.com')
            result = 'https://travel-search-test.dfds-pax-web.com/api'
        else if (window.location.host === 'www.accept.dfds.com')
            result = 'https://travel-search-uat.dfds-pax-web.com/api'
        else if (window.location.host.includes('localhost')) result = 'https://travel-search-dev.dfds-pax-web.com/api'
        return result                  
    }

    public getLoaderUrl(travelForm: TravelSelection, isAgent: boolean) {
        if (isAgent || window.location.hostname.includes('ferry')) return observableOf({ url : ''})
        
        const travelSearchBaseApi = this.getTravelSearchBaseApi()
        const salesOwner = this.resourceService.getSalesOwner()

        const cAges = this.getChdAges(travelForm.passengerSelections.childrenAges)

        const params = new URLSearchParams()
        const returnCode = travelForm.departureSelections.outboundRouteCode.slice(-2) + travelForm.departureSelections.outboundRouteCode.slice(0, -2)
        const outboundDateParsed = travelForm.departureSelections.outboundDepartureDate.toString()
        const returnDateParsed = travelForm.departureSelections.returnDepartureDate && travelForm.departureSelections.returnDepartureDate.toString()
        params.append('locale', window.location.hostname === 'localhost' ? localeMappings.find(x => x.seabookLocale === this.cookieService.getLocale()).locale  : window.location.pathname.split('/')[1])
        params.append('salesOwner', salesOwner)
        params.append('routeCode', travelForm.departureSelections.outboundRouteCode)
        params.append('outboundDate', outboundDateParsed)
        params.append('adults', travelForm.passengerSelections.adultCount.toString())
        params.append('isOneway', travelForm.departureSelections.oneWay.toString())
        travelForm.passengerSelections?.childCount > 0 && params.append('children', travelForm.passengerSelections.childCount.toString())
        cAges.map(age => { params.append('childAges', age)})
        travelForm.vehicleSelections?.vehicle && params.append('vehicleCode', travelForm.vehicleSelections.vehicle)
        travelForm.vehicleSelections?.trailer && params.append('trailerCode', travelForm.vehicleSelections.trailer)
        travelForm.passengerSelections?.petCount && params.append('pets', travelForm.passengerSelections.petCount.toString())
        travelForm?.code && params.append('offerOrProductCode', travelForm.code)
        !travelForm.departureSelections?.oneWay && params.append('routeHome', returnCode)
        !travelForm.departureSelections?.oneWay && params.append('homeboundDate', returnDateParsed)

        return this.httpClient.get<{ url: string }>(`${travelSearchBaseApi}/booking-url?${params.toString()}`)
    }

    private getChdAges = (chdAges: string[]): string[] => chdAges.filter(chdAge => chdAge != "")

    public postHybridSimple(p: TravelParams): Observable<IHybridResult> {
        return this.statefulHttpService.post<IHybridResult>(false, 'travel-simple', p)
    }

    public postHybridFull(p: FullPostParams): Observable<IHybridResult> {
        return this.statefulHttpService.post<IHybridResult>(false, 'travel-full', p)
    }
}

export interface TravelSelection {
  departureSelections: { oneWay: boolean, outboundDepartureDate: LocalDate, returnDepartureDate: LocalDate, outboundRouteCode: string }
  passengerSelections: { adultCount: number, childCount: number, petCount: number, childrenAges: string[] }
  vehicleSelections: { count: number, trailer: string, vehicle: string }
  code: string
}

export class TravelParams {
    routeOut: string
    depDateOut: Date
    depDateOutJoda: LocalDate
    routeHome: string
    depDateHome: Date
    depDateHomeJoda: LocalDate
    vty: string
    tty: string
    vehicleCount: number
    offer: string
    product: string
    adl?: number
    chd?: number
    inf?: number
    pet?: number
    chdAges?: number[]
    salesOwner: string
    locale: string
    miniBookId: string
    numberOfVehicles: number

    public any(): boolean {
        return !!(this.routeOut || this.depDateOut || this.routeHome || this.depDateHome || this.pet != undefined || this.vty
            || this.tty || this.offer || this.routeHome || this.adl != undefined || this.chd != undefined || this.inf != undefined)
    }

    public static fromQuery(qp: Params): TravelParams {
        let res = new TravelParams()
        let lower = {}
        for (let q in qp) lower[q.toLowerCase()] = qp[q]
        res.routeOut = lower['routeout']
        res.depDateOut = lower['depdateout'] && new Date(lower['depdateout'])
        res.depDateOutJoda = tryParseLocalDate(lower['depdateout'])

        res.routeHome = lower['routehome']
        res.depDateHome = lower['depdatehome'] && new Date(lower['depdatehome'])
        res.depDateHomeJoda = tryParseLocalDate(lower['depdatehome'])
        res.vty = TravelParams.vehicle(lower['vty'])
        res.tty = TravelParams.trailer(lower['vty'], lower['tty'])
        res.numberOfVehicles = lower['numberofvehicles'] ? Number(lower['numberofvehicles']) : 1
        res.vehicleCount = lower['vehiclecount']
        res.offer = lower['offer']
        res.miniBookId = lower['minibookid']

        res.product = lower['product']

        res.adl = lower['adl'] && parseInt(lower['adl'])
        res.chd = lower['chd'] && parseInt(lower['chd'])
        res.inf = lower['inf'] && parseInt(lower['inf'])
        res.pet = lower['pet'] && parseInt(lower['pet'])

        let chdAges = lower['chdages']
        if (typeof chdAges == 'string') {
            chdAges = [chdAges]
        }
        res.chdAges = []
        if (chdAges) {
            for (let chdAge of chdAges)
                res.chdAges.push(parseInt(chdAge))

            let ca = ChildrenAges.fromStrings(chdAges, res.chd)
            res.chd = ca.children
            res.inf = ca.infants
        }

        res.salesOwner = lower['salesowner']
        res.locale = lower['locale']

        return res
    }

    static vehicle(vty: string) {
        return vty == 'VEHICLE_TRAILER' ? 'CAR' : vty
    }

    static trailer(vty: string, tty: string) {
        return vty == 'VEHICLE_TRAILER' ? 'YES' : tty
    }

}
export class PassengerCount {
    Adult?: number
    Child?: number
    Infant?: number
    Pet?: number
    childrenAges?: number[]
}

export class VehicleAndTrailerType {
    public registrationNumberMandatory: boolean
    public vehicleText: string
    public trailerText: string
    constructor(
        public vehicleType: string,
        public trailerType: string,
        public count: number,
    ) { }
}

export class FullPostParams {
    DepartureRouteId: string
    DepartureDate?: LocalDate
    ReturnRouteId: string
    ReturnDate: LocalDate
    PassengerCount: PassengerCount
    SuppliedCode: string
    VehicleAndTrailerTypes: VehicleAndTrailerType[]
    public static fromForm(form: FormGroup, returnRoute: string, vehiclesWire: IVehicleTypes): FullPostParams {
        const res = new FullPostParams()
        const value: TravelForm = form.value
        const ds = value.departureSelections
        const passengers = value.passengerSelections
        res.SuppliedCode = value.code
        res.DepartureRouteId = ds.outboundRouteCode
        res.DepartureDate = ds.outboundDepartureDate
        res.ReturnRouteId = returnRoute
        res.ReturnDate = ds.returnDepartureDate
        res.PassengerCount = new PassengerCount()
        const ca = ChildrenAges.fromStrings(passengers.childrenAges, passengers.childCount)
        res.PassengerCount.Adult = passengers.adultCount
        res.PassengerCount.Child = ca.children
        res.PassengerCount.Infant = ca.infants
        res.PassengerCount.Pet = passengers.petCount
        res.PassengerCount.childrenAges = ca.ages
        const vehicles = value.vehicleSelections
        const vehicleAndTrailerTypes = new VehicleAndTrailerType(vehicles && vehicles.vehicle, vehicles && vehicles.trailer, vehicles && vehicles.count)
        const vehicleType = vehiclesWire.vehicleTypes.find(v => v.code == (vehicles && vehicles.vehicle))
        if (vehicleType) {
            vehicleAndTrailerTypes.vehicleText = vehicleType.fullDescription // used on flow-passenger
            vehicleAndTrailerTypes.registrationNumberMandatory = vehicleType.registrationNumberMandatory
            const trailer = vehicleType.availableTrailers.find(t => t.code == vehicles.trailer)
            vehicleAndTrailerTypes.trailerText = trailer && trailer.fullDescription // used on flow-passenger
        }
        if (vehicleAndTrailerTypes.vehicleType === "NCAR") {
            vehicleAndTrailerTypes.vehicleType = ""
        }
        res.VehicleAndTrailerTypes = [vehicleAndTrailerTypes]
        return res
    }
}

const localeMappings = [
    {
      locale: "nl-be",
      seabookLocale: "nl",
    },
    {
      locale: "fr-be",
      seabookLocale: "fr",
    },
    {
      locale: "pt-br",
      seabookLocale: "en",
    },
    {
      locale: "cs-cz",
      seabookLocale: "en",
    },
    {
      locale: "da-dk",
      seabookLocale: "da",
    },
    {
      locale: "de-de",
      seabookLocale: "de",
    },
    {
      locale: "et-ee",
      seabookLocale: "et",
    },
    {
      locale: "es-es",
      seabookLocale: "en",
    },
    {
      locale: "fr-fr",
      seabookLocale: "fr",
    },
    {
      locale: "fr-ma",
      seabookLocale: "fr",
    },
    {
      locale: "en",
      seabookLocale: "en",
    },
    {
      locale: "en-gb",
      seabookLocale: "en",
    },
    {
      locale: "it-it",
      seabookLocale: "en",
    },
    {
      locale: "lv-lv",
      seabookLocale: "lv",
    },
    {
      locale: "lt-lt",
      seabookLocale: "lt",
    },
    {
      locale: "nl-nl",
      seabookLocale: "nl",
    },
    {
      locale: "nb-no",
      seabookLocale: "no",
    },
    {
      locale: "pl-pl",
      seabookLocale: "pl",
    },
    {
      locale: "tr-tr",
      seabookLocale: "en",
    },
    {
      locale: "ro-ro",
      seabookLocale: "en",
    },
    {
      locale: "sk-sk",
      seabookLocale: "en",
    },
    {
      locale: "fi-fi",
      seabookLocale: "fi",
    },
    {
      locale: "sv-se",
      seabookLocale: "sv",
    },
    {
      locale: "bg-bg",
      seabookLocale: "en",
    },
    {
      locale: "ru-ru",
      seabookLocale: "en",
    },
    {
      locale: "ko-kr",
      seabookLocale: "en",
    },
    {
      locale: "zh-cn",
      seabookLocale: "en",
    },
    {
      locale: "ja-jp",
      seabookLocale: "en",
    },
  ]
