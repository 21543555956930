import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { IInputEditUser } from 'app/passengers/edit-user-details/edit-user-details.component'

@Component({
    selector: 'sbw-display-user-details',
    templateUrl: './display-user-details.component.html',
    styleUrls: ['./display-user-details.component.css']
})
export class DisplayUserDetailsComponent implements OnInit {

    @Input() model: IInputEditUser
    @Output() changeUser = new EventEmitter<boolean>()

    get userDetails() {
        return this.model.userDetails
    }

    get countryDescription(): string {
		let country = this.model.countries.find(x => x.code === this.userDetails.countryCode)
        return country && country.description
    }

    get titleDescription(): string {
        let title = this.model.titles.find(x => x.code === this.userDetails.titleCode)
        return title && title.description
    }

    constructor() { }

    ngOnInit() {
    }

    change() { this.changeUser.emit(true) }
}
