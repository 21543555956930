import { FlowStep } from '../models';


export class BreadcrumbModel {
    public Items: Array<BreadcrumbItemModel> = [];
}

export class BreadcrumbItemModel {
    public Title: string;
    public Url: string;
    public ExternalUrl: string;
    public ShowAsLink: boolean;
    public IsCurrentStep: boolean;
}
