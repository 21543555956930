import { NgModule, ErrorHandler } from '@angular/core'
import { BrowserModule, Title } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms'
import { RouterModule, Routes } from '@angular/router'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { CalendarModule } from 'primeng/calendar'
import { AppComponent } from './app.component'
import { PaymentModule } from './payment/payment.module'
import { TpaModule } from './tpa/tpa.module'
import { VehiclesModule } from './vehicles/vehicles.module'
import { CabinsModule } from './cabins/cabins.module'
import { CabinAllocationsModule } from './cabin-allocations/cabin-allocations.module'
import { AgentModule } from './agent/agent.module'
import { SbEvent } from './shared/sb-event.emitter'
import { SharedModule } from './shared/shared.module'
import { SbErrorHandler } from './error.handler'
import { BookingsModule } from './amendment/bookings/bookings.module'
import { MealsModule } from './meals/meals.module'
import { TravelModule } from './travel/travel.module'
import { PassengersModule } from 'app/passengers/passengers.module'
import { FaresModule } from './fares/fares.module'
import { EnvironmentService } from 'app/environment.service'
import { UIModule } from 'app/shared/UI.module'
import { FormBuilderTyped } from 'app/shared/form-builder-typed'
import { ErrorPageComponent } from 'app/error-page/error-page.component'
import { NotFoundComponent } from 'app/not-found/not-found.component'
import { CabinFaresModule } from './cabin-fares/cabin-fares.module'
import { JsonInterceptor } from 'app/shared/json-interceptor'
import { TrackingService } from './shared/tracking/tracking.service'
import { TestErrorComponent } from './test-error/test-error.component'
import { AuthService } from './auth/auth.service'
import { PetsModule } from './pets/pets.module'

import { LottieModule } from 'ngx-lottie'
import player from 'lottie-web'

import { MsalModule, MsalService, MsalGuard, MsalBroadcastService, MSAL_INSTANCE } from '@azure/msal-angular'
import { PublicClientApplication, IPublicClientApplication } from '@azure/msal-browser'
import { msalAuthSettings } from './auth/auth.config'
import { ShipLoaderComponent } from './shared/loader/ship-loader.component'

export function playerFactory() {
  return player
}

const ROUTES: Routes = [
  { path: 'error/sbw', component: ErrorPageComponent },
  { path: 'testerror', component: TestErrorComponent },
  { path: '**', component: NotFoundComponent },
]

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: msalAuthSettings.config.auth,
    cache: msalAuthSettings.config.cache,
  })
}

@NgModule({
  imports: [
    LottieModule.forRoot({ player: playerFactory }),
    BrowserModule,
    FormsModule,
    PaymentModule,
    TpaModule,
    VehiclesModule,
    CabinsModule,
    AgentModule,
    UIModule,
    SharedModule,
    MealsModule,
    BookingsModule,
    TravelModule,
    PassengersModule,
    CalendarModule,
    FaresModule,
    CabinAllocationsModule,
    CabinFaresModule,
    BrowserAnimationsModule,
    PetsModule,
    MsalModule,
    RouterModule.forRoot(ROUTES, { useHash: false }),
  ],
  declarations: [AppComponent, ShipLoaderComponent, ErrorPageComponent, NotFoundComponent, TestErrorComponent, TestErrorComponent],
  providers: [
    AuthService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    SbEvent,
    Title,
    EnvironmentService,
    TrackingService,
    FormBuilderTyped,
    { provide: ErrorHandler, useClass: SbErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: JsonInterceptor, multi: true },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
