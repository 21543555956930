import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ResourceService } from './resource.service';
import { format } from '../utils';
import { Subscription } from 'rxjs'

@Pipe({ name: 'resourceformat', pure: false })
@Injectable()
export class ResourceFormatPipe implements PipeTransform {

    private cachedResult: any = null;
    private cachedKey = '';
    private subscription: Subscription
    constructor(public resourceService: ResourceService) { }

    transform(key: string, param: string) {
        if (key !== this.cachedKey) {
            this.cachedResult = null
            this.cachedKey = key
            this.resourceService.get(param).subscribe(resource =>
                this.cachedResult = format(resource, key, this.resourceService.loadedLocale)
            )
        }
        return this.cachedResult
    }
}
