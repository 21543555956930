import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HeaderSplitterService } from '../header-splitter.service';

@Component({
    selector: 'sbw-information-popup',
    styleUrls: ['./information-popup.component.css'],
    templateUrl: './information-popup.component.html'
})

export class InformationPopupComponent implements OnInit {
  public visible = false

  @Input() heading = ""
  @Input() text
  @Input() label

  @Output() isOpen = new EventEmitter<boolean>()
  
  constructor(private splitter: HeaderSplitterService) {}

  ngOnInit(): void {
    const split = this.splitter.trySplit(this.text, this.heading)
    if (split.heading) this.heading = split.heading
    if (split.rest) this.text = split.rest
  }

  ngOnChanges() {
    const split = this.splitter.trySplit(this.text, "")
    if (split.heading) this.heading = split.heading
    if (split.rest) this.text = split.rest
  }

  public showDialog() {
    this.visible = true
    this.isOpen.emit(true)
  }

  public popupClosed() {
    this.isOpen.emit(false)
  }

}
