<div class="row" *ngIf="priceBreakdownItems">
    <div class="column small-12 large-12">
        <div class="surrounding-container price-breakdown-container">
            <div class="row price-item" [ngClass]="{
                    'merge-with-next-row': priceBreakdownItem.isReplace && priceBreakdownItem.state == stateEnum.Removed,
                    'removed': priceBreakdownItem.state == stateEnum.Removed,
                    'added': priceBreakdownItem.state == stateEnum.Added,
                    'price-changed': priceBreakdownItem.state == stateEnum.PriceChanged
                }" *ngFor="let priceBreakdownItem of priceBreakdownItems">
                <label class="column small-8" [ngClass]="{ 'dfds-bold': (!priceBreakdownItem.showPrice) }">
                    {{ priceBreakdownItem.priceItem.label }}
                </label>
                <div *ngIf="priceBreakdownItem.showPrice" class="column small-4 text-right price" [ngClass]="{ 'dfds-bold': false }">
                    <div class="previous-price" *ngIf="priceBreakdownItem.state == stateEnum.PriceChanged">
                        {{currencyCode | resource}}{{ priceBreakdownItem.previousPrice | resourceformat:'CURRENCY_FORMAT' }}
                    </div>
                    {{currencyCode | resource}}{{ priceBreakdownItem.priceItem.price | resourceformat:'CURRENCY_FORMAT' }}
                </div>
            </div>
            <div class="row sum">
                <label *ngIf="totalPrice != null" class="column small-8 dfds-bold">
                    {{ 'TOTAL_PRICE' | resource }}
                </label>
                <div *ngIf="totalPrice != null" class="column small-4 text-right dfds-bold total-price">
                    {{currencyCode | resource}}{{ totalPrice | resourceformat:'CURRENCY_FORMAT' }}
                </div>
                <div *ngIf="!isCreditAgent && remainingAmount > 0" class="column small-8 dfds-bold">
                    {{ 'TOTAL_REMAINING_AMOUNT' | resource }}
                </div>
                <div *ngIf="!isCreditAgent && remainingAmount > 0" class="column small-4 text-right dfds-bold remaining-amount">
                    {{currencyCode | resource}}{{ remainingAmount | resourceformat:'CURRENCY_FORMAT' }}
                </div>
            </div>
        </div>
    </div>
</div>
