<div class="help wrapper" *ngIf="userDetailsWire">
  <div class="row">
    <ul *ngIf="menuReady" class="column small-12" id="OKTA">
      <!-- Phone + online help center -->
      <li *ngIf="serviceLinks.contact">
        <a title="PhoneNumber" class="icon-phone tel-small" href="tel:{{ serviceLinks.contact }}">{{ serviceLinks.contact }}</a>
        <a title="PhoneNumber" class="icon-phone tel-large">{{ serviceLinks.contact }}</a>
      </li>
      <li *ngIf="serviceLinks.onlineHelp">
        <a title="OnlineHelp" class="icon-bubbles" href="{{ serviceLinks.onlineHelp }}" target="_blank">{{ 'ONLINE_HELP' | resource }}</a>
      </li>
      <li *ngIf="!userDetailsWire.isAgent && authService.isLoggedIn()">
        <a title="User" class="icon-user" href="{{ linkToMyBookings }}">{{ linkTextMyBookings }}</a>
      </li>
      <li *ngIf="!userDetailsWire.isAgent && authService.isLoggedIn()">
        <a title="Logout" class="icon-lock" (click)="authService.initiateLogout()">{{ 'LOG_OUT' | resource }}</a>
      </li>
      <li *ngIf="!agentPage && !userDetailsWire.isAgent && !authService.isLoggedIn()">
        <a title="Login/Create user" class="icon-user" (click)="authService.initiateLogin()"
          >{{ 'ACCOUNT_LOGIN' | resource }} / {{ 'ACCOUNT_REGISTER' | resource }}</a
        >
      </li>

      <!-- Agent -->
      <li *ngIf="userDetailsWire.isAgent && userDetailsWire.agent">
        <a title="User" class="icon-user" routerLink="/agent/bookings" routerLinkActive="active"
          >{{ userDetailsWire.agent.name }} | {{ userDetailsWire.agent.company }} | {{ userDetailsWire.agent.reference }}</a
        >
      </li>
      <li *ngIf="userDetailsWire.isAgent">
        <a title="Logout" class="icon-lock" (click)="agentLogout()">{{ 'LOG_OUT' | resource }}</a>
      </li>
      <li *ngIf="userDetailsWire.isAgent">
        <a title="User" class="icon-plus" routerLink="/agent" routerLinkActive="active">{{ 'START_NEW_RESERVATION' | resource }} </a>
      </li>
    </ul>
  </div>
</div>
<div class="help wrapper" *ngIf="!userDetailsWire">
  <div class="row">
    <ul class="column small-12">
      <li><a class="dummy-loading">&nbsp;</a></li>
      <li><a class="dummy-loading">&nbsp;</a></li>
      <li><a class="dummy-loading">&nbsp;</a></li>
      <li><a class="dummy-loading">&nbsp;</a></li>
    </ul>
  </div>
</div>
