import { Component, OnInit, Input } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms'
import { ICabinAllocationDeparture, ICabinAllocationPassenger } from '../cabin-allocations.interface'


@Component({
    selector: 'sbw-cabin-allocation-group',
    templateUrl: './cabin-allocation-group.component.html',
    styleUrls: ['./cabin-allocation-group.component.css']
})
export class CabinAllocationGroupComponent implements OnInit {

    @Input() formDepartureGroup: FormGroup;
    @Input() formPassengerSelections: FormArray;

    get departure(): ICabinAllocationDeparture {
        return <ICabinAllocationDeparture>this.formDepartureGroup.value
    }

    constructor() {
        
    }

    ngOnInit() {
        
    }
}
