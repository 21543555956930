import { Injectable } from '@angular/core'
import { StorageService } from 'app/shared/storage.service'
import { IPassenger, IPriceItem, IRoom, IDepartureSelection } from 'app/shared/models/reservation.interfaces'
import { MealWire } from 'app/meals/meals.interfaces'
import { ITpaItemSelection } from 'app/tpa/tpa.interfaces'
import { ICabinDepartureSelection } from 'app/cabins/cabins.interfaces'
import { IVehicleSelection, IVehicleEditSelection } from 'app/vehicles/vehicles.interfaces'
import { ICabinAllocationEditSelection } from 'app/cabin-allocations/cabin-allocations.interface'
import { Fare } from 'app/fares/fares.interfaces'
import { LocalDateTime } from 'js-joda'

@Injectable()
export class SnapshotService {

  constructor(
    private storageService: StorageService
  ) { }

  private getStorageKey(reservationCode: string): string {
      return `SBW_BNO_${reservationCode}`
  }

  private getSnapshotKey(reservationCode: string): string {
      return `SBW_SNAP_${reservationCode}`
  }


  public setSnapshot(reservationCode: string, snapshotObj: ISnapshot): void {
      let snapshot = this.getSnapshot(reservationCode) || {}
      let key = this.getSnapshotKey(reservationCode)
      let newSnapshot = { ...snapshot, ...snapshotObj }
      this.storageService.setItem(key, JSON.stringify(newSnapshot))
  }

  public getSnapshot(reservationCode: string): ISnapshot {
      let key = this.getSnapshotKey(reservationCode)
      let snapshotString = this.storageService.getItem(key)
      if (!snapshotString) {
          return null
      }
      let snapshot: ISnapshot = {}
      return JSON.parse(snapshotString)
  }

  public clearSnapshot(reservationCode: string) {
      this.storageService.removeItem(this.getSnapshotKey(reservationCode))
      this.storageService.removeItem(this.getStorageKey(reservationCode))
  }

  public hasSnapshots(reservationCode: string): boolean {
      let snapshot = this.getSnapshot(reservationCode)
      return snapshot !== null
  }

}

export interface ISnapshot {
    departures?: Fare.IOutReturn<ISnaphotDeparture>
    passengers?: IPassenger[]
    meals?: ISnapshotMeals
    tpas?: ITpaItemSelection[]
    cabins?: ICabinDepartureSelection[]
    cabinAllocations?: ICabinAllocationEditSelection[]
    vehicles?: IVehicleEditSelection,
    vehiclesForm?: any
}

export interface ISnaphotDeparture  {
    id: string
    oldId: string
    route: string
    departureTime: LocalDateTime
}

export interface ISnapshotMeals {
    patch?: MealWire[]
    get?: ISnapshotMealsGet
}

export interface ISnapshotMealsGet {
    outbound?: IRoom[]
    return?: IRoom[]
    childrenAges?: number[]
}
