import { Injectable } from '@angular/core'
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { AuthService } from 'app/auth/auth.service'

@Injectable()
export class StatelessHttpService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  private root = '/sbwapi/booking'

  public get<T>(secure: boolean, path: string, options?: IHttpOptions): Observable<T> {
    const agent = localStorage.getItem('sbw_AgentGenericId')

    if (secure && !agent) {
      // Verify that user is logged into msal
      if (!this.authService.isLoggedIn()) {
        this.authService.initiateLogin()
        return
      }

      options = this.setAuth(options)
    }

    if (secure && agent) {
      options = this.setAuth(options)
    }

    return this.http.get<T>(`${this.root}/${path}`, options)
  }

  public post<T>(secure: boolean, path: string, body: any, options?: IHttpOptions): Observable<T> {
    const agent = localStorage.getItem('sbw_AgentGenericId')

    if (secure && !agent) {
      // Verify that user is logged into msal
      if (!this.authService.isLoggedIn()) {
        this.authService.initiateLogin()
        return
      }

      options = this.setAuth(options)
    }

    if (secure && agent) {
      options = this.setAuth(options)
    }

    return this.http.post<T>(`${this.root}/${path}`, body, options)
  }

  public put<T>(path: string, body: any, options?: IHttpOptions): Observable<T> {
    return this.http.put<T>(`${this.root}/${path}`, body, options)
  }

  public patch<T>(path: string, body: any, options?: IHttpOptions): Observable<T> {
    return this.http.patch<T>(`${this.root}/${path}`, body, this.setAuth(options))
  }

  public delete<T>(path: string, options?: IHttpOptions): Observable<T> {
    return this.http.delete<T>(`${this.root}/${path}`, this.setAuth(options))
  }

  private setAuth(options?: IHttpOptions): object {
    const token = sessionStorage.getItem('pax_Token')
    if (!token) {
      throw new Error('No token for auth')
    }
    const headers = { ...((options && options.headers) || {}), Authorization: `Bearer ${token}` }
    return { ...options, headers }
  }

  public ensureAuthTokenPresent() {
    return this.setAuth()
  }
}

export interface IHttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[]
      }
  params?:
    | HttpParams
    | {
        [param: string]: string | string[]
      }
  reportProgress?: boolean
  responseType?: any
  withCredentials?: boolean
}
