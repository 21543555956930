<sbw-layout
    [continueButtonResource]="'FINALISE_BOOKING'"
    [backButtonVisible]="true"
    [quoteModel]="quoteData"
    [quoteShowTotalPrice]="true"
    [quoteShowDepartureTime]="true"
    quoteStep="Payment"
    (continueEvent)="continue()"
    (backEvent)="navigateBack()"
    >
    <div class="sbw_pagebox">
        <div class="payment-container">
            <h1>{{'AGENT_TITLE' | resource:'Payment'}}</h1>
            <p>{{'AGENT_INTRO' | resource:'Payment'}}</p>
            <p>{{agentReference}}
                <br> {{agentAccountNumber}}
            </p>
        </div>
    </div>
</sbw-layout>
