<div>
    <div class="column booking-details-container">
        <div class="column small-12 large-8">
            <h1 data-e2e="header-booking">{{ 'AMENDMENT_TABLE_HEADER_RESERVATION_ID' | resource }} {{ reservationCode }}</h1>
        </div>
        <div class="column small-12 half-gutter">
            <sbw-validation-summary [errorList]="errors"></sbw-validation-summary>
        </div>
        <div class="column small-12 gutter" *ngIf="done">
            <sbw-grid>
                <sbw-grid-header>
                    <div class="row">
                        <div class="column small-12 medium-6">
                            <h5>{{ 'BOOKING_INFO' | resource }}</h5>
                        </div>
                    </div>
                </sbw-grid-header>
                <sbw-grid-body>
                    <sbw-booking-info [content]="amendmentBookingContent" [inputBookingInfo]="inputBookingInfo">
                    </sbw-booking-info>
                    <sbw-price-breakdown [reservation]="reservation" [priceItems]="priceItems"></sbw-price-breakdown>
                </sbw-grid-body>
            </sbw-grid>
        </div>
        <div class="column small-12 gutter hide-for-medium-down" [innerHTML]="'DISCLAIMER_FOOTER' | resource"></div>
    </div>
    <div class="column booking-amendment-container" *ngIf="done">
        <div class="column large-12 details">
            <div>
                <h1>{{amendmentBookingContent.passengerDetails}}</h1>
                <sbw-contact-details [inputContactDetails]="inputContactDetails"></sbw-contact-details>
            </div>
            <div *ngIf="agentContact" class="medium-gutter-top">
                <div class="row">
                    <div class="column small-12">
                        <h3>{{ 'AGENT_CONTACT' | resource }}</h3>
                    </div>
                    <div class="column small-12 gutter">
                        {{ agentContact }}
                    </div>
                </div>
            </div>
            <div class="gutter">
                <sbw-button type="secondary" forceLink="true" (clicked)="printConfCallback()" 
                    [text]="'PRINT_HOVER' | resource"></sbw-button>
            </div>

            <div>
                <h1>{{ 'MAKE_CHANGES' | resource }}</h1>
                <sbw-amendment-links [reservation]="reservation" [disabledLinksPopup]="disabledLinksPopup"></sbw-amendment-links>
            </div>
            <div>
                <div id="button-container">
                    <sbw-button *ngIf="displayOutstandingAmountBtn()" id="outstanding-payment-button"
                        type="preferred" (clicked)="payRemainingAmount()" [(state)]="buttonStatePayNow"
                        [text]="'AMENDMENT_OUTSTANDING_PAYMENT' | resource"></sbw-button>
                    <sbw-button *ngIf="displaySaveChangesBtn()" id="save-changes" type="preferred"
                        [(state)]="buttonStateReservationChanged" (clicked)="updateReservation()"
                        [text]="'AMENDMENT_SAVE_CHANGES' | resource"></sbw-button>
                    <div *ngIf="!reservationHasChanged" class="amendmentButton">
                        <sbw-button type="previous" (clicked)="navigateToBookingOverview()"
                            [text]="'BACK_TO_BOOKING_LIST' | resource"></sbw-button>
                    </div>
                    <div *ngIf="reservationHasChanged" class="amendmentButton">
                        <sbw-button type="previous" (clicked)="undoAmendment()"
                            [state]="updatingReservation ? 'disabled' : 'activated'"
                            [text]="'UNDO_AMENDMENT' | resource">
                        </sbw-button>
                    </div>
                    <div class="amendmentButton">
                        <sbw-button type="cancel" (clicked)="cancelReservation()" state="activated"
                            onClickState="activated" [text]="'CANCEL_RESERVATION' | resource"></sbw-button>
                    </div>
                </div>
            </div>
        </div>
        <sbw-popup [showPopup]="showCancelablePopup" [header]="'CONFIRM_CANCEL_RESERVATION' | resource | heading" [closable]="false">
            <div class="popuptext">
                <div class="row">
                    <div class="column small-12 gutter" [innerHTML]="'CONFIRM_CANCEL_RESERVATION' | resource | withoutHeading"></div>
                </div>
                <div class="row">
                    <div class="column small-12 medium-6 popup">
                        <div role="dialog">
                            <sbw-button type="preferred" (clicked)="confirmCancelReservation()"
                                [(state)]="buttonStateConfirmCancelReservation" [text]="'CANCEL_RESERVATION' | resource">
                            </sbw-button>
                        </div>
                    </div>
                    <div class="column small-12 medium-6">
                        <sbw-button type="previous" (clicked)="closeCancelReservationPopup()" [text]="'CLOSE' | resource"></sbw-button>
                    </div>
                </div>
            </div>
        </sbw-popup>
    </div>
    <div id="sticky-container">
      <sbw-button *ngIf="displaySaveChangesBtn()" id="save-changes" type="preferred" [(state)]="buttonStateReservationChanged" (clicked)="updateReservation()" [text]="'AMENDMENT_SAVE_CHANGES' | resource"></sbw-button>
      <sbw-button *ngIf="displayOutstandingAmountBtn()" id="outstanding-payment-button" type="preferred" (clicked)="payRemainingAmount()" [(state)]="buttonStatePayNow" [text]="'AMENDMENT_OUTSTANDING_PAYMENT' | resource"></sbw-button>
    </div>
</div>
