import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { StepService } from 'app/shared/steps/step.service'

@Injectable()
export class FlowGuard implements CanActivate {

    constructor(private stepService: StepService) {}

    canActivate(): boolean {
        const isFLowActivated = this.stepService.isFlowActivated()
        if (!isFLowActivated) { 
            const href = window.location.href
            if (href.includes('booking')) window.location.href = href.split('booking')[0] 
            else window.location.href = '/'
        }
        return isFLowActivated
    }
}
