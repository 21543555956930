import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StatelessHttpService } from "app/shared/stateless-http.service";
import { ResourceService } from "app/shared/resources/resource.service";
import { toParamsObj } from "app/shared/utils";

@Injectable()
export class AgentSearchService {
    constructor(
        private statelessHttpService: StatelessHttpService,
        private resourceService: ResourceService
    ) { }

    getBookings(searchFilter: string, dateFilter: string, from: string, to: string, route: string, criteria: string): Observable<any> {
        const agentGenericId = localStorage.getItem("sbw_AgentGenericId");

        let params = {
            locale: this.resourceService.getLocale(),
            agentGenericId: agentGenericId,
            from: from,
            to: to,
            searchFilter: searchFilter,
            dateFilter: dateFilter,
            selectedRoute: route,
            criteria: criteria
        };

        return this.statelessHttpService.get<any>(true, `agent/search/bookings`, {
            params: toParamsObj(params),
        })
    }
}

"http://localhost:9010/sbwapi/booking/agent/search/bookings?locale=en&agentGenericId=14f9f84b-49b9-482d-a1b6-63af51b8b5b1&searchFilter=reservationNumber&dateFilter=BookingDate&to=2020-09-11&from=2020-09-01"
