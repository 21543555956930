export interface ITracking {
    marketingHost: string
    price: number
    depositAmount: number
    creditCardFee: number
    products: IWireProduct[]
}

export interface IWireProduct {
    id: string
    name: string
    price: number
    dimensions?: IWireDimensions
    quantity?: number
}

export interface IWireDimensions {
    d1: string
    d2: string
    d3: string
    d4: string
}

export enum TrackingFlowStep {
    TRAVEL = 1,
    FARES = 2, // also cabin fares
    MEALS = 3,
    TPA = 4,
    PASSENGERS = 6,
    CABIN = 7,
    CONFIRMATION = 99
}

export enum TrackingAmendmentStep {
    TPA,
    VECHILES,
    PASSENGERS,
    MEALS,
    CABINS,
    ACTIVE,
    INACTIVE,
    BOOKINGS,
    OUTSTANDINGCONFIRMATION,
    AMENDMENTCONFIRMATION,
    DEPOSIT,
    PAYMENT,
    CABINALLOCATION,
    CHANGEDATES
}


export interface AmendmentTrackingReservation {
    departures: { departureDateTime: any }[]
    productCode: string
    locale: string
    routeCode: string
}
