import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { FormControl } from '@angular/forms'
import { CodeStatus } from '../travel.interfaces'
import { ResourceService } from 'app/shared/resources/resource.service'

@Component({
    selector: 'sbw-travel-code',
    templateUrl: './travel-code.component.html',
    styleUrls: ['./travel-code.component.css']
})
export class TravelCodeComponent implements OnChanges, OnInit {
    public codeFieldValue = null
    public codeValue = null

    @Input() heading: string
    @Input() control: FormControl
    @Input() codeStatus: CodeStatus = 'CodeNotSpecified';

      constructor(
        private resourceService: ResourceService
    ) { }
  
    ngOnInit(): void {
      this.codeValue = this.control.value
      this.codeFieldValue = this.control.value
      this.control.valueChanges.subscribe(value => {
        this.codeFieldValue = this.control.value
        this.codeValue = value
      })
    }

    assistiveText: string | undefined
    errorText: string | undefined
    value: string | undefined

    getResourceKey = (): string => {
        switch (this.codeStatus) {
            case this.IS_VALID_OFFERCODE:
                return 'OFFER_CODE_CORRECT'
            case this.IS_INVALID_OFFERCODE:
                return 'VALIDATION_OFFER_CODE_WRONG'
            case this.IS_VALID_PRODUCTCODE:
                return 'OFFER_CODE_CORRECT'
            case this.ROUTES_AND_PRODUCT_DOES_NOT_MATCH:
                return 'VALIDATION_PRODUCT_DOES_NOT_CONTAIN_ROUTE'
            default:
                return '';
        }
    }

    hasValidOfferOrProductCode = () => {
      if (this.codeStatus === 'IsValidOfferCode' || this.codeStatus === 'IsValidProductCode') return true
    }

    verifyCode = () => {
      this.control.setValue(this.codeFieldValue)
    }

    removeCode = () => {
      this.control.setValue(null)
      this.codeFieldValue = null
    }

    ngOnChanges(changes: SimpleChanges): void {
      this.resourceService.get(this.getResourceKey(), false).subscribe(s => {
        this.assistiveText = this.codeStatus === this.IS_VALID_OFFERCODE || this.codeStatus === this.IS_VALID_PRODUCTCODE ? s : ''
        this.errorText = this.codeStatus === this.IS_INVALID_OFFERCODE || this.codeStatus === this.ROUTES_AND_PRODUCT_DOES_NOT_MATCH ? s : ''
      })
    }

    private IS_VALID_OFFERCODE: string = 'IsValidOfferCode'
    private IS_VALID_PRODUCTCODE: string = 'IsValidProductCode'
    private IS_INVALID_OFFERCODE: string = 'IsInvalidOfferCode'
    private ROUTES_AND_PRODUCT_DOES_NOT_MATCH: string = 'RoutesAndProductDoesNotMatch'
}
