<sbw-layout [backButtonVisible]="true" [continueButtonAlwaysEnabled]="true" useValidation [quoteModel]="quoteData" [quoteShowTotalPrice]="true"
    [quoteShowDepartureTime]="true" quoteStep="Passengers" [form]="passengerForm" (validationEvent)="validate()" (continueEvent)="continue()"
    (backEvent)="navigateBack()">
    <div class="sbw_pagebox">
        <sbw-price-changed-popup *ngIf="priceChangedPopupModel" [model]="priceChangedPopupModel" />
        <h1>{{ 'PASSENGERS_TITLE' | resource }}</h1>
        <div *ngIf="!loading" class="row uncollapse passengers-container">
            <form [formGroup]="passengerForm">
                <sbw-validation-summary [errorList]="errorList"></sbw-validation-summary>

                <sbw-display-user-details *ngIf="inputEditUser && !isInEditMode" (changeUser)="isInEditMode = true"
                    [model]="inputEditUser"></sbw-display-user-details>

                <sbw-edit-user-details *ngIf="inputEditUser" [model]="inputEditUser" [mode]="'Flow'"
                    [(isInEditMode)]="isInEditMode" [validationError]="shouldShowErrors" (editDetailsEvent)="editDetailsEventHandler($event)">
                </sbw-edit-user-details>

                <sbw-agent-details *ngIf="inputAgentDetails" [inputModel]="inputAgentDetails"></sbw-agent-details>

                <div *ngIf="inputPassengers" class="row">
                    <h2 class="column middle">{{'PASSENGERS_PASSENGER_DETAILS' | resource:'Passengers'}}</h2>
                    <div class="half-gutter column"
                        [innerHtml]="'PASSENGERS_PASSENGER_DETAILS_NOTE' | resource:'Passengers'"></div>
                    <sbw-passengers [mode]="'Flow'" [validationError]="shouldShowErrors" [model]="inputPassengers" [editModel]="editModel"></sbw-passengers>
                </div>

                <div class="row gutter" *ngIf="vehicleDetails.enabled && vehicleWire">
                    <h2 class="column middle">{{'PASSENGERS_VEHICLE_DETAILS' | resource}}</h2>
                    <sbw-vehicles-details-rows mode="Flow" [validationError]="shouldShowErrors" [formControls]="vehicleDetails"
                        [vehicleName]="vehicleWire.vehicleText" [trailerName]="vehicleWire.trailerText">
                    </sbw-vehicles-details-rows>
                </div>

                <div class="gutter">
                    <sbw-additional-payments *ngIf="inputAdditionalPayments" [mandatoryCampaign]="mandatoryCampaign"
                        [mode]="'Flow'" [model]="inputAdditionalPayments"
                        (campaignCodeRedeemed)="campaignCodeRedeemed($event)"
                        (additionalPaymentSelected)="updateQuoteWithAdditionalPayment($event)">
                    </sbw-additional-payments>
                </div>

                <div class="half-gutter">
                    <sbw-credit-cards *ngIf="inputCreditCard && useCreditCards" [validationError]="shouldShowErrors" [model]="inputCreditCard">
                    </sbw-credit-cards>
                </div>

                <div *ngIf="!loading" class="requirements">
                    <h2>{{'PASSENGERS_SPECIAL_INSTRUCTIONS' | resource:'Passengers'}}</h2>
                    <div class="row">
                        <div class="column small-12 field">
                            <p>{{ 'PASSENGERS_SPECIAL_INSTRUCTIONS_NOTE' | resource }}</p>
                        </div>
                        <div class="column small-12 field">
                            <input type="radio" [checked]="!specialInstructionsSelected" name="specialInstructions"
                                value="none" (click)="specialInstructionsNoneDisabledClicked()"
                                id="specialInstructionsNone">
                            <label for="specialInstructionsNone" class="half-gutter">{{'PASSENGERS_SPECIAL_INSTRUCTIONS_NONE'
                                | resource:'Passengers'}}</label>
                            <input type="radio" [checked]="specialInstructionsSelected" name="specialInstructions"
                                value="disabled" id="specialInstructionsDisabled"
                                (click)="specialInstructionsDisabledClicked()">
                            <label for="specialInstructionsDisabled" class="half-gutter">{{'PASSENGERS_SPECIAL_INSTRUCTIONS_DISABLED'
                                | resource:'Passengers'}}</label>
                            <label class="half-gutter"><img
                                    src='/_ng/booking/assets/speciel-requirement-wheelchair.svg'></label>
                            <label class="half-gutter"><img
                                    src='/_ng/booking/assets/speciel-requirement-handicap-man.svg'></label>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="column small-12">
                        <div class="seperator"></div>
                    </div>
                </div>

                <sbw-terms-and-conditions *ngIf="inputTermsAndCondition" mode="Flow" [validationError]="shouldShowErrors" [model]="inputTermsAndCondition">
                </sbw-terms-and-conditions>
            </form>
        </div>
    </div>
</sbw-layout>
