import { Component } from '@angular/core'
@Component({
    selector: "sbw-spinner",
    template: `<div>
                 <div class="spinner-container">
                   <img class="spinner" src="/_ng/booking/assets/loading.svg" />
                 </div>
               </div>`,
    styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent {}