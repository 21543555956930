import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'

import { Observable, of, empty } from 'rxjs'

import { SbEvent } from 'app/shared/sb-event.emitter'
import { QuoteChanged, PriceSpecificationType, UserQuoteDetails, QuoteLine } from 'app/shared/quote/quote.events'
import { LayoutState } from 'app/shared/layout/layout-state'
import { IFlowReservation } from 'app/shared/steps/step.model'
import { ResourceService } from 'app/shared/resources/resource.service'
import { StepService } from 'app/shared/steps/step.service'
import { CabinFaresService } from '../cabin-fares.service'
import { TrackingService } from 'app/shared/tracking/tracking.service'
import { TrackingFlowStep } from 'app/shared/tracking/tracking-wire.interfaces'
import { LocalDate, ChronoUnit } from 'js-joda'
import {
  ICabinFares,
  ILegSelections,
  ICabinSelections,
  ILegSelection,
  getSelectedDeparture,
  getMiniCruiseReturnDep,
  flatSelections,
  ILegDepartureSelections,
  expandedSelections,
} from '../cabin-fares.interfaces'
import { EnvironmentService } from 'app/environment.service'
import { Title } from '@angular/platform-browser'
import { HybridService } from 'app/shared/hybrid/hybrid.service'
import { QuoteService } from 'app/shared/quote/quote.service'
import { flatMap, entries, toObj, distinctUntilChangedDeep } from 'app/shared/utils'
import { ICabinGroup, IInputCabinFaresGroup } from '../cabin-fares-group/cabin-fares-group.component'
import { IInputCabinFaresSelector } from '../cabin-fares-selector/cabin-fares-selector.component'
import { map, debounceTime, skip } from 'rxjs/operators'
import { LoggingService } from 'app/shared/logging.service'
import { ContentfulDfdsService } from 'app/shared/resources/contentful-dfds.service'
import { StorageService } from 'app/shared/storage.service'
import { Router } from '@angular/router'

@Component({
  selector: 'sbw-flow-cabin-fares',
  templateUrl: './flow-cabin-fares.component.html',
  styleUrls: ['./flow-cabin-fares.component.css'],
})
export class FlowCabinFaresComponent implements OnInit {
  public popup: PopupCanFitInLessCabins = null
  public reservation: IFlowReservation
  public cabinFaresForm: FormGroup
  public quoteData: Observable<QuoteChanged> = empty()
  public cabinFaresModel: ICabinFares
  public flowCabinFaresContent?: any
  public flowCabinFaresContentfulEntry?: any
  public contentfulEditMode: boolean
  public errorList: any[]
  public initialDateDiff: number
  public datePickerInfoLabel: string
  public refreshNeededModal = false

  get outboundDepartureDateControl(): FormControl {
    return this.cabinFaresForm.get('out.departureDate') as FormControl
  }
  get returnDepartureDateControl(): FormControl {
    return this.cabinFaresForm.get('return.departureDate') as FormControl
  }
  get outboundFormGroup(): FormGroup {
    return this.cabinFaresForm.get('out') as FormGroup
  }
  get returnFormGroup(): FormGroup {
    return this.cabinFaresForm.get('return') as FormGroup
  }

  constructor(
    private sbEvent: SbEvent,
    private layoutState: LayoutState,
    private resourceService: ResourceService,
    private stepService: StepService,
    private cabinFaresService: CabinFaresService,
    private contentfulDfdsService: ContentfulDfdsService,
    private trackingService: TrackingService,
    private environmentService: EnvironmentService,
    private title: Title,
    private hybridService: HybridService,
    private quoteService: QuoteService,
    private loggingService: LoggingService,
    private storage: StorageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.environmentService.contentfulEditModeChanged$.subscribe((enabled) => {
      this.contentfulEditMode = enabled
    })
    this.cabinFaresForm = new FormGroup(
      {
        out: new FormGroup({
          departureDate: new FormControl(),
        }),
        return: new FormGroup({
          departureDate: new FormControl(),
        }),
      },
      (a) => (getSelectedDeparture(a.value, this.cabinFaresModel) ? null : { noSelectedDeparture: true })
    )
    let locale = this.resourceService.getLocale()
    this.reservation = this.stepService.combineToReservation()
    this.cabinFaresForm.valueChanges
      .pipe(debounceTime(2))
      .pipe(map((p) => getSelectedDeparture(p, this.cabinFaresModel)))
      .pipe(distinctUntilChangedDeep())
      .subscribe((s) => this.updateBackendQuote(s))

    this.cabinFaresService.initialize()

    this.initialDateDiff =
      this.reservation.returnDepartureDate &&
      this.reservation.outboundDepartureDate.until(this.reservation.returnDepartureDate, ChronoUnit.DAYS)
    this.resourceService
      .loadResourcesPromise(this.reservation.outboundRouteCode, locale, ['Common', 'Account', 'Quote', 'Currency', 'Cabin'])
      .then(() => {
        this.loadData(
          this.reservation.outboundDepartureDate.toLocalDate(),
          this.reservation.returnDepartureDate && this.reservation.returnDepartureDate.toLocalDate(),
          true
        )
        this.setOfferTextInDatePicker()
        this.trackingService.trackFlow(TrackingFlowStep.FARES, 'Select your cabin', this.reservation, true)
        this.quoteService.quoteErrors.subscribe((_) => {
          this.refreshNeededModal = true
          this.loggingService.logWarn({ message: 'Quote failed and user was asked to reload' })
        })
      })
  }

  setOfferTextInDatePicker() {
    let miniBookId = this.storage.getItem('sbw_MiniBookId')
    if (miniBookId) {
      this.contentfulDfdsService.getMinibookById(miniBookId).then((miniBookEntry) => {
        this.datePickerInfoLabel = miniBookEntry && miniBookEntry.fields.offerCodeInfoText
      })
    }
  }

  updateInitialForm() {
    let rDate = this.reservation.returnDepartureDate
    if (rDate) {
      this.cabinFaresForm.patchValue({
        out: { departureDate: this.cabinFaresModel.outbound.departureDate },
        return: { departureDate: this.cabinFaresModel.return.departureDate },
      })
    }
    if (!rDate) {
      this.cabinFaresForm.patchValue({ out: { departureDate: this.cabinFaresModel.outbound.departureDate } })
    }

    this.cabinFaresForm.valueChanges
      .pipe(
        map((val) => ({
          outbound: val.out.departureDate,
          return: val.return ? this.checkReturnDate(val.out.departureDate, val.return.departureDate) : null,
        }))
      )
      .pipe(distinctUntilChangedDeep())
      .pipe(skip(1))
      .subscribe((s) => {
        this.loadData(s.outbound, s.return, false)
      })
  }

  updateForm() {
    let rDate = this.reservation.returnDepartureDate
    if (rDate) {
      this.cabinFaresForm.patchValue({
        out: { departureDate: this.cabinFaresModel.outbound.departureDate },
        return: { departureDate: this.cabinFaresModel.return.departureDate },
      })
    }
    if (!rDate) {
      this.cabinFaresForm.patchValue({ out: { departureDate: this.cabinFaresModel.outbound.departureDate } })
    }
  }

  checkReturnDate(departureDate: LocalDate, returnDate: LocalDate): LocalDate {
    if (this.cabinFaresModel.outbound.isMiniCruise && departureDate) {
      return departureDate.plusDays(1)
    }
    if (departureDate && departureDate >= returnDate) {
      if (
        this.cabinFaresModel.return.dates &&
        departureDate.until(this.cabinFaresModel.return.dates.max, ChronoUnit.DAYS) < this.initialDateDiff
      )
        this.initialDateDiff = departureDate.until(this.cabinFaresModel.return.dates.max, ChronoUnit.DAYS)
      return departureDate.plusDays(this.initialDateDiff) // Note this gives problems when initialDateDiff is big, and user picks dates close to maxDate
    }
    return returnDate
  }

  updateCabinFaresModel(cabinFaresModel: ICabinFares) {
    if (!this.cabinFaresModel) {
      this.cabinFaresModel = cabinFaresModel
    } else {
      // only update the directions that has changed, to avoid layout flickering
      if (!this.cabinFaresModel.outbound.departureDate.equals(cabinFaresModel.outbound.departureDate))
        this.cabinFaresModel.outbound = cabinFaresModel.outbound

      // We need to update return since valid dates changes when outbound is updated
      this.cabinFaresModel.return = cabinFaresModel.return
    }
  }

  loadData(outboundDepartureDate: LocalDate, returnDepartureDate: LocalDate, initialLoad: boolean) {
    this.layoutState.setIsLoading(true)
    this.cabinFaresService.getCabinFaresModel(outboundDepartureDate, returnDepartureDate).subscribe((cabinFaresModel: ICabinFares) => {
      this.flowCabinFaresContent = cabinFaresModel.flowCabinFaresContent
      this.flowCabinFaresContentfulEntry = cabinFaresModel.flowCabinFaresContentfulEntry
      this.updateCabinFaresModel(cabinFaresModel)
      if (!returnDepartureDate) this.cabinFaresForm.get('return').disable()
      if (cabinFaresModel.outbound.departureDate)
        this.reservation.outboundDepartureDate = cabinFaresModel.outbound.departureDate.atStartOfDay()
      if (cabinFaresModel.return && cabinFaresModel.return.departureDate)
        this.reservation.returnDepartureDate = cabinFaresModel.return.departureDate.atStartOfDay()
      this.title.setTitle(cabinFaresModel.flowCabinFaresContent.title)

      this.updateForm()

      if (initialLoad) {
        this.updateInitialForm()
      }

      this.logIfNoCapacityOnFirstLoad(initialLoad)
      this.layoutState.setIsLoading(false)
      this.layoutState.setIsContentLoaded(true)
    })
  }

  logIfNoCapacityOnFirstLoad(initialLoad: boolean) {
    let outboundDeparturesWithCabins = this.cabinFaresModel.outbound.departures.filter((d) => d.cabinGroups.length > 0)
    let returnDeparturesWithCabins =
      this.cabinFaresModel.return &&
      this.cabinFaresModel.return.departures &&
      this.cabinFaresModel.return.departures.filter((d) => d.cabinGroups.length > 0)

    let hasNoOutboundCapacity = !outboundDeparturesWithCabins.length
    let hasNoReturnCapacity =
      !this.cabinFaresModel.outbound.isMiniCruise && returnDeparturesWithCabins && !returnDeparturesWithCabins.length

    if (hasNoOutboundCapacity || hasNoReturnCapacity) {
      this.loggingService.logWarn({
        message: 'No capacity on the selected date for {Offer} {Product} {Initial}',
        params: [this.reservation.offerCode, this.reservation.productCode, initialLoad],
      })
    }
  }

  public continue() {
    let model: ILegDepartureSelections = this.cabinFaresForm.value
    let out = expandedSelections(flatSelections(model.out.cabins, this.cabinFaresModel.outbound.departures))
    let ret = this.cabinFaresModel.return && expandedSelections(flatSelections(model.return.cabins, this.cabinFaresModel.return.departures))
    let popupLessCabinsOut = new PopupCanFitInLessCabins(
      this.flowCabinFaresContent,
      this.reservation.adults,
      this.reservation.children,
      out
    )
    let popupLessCabinsRet =
      ret && new PopupCanFitInLessCabins(this.flowCabinFaresContent, this.reservation.adults, this.reservation.children, ret)
    if (popupLessCabinsOut.shouldShowPopup()) this.popup = popupLessCabinsOut
    else if (popupLessCabinsRet && popupLessCabinsRet.shouldShowPopup()) this.popup = popupLessCabinsRet
    else this.postChoices()
  }

  public cancelPopup() {
    this.popup = null
  }

  public closePopupAndPost() {
    this.popup = null
    this.postChoices()
  }

  postChoices() {
    this.layoutState.setIsContinueLoading(true)

    this.cabinFaresService.postHybridCabinFares(getSelectedDeparture(this.cabinFaresForm.value, this.cabinFaresModel)).subscribe((s) => {
      if (s.nextStepUrl) {
        this.stepService.saveStepData(s.step, '/cabinfares', true)
        this.quoteService.saveQuoteInSessionStorage(s.quote)
        this.hybridService.changeWindowLocation(s.nextStepUrl)
        this.layoutState.setIsContinueLoading(false)
      } else {
        this.errorList = s.errorList.map((e) => e.message)
        this.layoutState.setIsContinueLoading(false)
      }
    })
  }

  public navigateBack() {
    this.sbEvent.historyGoBack.next()
  }

  updateBackendQuote(selections: ILegSelections) {
    if (!selections || !selections.out || !selections.out.cabins) return this.quoteService.getQuoteData('Cabinfares')
    let buildLeg = (leg: ILegSelection, prefix: string) =>
      toObj(
        entries(leg.cabins).filter((v) => v.value),
        (k) => prefix + k.key,
        (v) => v.value
      )
    let cabinout = buildLeg(selections.out, 'cabinout.')
    let cabinret = selections.return && buildLeg(selections.return, 'cabinret.')

    let retDepartureId

    if (this.cabinFaresModel.outbound.isMiniCruise) {
      retDepartureId = getMiniCruiseReturnDep(selections.out, this.cabinFaresModel, this.loggingService)
      if (!retDepartureId) return this.quoteService.getQuoteData('Cabinfares')
    } else retDepartureId = this.cabinFaresModel.return && selections.return && selections.return.departure

    this.quoteService.getQuotePreview(selections.out.departure, retDepartureId, cabinout, cabinret)
  }

  createQuoteLines(groups: ICabinGroup[], selection: ICabinSelections, findPrice: (s: IInputCabinFaresSelector) => number): QuoteLine[] {
    return (
      selection &&
      flatMap(groups, (g) => g.inputCabinFaresSelectors)
        .map((c) => new QuoteLine(c.description, findPrice(c), this.reservation.currencyCode, +selection[c.cabinTypeCode]))
        .filter((l) => l.count)
    )
  }

  createQuoteData(outbound: QuoteLine[], ret: QuoteLine[], selections: ILegSelections): Observable<QuoteChanged> {
    let getDepartureTime = (sel: ILegSelection, leg: IInputCabinFaresGroup) => {
      let d = leg.departures.find((d) => d.departureId == sel.departure)
      return d && d.departureTime.toString()
    }
    let outLeg = new UserQuoteDetails(
      outbound,
      null,
      this.reservation.outboundRouteName,
      getDepartureTime(selections.out, this.cabinFaresModel.outbound)
    )
    let returnLeg =
      ret &&
      new UserQuoteDetails(ret, null, this.reservation.returnRouteName, getDepartureTime(selections.return, this.cabinFaresModel.return))
    return of(new QuoteChanged(PriceSpecificationType.Cabins, outLeg, returnLeg, null))
  }

  reload() {
    this.router.navigateByUrl('/doesnotexist', { skipLocationChange: true }).then(() => this.router.navigate(['cabin-fares']))
  }
}

export interface IFlowCabinFaresContent {
  moreThanOneCabinSelectedPopupTitle: string
  moreThanOneCabinSelectedPopupContent: string
  moreThanOneCabinSelectedPopupStayButton: string
  moreThanOneCabinSelectedPopupContinueButton: string
}

export class PopupCanFitInLessCabins {
  totalPassengers: number
  constructor(private content: IFlowCabinFaresContent, adults: number, children: number, private selectedCabins: number[]) {
    this.totalPassengers = adults + children
  }

  shouldShowPopup(): boolean {
    let sumWithoutSmallest = this.selectedCabins
      .sort()
      .filter((_, i) => i)
      .reduce((a, b) => a + b, 0)
    return sumWithoutSmallest >= this.totalPassengers
  }

  get title(): string {
    return (
      this.content.moreThanOneCabinSelectedPopupTitle &&
      this.content.moreThanOneCabinSelectedPopupTitle.replace('{numberOfCabins}', '' + this.selectedCabins.length)
    )
  }
  get contentText(): string {
    return this.content.moreThanOneCabinSelectedPopupContent
  }
  get stayButtonText(): string {
    return this.content.moreThanOneCabinSelectedPopupStayButton
  }
  get continueButtonText(): string {
    return this.content.moreThanOneCabinSelectedPopupContinueButton
  }
}
