<div (click)="showDialog()" class="information-popup-container">
  <img class="information-popup-icon" src="/_ng/booking/assets/information.svg" />
  <span class="information-popup-text" *ngIf="label">{{label}}</span>
</div>
<p-dialog *ngIf="!text" [header]="heading" (visibleChange)="popupClosed()" [dismissableMask]="true" [(visible)]="visible" [modal]="true" [breakpoints]="{ '767px': '375px' }" [style]="{ width: '500px' }" [draggable]="false" [resizable]="false">
    <div class="dialog-content">
      <ng-content></ng-content>
    </div>
</p-dialog>

<p-dialog *ngIf="text" [header]="heading" (visibleChange)="popupClosed()" [dismissableMask]="true" [(visible)]="visible" [modal]="true" [breakpoints]="{ '767px': '375px' }" [style]="{ width: '500px' }" [draggable]="false" [resizable]="false">
    <div [innerHTML]="text"></div>
</p-dialog>