<div *ngIf="childrenAgeArray" class="row">
    <div class="column small-12 medium-6 large-6 xlarge-3">
        <div class="row">
            <div class="column small-12">
                <sbw-select-field name="passengerCount_adult" [control]="adultCount" label="{{'ADULTS' | resource:'Travel'}}" [items]="adultRange">
                </sbw-select-field>
            </div>
        </div>
    </div>
    <div class="column small-12 medium-6 large-6 xlarge-3">
        <div class="row">
            <div class="column small-12">
                <sbw-select-field name="passengerCount_child" [control]="childCount" label="{{'CHILDREN015' | resource:'Travel'}}"
                    [items]="childRange"></sbw-select-field>
            </div>
        </div>
    </div>
    <!-- If no kids we can take full width in large -->
    <div class="column hide-for-small-only hide-for-xlarge-up end"
        [ngClass]="childCount.value == 0 ? 'large-12 large-6' : 'large-6 medium-6' ">
        <sbw-travel-pets name="passengerCount_pet" [fullWidth]="childCount.value == 0" [petCount]="petCount" [petRange]="petRange"
            [showPets]="showPets"></sbw-travel-pets>
    </div>
    <div *ngIf="childrenSelected.length > 0" class="column small-12 medium-6 large-6 xlarge-6 ">
        <form [formGroup]="childrenAgeArray" novalidate>
            <div class="row">
                <div *ngFor="let _ of childrenSelected; index as i" class="column small-6 medium-4 large-4 end" [ngClass]="{'call-to-action': callAttentionChildAges(i) }">
                    <sbw-select-field [id]="'childage_' + i" [name]="childage_i" [control]="childrenAgeArray.controls[i]" [label]="getLabel(i) | async"
                        [items]="childAgeRange"></sbw-select-field>
                </div>
                <!-- make a conditional xlarge st. INFANTS_EXPLAIN is full with when in can not be fit in after the only child -->
                <div *ngIf="hasInfants" class="column small-12 end"
                    [ngClass]="childCount.value == 1 ? 'xlarge-8' : 'xlarge-12' ">
                    {{'INFANTS_EXPLAIN' | resource}}
                </div>
            </div>
        </form>
    </div>
    <div class="column small-12 xlarge-6 hide-for-medium-only hide-for-large-only end">
        <sbw-travel-pets name="passengerCount_pet" [petCount]="petCount" [petRange]="petRange" [showPets]="showPets"></sbw-travel-pets>
    </div>
</div>
