<sbw-layout
    [type]="'Amendment'"
    [backButtonVisible]="backButtonVisible"
    (backEvent)="goBack()"
    [continueButtonVisible]="false"
    [footerVisible]="false"
    [menuVisible]="false"
    >
    <div class="sbw_pagebox">
        <div class="row uncollapse">
            <h1>{{'GLOBALERROR_HEADER' | resource}}</h1>
            <p class="correlationId" *ngIf="correlationId">{{correlationId}}</p>
            <p [innerHTML]="'GLOBALERROR_BODY' | resource"></p>
        </div>
    </div>
</sbw-layout>
