import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { format, IFlowOrAmendment, ensureFlowOrAmendment, FlowOrAmendment, toParamsObj } from 'app/shared/utils'
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms'
import { ResourceService } from 'app/shared/resources/resource.service'
import { ResourceDateformatPipe } from 'app/shared/resources/resourcedateformat.pipe'
import { zip } from 'rxjs'
import { FormGroup } from '@angular/forms'
import { IDepositPayment, ICampaignCodeValidationResult } from 'app/passengers/passengers.interfaces'
import { FormBuilderTyped } from 'app/shared/form-builder-typed';
import { StatelessHttpService } from 'app/shared/stateless-http.service'

@Component({
    selector: 'sbw-additional-payments',
    templateUrl: './additional-payments.component.html',
    styleUrls: ['./additional-payments.component.css']
})
export class AdditionalPaymentsComponent implements OnInit, IFlowOrAmendment {
    @Input() mode: FlowOrAmendment
    @Input() model: IInputAdditionalPayments
    @Input() mandatoryCampaign: boolean
    @Output() campaignCodeRedeemed = new EventEmitter<number>()
    @Output() additionalPaymentSelected = new EventEmitter<boolean>()

    private resourceDateformatPipe: ResourceDateformatPipe

    internalForm = null
    private form = null
    showDeposit = false
    depositInstructions: string
    fullPaymentInstructions: string

    get campaignCode(): FormControl {
        return this.internalForm.get('campaignCode')
    }

    constructor(
        private resourceService: ResourceService,
        private statelessHttpService: StatelessHttpService,
        private fb: FormBuilderTyped
    ) { }

    ngOnInit() {
        ensureFlowOrAmendment(this.mode)
        this.resourceDateformatPipe = new ResourceDateformatPipe(this.resourceService)
        if (this.mode === 'Flow') {
            this.useFlow()
        } else if (this.mode === 'Amendment') {
            this.useAmendment()
        }
    }
    useFlow() {
        const { depositPayment, campaignCode, productCode, payment } = this.model
        this.showDeposit = !!depositPayment
        this.internalForm = this.fb.group<IAdditionalPayments>({
            'payment': this.fb.control(payment),
            'campaignCode': new FormControl(campaignCode, {
                updateOn: 'blur',
                asyncValidators: (c: AbstractControl): Promise<ValidationErrors | null> => {
                    return this.validatorValidateCampaignCode(c.value, productCode)
                }
            })
        })
        this.form = this.fb.group<IAdditionalPayments>({
            'payment': this.fb.control(payment),
            'campaignCode': this.fb.control(campaignCode, this.mandatoryCampaign ? Validators.required : null)
        })

        this.model.form.addControl('additionalPayments', this.form)
        if (this.showDeposit) {
            const { amountToPay, remainingAmount, currencyCode, paymentDueDate } = depositPayment
            this.additionalPaymentSelected.emit(true)
            zip(
                this.resourceService.get('PASSENGERS_DEPOSIT_AMOUNT_PAYMENT_CHOICE'),
                this.resourceService.get('PASSENGERS_FULL_AMOUNT_PAYMENT_CHOICE'),
                this.resourceService.get('CURRENCY_FORMAT'),
                this.resourceService.get(currencyCode)
            ).subscribe(response => {
                const [depositText, fullPaymentText, currencyFormat, currencySymbol] = response
                const locale = this.resourceService.getLocale()
                const _amountToPay = currencySymbol + format(currencyFormat, amountToPay.toString(), locale)
                const _remainingAmount = currencySymbol + format(currencyFormat, remainingAmount.toString(), locale)
                const _dueDate = this.resourceDateformatPipe.transform(paymentDueDate, 'MOMENT_SHORT_DATE')
                this.depositInstructions = depositText.replace('{0}', _amountToPay).replace('{1}', _remainingAmount).replace('{2}', _dueDate)
                const _fullAmount = currencySymbol + format(currencyFormat, (amountToPay + remainingAmount).toString(), locale)
                this.fullPaymentInstructions = fullPaymentText.replace('{0}', _fullAmount)
            })
        }

        this.campaignCode.statusChanges.subscribe(x => {
            if (x === 'VALID' && !this.mandatoryCampaign) { return this.form.get('campaignCode').setErrors(null) }
            if (x === 'VALID' && this.mandatoryCampaign && this.campaignCode.value != null && this.campaignCode.value.length > 0) {
                return this.form.get('campaignCode').setErrors(null)
            }
            if (x === 'INVALID') { return this.form.get('campaignCode').setErrors({ campaignCode: true }) }
            if (this.mandatoryCampaign) { return this.form.get('campaignCode').setErrors({ campaignCode: true }) }
        })
        this.campaignCode.valueChanges.subscribe(x => this.form.get('campaignCode').patchValue(x))
        
        this.internalForm.get('payment').valueChanges.subscribe(x => {
            this.additionalPaymentSelected.emit(x === 'deposit-payment')
            this.form.get('payment').patchValue(x)
        })

    }
    useAmendment() {
        throw new Error('Method not implemented.')
    }

    isDepositSeleted(): boolean {
        if (!this.showDeposit) { return false }
        return this.internalForm.get('payment').value === 'deposit-payment'
    }

    validatorValidateCampaignCode(campaignCode: string, productCode: string): Promise<ValidationErrors | null> {
        return new Promise((res, rej) => {
            if (!campaignCode) {
                return res(null)
            }
            this.resourceService.getSalesOwner()

            let params = {
                'productCode': productCode,
                'salesOwner': this.resourceService.getSalesOwner()
            }

            const path = `campaigncode/${campaignCode.trim()}/validate`
            this.statelessHttpService.get<ICampaignCodeValidationResult>(true, path, { params: toParamsObj(params) }).subscribe((x) => {
                res(x.isValid ? null : { errorMessage: x.error })
                this.campaignCodeRedeemed.emit(x.isValid ? x.value : null)
            })
        })
    }

}

export interface IInputAdditionalPayments {
    form: FormGroup
    depositPayment: IDepositPayment
    payment: 'full-payment' | 'deposit-payment'
    productCode: string
    campaignCode: string
}

export interface IAdditionalPayments {
    campaignCode: string
    payment: 'full-payment' | 'deposit-payment'
}
