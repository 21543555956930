<sbw-layout
  [backButtonVisible]="true"
  [quoteModel]="quoteData"
  [quoteShowTotalPrice]="true"
  [quoteShowDepartureTime]="true"
  [form]="faresForm"
  [continueButtonDisabled]="productNotActive"
  [footerVisible]="!productNotActive"
  quoteStep="Fares"
  (continueEvent)="postChoices()"
  (backEvent)="navigateBack()"
>
  <div class="sbw_pagebox" *ngIf="resourceLoaded">
    <div *ngIf="productNotActive">
      <div class="row uncollapse fares-container">
        <div class="row">
          <div class="column small-12">
            <h1>{{ 'PRODUCT_NO_LONGER_ACTIVE_HEADLINE' | resource: 'Fares' }}</h1>
          </div>
          <div class="column small-12 gutter">
            {{ 'PRODUCT_NO_LONGER_ACTIVE' | resource: 'Fares' }}
          </div>
          <div class="column small-12 medium-4 medium-pull-8">
            <sbw-button id="SubmitBack" type="preferred" [link]="'/'" [text]="'FARES_RESTART_FLOW' | resource: 'Fares'"></sbw-button>
          </div>
        </div>
      </div>
    </div>
    <form [formGroup]="faresForm" *ngIf="!productNotActive">
      <div class="row uncollapse fares-container">
        <div class="row">
          <div class="column small-12 gutter">
            <div class="sbw_legend" [innerHTML]="'FARES_TEASER' | resource"></div>
          </div>
        </div>

        <div class="row">
          <div class="column small-12">
            <sbw-validation-summary [errorList]="errorList"></sbw-validation-summary>
            <div class="row">
              <div class="column small-12">
                <sbw-fares-selector
                  [content]="content"
                  *ngIf="data"
                  [data]="data"
                  [faresForm]="faresForm"
                  [currency]="currency"
                  [isLoading]="isLoading"
                  (fareChanged)="fareChangedHandler($event)"
                  [datePickerInfoLabel]="datePickerInfoLabel"
                  (prevNext)="prevNext($event)"
                  (departureDateChanged)="departureDateChangedHandler($event)"
                >
                </sbw-fares-selector>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</sbw-layout>
