
<div *ngIf="model && form" class="row uncollapse group-item cabin-item">
    <div class="column small-8 large-9 cabin-name">
        <span class="cabin-description">
            <sbw-information-popup [heading]="model.cabinDetailsFromContentful.headline" *ngIf="model.cabinDetailsFromContentful && model.cabinDetailsFromContentful.description">
                <sbw-cabin-details [cabinDetails]="model.cabinDetailsFromContentful"></sbw-cabin-details>
            </sbw-information-popup>
            {{model.description}}
        </span>
        <span class="number-of-berths icon-user">x{{model.numberOfOccupiableBerths}}</span>
    </div>
    <div class="column small-4 large-3 cabin-prices" [attr.data-e2e-berths-count]="model.numberOfOccupiableBerths">
        <label class="small-12 large-12 selected-column" [ngClass]="{'selected-cell': form.value > 0 }">
            <ng-container *ngIf="model.originalPrice != undefined">
                <span class="display-cabin-price suppressed superscript">{{model.currency |
                    resource}}{{model.originalPrice}}</span>&nbsp;
            </ng-container>
            <span class="display-cabin-price">{{model.currency | resource}}{{model.price}}</span>
            <select [formControl]="form">
                <option *ngFor="let cabinCount of numberOfAvailableCabins" [ngValue]="cabinCount">{{cabinCount}}</option>
            </select>
        </label>
    </div>
</div>
