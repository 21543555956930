import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { AmendmentPassengersComponent } from './amendment-passengers/amendment-passengers.component'
import { PassengerService } from 'app/passengers/passenger.service'
import { RouterModule, Routes } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'app/shared'
import { UIModule } from 'app/shared/UI.module'
import { FlowPassengersComponent } from './flow-passengers/flow-passengers.component'
import { StepService } from 'app/shared/steps/step.service'
import { PaymentModule } from 'app/payment/payment.module'
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component'
import { PassengersComponent } from 'app/passengers/passengers/passengers.component'
import { PassengerComponent } from 'app/passengers/passenger/passenger.component'
import { ValidationModel } from 'app/shared/models/validation.model'

import { EditUserDetailsComponent } from './edit-user-details/edit-user-details.component'
import { DisplayUserDetailsComponent } from './display-user-details/display-user-details.component'
import { FindAddressComponent } from './find-address/find-address.component'
import { AddressService } from 'app/passengers/find-address/address.service'
import { AdditionalPaymentsComponent } from './additional-payments/additional-payments.component'
import { VehiclesModule } from 'app/vehicles/vehicles.module'
import { AgentDetailsComponent } from './agent-details/agent-details.component'
import { AuthGuard } from 'app/shared/guards/auth.guard'
import { FlowGuard } from 'app/shared/guards/flow.guard'
import { PriceChangedPopupComponent } from './price-changed-popup/price-changed-popup.component'

const appRoutes: Routes = [
  { path: 'passengers', component: FlowPassengersComponent, canActivate: [AuthGuard, FlowGuard] },
  { path: 'amendment/booking/:reservationCode/passengers', component: AmendmentPassengersComponent, canActivate: [AuthGuard] },
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes),
    FormsModule,
    SharedModule,
    UIModule,
    PaymentModule,
    ReactiveFormsModule,
    VehiclesModule,
  ],
  declarations: [
    AmendmentPassengersComponent,
    FlowPassengersComponent,
    TermsAndConditionsComponent,
    PassengersComponent,
    PassengerComponent,
    EditUserDetailsComponent,
    DisplayUserDetailsComponent,
    FindAddressComponent,
    AdditionalPaymentsComponent,
    AgentDetailsComponent,
    PriceChangedPopupComponent,
  ],
  providers: [PassengerService, StepService, ValidationModel, AddressService],
})
export class PassengersModule {}
