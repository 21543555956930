import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContentfulInfoBlock } from './contentful-info-block';
import { InfoBlockBehaviour } from '../info-block-behaviour';

@Component({
    selector: 'sbw-contentful-info-block',
    templateUrl: './contentful-info-block.component.html',
    styleUrls: ['./contentful-info-block.component.css']
})

export class ContentfulInfoBlockComponent implements OnInit {

    @Input() InfoBlock: ContentfulInfoBlock
    @Input() InfoBlockBehaviour: InfoBlockBehaviour
    @Input() Expanded: boolean = false
    
    @Output() onToggleExpand = new EventEmitter();


    expanded: boolean
    expandable: boolean

    constructor() { }

    ngOnInit() {    
        if (this.InfoBlockBehaviour !== InfoBlockBehaviour.NoCollapse)       
        {            
            this.expandable = true                   
            this.expanded = this.Expanded              
        } else
        {
            this.expandable = false 
            this.expanded = true
        }
    }

    public collapse() {
        this.expanded = false
    }

    public toggleCollapseExpand() {        
        if (this.InfoBlockBehaviour != InfoBlockBehaviour.NoCollapse)
        {
            this.expanded = !this.expanded
            this.onToggleExpand.emit({
                'target': this,
                'expanded': this.expanded
            })
        }
    }
}

