import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable()
export class SbEvent {
  public breadcrumbLoaded = new Subject<void>()
  public historyGoBack = new Subject<void>()
  public escapePressed = new Subject<void>()
  public resourceSetChanging = new Subject<string>()
}
