<sbw-layout [type]="'Amendment'" [backButtonVisible]="false" (backEvent)="goBack()" [continueButtonVisible]="false" [footerVisible]="false"
    [menuVisible]="false">

    <div class="sbw_pagebox">
        <div class="row">
            <div class="column small-12">
                <h1>{{'NOTFOUND_HEADER' | resource:'Common'}}</h1>
            </div>
            <div class="column small-12">
                <p [innerHTML]="'NOTFOUND_BODY' | resource:'Common'"></p>
            </div>
            <div class="column small-12 medium-4 medium-push-8 large-3 large-push-9 field submit"></div>
            <div class="column small-12 medium-4 medium-pull-8 large-3 large-pull-9 field submit">
                <sbw-button id="SubmitBack" type="previous" [link]="'/'" [text]="'NOTFOUND_RESTART_FLOW' | resource:'Common'"></sbw-button>
            </div>
        </div>
    </div>
</sbw-layout>