import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'

import { FlowCabinFaresComponent } from 'app/cabin-fares/flow-cabin-fares/flow-cabin-fares.component'
import { SharedModule } from 'app/shared'
import { UIModule } from 'app/shared/UI.module'
import { CabinFaresGroupComponent } from './cabin-fares-group/cabin-fares-group.component'
import { CabinFaresSelectorComponent } from './cabin-fares-selector/cabin-fares-selector.component'
import { CabinFaresService } from './cabin-fares.service'
import { MarkdownModule, MarkdownService } from 'ngx-markdown'
import { CabinDetailsService } from 'app/cabins/cabin-details/cabin-details.service'
import { CabinFaresWireService } from './cabin-fares-wire.service'
import { CabinsModule } from 'app/cabins/cabins.module'
import { FlowGuard } from 'app/shared/guards/flow.guard'

const appRoutes: Routes = [{ path: 'cabin-fares', component: FlowCabinFaresComponent, canActivate: [FlowGuard] }]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes),
    SharedModule,
    UIModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    MarkdownModule,
    CabinsModule,
  ],
  declarations: [FlowCabinFaresComponent, CabinFaresGroupComponent, CabinFaresSelectorComponent],
  providers: [CabinFaresService, CabinFaresWireService, CabinDetailsService, MarkdownService],
})
export class CabinFaresModule {}
