<div class="datepicker" [ngClass]="styleBorder()">
    <p-calendar 
        (onSelect)="onSelect($event)" 
        [readonlyInput]="isReadonly" 
        [(ngModel)]="value" 
        [disabledDates]="viewModel.disabledDates"
        [minDate]="viewModel.minDate" 
        [maxDate]="viewModel.maxDate" 
        [showOtherMonths]="false" 
        (onFocus)="onFocus($event)" 
        (onBlur)="onBlur($event)" 
        [disabled]="disabled"
        appendTo="body"
        showIcon="true"
        [readonlyInput]="true"
        [defaultDate]="viewModel.defaultDate">
        <ng-template pTemplate="date" let-date>
            <span [ngClass]="{ 'highlight-date': mustHighlight(date) }">{{ date.day }}</span>
        </ng-template>
    </p-calendar>
</div>