<sbw-popup [showPopup]="content" [header]="content?.header" [closable]="false" >
    <p>
        {{beforePrice}}
        <strong>{{model.currencyCode | resource}}{{model.priceDiff() | resourceformat:'CURRENCY_FORMAT'}}</strong>
        {{afterPrice}}
    </p>
    <div class="last no-column-margin">
        <div class="column small-12 medium-6 field">
            <sbw-button [text]="content?.goBack" (clicked)="reject()"></sbw-button>
        </div>
        <div class="column small-12 medium-6 field">
            <sbw-button [text]="content?.acceptNewPrice" (clicked)="accept()" type="preferred"></sbw-button>
        </div>
    </div>
</sbw-popup>
<div class="is-loading active" *ngIf="!content"></div>
