import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class LayoutState {
    public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public isQuoteLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public isContentLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public isContinueLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    setIsLoading(value: boolean) {
        this.isLoading$.next(value)
    }

    setIsQuoteLoading(value: boolean) {
        this.isQuoteLoading$.next(value)
    }

    setIsContinueLoading(value: boolean) {
        this.isContinueLoading$.next(value)
    }

    // when content is loaded, by default put a delay to allow rendering
    // when content is unloading, put loading screen on at once
    setIsContentLoaded(value: boolean, delay?: number) {
        if (value) {
            if (delay === 0) this.isContentLoaded$.next(value)
            else setTimeout(() => this.isContentLoaded$.next(value), delay || 400)
        }
        else this.isContentLoaded$.next(value)
    }
}
