import { Component, NgZone, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { IAmendmentVehicleWire, IVehicleEditSelection, IVehicleType, IAvailableTrailer } from 'app/vehicles/vehicles.interfaces'
import { ResourceService } from 'app/shared/resources/resource.service'
import { VehicleService } from 'app/vehicles/vehicle.service'
import { LayoutState } from 'app/shared/layout/layout-state'
import { AmendService } from 'app/shared/reservation/amend.service'
import { SnapshotService } from 'app/shared/snapshot.service'
import { startWith, debounceTime } from 'rxjs/operators'
import { TrackingAmendmentStep } from 'app/shared/tracking/tracking-wire.interfaces'
import { VirtualPageViewTrackingService } from 'app/shared/tracking/virtual-page-view-tracking/virtual-page-view-tracking.service'
import { requiredNonWhiteSpaceValidator } from 'app/shared/utils'
import { AuthService } from 'app/auth/auth.service'
import { LocaleService } from 'app/shared/locale-service'
import { CookieService } from 'app/shared/cookie.service'

@Component({
  selector: 'sbw-amendment-vehicles',
  templateUrl: './amendment-vehicles.component.html',
  styleUrls: ['./amendment-vehicles.component.css'],
})
export class AmendmentVehiclesComponent implements OnInit {
  public amendmentsForm: FormGroup = new FormGroup({})
  public vehicles: IAmendmentVehicleWire
  public reservationCode: string
  public errors: string[] = []
  public isAgent: boolean
  public continueButtonDisabled = true
  private initialValue
  isAmendmentCompatible = true

  get selector(): FormGroup {
    return this.amendmentsForm.get('selector') as FormGroup
  }

  get details(): FormArray {
    return this.amendmentsForm.get('details') as FormArray
  }

  constructor(
    private route: ActivatedRoute,
    private resourceService: ResourceService,
    private vehicleService: VehicleService,
    private title: Title,
    private fb: FormBuilder,
    private router: Router,
    private layoutState: LayoutState,
    private amendService: AmendService,
    private snapshotService: SnapshotService,
    private trackingService: VirtualPageViewTrackingService,
    private authService: AuthService,
    private zone: NgZone,
    private localeService: LocaleService,
    private cookieService: CookieService
  ) {}

  async ngOnInit() {
    this.isAgent = !!localStorage.getItem('sbw_AgentGenericId')
    let authType = this.cookieService.getAuthType()

    if ((authType === 'agent' && this.isAgent) || this.authService.isLoggedIn()) {
      this.getData()
    } else {
      // Do something with azure ad
    }
  }

  private buildForm(snapshot: any): void {
    if (!this.vehicles.isAmendmentCompatible) {
      this.isAmendmentCompatible = false
      this.resourceService.get('AMENDMENT_VEHICLE_INCOMPATIBLE').subscribe((s) => (this.errors = [s]))
    }

    const { vehicle, trailer, count } = this.vehicles.selectedType
    this.amendmentsForm = this.fb.group({
      selector: this.fb.group({ vehicle, trailer, count }),
      details: this.fb.array(
        this.vehicles.selectedDetails
          .concat(new Array(9).fill({}))
          .splice(0, 9)
          .map((d) =>
            this.fb.group({
              vehicleRegistrationNumber: this.fb.control(d.vehicleRegistrationNumber, requiredNonWhiteSpaceValidator),
              trailerRegistrationNumber: this.fb.control(d.trailerRegistrationNumber, requiredNonWhiteSpaceValidator),
            })
          )
      ),
    })

    if (snapshot) this.amendmentsForm.patchValue(snapshot)

    const selector = this.amendmentsForm.get('selector')
    selector.valueChanges.pipe(startWith(selector.value)).subscribe((v: Selector) => {
      const p = parseInt(v.count)
      const { registrationNumberMandatory } = this.selectedVehicle()

      for (let c of this.details.controls.map((control, i) => ({ control, selected: i < p && registrationNumberMandatory }))) {
        if (c.selected) {
          c.control.enable()
          const trailer = c.control.get('trailerRegistrationNumber')
          if (this.selectedTrailer().code) trailer.enable()
          else trailer.disable()
        } else c.control.disable()
      }
    })

    this.amendmentsForm.valueChanges.pipe(debounceTime(1)).subscribe((v) => {
      const json = JSON.stringify(v)
      if (!this.initialValue) this.initialValue = json
      this.continueButtonDisabled = !this.vehicles.isAmendmentCompatible || json === this.initialValue
    })
  }

  selectedVehicle() {
    return (
      this.vehicles.vehicles.vehicleTypes.find((v) => v.code == this.amendmentsForm.get('selector.vehicle').value) || ({} as IVehicleType)
    )
  }

  selectedTrailer() {
    const t = this.selectedVehicle().availableTrailers
    return (t && t.find((t) => t.code == this.amendmentsForm.get('selector.trailer').value)) || ({} as IAvailableTrailer)
  }

  public continue() {
    this.layoutState.setIsContinueLoading(true)
    const dryRun = true

    const value = this.amendmentsForm.value as IVehiclesForm
    const count = parseInt(value.selector.count)
    const bikeFallback = new Array(count).fill({})
    let editWire: IVehicleEditSelection = {
      selections: (value.details || bikeFallback).map((v) => ({
        vehicleId: this.selectedVehicle().id,
        vehicleRegistrationNumber: v.vehicleRegistrationNumber,
        trailerId: this.selectedTrailer().id,
        trailerRegistrationNumber: v.trailerRegistrationNumber,
      })),
      isNoVehicleSelected: count === 0,
    }
    this.amendService.changeVehicles(this.reservationCode, editWire, dryRun).subscribe((response) => {
      this.layoutState.setIsContinueLoading(false)

      if (response.errorMsgs && response.errorMsgs.length) {
        this.errors = response.errorMsgs.map((m) => m.message)
        this.layoutState.setIsContentLoaded(true)
        return
      }
      this.snapshotService.setSnapshot(this.reservationCode, { vehicles: editWire, vehiclesForm: value })
      this.navigateBack()
    })
  }

  navigateBack() {
    this.router.navigate(['/amendment/booking', this.reservationCode])
  }

  private async getData() {
    const locale = this.localeService.getLocale()
    this.resourceService
      .loadResourcesPromise(
        'Default',
        locale,
        ['Common', 'Account', 'Amendment', 'Currency', 'Quote', 'Travel', 'Passengers'].concat(this.isAgent ? ['Agent', 'MenuAgent'] : [])
      )
      .then(async () => {
        this.reservationCode = this.route.snapshot.params['reservationCode']
        this.vehicles = await this.vehicleService.getVehiclesAmendment(this.reservationCode).toPromise()
        this.resourceService.get('TITLE_EDIT_VEHICLE_REGISTRATION', false).subscribe((s) => this.title.setTitle(s))
        let snapshot = this.snapshotService.getSnapshot(this.reservationCode)
        this.buildForm(snapshot && snapshot.vehiclesForm)
        this.trackingService.trackAmendment(TrackingAmendmentStep.VECHILES, this.vehicles)
        this.layoutState.setIsContentLoaded(true)
      })
  }
}

interface Selector {
  vehicle: string
  trailer: string
  count: string
}

interface Detail {
  vehicleRegistrationNumber: string
  trailerRegistrationNumber: string
}

interface IVehiclesForm {
  selector: Selector
  details: Detail[]
}
