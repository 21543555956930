import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EnvironmentService {

    public contentfulEditModeChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    public setContentfulEditMode(editMode: boolean) {
        this.contentfulEditMode = editMode
        this.contentfulEditModeChanged$.next(this.contentfulEditMode)
    }

    public contentfulEditMode: boolean

    public getDfdsContentfulEnvironment(): string {
        return (<any>window).sbContentfulEnvironment
    }

    public getSbMockBackend(): boolean {
        return (<any>window).sbMockBackend
    }

    public getSbContentfulAccessToken(): string {
        return (<any>window).sbContentfulAccessToken
    }

    public getSbContentfulPreviewAccessToken(): string {
        return (<any>window).sbContentfulPreviewAccessToken
    }

    public getSbContentfulUsePreview(): boolean {
        return (<any>window).sbContentfulUsePreview
    }

    public getSbContentfulSpaceId(): string {
        return (<any>window).sbContentfulSpaceId
    }
}
