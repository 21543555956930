import { CustomerCategory } from 'app/shared/models/reservation.interfaces';
import { DatepickerView, DatepickerLocaleType } from 'app/shared/datepicker/datepicker.models';

export class Title {
    constructor(
        public code: string,
        public description: string
    ) { }
}

export class Country {
    constructor(
        public code: string,
        public description: string
    ) { }
}

export class DateOfBirthSettings {
    private _datepickerView: DatepickerView

    constructor(private dob: Date, private locale: string, private minDate, private maxDate) {
        this._datepickerView = new DatepickerView(<DatepickerLocaleType>this.locale)
        this._datepickerView.defaultDate = maxDate

        this.dob = this.dob && this.dob.getFullYear ? this.dob : new Date()

        this._datepickerView.minDate = minDate
        this._datepickerView.maxDate = maxDate
        this.setYearSelectorRange()
    }

    public get datepickerView(): DatepickerView {
        return this._datepickerView
    }

    private setYearSelectorRange() {
        const minYear = this._datepickerView.minDate.getFullYear()
        const maxYear = this._datepickerView.maxDate.getFullYear()
        this._datepickerView.yearSelectorRange = `${minYear}:${maxYear}`
    }
}

export class Passenger {
    constructor(
        public id: string,
        public category: CustomerCategory,
        public title: string,
        public firstName: string,
        public lastName: string,
        public dateOfBirth: Date,
        public nationality: string,
        public passportNumber: string
    ) { }
}
