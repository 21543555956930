import { Component, Input, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { ITravelDeparture } from 'app/travel/travel.interfaces';
import { FormGroup, FormControl } from '@angular/forms';
import { SelectItem } from 'app/shared/select-field/select.model';
import { ResourceService } from 'app/shared/resources/resource.service';

@Component({
    selector: 'sbw-travel-departure',
    templateUrl: './travel-departure.component.html',
    styleUrls: ['./travel-departure.component.css', './travel-departure.component.scss']
})
export class TravelDepartureComponent implements OnInit, OnChanges {
    
    constructor(private resourceService: ResourceService) { }

    @Input() content: any
    @Input() outboundRoute: ITravelDeparture
    @Input() returnRoute: ITravelDeparture
    @Input() formDepartureSelections: FormGroup
    @Input() datePickerInfoLabel: string

    routes: SelectItem[]
    locale = this.resourceService.getLocale()

    ngOnInit(): void {
        this.routes = this.outboundRoute.routes.map(x => {return { code: x.routeCode, name: x.description}})
    }

    ngOnChanges(changes: SimpleChanges) {
        let { returnRoute } = changes
        if (!returnRoute) return
        if (this.isMiniCruise()) this.oneWay.setValue(false)
        else if (this.isOneWayOnly()) this.oneWay.setValue(true)
        this.routes = this.outboundRoute.routes.map(x => {return { code: x.routeCode, name: x.description}})
    }

    isMiniCruise(): boolean {
        return !this.returnRoute.routes.some(r => r.routeCode == 'ONE_WAY')
    }

    isOneWayOnly(): boolean {
        return this.returnRoute.routes.every(r => r.routeCode == 'ONE_WAY')
    }

    get outboundRouteCode(): FormControl {
        return this.formDepartureSelections.get('outboundRouteCode') as FormControl
    }

    get outboundDepartureDate(): FormControl {
        return this.formDepartureSelections.get('outboundDepartureDate') as FormControl
    }

    get returnDepartureDate(): FormControl {
        return this.formDepartureSelections.get('returnDepartureDate') as FormControl
    }

    get oneWay(): FormControl {
        return this.formDepartureSelections.get('oneWay') as FormControl
    }

    public changeDepartureType(type: string) {
        if (this.isMiniCruise() || this.isOneWayOnly()) return
        this.oneWay.setValue(type == 'oneway')
    }

}
