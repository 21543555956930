import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ICreditCardWire, IPaymentResult, IConfirmResult, IFinalizeResult } from 'app/payment/payment.interfaces'
import { IReservation } from 'app/shared/models/reservation.interfaces'
import { ResourceService } from 'app/shared/resources/resource.service'
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service'
import { toParamsObj } from 'app/shared/utils'
import { StatelessHttpService } from 'app/shared/stateless-http.service'

@Injectable()
export class PaymentService {

    constructor(
        private salesChannelService: SalesChannelService,
        private resourceService: ResourceService,
        private statelessHttpService: StatelessHttpService) {
    }

    getCreditCards(salesOwnerId: number, salesChannelCode: string): Observable<ICreditCardWire[]> {
        return this.statelessHttpService.get<ICreditCardWire[]>(false, `payment/mechanisms/salesowner/${salesOwnerId}/channel/${salesChannelCode}`)
    }

    getReservation(reservationCode: string, token: string): Observable<IReservation> {
        return this.statelessHttpService.get<IReservation>(false, `reservation/${reservationCode}`, { headers: this.getHeaders(token) })
    }

    postOutstandingPayment(cardType: string, reservationCode: string, token: string, prevUrl: string): Observable<IPaymentResult> {
        const body = { reservationCode, cardType, prevUrl }
        return this.statelessHttpService.post<IPaymentResult>(false, `payment/outstanding/payment?reservationCode=${reservationCode}`, body, { headers: this.getHeaders(token) }) // OutstandingTokenAuthorizeAttribute on server uses reservationCode from request which is the reason it is also included in the url as parameter
    }

    postAmendmentPayment(cardType: string, reservationCode: string, prevUrl: string): Observable<IPaymentResult> {
        const body = { reservationCode, cardType, prevUrl }
        return this.statelessHttpService.post<IPaymentResult>(true, 'payment/amendment/payment', body)    
    }

    getAgentFinalizeFlow(): Observable<IFinalizeResult> {
        let params = {
            'salesOwner': this.resourceService.getSalesOwner(),
            'salesChannel': this.salesChannelService.getCode()
        }
        return this.statelessHttpService.post<IFinalizeResult>(true, 'agent/finalize', { salesOwner: this.resourceService.getSalesOwner(), salesChannel: this.salesChannelService.getCode() })
    }

    postAgentFinalizeFlow(reservationCode: string) {
        const body = { reservationCode }
        return this.statelessHttpService.post<IConfirmResult>(true, `agent/confirm/${reservationCode}`, body)
    }

    private getHeaders(token: string): any {
        return {
            'Cache-Control': 'no-cache',
            'Authorization': `DFDSSB ${token}` // Token (generated in Seabook) origins from parameter in link from outstanding mail
        }
    }

}
