<sbw-layout
    [backButtonVisible]="true"
    [type]="'Amendment'"
    [continueButtonDisabled]="!this.mealsChanged"
    (continueEvent)="continue()"
    (backEvent)="navigateBack()" >
    <div class="sbw_pagebox">
        <div class="row uncollapse meals-container">
            <h1>{{'MEALS_AMENDMENT_TITLE' | resource}}</h1>
            <div class="sbw_legend" [innerHTML]="'MEALS_AMENDMENT_TEASER' | resource"></div>
            <form *ngIf="this.showMeals" action="/meals" id="sbw_meals_form" method="post">
                <sbw-validation-summary [errorList]="errors"></sbw-validation-summary>
                    <div class="row">
                        <div class="column gutter leg-container no-padding-mobile">
                            <div class="meals-header padding-mobile">
                                <h2>{{outboundRoute}}
                                    <span>{{outboundDepartureDate | resourceDateformat:'MOMENT_DEPARTURE_HEADER_DATE_FORMAT'}}</span>
                                </h2>
                            </div>
                            <sbw-meals-selector *ngIf="outboundRestaurants" [restaurants]="outboundRestaurants" [routeCode]="reservation.routeCode" [currencyCode]="currencyCode" (mealsChanged)="mealsChangedHandler()"></sbw-meals-selector>
                        </div>
                        <div *ngIf="returnRoute" class="column gutter leg-container no-padding-mobile">
                            <div class="meals-header padding-mobile">
                                <h2>{{returnRoute}}
                                    <span>{{returnDepartureDate | resourceDateformat:'MOMENT_DEPARTURE_HEADER_DATE_FORMAT'}}</span>
                                </h2>
                            </div>
                            <sbw-meals-selector *ngIf="returnRestaurants" [restaurants]="returnRestaurants" [routeCode]="reservation.routeCode" [currencyCode]="currencyCode" (mealsChanged)="mealsChangedHandler()"></sbw-meals-selector>
                        </div>
                    </div>
            </form>
        </div>
    </div>
</sbw-layout>
