import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { zip } from 'rxjs'
import { Router } from '@angular/router'
import { IReservation, IPriceItem } from '../../../shared/models/reservation.interfaces'
import { PassengerService } from 'app/passengers/passenger.service'
import { IInputBookingInfo } from 'app/amendment/bookings/booking-info/booking-info.component'
import { IInputContactDetails } from 'app/amendment/bookings/contact-details/contact-details.component'
import { TrackingService } from '../../../shared/tracking/tracking.service'
import { TrackingAmendmentStep } from '../../../shared/tracking/tracking-wire.interfaces'
import { ContentfulClientApiFast } from 'app/shared/resources/contentfulClientApiFast'
import { LayoutState } from 'app/shared/layout/layout-state'

@Component({
    selector: 'sbw-inactive',
    templateUrl: './inactive.component.html',
    styleUrls: ['./inactive.component.css']
})

export class InactiveComponent implements OnInit {

    @Input() reservation: IReservation
    @Input() errors: string[] = []
    @Input() public printConfCallback: Function
    @Output() loadingDone = new EventEmitter<string>()
    @Input() pathToMyBookings: string

    public agentContact: string
    public amendmentBookingContent: any
    public priceItems: IPriceItem[]
    public done = false
    public reservationCode: string

    inputBookingInfo: IInputBookingInfo
    inputContactDetails: IInputContactDetails

    constructor(
        private router: Router,
        private passengerService: PassengerService,
        private trackingService: TrackingService,
        private contentful: ContentfulClientApiFast,
        private layoutState: LayoutState,
    ) { }

    ngOnInit() {
        this.reservationCode = this.reservation.reservationCode;
        this.agentContact = this.reservation.agentContact

        zip(
            this.passengerService.getTitles(this.reservation.salesOwnerId.toString(), this.reservation.locale),
            this.passengerService.getCountries(this.reservation.locale),
            this.contentful.getEntries<any>('amendmentBooking')).subscribe(response => {
                const [titles, countries, content] = response
                this.amendmentBookingContent = content.items[0].fields
 
                if (this.reservation.reservationStatus === 'Preliminary' || this.reservation.reservationStatus === 'Pending') {
                    this.errors = [this.amendmentBookingContent.preliminaryPendingMessage]
                }

                this.inputContactDetails = <IInputContactDetails>{
                    title: this.reservation.contact.title,
                    firstName: this.reservation.contact.firstName,
                    lastName: this.reservation.contact.lastName,
                    addressLine1: this.reservation.contact.addressLine1,
                    addressLine2: this.reservation.contact.addressLine2,
                    city: this.reservation.contact.city,
                    county: this.reservation.contact.county,
                    country: this.reservation.contact.country,
                    postalCode: this.reservation.contact.postalCode,
                    mobileNumber: this.reservation.contact.mobileNumber,
                    emailAddress: this.reservation.contact.emailAddress,
                }

                this.inputBookingInfo = <IInputBookingInfo>{
                    departures: {
                        out: this.reservation.departures[0],
                        return: this.reservation.departures.length > 1 ? this.reservation.departures[1] : null
                    },
                    snapshotDepartures: null,
                    vehicles: this.reservation.departures[0].vehicles,
                    snapshotVehicle: null,
                    titles,
                    countries,
                    pets: this.reservation.departures[0].pets,
                    passengers: this.reservation.passengers,
                    snapshotPassengers: null,
                    bookedOn: this.reservation.bookingDate,
                    creditCard: this.reservation.creditCard,
                    modified: this.reservation.modifiedDate
                }
                
                this.priceItems = this.reservation.priceItems
                this.done = true
                this.layoutState.setIsContentLoaded(true)
                this.trackingService.trackAmendment(TrackingAmendmentStep.INACTIVE, false, this.reservation)
        })
    }

    navigateToBookingOverview = () => {
      const href = window.location.href
      const origin = window.location.origin
      const locale = href.split('/')[3]
      if (this.agentContact) {
        const agentBookingsUrl = `${origin}/${locale}/${href.split('/')[4]}/booking/agent/bookings`
        window.location.href = agentBookingsUrl
      }
      else {
        const bookingsUrl = `${origin}/${locale}/${this.pathToMyBookings}`
        window.location.href = bookingsUrl
      }
    }
 }
