import { Component, OnInit, Input } from '@angular/core';
import { Title, Country } from '../passengers.models';
import { FormGroup, FormArray } from '@angular/forms';
import { CustomerCategory, IReservation } from 'app/shared/models/reservation.interfaces';
import { IInputPassenger } from 'app/passengers/passenger/passenger.component';
import { FlowOrAmendment, ensureFlowOrAmendment, IFlowOrAmendment } from 'app/shared/utils';
import { IFlowPassengersUserWire } from 'app/passengers/passengers.interfaces';
import { IFlowReservation } from 'app/shared/steps/step.model';
import { StepService } from 'app/shared/steps/step.service';
import { ResourceService } from 'app/shared/resources/resource.service';
import { SnapshotService } from 'app/shared/snapshot.service';
import { IEditDetaisChangedEvent } from 'app/passengers/edit-user-details/edit-user-details.component';
import { convert as JsJodaConvert } from 'js-joda'

@Component({
    selector: 'sbw-passengers',
    templateUrl: './passengers.component.html',
    styleUrls: ['./passengers.component.css']
})
export class PassengersComponent implements OnInit, IFlowOrAmendment {
    @Input() model: IInputPassengers
    @Input() mode: FlowOrAmendment
    @Input() validationError: boolean
    @Input() editModel: IEditDetaisChangedEvent

    routeCode: string
    inputPassengers: IInputPassenger[] = []
    reservation: IReservation | IFlowReservation
    titles: Title[] = []
    countries: Country[] = []
    locale: string
    departureDate: Date
    passengersFormGroup = new FormArray([])

    constructor(
        private stepService: StepService,
        private resourceService: ResourceService,
        private snapshotService: SnapshotService
    ) { }

    ngOnInit(): void {
        ensureFlowOrAmendment(this.mode)
        if (!this.model) { return }
        const { titles, countries } = this.model || <any>{}
        this.titles = titles
        this.countries = countries
        this.locale = this.resourceService.getLocale()

        if (this.mode === 'Flow') {
            this.useFlow()
        } else if (this.mode === 'Amendment') {
            this.useAmendment()
        }
    }

    useFlow() {
        this.reservation = <IFlowReservation>this.stepService.combineToReservation()
        this.routeCode = this.reservation.outboundRouteCode
        if (this.reservation.outboundDepartureDate) {
            this.departureDate = JsJodaConvert(this.reservation.outboundDepartureDate).toDate()
        }

        if (this.reservation.flowPassengers && this.reservation.flowPassengers.passengers) {
            const { childrenAges } = this.reservation
            const { passengers } = this.reservation.flowPassengers
            
            let index = 0
            for (let passenger of passengers) {
                const { customerCategory, nationalityCode } = passenger
                if (customerCategory === "ADULT") {
                    this.inputPassengers.push({ form: this.passengersFormGroup, passenger, customerCategory, countryCode: nationalityCode })
                }
                else if (customerCategory === "CHILD" || customerCategory === "INFANT") {
                    this.inputPassengers.push({ form: this.passengersFormGroup, passenger, customerCategory, countryCode: nationalityCode, passengerAge: childrenAges[index] })
                    index++
                }
            }
        } else {
            const { userProfile } = this.model
            const { countryCode } = userProfile || <any>{}
            const { adults, children, infants } = this.reservation

            this.inputPassengers.push({ form: this.passengersFormGroup, userProfile, customerCategory: 'ADULT', countryCode })
            this.factoryNewPassengers('ADULT', countryCode, adults - 1)
            this.factoryNewPassengers('CHILD', countryCode, children)
            this.factoryNewPassengers('INFANT', countryCode, infants)
        }
        this.model.form.addControl('passengers', this.passengersFormGroup)
    }

    useAmendment() {
        this.reservation = <IReservation>this.model.reservation
        this.routeCode = this.reservation.routeCode
        this.departureDate = JsJodaConvert(this.reservation.departures[0].departureDateTime).toDate()
        let snapshot = this.snapshotService.getSnapshot(this.reservation.reservationCode)
        if (snapshot && snapshot.passengers) {
            this.reservation.passengers = snapshot.passengers
        }
        for (let passenger of this.reservation.passengers.filter(p => p.customerCategory === 'ADULT')) {
            this.inputPassengers.push({ form: this.passengersFormGroup, passenger, customerCategory: 'ADULT' })
        }
        for (let passenger of this.reservation.passengers.filter(p => p.customerCategory === 'CHILD')) {
            this.inputPassengers.push({ form: this.passengersFormGroup, passenger, customerCategory: 'CHILD' })
        }
        for (let passenger of this.reservation.passengers.filter(p => p.customerCategory === 'INFANT')) {
            this.inputPassengers.push({ form: this.passengersFormGroup, passenger, customerCategory: 'INFANT' })
        }
        this.model.form.addControl('passengers', this.passengersFormGroup)
    }

    factoryNewPassengers(customerCategory: CustomerCategory, countryCode: string, countTotal: number) {
        const { childrenAges } = this.reservation
        for (let count = 0; count < countTotal; count++) {
            let passengerAge
            if (customerCategory !== 'ADULT') {
                const index = childrenAges.findIndex(x => (x <= 3 && customerCategory === 'INFANT') || (x > 3 && customerCategory === 'CHILD'))
                passengerAge = index !== -1 ? childrenAges.splice(index, 1)[0] : null
            }
            this.inputPassengers.push({ form: this.passengersFormGroup, customerCategory, passengerAge, countryCode })
        }
    }


    public created(formPassenger: FormGroup) {
        this.passengersFormGroup.push(formPassenger)
    }
}


export interface IInputPassengers {
    reservation?: IReservation | IFlowReservation
    userProfile?: IFlowPassengersUserWire

    form: FormGroup

    titles: Title[],
    countries: Country[]
}
