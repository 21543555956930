<div class="row">
    <div class="column small-12">
        <h2 class="middle">{{ 'PAYMENT' | resource:'Passengers' }}</h2>
        <div class="popup">
            <sbw-information-popup [text]="'PAYMENT_POP_UP' | resource:'Passengers':false"></sbw-information-popup>
            {{ 'PAYMENT_INFO' | resource: 'Payment' }}
        </div>
    </div>
</div>
<div class="row">
    <form [formGroup]="creditCardForm">
        <div class="column small-12 medium-6 xlarge-6">
            <select formControlName="creditCard">
                <option *ngFor="let card of model.creditCards" [value]="card.code">{{card.description}}</option>
            </select>
            <div *ngIf="(validationError && creditCardForm.invalid) || (creditCardForm.invalid && creditCardForm.dirty)">
                <span class="field-validation-error">{{ 'VALIDATION_SELECT_PAYMENT_TYPE' | resource:'Passengers' }}</span>
            </div>
        </div>
    </form>
</div>
