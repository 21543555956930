import { Component, OnInit, Input } from '@angular/core'
import { ResourceDateformatPipe } from 'app/shared/resources/resourcedateformat.pipe'
import { ResourceService } from 'app/shared/resources/resource.service'
import { IOutReturn } from 'app/shared/utils'
import { IDeparture, IPassenger, IPet, IVehicle } from 'app/shared/models/reservation.interfaces'
import { ISnaphotDeparture } from 'app/shared/snapshot.service'
import { Title, Country } from 'app/passengers/passengers.models'
import { IVehicleEditSelection } from 'app/vehicles/vehicles.interfaces'
import { LocalDateTime } from 'js-joda'

@Component({
    selector: 'sbw-booking-info',
    templateUrl: './booking-info.component.html',
    styleUrls: ['./booking-info.component.css']
})
export class BookingInfoComponent implements OnInit {

    @Input() content: any

    departureInfo: IDepartureInfo
    passengerInfoList: IPassengerInfo[]
    vehicleInfoList: IVehicleInfo[]
    pets: string
    bookedOn: LocalDateTime
    creditCard: string
    modified: LocalDateTime

    private pipe: ResourceDateformatPipe

    @Input() inputBookingInfo: IInputBookingInfo

    constructor(private resourceService: ResourceService) { }

    ngOnInit() {
        this.pipe = new ResourceDateformatPipe(this.resourceService)

        this.departureInfo = this.mapDepartures(this.inputBookingInfo.departures, this.inputBookingInfo.snapshotDepartures)
        this.passengerInfoList = this.mapPassengers(this.inputBookingInfo.passengers, this.inputBookingInfo.snapshotPassengers)
        this.vehicleInfoList = this.mapVehicles(this.inputBookingInfo.vehicles, this.inputBookingInfo.snapshotVehicle)
        this.pets = this.mapPets(this.inputBookingInfo.pets)
        this.bookedOn = this.inputBookingInfo.bookedOn
        this.creditCard = this.inputBookingInfo.creditCard
        this.modified = this.inputBookingInfo.modified
    }

    getDateOfBirth(dateOfBirth: Date, key: string): string {
        return dateOfBirth ? ', ' + this.pipe.transform(dateOfBirth, key) : null
    }

    getBlankIfNull(theString: string): string {
        return theString ? ', ' + theString : null
    }

    mapDepartures(departures: IOutReturn<IDeparture>, snapshotDepartures: IOutReturn<ISnaphotDeparture>): IDepartureInfo {
        let departureInfo = <IDepartureInfo>{
            out: [{
                departureTime: departures.out.departureDateTime,
                route: departures.out.routeName,
                state: 'Unchanged'
            }],
            return: departures.return ? [{
                departureTime: departures.return.departureDateTime,
                route: departures.return.routeName,
                state: 'Unchanged'
            }] : []
        }

        if (snapshotDepartures) {
            this.mapSnapshotDeparture(departureInfo.out, snapshotDepartures.out)
            this.mapSnapshotDeparture(departureInfo.return, snapshotDepartures.return)
        }

        return departureInfo
    }

    mapSnapshotDeparture(departureDetailsList: IDepartureDetails[], snapshotDeparture: ISnaphotDeparture) {
        if (snapshotDeparture && (departureDetailsList[0].departureTime !== snapshotDeparture.departureTime || departureDetailsList[0].route !== snapshotDeparture.route)) {
            departureDetailsList[0].state = 'Old'
            departureDetailsList.push({
                departureTime: LocalDateTime.parse(snapshotDeparture.departureTime.toString()),
                route: snapshotDeparture.route,
                state: 'New'
            })
        }
    }

    mapPassengers(passengers: IPassenger[], snapshotPassengers: IPassenger[]): IPassengerInfo[] {
        let passengerInfoList = passengers.map(p => this.mapPassenger(p))
        if (snapshotPassengers && snapshotPassengers.length > 0) {
            this.mapSnapshotPassengers(passengerInfoList, snapshotPassengers)
        }
        return passengerInfoList
    }

    mapSnapshotPassengers(passengerInfoList: IPassengerInfo[], snapshotPassengers: IPassenger[] ) {
        snapshotPassengers.forEach(snapshotPassenger => {
            let passengerIndex = passengerInfoList.findIndex(p => p.id === snapshotPassenger.id)
            let oldPax = passengerInfoList[passengerIndex]
            let newPax = this.mapPassenger(snapshotPassenger)

            if (!this.isEqual(oldPax, newPax)) {
                oldPax.state = 'Old'
                newPax.state = 'New'
                passengerInfoList.push(newPax)
            }
        })
        return passengerInfoList
    }

    mapPassenger(passenger: IPassenger): IPassengerInfo {
        return <IPassengerInfo>{
            id: passenger.id,
            category: passenger.customerCategory,
            title: this.getTitle(passenger.title),
            firstName: passenger.firstName,
            lastName: passenger.lastName,
            dateOfBirth: passenger.dateOfBirth,
            nationality: this.getNationality(passenger.nationalityCode),
            passportNumber: passenger.passportNumber,
            state: 'Unchanged'
        }
    }

    mapVehicles(vehicles: IVehicle[], snapshotVehicle: IVehicleEditSelection): IVehicleInfo[] {

        let existingVehicles = vehicles.map(v => <IVehicleInfo> {
            registrationNumber: v.registrationNumber,
            trailerRegistrationNumber: v.trailer && v.trailer.registrationNumber,
            state: 'Unchanged'
        })

        let newVehicles = snapshotVehicle ? snapshotVehicle.selections.map(x => <IVehicleInfo> {
            registrationNumber: x.vehicleRegistrationNumber,
            trailerRegistrationNumber: x.trailerRegistrationNumber ? x.trailerRegistrationNumber : null,
            state: 'Unchanged'
        }) : []

        if (newVehicles.length === 0 || this.isEqual(existingVehicles, newVehicles)) {
            return existingVehicles
        } else {
            existingVehicles.forEach(v => v.state = 'Old')
            newVehicles.forEach(v => v.state = 'New')
            return existingVehicles.concat(newVehicles)
        }
    }

    getTitle(titleCode: string): string {
        const title = this.inputBookingInfo.titles.find(c => { return c.code === titleCode })
        return title != null ? title.description : null
    }

    getNationality(nationalityCode: string): string {
        const country = this.inputBookingInfo.countries.find(c => { return c.code === nationalityCode })
        return country != null ? country.description : null
    }

    mapPets(pets: IPet[]): string {
        if (pets === null) { return null }

        let result = pets.map(p => (p.count > 1 ? (p.count + 'x ') : '') + p.description).join(', ')
        if (result.length === 0) { this.pets =  null }
        return result
    }

    isEqual(x, y) {
        const ok = Object.keys, tx = typeof x, ty = typeof y;
        return x && y && tx === 'object' && tx === ty ? (
            ok(x).length === ok(y).length &&
            ok(x).every(key => this.isEqual(x[key], y[key]))
        ) : (x === y);
    }

}

export interface IInputBookingInfo {
    departures: IOutReturn<IDeparture>
    snapshotDepartures: IOutReturn<ISnaphotDeparture>
    passengers: IPassenger[]
    snapshotPassengers: IPassenger[]
    vehicles: IVehicle[]
    snapshotVehicle: IVehicleEditSelection
    pets: IPet[]
    titles: Title[]
    countries: Country[]
    bookedOn: LocalDateTime
    creditCard: string
    modified: LocalDateTime
}

interface IDepartureInfo extends IOutReturn<IDepartureDetails[]> { }

interface IDepartureDetails {
    route: string
    departureTime: LocalDateTime
    state: State
}

interface IPassengerInfo {
    id: string
    category: string
    title: string
    firstName: string
    lastName: string
    dateOfBirth: string
    nationality: string
    passportNumber: string
    state: State
}

export interface IVehicleInfo {
    registrationNumber: string
    trailerRegistrationNumber?: string
    state: State
}

export type State = 'Unchanged' | 'Old' | 'New'
