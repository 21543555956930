import { Component, NgZone, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'

import { Observable, empty, of, zip } from 'rxjs'
import { map } from 'rxjs/operators'
import { LocalTime } from 'js-joda'

import { IRestaurantModel } from 'app/meals/meals-selector-models'
import { MealsService } from 'app/meals/meals.service'
import { MealsSelectorView } from 'app/meals/meals-selector-view'
import { ResourceService } from 'app/shared/resources/resource.service'
import { StepService } from 'app/shared/steps/step.service'
import { IPassenger, IRoom } from 'app/shared/models/reservation.interfaces'
import { SbEvent } from 'app/shared/sb-event.emitter'
import { ButtonStateType } from 'app/shared/button/button.component'
import { IPopupResources, MealPopups } from 'app/meals/meals.interfaces'
import { HybridService } from 'app/shared/hybrid/hybrid.service'
import { IFlowReservation } from 'app/shared/steps/step.model'
import { QuoteChanged } from 'app/shared/quote/quote.events'
import { QuoteService } from 'app/shared/quote/quote.service'
import { LayoutState } from 'app/shared/layout/layout-state'
import { TrackingService } from 'app/shared/tracking/tracking.service'
import { TrackingFlowStep } from 'app/shared/tracking/tracking-wire.interfaces'
import { AuthService } from 'app/auth/auth.service'
import { ContentfulSeabookWebService } from 'app/shared/resources/contentful-seabookweb.service'
import { getPassengerRedirectUri } from 'app/auth/auth.config'

@Component({
  selector: 'sbw-flow-meals',
  templateUrl: './flow-meals.component.html',
  styleUrls: ['./flow-meals.component.css'],
})
export class FlowMealsComponent implements OnInit {
  public popup: PopupValues = new PopupValues()
  public returnRestaurants: IRestaurantModel[]
  public outboundRestaurants: IRestaurantModel[]
  public mealsSelectorViewOutbound: MealsSelectorView
  public mealsSelectorViewReturn: MealsSelectorView
  public errorList = []
  public mealsChanged: boolean
  private reservation: IFlowReservation
  buttonState: ButtonStateType = 'disabled'
  public quoteData: Observable<QuoteChanged> = empty()
  public isAgent: boolean
  public mealsContent = {}

  constructor(
    private mealsService: MealsService,
    private resourceService: ResourceService,
    private stepService: StepService,
    private sbEvent: SbEvent,
    private zone: NgZone,
    private hybridService: HybridService,
    private authService: AuthService,
    private titleService: Title,
    private quoteService: QuoteService,
    private layoutState: LayoutState,
    private trackingService: TrackingService,
    private contentful: ContentfulSeabookWebService
  ) {}

  async ngOnInit() {
    this.isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
    let locale = this.resourceService.getLocale()
    this.reservation = this.stepService.combineToReservation()
    this.resourceService.get('MEALS_TITLE', false).subscribe((t) => this.titleService.setTitle(t))
    let childrenAges = this.reservation.childrenAges
    let mealsSelections = this.reservation.mealsSelections
    let outRooms: IRoom[] = []
    let retRooms: IRoom[] = []

    if (mealsSelections) {
      if (mealsSelections.out && mealsSelections.out.selectedMeals) {
        outRooms = mealsSelections.out.selectedMeals.map(
          (m) =>
            <IRoom>{
              description: null,
              roomCode: m.roomCode,
              seatingTime: m.selectedTime ? LocalTime.parse(m.selectedTime) : null,
              roomArrangements: [{ id: m.mealId }],
            }
        )
      }
      if (mealsSelections.return && mealsSelections.return.selectedMeals) {
        retRooms = mealsSelections.return.selectedMeals.map(
          (m) =>
            <IRoom>{
              description: null,
              roomCode: m.roomCode,
              seatingTime: m.selectedTime ? LocalTime.parse(m.selectedTime) : null,
              roomArrangements: [{ id: m.mealId }],
            }
        )
      }
    }

    this.resourceService
      .loadResourcesPromise(
        this.reservation.outboundRouteCode,
        locale,
        ['Meals', 'Account', 'Menu', 'Common', 'Currency', 'Notification', 'Quote'].concat(this.isAgent ? ['Agent', 'MenuAgent'] : [])
      )
      .then(async () => {
        const mealsContent$ = this.contentful.loadFlowMeals()
        const mealsServiceResponse = await this.mealsService.getMealsFlow(this.getAges(this.reservation)).toPromise()
        const restaurantContent = await this.contentful.loadMealsRestaurantDetailsForRestaurantCodes(
          MealsSelectorView.restaurantCodes(mealsServiceResponse),
          this.reservation.outboundRouteCode
        )
        this.mealsSelectorViewOutbound = new MealsSelectorView(
          mealsServiceResponse.outboundRestaurants,
          outRooms,
          this.getPassengers(this.reservation),
          childrenAges,
          restaurantContent
        )
        this.outboundRestaurants = this.mealsSelectorViewOutbound.restaurants

        if (mealsServiceResponse.returnRestaurants) {
          this.mealsSelectorViewReturn = new MealsSelectorView(
            mealsServiceResponse.returnRestaurants,
            retRooms,
            this.getPassengers(this.reservation),
            childrenAges,
            restaurantContent
          )
          this.returnRestaurants = this.mealsSelectorViewReturn.restaurants
        }
        this.quoteData = of(this.prepareQuoteData(this.outboundRestaurants, this.returnRestaurants))
        this.layoutState.setIsContentLoaded(true)
        this.mealsContent = (await mealsContent$).fields
        this.trackingService.trackFlow(TrackingFlowStep.MEALS, 'Meals', this.reservation, true)
      })
  }

  private getAges(reservation: IFlowReservation): number[] {
    let adults: number[] = []
    for (let i = 0; i < reservation.adults; i++) {
      adults.push(16)
    }
    let ages = adults.concat(reservation.childrenAges || [])
    return ages
  }

  private getPassengers(reservation: IFlowReservation): IPassenger[] {
    let passengers: IPassenger[] = []
    for (let i = 0; i < reservation.adults; i++) {
      passengers.push(<IPassenger>{ customerCategory: 'ADULT' })
    }
    for (let i = 0; i < reservation.children; i++) {
      passengers.push(<IPassenger>{ customerCategory: 'CHILD' })
    }
    for (let i = 0; i < reservation.infants; i++) {
      passengers.push(<IPassenger>{ customerCategory: 'INFANT' })
    }
    return passengers
  }

  public continue() {
    let hasReturn = this.reservation.returnDepartureId && this.mealsSelectorViewReturn
    let outboundMeals = this.mealsService.getMealsWire(
      this.reservation.outboundDepartureId,
      this.mealsSelectorViewOutbound,
      this.reservation.childrenAges
    )
    let returnMeals =
      hasReturn &&
      this.mealsService.getMealsWire(this.reservation.returnDepartureId, this.mealsSelectorViewReturn, this.reservation.childrenAges)
    this.popupResources().subscribe((resourcesDefined: IPopupResources) => {
      if (
        resourcesDefined.noMeals &&
        !outboundMeals.selectedMeals.length &&
        (!hasReturn || !returnMeals.selectedMeals.length) &&
        this.popup.keyBase != 'MEALS_POPUP_NO_MEALS'
      ) {
        this.popup = new PopupValues('MEALS_POPUP_NO_MEALS')
      } else if (
        resourcesDefined.oneLeg &&
        outboundMeals.selectedMeals.length &&
        hasReturn &&
        !returnMeals.selectedMeals.length &&
        this.popup.keyBase != 'MEALS_POPUP_ONE_LEG'
      ) {
        this.popup = new PopupValues('MEALS_POPUP_ONE_LEG')
      }
    })
    if (!this.popup.show) {
      this.postChoices()
    }
  }

  public closePopupAndPost() {
    this.popup = new PopupValues()
    this.postChoices()
  }

  public continueOrderMeals() {
    this.popup.show = false
  }

  public postChoices() {
    this.layoutState.setIsContinueLoading(true)
    let hasReturn = this.reservation.returnDepartureId && this.mealsSelectorViewReturn
    let outboundMeals = this.mealsService.getMealsWire(
      this.reservation.outboundDepartureId,
      this.mealsSelectorViewOutbound,
      this.reservation.childrenAges
    )
    let returnMeals =
      hasReturn &&
      this.mealsService.getMealsWire(this.reservation.returnDepartureId, this.mealsSelectorViewReturn, this.reservation.childrenAges)

    if (!outboundMeals.departureId || (hasReturn && !returnMeals.departureId)) {
      throw new Error('Missing departureId')
    }

    this.mealsService.postHybridMeals(outboundMeals, returnMeals).subscribe(
      (data) => {
        if (data.nextStepUrl === 'passengers' && !this.isAgent) {
          if (!this.authService.isLoggedIn()) {
            this.layoutState.setIsContinueLoading(false)
            this.authService.initiateLogin(getPassengerRedirectUri())
          } else {
            this.trackingService.saveFlowTrackingData(data.trackingData)
            this.quoteService.saveQuoteInSessionStorage(data.quote)
            this.hybridService.changeWindowLocation(data.nextStepUrl)
            this.layoutState.setIsContinueLoading(false)
          }
        } else {
          this.trackingService.saveFlowTrackingData(data.trackingData)
          this.quoteService.saveQuoteInSessionStorage(data.quote)
          this.hybridService.changeWindowLocation(data.nextStepUrl)
          this.layoutState.setIsContinueLoading(false)
        }
      },
      (err) => {
        this.layoutState.setIsContinueLoading(false)
        switch (err.status) {
          case 412: // PreconditionFailed
            let errors = err.error
            this.errorList = errors.errorList.map((e) => e.message)
            break
          default:
            throw err
        }
      }
    )
  }

  public navigateBack() {
    this.sbEvent.historyGoBack.next()
  }

  private mealsChangedHandler(): void {
    this.quoteData = of(this.prepareQuoteData(this.outboundRestaurants, this.returnRestaurants))
  }

  private prepareQuoteData(restaurantsOut: IRestaurantModel[], restaurantsReturn: IRestaurantModel[]) {
    if (!restaurantsOut) {
      return
    }
    return this.mealsService.getQuoteModel(
      this.mealsSelectorViewOutbound,
      this.mealsSelectorViewReturn,
      this.reservation.currencyCode,
      'HH:mm'
    )
  }

  private popupResources(): Observable<IPopupResources> {
    return zip(this.resourceService.get('MEALS_POPUP_NO_MEALS_TEXT'), this.resourceService.get('MEALS_POPUP_ONE_LEG_TEXT')).pipe(
      map((arr) => ({
        noMeals: arr[0] != 'MEALS_POPUP_NO_MEALS_TEXT' && arr[0] != '',
        oneLeg: arr[1] != 'MEALS_POPUP_ONE_LEG_TEXT' && arr[1] != '',
      }))
    )
  }
}

export class PopupValues {
  public textKey?: string
  public stayKey?: string
  public continueKey?: string
  public show: boolean
  constructor(public keyBase?: MealPopups) {
    this.show = !!keyBase
    if (keyBase) {
      this.textKey = keyBase + '_TEXT'
      this.stayKey = keyBase + '_STAY'
      this.continueKey = keyBase + '_CONTINUE'
    }
  }
}
