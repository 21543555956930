import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'
import { ICabinWire, ICabinDepartureSelection, ICabinSelection, ICabinGroup, ICabinDeparture, ICabin } from 'app/cabins/cabins.interfaces'
import { ResourceService } from 'app/shared/resources/resource.service';
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service';
import { IFlowReservation } from 'app/shared/steps/step.model';
import { QuoteChanged, PriceSpecificationType, UserQuoteDetails, QuoteLine } from 'app/shared/quote/quote.events';
import { toParamsObj } from '../shared/utils';
import { StatelessHttpService } from 'app/shared/stateless-http.service';


@Injectable()
export class CabinsService {

    constructor(
        private statelessHttpService: StatelessHttpService, 
        private resourceService: ResourceService, 
        private salesChannelService: SalesChannelService) { }

    public getCabinsFlow(reservation: IFlowReservation): Observable<ICabinWire> {
        let params = {
            'salesOwner': this.resourceService.getSalesOwner(),
            'locale': this.resourceService.getLocale(),
            'salesChannel': this.salesChannelService.getCode(),
            'departureOut': reservation.outboundDepartureId,
            'product': reservation.productCode,
            'departureReturn': reservation.returnDepartureId,
            'adults': '' + reservation.adults,
            'children': '' + reservation.children
        }

        return this.statelessHttpService.get<ICabinWire>(false, 'cabins-flow', { params: toParamsObj(params) })
    }

    public getCabinsFromReservation(reservationCode: string): Observable<ICabinWire> {
        let params = { 'reservationCode': reservationCode }
        return this.statelessHttpService.get<ICabinWire>(true, 'cabins-amendment/', { params: toParamsObj(params) })
    }

    public postHybridCabins(cabinSelections: ICabinDepartureSelection[]): Observable<any> {
        return this.statelessHttpService.post(false, 'cabins-flow', cabinSelections)
    }

    public getQuoteModel(outboundSelections: ICabinSelection[], outboundOptions: ICabinDeparture, homeboundSelections: ICabinSelection[], homeboundOptions: ICabinDeparture): QuoteChanged {
        let outbound = this.buildUserQuoteDetails(outboundSelections, outboundOptions)
        let homebound = homeboundOptions && this.buildUserQuoteDetails(homeboundSelections, homeboundOptions)
        return new QuoteChanged(PriceSpecificationType.Cabins, outbound, homebound, null)
    }

    private buildUserQuoteDetails(selections: ICabinSelection[], options: ICabinDeparture) {
        let lines = selections.map(s => options.cabinGroups.flatMap<ICabinGroup, ICabin>(c => c.cabins).filter(f => f.id === s.id && +s.count !== 0).map(m => new QuoteLine(m.description, m.price.currentPrice, m.price.currencySymbol, s.count))).reduce((a, b) => a.concat(b), [])
        return new UserQuoteDetails(lines, null)
    }
}
