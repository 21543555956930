<div class="meal-container" *ngFor="let restaurant of restaurants">
    <sbw-grid *ngIf="restaurant.hasCapacity" [border]="false">

        <!-- mobile and up -->
        <sbw-grid-header class="hide-for-small-down" [noPadding]="isHighlightedRestaurant(restaurant)">
            <div class="row">
                <div [ngClass]="{'highlight' : isHighlightedRestaurant(restaurant)}"></div>
                <div class="column medium-12 large-8">
                    <div class="restaurant" [ngClass]="{'highlight-text' : isHighlightedRestaurant(restaurant) } ">
                        <sbw-information-popup *ngIf="restaurant.content" [heading]="restaurant.content?.headline">
                            <sbw-restaurant-menu [restaurant]="restaurant.content"></sbw-restaurant-menu>
                        </sbw-information-popup>
                        <h5>{{ restaurant.content?.headline }}</h5>
                        <br>
                        <span class="highlight-inline" *ngIf="isHighlightedRestaurant(restaurant)" >{{ 'HIGHLIGHT_RESTAURANT_' + getRestaurantCode(restaurant) | resource:'Meals' }}</span>
                    </div>
                    <div *ngIf="showCapacityWarningWhenUnder10(restaurant.mealArrangements)" class="limited-capacity">{{ tableHourLessThan10 }}</div>
                    <div *ngIf="showCapacityWarningWhenUnder20(restaurant.mealArrangements)" class="limited-capacity">{{ tableHourLessThan20 }}</div>
                </div>
                <div class="column medium-2 large-2 nopadding hide-for-medium-down">
                    <h5>{{ 'MEALS_TOTAL_PRICE' | resource }}</h5>
                </div>
                <div class="column medium-2 large-1 padding-left end hide-for-medium-down">
                    <h5>{{ 'MEALS_SELECTED' | resource }}</h5>
                </div>
            </div>
        </sbw-grid-header>

        <!-- on mobile  -->
        <sbw-grid-header class="hide-for-medium-up" [noPadding]="isHighlightedRestaurant(restaurant) && !(showCapacityWarningWhenUnder10(restaurant.mealArrangements) || showCapacityWarningWhenUnder20(restaurant.mealArrangements))" class="hide-for-medium-up">
            <div class="row">
                <div [ngClass]="{'highlight' : isHighlightedRestaurant(restaurant),'hide-highlighting' : showCapacityWarningWhenUnder10(restaurant.mealArrangements) || showCapacityWarningWhenUnder20(restaurant.mealArrangements)}"></div>
                <div class="column small-12 medium-12 xlarge-8">
                    <div class="restaurant" [ngClass]="{'highlight-text' : isHighlightedRestaurant(restaurant) && !(showCapacityWarningWhenUnder10(restaurant.mealArrangements) || showCapacityWarningWhenUnder20(restaurant.mealArrangements))}">
                        <sbw-information-popup *ngIf="restaurant.content" [heading]="restaurant.content?.headline">
                            <sbw-restaurant-menu [restaurant]="restaurant.content"></sbw-restaurant-menu>
                        </sbw-information-popup>
                        <h5>{{ restaurant.content?.headline }}</h5>
                        <br>
                        <span class="highlight-inline" *ngIf="isHighlightedRestaurant(restaurant)" [ngClass]="{'hide-highlighting' : showCapacityWarningWhenUnder10(restaurant.mealArrangements) || showCapacityWarningWhenUnder20(restaurant.mealArrangements) }">{{ 'HIGHLIGHT_RESTAURANT_' + getRestaurantCode(restaurant) | resource:'Meals' }}</span>
                    </div>
                </div>
            </div>
        </sbw-grid-header>

        <div [ngClass]="{'three-d-effect' : isHighlightedRestaurant(restaurant), 'hide-highlighting' : showCapacityWarningWhenUnder10(restaurant.mealArrangements) || showCapacityWarningWhenUnder20(restaurant.mealArrangements)}"></div>
        <sbw-grid-body>
            <div
                *ngFor="let mealArrangement of restaurant.mealArrangements; let last = last; let first = first;"
                class="row uncollapse meal-row"
                [ngClass]="{'last': last}">
                <div *ngIf="mealArrangement.hasCapacity">
                    <div class="column medium-7 large-8 option-name hide-for-medium-down">
                        <img *ngIf="mealArrangement.mealTimes" src='/_ng/booking/assets/chevron.svg' [ngClass]="{'expand': expand(mealArrangement.id), 'collapse': !expand(mealArrangement.id) }" (click)="toggleCollapseExpand(mealArrangement.id)">
                        {{mealArrangement.description}} <span *ngIf="'MEALS_PRICE_ADVANTAGE_' + mealArrangement.id | resource:'Meals':false" class="savings" >{{ 'MEALS_PRICE_ADVANTAGE_' + mealArrangement.id | resource:'Meals':false }}</span>
                    </div>
                    <div *ngIf="showCapacityWarningWhenUnder10(restaurant.mealArrangements) && first" class="column small-12 hide-for-medium-up limited-capacity-mobile">{{ tableHourLessThan10 }}</div>
                    <div *ngIf="showCapacityWarningWhenUnder20(restaurant.mealArrangements) && first" class="column small-12 hide-for-medium-up limited-capacity-mobile">{{ tableHourLessThan20 }}</div>
                    <div class="column small-8 medium-9 option-name hide-for-large-up">
                        {{mealArrangement.description}} <span *ngIf="'MEALS_PRICE_ADVANTAGE_' + mealArrangement.id | resource:'Meals':false" class="savings" >{{ 'MEALS_PRICE_ADVANTAGE_' + mealArrangement.id | resource:'Meals':false }}</span>
                    </div>
                    <div class="column small-4 medium-3 large-2 nopadding">
                        <div class="row">
                            <div class="column medium-12 option-price">
                                {{currencyCode | resource}}{{ mealArrangement.pricePerAdult | resourceformat:'CURRENCY_FORMAT'}}
                            </div>
                        </div>
                    </div>
                    <div class="column small-12 medium-12 large-1 padding-left end">
                        <div class="row">
                            <div class="column small-6 medium-10 hide-for-large-up option-text">
                                <h5>{{ 'MEALS_SELECTED' | resource }}</h5>
                            </div>
                            <div class="column small-6 medium-2 large-12 option-checkbox">
                                <sbw-checkbox
                                    [disabled]="mealArrangement.isReservationRule"
                                    [checked]="mealArrangement.isReservationRule || mealArrangement.selected"
                                    (changed)="selectedMeal(mealArrangement)"
                                    [dataId]="mealArrangement.id"
                                    [attr.data-e2e]="mealArrangement.mealTimes ? 'meal-time' : 'no-meal-time'"
                                ></sbw-checkbox>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="mealArrangement.mealTimes && expand(mealArrangement.id)" class="column small-12 medium-12 xlarge-12">
                        <div class="row meal-times" [ngClass]="{'seperator' : !last}">
                            <label [attr.title]="showCapacityOnTableHour(mealTime.avalilableCapacity) ? tableHourLessThan10 : ''"
                                class="meal-time"
                                [ngClass]="{'selected' : isSelected(mealArrangement.selectedTime, mealTime.selectItem.value) && (mealArrangement.isMandatoryRule || mealArrangement.selected)}"
                                *ngFor="let mealTime of mealArrangement.mealTimes"
                                (click)="selectedTimeMeal(mealTime.selectItem.value, mealArrangement)"
                                [@hourState]="expand(mealArrangement.id) ? 'expand' : 'collapse'">
                                {{mealTime.selectItem.text}}
                            </label>
                        </div>
                    </div>
                    <div *ngIf="mealArrangement.mealTimes">
                        <div class="column small-12 hide-for-large-up collapse-expand-bar" (click)="toggleCollapseExpand(mealArrangement.id)">
                            <img src='/_ng/booking/assets/chevron.svg' [ngClass]="{'expand': expand(mealArrangement.id), 'collapse': !expand(mealArrangement.id) }" >
                            <div *ngIf="expand(mealArrangement.id)">{{ 'MEALS_HIDE_TIME_SLOTS' | resource : 'Meals' }}</div>
                            <div *ngIf="!expand(mealArrangement.id)">{{ 'MEALS_SHOW_TIME_SLOTS' | resource : 'Meals' }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </sbw-grid-body>
    </sbw-grid>
</div>
