import { Component, OnInit } from "@angular/core"
import { ContentfulDfdsService } from "../resources/contentful-dfds.service"
import { LoggingService } from "../logging.service"
import { LocaleService } from "../locale-service"

@Component({
    selector: "sbw-chat",
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.css']
})

export class ChatComponent implements OnInit {
    constructor(
        private contentfulDfdsService: ContentfulDfdsService,
        private logService: LoggingService,
        private localeService: LocaleService
    ) { }

    customerKey: string
    configurationId: string
    queueOverride: string[]
    show: boolean = false

    async ngOnInit() {
        try {
            const entry = await this.contentfulDfdsService.getPuzzelChat();
            if (
                entry &&
                entry.fields.chatEnabledBookingFlow
            ) {
                this.customerKey = entry.fields.customerKey
                this.configurationId = entry.fields.configurationId
                this.queueOverride = entry.fields.queueOverride
                this.show = true
            }
            else this.show = false
        } catch {
            this.logService.logWarn({
                message: "Live chat not working on Locale: {Locale}",
                params: [this.localeService.getLocale()],
            });
        }
    }

    w = (): { jQuery: any } => window as any;

    scriptPromise = (src) =>
        new Promise((r) => {
            var e = document.createElement("script");
            e.src = src;
            e.onload = r;
            document.body.appendChild(e);
        });

    loadPuzzelScripts = async () => {
        const puzzelLatest =
            "https://chat.puzzel.com/Content/Client/js/jquery-intelecomchat.latest.min.js";
        if (!document.querySelector(`script[src="${puzzelLatest}"]`)) {
            //check if scripts have been previously loaded. Can happen if user navigates away and back
            await this.scriptPromise(
                "https://chat.puzzel.com/Content/Client/js/jquery-latest.min.js"
            );
            await this.scriptPromise(
                "https://chat.puzzel.com/Content/Client/js/jquery-intelecomchat.libs.latest.min.js"
            );
            await new Promise((r) => {
                var e = document.createElement("link");
                e.href =
                    "https://chat.puzzel.com/Content/Client/css/intelecom-light.css";
                e.rel = "stylesheet";
                e.type = "text/css";
                e.onload = r;
                document.body.appendChild(e);
            });
            var style = document.createElement("style");
            style.innerHTML = `
        #chathost .intelecomchatheader { background-color: #002b45; } 
        #chathost .intelecomchat__button { background: #002b45; }
    `;
            document.head.appendChild(style);
        }
        //this needs to always be loaded or it will not work
        await this.scriptPromise(puzzelLatest);
    };


    onLoadPuzzle = async () => {
        if (document.querySelector("#chathost").children.length > 0) return; //chat have been loaded here. Nothing for us to do
        const feedbackButton = document.querySelector("#_hj_feedback_container div") as HTMLElement
        if (feedbackButton) feedbackButton.style['z-index'] = 50    
        await this.loadPuzzelScripts();
        const _customerKey = this.customerKey
        const _configurationId = this.configurationId
        const _queueOverride = this.queueOverride
        this.w().jQuery("#chathost").intelecomChat({
            customerKey: _customerKey,
            configurationId: _configurationId,
            chatlink: "#startchat",
            showStarter: false,
            showStarterWhenMinimized: false,
            onChatWindowShow: () => {},
            onChatWindowMinimize: () => {},
            onChatStart: (el, evt, params) => {
                const route = params.Variables.Route;
                const queueKey = (_queueOverride || [])
                    .map((a) => a.match(/^([A-Z]+)=>([a-z_]+)$/))
                    .filter((a) => a && a[1] === route)
                    .map((a) => a[2])[0];
                if (queueKey) {
                    console.log('redirecting chat queue to', queueKey)
                    this.w().jQuery("#chathost")
                        .intelecomChat("reloadOptions", { queueKey });
                }
            },
        });
        const poller = setInterval(() => {
            if (document.querySelector("#chathost").children.length > 0) {
                this.w().jQuery("#chathost").intelecomChat("startChat"); // this unfortunately fails if run immediately which is why we poll
                clearInterval(poller);
            }
        }, 50);   
    }
}