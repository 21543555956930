import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { QuoteChanged, PriceSpecificationType, QuoteLine, UserQuoteDetails } from 'app/shared/quote/quote.events'
import { Observable, of, empty } from 'rxjs'
import { FormGroup } from '@angular/forms'
import { ResourceService } from 'app/shared/resources/resource.service'
import { LayoutState } from 'app/shared/layout/layout-state'
import { SbEvent } from 'app/shared/sb-event.emitter'
import { Router } from '@angular/router'

@Component({
  selector: 'sbw-fake-payment',
  templateUrl: './fake-payment.component.html',
  styleUrls: ['./fake-payment.component.css'],
})
export class FakePaymentComponent implements OnInit {
  quoteData: Observable<QuoteChanged> = empty()
  paymentForm: FormGroup

  currency: string
  amountToPay: number
  paymentType: string
  months: string[]
  years: string[]

  constructor(
    private activatedRoute: ActivatedRoute,
    private resourceService: ResourceService,
    private layoutState: LayoutState,
    private sbEvent: SbEvent,
    private router: Router
  ) {}

  ngOnInit() {
    this.resourceService.loadResourcesPromise('default', this.resourceService.getLocale(), ['Common', 'Quote', 'Currency']).then(() => {
      const { currency, amountToPay, paymentType } = this.activatedRoute.snapshot.queryParams

      this.currency = currency
      this.amountToPay = amountToPay
      this.paymentType = paymentType

      this.months = this.monthRange(1, 12)
      this.years = this.yearRange(25)
      this.quoteData = of(this.updateQuote(null, null, null, null, null))
      this.layoutState.setIsContentLoaded(true)
    })
  }

  monthRange(start: number, end: number) {
    return Array(end - start + 1)
      .fill(0)
      .map((_, idx) => {
        let res = start + idx
        return res > 9 ? res.toString() : `0${res}`
      })
  }

  yearRange(years: number) {
    let start = new Date().getFullYear()
    let end = start + years
    return Array(end - start + 1)
      .fill(0)
      .map((_, idx) => {
        let res = start + idx
        return res.toString()
      })
  }

  completeBooking() {
    const fakeBookingNumber = Math.random().toString().slice(2, 10)
    this.router.navigate([`/confirmation/${fakeBookingNumber}`])
  }

  navigateBack() {
    this.router.navigate(['/passengers'])
  }

  updateQuote(type: PriceSpecificationType, text: string, amount: number, currenyCode: string, count: number): QuoteChanged {
    const quoteLine = new QuoteLine(text, amount, currenyCode, count)
    const quoteDetails = Number.isInteger(amount) ? new UserQuoteDetails([quoteLine]) : new UserQuoteDetails([])
    const quoteChanged = new QuoteChanged(type, null, null, quoteDetails)
    return quoteChanged
  }
}
