<h2 class="middle">{{'PASSENGERS_TERMS_AND_CONDITIONS' | resource}}</h2>
<div class="row">
    <div class="column small-12 field">
        <sbw-checkbox (checkedChange)="changedEvent($event)">
            <span [innerHTML]="linkText"></span>
        </sbw-checkbox>
    </div>
    <div class="column small-12">
        <div *ngIf="(validationError && control.invalid) || (control.invalid && control.dirty)">
            <span class="field-validation-error">{{ 'VALIDATION_TERMS_AND_CONDITIONS' | resource }}</span>
        </div>
    </div>
</div>
