<form [formGroup]="internalForm">
    <h2>{{ 'PASSENGERS_ADDITIONALS' | resource }}</h2>
    <div *ngIf="showDeposit">
        <div class="label">{{'PASSENGERS_PAYMENT_CHOICE' | resource:'Passengers'}}</div>
        <div class="row">
            <div class="column small-12 field">
                <input formControlName="payment" id="depositRadio" name="payment" type="radio" value="deposit-payment">
                <label for="depositRadio">
                    <span class="deposit-data"> {{ depositInstructions }} </span>
                </label>
            </div>
        </div>
    </div>
    <!-- <div class="payment-choice-box"> -->
    <div *ngIf="showDeposit" class="row">
        <div class="column small-12 field">
            <input formControlName="payment" id="fullPaymentRadio" name="payment" type="radio" value="full-payment">
            <label for="fullPaymentRadio">
                <span class="full"> {{ fullPaymentInstructions }} </span>
            </label>
        </div>
    </div>

    <div *ngIf="!mandatoryCampaign" id="campaign-code-container">
        <div class="row">
            <div class="column small-12 label">
                <div>{{'PASSENGERS_CAMPAIGN_CODE_TITLE' | resource:'Passengers'}}</div>
            </div>
            <div class="column small-12 large-6">
                <div class="row collapse inline-right secondary-action-form">
                    <div class="column small-6 medium-8 field">
                        <input [readonly]="isDepositSeleted()" formControlName="campaignCode" type="text">
                    </div>
                    <div class="column small-6 medium-4">
                        <sbw-button [state]="internalForm.pending ? 'spinner' : 'activated'" type="secondary"
                            onClickState="activated" [text]="'PASSENGERS_CAMPAIGN_CODE_VERIFY' | resource:'Passengers'">
                        </sbw-button>
                    </div>
                </div>
            </div>
            <div class="column small-12 validation-message">
                <div class="row">
                    <div class="column small-12 large-6">
                        <span *ngIf="campaignCode.dirty && campaignCode.valid && campaignCode.value.length"
                            class="field-validation-ok">{{'PASSENGERS_CAMPAIGN_CODE_VALID' | resource:'Passengers'}}</span>
                        <span *ngIf="campaignCode.dirty && campaignCode.invalid"
                            class="field-validation-error">{{campaignCode.errors.errorMessage.message}}</span>
                    </div>
                </div>
            </div>
            <div class="column small-12 info">
                <sbw-information-popup [text]="'PASSENGERS_CAMPAIGN_CODE_POPUP' | resource:'Passengers'"></sbw-information-popup>
                {{'PASSENGERS_CAMPAIGN_CODE_NOTE' | resource:'Passengers'}}
            </div>
        </div>
    </div>
    <div *ngIf="mandatoryCampaign" id="campaign-code-container">
        <div class="row">
            <div class="column small-12 label">
                <div>{{'PASSENGERS_MANDATORY_CAMPAIGN_CODE_TITLE' | resource:'Passengers'}}</div>
            </div>
            <div class="column small-12 medium-6">
                <div class="row collapse inline-right secondary-action-form">
                    <div class="column small-6 medium-8 field">
                        <input [readonly]="isDepositSeleted()" formControlName="campaignCode" type="text">
                    </div>
                    <div class="column small-6 medium-4">
                        <sbw-button [state]="internalForm.pending ? 'spinner' : 'activated'" type="secondary"
                            onClickState="activated" [text]="'PASSENGERS_CAMPAIGN_CODE_VERIFY' | resource:'Passengers'">
                        </sbw-button>
                    </div>
                </div>
            </div>
            <div class="column small-12 validation-message">
                <div class="row">
                    <div class="column small-12 medium-6">
                        <span
                            *ngIf="campaignCode.value == null || (campaignCode.value != null && campaignCode.value.length == 0)"
                            class="field-validation-note">{{'PASSENGERS_MANDATORY_CAMPAIGN_CODE' | resource:'Passengers'}}</span>
                        <span *ngIf="campaignCode.dirty && campaignCode.valid && campaignCode.value.length"
                            class="field-validation-ok">{{'PASSENGERS_CAMPAIGN_CODE_VALID' | resource:'Passengers'}}</span>
                        <span *ngIf="campaignCode.dirty && campaignCode.invalid"
                            class="field-validation-error">{{campaignCode.errors.errorMessage.message}}</span>
                    </div>
                </div>
            </div>
            <div class="column small-12 info">
                <sbw-information-popup [text]="'PASSENGERS_CAMPAIGN_CODE_POPUP' | resource:'Passengers'"></sbw-information-popup>
                {{'PASSENGERS_MANDATORY_CAMPAIGN_CODE_NOTE' | resource:'Passengers'}}
            </div>
        </div>
    </div>
    <!-- </div> -->
</form>
