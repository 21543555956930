import { Injectable } from '@angular/core'

const secondsInHour = 60 * 60
const secondsInDay = 24 * secondsInHour

@Injectable()
export class CookieService {
  private sbwReservationToken = 'sbwReservationToken'
  private sbwOutstandingAuthToken = 'sbwOutstandingAuthToken'
  private sbwAuthorizationToken = 'sbwAuthorizationToken'
  private sbwOutstandingConfirmPage = 'sbwOutstandingConfirmPage'
  private sbwAmendmentConfirmPage = 'sbwAmendmentConfirmPage'
  private sbwDebuggingEnabled = 'sbwDebuggingEnabled'
  private sbwCookiesAccepted = 'GDPR'
  public sbwAuthType = 'pax_AuthType'
  private sbwMarket = 'sbw_Market'

  constructor() {}

  public getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';')
    let caLen: number = ca.length
    let cookieName = name + '='
    let c: string

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '')
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length)
      }
    }
    return ''
  }

  public deleteCookie(name) {
    let path = this.getPath()
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;${path}`
  }

  public setCookie(name: string, value: string, expireSeconds?: number) {
    const expireStr = expireSeconds ? ';expires=' + new Date(new Date().getTime() + 1000 * expireSeconds).toUTCString() : ''
    let path = this.getPath()
    document.cookie = `${name}=${value}${expireStr};${path}`
  }

  public getPath() {
    return 'path=/'
  }

  public getLocale() {
    return this.getCookie('sbw_Locale')
  }

  public setMarket(market: string) {
    this.setCookie(this.sbwMarket, market)
  }

  public getMarket(): string {
    return this.getCookie(this.sbwMarket)
  }

  public getReservationToken(): string {
    return decodeURIComponent(this.getCookie(this.sbwReservationToken))
  }

  public setReservationToken(token: string) {
    this.setCookie(this.sbwReservationToken, encodeURIComponent(token))
  }

  public deleteReservationToken() {
    this.deleteCookie(this.sbwReservationToken)
  }

  public getOutstandingAuthToken(): string {
    return decodeURIComponent(this.getCookie(this.sbwOutstandingAuthToken))
  }

  public setOutstandingAuthToken(token: string) {
    this.setCookie(this.sbwOutstandingAuthToken, encodeURIComponent(token))
  }

  public deleteOutstandingAuthToken() {
    this.deleteCookie(this.sbwOutstandingAuthToken)
  }

  public getAuthorizationToken(): string {
    return decodeURIComponent(this.getCookie(this.sbwAuthorizationToken))
  }

  public setAuthorizationToken(token: string) {
    this.setCookie(this.sbwAuthorizationToken, encodeURIComponent(token))
  }

  public deleteAuthorizationToken() {
    this.deleteCookie(this.sbwAuthorizationToken)
  }

  public setOutstandingConfirmPage() {
    this.setCookie(this.sbwOutstandingConfirmPage, 'true', 1800)
  }

  public deleteOutstandingConfirmPage() {
    this.deleteCookie(this.sbwOutstandingConfirmPage)
  }

  public setAmendmentConfirmPage() {
    this.setCookie(this.sbwAmendmentConfirmPage, 'true', 1800)
  }

  public deleteAmendmentConfirmPage() {
    this.deleteCookie(this.sbwAmendmentConfirmPage)
  }

  public setDebuggingEnabled(): void {
    if (!this.getDebuggingEnabled()) {
      this.setCookie(this.sbwDebuggingEnabled, 'true')
    }
  }

  public getDebuggingEnabled(): boolean {
    return this.getCookie(this.sbwDebuggingEnabled) === 'true'
  }

  public getCookesAccepted(): boolean {
    return this.getCookie(this.sbwCookiesAccepted) === 'true'
  }

  public setCookesAccepted(accepted: boolean) {
    this.setCookie(this.sbwCookiesAccepted, accepted ? 'true' : 'false', 365 * secondsInDay)
  }

  public getAuthType(): string {
    return this.getCookie(this.sbwAuthType)
  }

  public setAuthType(value: string): void {
    this.setCookie(this.sbwAuthType, value)
  }

  public isAgent(): boolean {
    return this.getAuthType() === 'agent'
  }
}
