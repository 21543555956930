<div class="checkbox-container">
    <label *ngIf="label" >
        <input type="checkbox" [attr.id]="id ? id : null" [attr.checked]="checked ? '' : null" (change)="changedEvent($event)" [attr.disabled]="disabled || readonly ? '' : null"
            [attr.readonly]="readonly ? '' : null" [attr.data-checkbox-id]="!dataId ? null : dataId" />
        <span #ref [ngClass]="{'empty': ref.childNodes.length == 0, 'checked': checked}" [attr.data-label-id]="!dataId ? null : dataId">
            <ng-content></ng-content>
        </span>
    </label>

    <input *ngIf="!label" type="checkbox" [attr.id]="id ? id : null" [attr.checked]="checked ? '' : null" (change)="changedEvent($event)"
        [attr.disabled]="disabled || readonly ? '' : null" [attr.readonly]="readonly ? '' : null" [attr.data-checkbox-id]="!dataId ? null : dataId"
    />
    <span *ngIf="!label" class="empty" [attr.data-label-id]="!dataId ? null : dataId"></span>
</div>