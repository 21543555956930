<div>
    <div class="column booking-details-container">
        <div class="column small-12 large-8 half-gutter">
            <h1 data-e2e="header-booking">{{ 'AMENDMENT_TABLE_HEADER_RESERVATION_ID' | resource }} {{ reservationCode }}</h1>
        </div>
        <div class="column small-12 half-gutter">
            <sbw-validation-summary [errorList]="errors"></sbw-validation-summary>
        </div>
        <div class="column small-12 gutter" *ngIf="done">
            <sbw-grid>
                <sbw-grid-header>
                    <div class="row">
                        <div class="column small-12 medium-6">
                            <h5>{{ 'BOOKING_INFO' | resource }}</h5>
                        </div>
                    </div>
                </sbw-grid-header>
                <sbw-grid-body>
                    <div class="row cancelled" *ngIf="reservation.reservationStatus === 'Cancelled'" >
                        <div class="column small-12 medium-4 large-3">
                            {{ 'STATUS' | resource }}
                        </div>
                        <div class="column small-12 medium-8 large-9">
                            <span >{{ 'CANCELLED' | resource }}</span>
                        </div>
                    </div>
                    <sbw-booking-info [content]="amendmentBookingContent" [inputBookingInfo]="inputBookingInfo">
                    </sbw-booking-info>
                    <sbw-price-breakdown [reservation]="reservation" [priceItems]="priceItems"></sbw-price-breakdown>
                </sbw-grid-body>
            </sbw-grid>
        </div>
        <div class="column small-12 gutter" [innerHTML]="'DISCLAIMER_FOOTER' | resource"></div>
    </div>
    <div class="column small-3 booking-amendment-container" *ngIf="done">
        <div class="column large-12 details">
            <div>
                <h1>{{amendmentBookingContent.passengerDetails}}</h1>
                <sbw-contact-details [inputContactDetails]="inputContactDetails"></sbw-contact-details>
            </div>
            <div *ngIf="agentContact" class="medium-gutter-top">
                <div class="row">
                    <div class="column small-12">
                        <h3>{{ 'AGENT_CONTACT' | resource }}</h3>
                    </div>
                    <div class="column small-12 gutter">
                        {{ agentContact }}
                    </div>
                </div>
            </div>
            <div class="button-padding">
            <sbw-button type="secondary" [forceLink]="true" (clicked)="printConfCallback()" 
                [text]="'PRINT_HOVER' | resource"></sbw-button></div>
            <sbw-button type="previous" (clicked)="navigateToBookingOverview()" [text]="'BACK_TO_BOOKING_LIST' | resource"></sbw-button>
        </div>
    </div>
</div>
