import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'
import { Title } from '@angular/platform-browser'

import { LayoutState } from 'app/shared/layout/layout-state'
import { StepService } from 'app/shared/steps/step.service'
import { ResourceService } from 'app/shared/resources/resource.service'
import { ReservationService } from 'app/shared/reservation/reservation.service'
import { IReservation, IDepartureSelection } from 'app/shared/models/reservation.interfaces'
import { FaresService } from 'app/fares/fares.service'
import { SnapshotService, ISnaphotDeparture } from 'app/shared/snapshot.service'
import { Fare } from 'app/fares/fares.interfaces'
import { AmendService } from 'app/shared/reservation/amend.service'
import { LocalDateTime, DateTimeFormatter, LocalDate } from 'js-joda'
import { TrackingAmendmentStep } from '../../shared/tracking/tracking-wire.interfaces'
import { TrackingService } from '../../shared/tracking/tracking.service'
import { ContentfulClientApiFast } from 'app/shared/resources/contentfulClientApiFast'
import { ISelectedDeparture } from 'app/cabin-fares/cabin-fares.interfaces'
import { hashObject, distinctUntilChangedDeep } from 'app/shared/utils'
import { map, skip } from 'rxjs/operators'

@Component({
  selector: 'sbw-amendment-fares',
  templateUrl: './amendment-fares.component.html',
  styleUrls: ['./amendment-fares.component.css'],
})
export class AmendmentFaresComponent implements OnInit {
  public reservation: IReservation
  public reservationCode: string
  public amendmentsForm: FormGroup = new FormGroup({})
  public isAmendmentCompatible = false
  public canAmend: boolean = true
  public errors: string[] = []
  public data: Fare.IWire
  public amendmentFaresContent?: any
  public resourceLoaded = false
  public isLoading = false
  public updatedOutDepartureDate: LocalDateTime
  public updatedReturnDepartureDate: LocalDateTime
  public returnRoute: boolean = false
  public isAgent: boolean
  public fareChanged: boolean
  public fareSelections: ISelectedDeparture
  private initialMd5: any
  private loadDepartures: boolean = true
  selectedOutDeparture: Fare.ISelectedDeparture
  selectedReturnDeparture: Fare.ISelectedDeparture

  outboundViewModel: amendmentFaresModel
  returnViewModel: amendmentFaresModel

  outboundDepartureDate: LocalDateTime
  returnDepartureDate: LocalDateTime

  get outboundDepartureDateControl(): FormControl {
    return this.amendmentsForm.get('departureDate') as FormControl
  }
  get returnDepartureDateControl(): FormControl {
    return this.amendmentsForm.get('returnDate') as FormControl
  }

  constructor(
    private stepService: StepService,
    private router: Router,
    private route: ActivatedRoute,
    private layoutState: LayoutState,
    private resourceService: ResourceService,
    private title: Title,
    private reservationService: ReservationService,
    private faresService: FaresService,
    private snapshotService: SnapshotService,
    private trackingService: TrackingService,
    private amendService: AmendService,
    private contentful: ContentfulClientApiFast
  ) {}

  ngOnInit() {
    this.isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
    this.reservationCode = this.route.snapshot.params['reservationCode']

    this.amendmentsForm = new FormGroup(
      {
        departureDate: new FormControl({}),
        returnDate: new FormControl({}),
      },
      (c) => {
        let ret = c.get('return')
        if (!ret) return undefined
        if (!ret.value) return { noDepartures: true }
        let out = c.get('out')
        if (!out.value) return { noDepartures: true }
      }
    )

    this.contentful.getEntries<any>('amendmentFares').subscribe((s) => {
      this.amendmentFaresContent = s.items[0].fields
      this.reservationService.getReservation(this.reservationCode).subscribe((reservation) => {
        this.reservation = reservation
        this.resourceService
          .loadResourcesPromise(
            this.reservation.routeCode,
            this.reservation.locale,
            ['Common', 'Amendment', 'ArrangementOptions', 'Currency', 'Quote', 'Fares'].concat(this.isAgent ? ['Agent', 'MenuAgent'] : [])
          )
          .then(() => {
            this.title.setTitle(this.amendmentFaresContent.title)
            this.outboundDepartureDate = reservation.departures[0].departureDateTime

            let date = this.outboundDepartureDate.format(DateTimeFormatter.ofPattern(this.amendmentFaresContent.dateFormat))
            let time = this.outboundDepartureDate.format(DateTimeFormatter.ofPattern(this.amendmentFaresContent.timeFormat))
            let day = this.getDayOfWeek(this.outboundDepartureDate)
            let route = this.reservation.departures[0].routeName
            this.outboundViewModel = new amendmentFaresModel(date, time, day, route)

            if (this.reservation.departures[1] && this.reservation.departures.length > 1) {
              this.returnRoute = true
              this.returnDepartureDate = reservation.departures[1].departureDateTime

              let date = this.returnDepartureDate.format(DateTimeFormatter.ofPattern(this.amendmentFaresContent.dateFormat))
              let time = reservation.departures[1].departureDateTime.format(
                DateTimeFormatter.ofPattern(this.amendmentFaresContent.timeFormat)
              )
              let day = this.getDayOfWeek(this.returnDepartureDate)
              let route = this.reservation.departures[1].routeName
              this.returnViewModel = new amendmentFaresModel(date, time, day, route)
            } else {
              this.returnRoute = false
            }
            this.loadData(
              this.reservation.departures[0].departureDateTime,
              this.reservation.departures[1] && this.reservation.departures[1].departureDateTime
                ? this.reservation.departures[1].departureDateTime
                : null,
              true
            )
          })
      })
    })
  }

  private loadData(outboundDate: LocalDateTime, returnDate: LocalDateTime, initialLoad: boolean) {
    this.layoutState.setIsLoading(true)
    if (this.updatedOutDepartureDate) {
      this.outboundDepartureDate = this.updatedOutDepartureDate
    }
    if (this.updatedReturnDepartureDate) {
      this.returnDepartureDate = this.updatedReturnDepartureDate
    }
    this.faresService.getFaresAmendment(outboundDate, returnDate, this.reservation.reservationCode).subscribe((fares) => {
      this.data = fares
      let snapshot = this.snapshotService.getSnapshot(this.reservationCode)

      //Adding a selectedDeparture object to this.data because the backend returns a selectedItem Object
      this.data.out.selectedDeparture = fares.out && fares.out.selectedItem
      if (this.data.return) {
        this.data.return.selectedDeparture = fares.return && fares.return.selectedItem
      }

      this.initialMd5 = this.getMd5()
      this.updateDepartureSelections()

      if (this.data == null) {
        this.canAmend = false
      } else {
        this.canAmend = true
      }

      if (snapshot && snapshot.departures) {
        this.setSelectedDeparture(this.data.out.selectedDeparture, snapshot.departures.out)
        if (snapshot.departures.return) {
          this.setSelectedDeparture(this.data.return.selectedDeparture, snapshot.departures.return)
        }
      }

      this.updateForm()

      if (initialLoad) {
        this.updateInitialForm()
      }

      this.trackingService.trackAmendment(TrackingAmendmentStep.CHANGEDATES, false, this.reservation)
      this.loadDepartures = false

      this.layoutState.setIsLoading(false)
      this.layoutState.setIsContentLoaded(true)
      this.resourceLoaded = true
    })
  }

  private updateInitialForm() {
    let rDate = this.returnDepartureDate
    if (rDate) {
      this.amendmentsForm.patchValue({ departureDate: this.data.out.dateOfDeparture, returnDate: this.data.return.dateOfDeparture })
    }
    if (!rDate) {
      this.amendmentsForm.patchValue({ departureDate: this.data.out.dateOfDeparture })
    }

    this.amendmentsForm.valueChanges
      .pipe(map((val) => ({ outbound: val.departureDate, return: this.checkReturnDate(val.departureDate, val.returnDate) })))
      .pipe(distinctUntilChangedDeep())
      .pipe(skip(1))
      .subscribe((s) => {
        this.loadData(s.outbound, s.return.month ? s.return : null, false)
      })
  }

  private checkReturnDate(oDate: LocalDate, rDate: LocalDate): any {
    if (oDate > rDate) {
      return oDate
    }
    return rDate
  }

  private updateForm() {
    let rDate = this.reservation.departures[1] ? this.reservation.departures[1].departureDateTime : null
    if (rDate) {
      this.amendmentsForm.patchValue({ departureDate: this.data.out.dateOfDeparture, returnDate: this.data.return.dateOfDeparture })
    }
    if (!rDate) {
      this.amendmentsForm.patchValue({ departureDate: this.data.out.dateOfDeparture })
    }
  }

  public departureChanged() {
    this.fareChanged = this.getMd5() !== this.initialMd5
  }

  private updateDepartureSelections() {
    if (this.selectedOutDeparture) {
      this.data.out.selectedDeparture = this.selectedOutDeparture
    }
    if (this.selectedReturnDeparture) {
      this.data.return.selectedDeparture = this.selectedReturnDeparture
    }
  }

  private setSelectedDeparture(selection: Fare.ISelectedDeparture, departureSnapshot: ISnaphotDeparture) {
    if (!departureSnapshot) {
      return
    }
    selection.departureId = departureSnapshot.id
  }

  private getDayOfWeek(date: LocalDateTime): string {
    let day = date.dayOfWeek().toString().toLowerCase()
    return day.charAt(0).toUpperCase() + day.slice(1)
  }

  public continue() {
    this.layoutState.setIsContinueLoading(true)
    let outDepartureField = this.amendmentsForm.get('out')
    if (!outDepartureField) {
      this.navigateBack()
      return
    }

    const outDepartureId = outDepartureField.value.departureId
    const returnDepartureId = this.amendmentsForm.get('return') ? this.amendmentsForm.get('return').value.departureId : null

    const dryRun = true

    let fareSelections: IDepartureSelection[] = this.getFareSelections(outDepartureId, returnDepartureId)

    this.amendService.changeDepartures(this.reservationCode, fareSelections, dryRun).subscribe((response) => {
      this.layoutState.setIsContinueLoading(false)

      if (response.errorMsgs && response.errorMsgs.length) {
        this.errors = response.errorMsgs.map((m) => m.message)
        this.layoutState.setIsContentLoaded(true)
        return
      }

      this.snapshotService.setSnapshot(this.reservationCode, {
        departures: {
          out:
            this.amendmentsForm.get('out') && outDepartureId != this.reservation.departures[0].departureId
              ? this.getSnapshotDeparture(this.data.out.rows, outDepartureId, this.reservation.departures[0].departureId)
              : null,
          return:
            this.amendmentsForm.get('return') && returnDepartureId != this.reservation.departures[1].departureId
              ? this.getSnapshotDeparture(this.data.return.rows, returnDepartureId, this.reservation.departures[1].departureId)
              : null,
        },
      })
      this.navigateBack()
    })
  }

  private getSnapshotDeparture(rows: Fare.IRow[], selectedFareId: string, oldDepartureId: string): ISnaphotDeparture {
    let snapshotDeparture: ISnaphotDeparture = null
    rows.forEach((r) => {
      if (r.departureId === selectedFareId) {
        snapshotDeparture = <ISnaphotDeparture>{
          id: r.departureId,
          oldId: oldDepartureId,
          departureTime: r.departureTime,
          route: r.route,
        }
      }
    })
    return snapshotDeparture
  }

  private getFareSelections(outboundDepartureId: string, returnDepartureId: string): IDepartureSelection[] {
    let fareSelection: IDepartureSelection[] = []
    if (outboundDepartureId && outboundDepartureId != this.reservation.departures[0].departureId) {
      fareSelection.push({ newDepartureId: outboundDepartureId, oldDepartureId: this.reservation.departures[0].departureId })
    }
    if (returnDepartureId && returnDepartureId != this.reservation.departures[1].departureId) {
      fareSelection.push({ newDepartureId: returnDepartureId, oldDepartureId: this.reservation.departures[1].departureId })
    }
    return fareSelection
  }

  navigateBack() {
    this.router.navigate(['/amendment/booking', this.reservationCode])
  }

  private getMd5() {
    return hashObject({
      o: this.data.out.selectedDeparture,
      r: this.data.return ? this.data.return.selectedDeparture : null,
    })
  }
}

class amendmentFaresModel {
  constructor(public date: string, public time: string, public day: string, public route: string) {}
}
