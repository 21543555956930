<sbw-layout [backButtonVisible]="false" [type]="'Amendment'" [isAgent]="true" [continueButtonVisible]="false">
  <div class="sbw_pagewbox" *ngIf="resourcesLoaded">
    <div class="row uncollapse agent-profile-container">
      <h1>{{ 'MENU_AGENT_TOP_BOOKINGS' | resource }}</h1>
    </div>
    <div class="route-section">
      <div class="row">
        <div class="small-12 medium-3 column end">
          <div class="field">
            <span class="label">{{ 'AGENT_SEARCH_ROUTE' | resource }}</span>
            <select id="defaultRoute" [formControl]="selectedRoute">
              <option value="all">
                {{ 'ALL_ROUTES' | resource }}
              </option>
              <option *ngFor="let route of agentWire.outboundRoutes" [value]="route.code">
                {{ route.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="timeframe-section">
      <div class="row">
        <div class="box_item small-12 column end half-gutter">
          <span class="radio-button-gutter">
            <input [formControl]="dateFilter" id="bookingDate" value="BookingDate" name="DateFilter" type="radio" />
            <label for="bookingDate">{{ 'AGENT_SEARCH_DATE_OF_BOOKING' | resource }}</label>
          </span>
          <span class="radio-button-gutter">
            <input [formControl]="dateFilter" id="departureDate" value="DepartureDate" name="DateFilter" type="radio" />
            <label for="departureDate">{{ 'AGENT_SEARCH_DEPARTURE_DATE' | resource }}</label>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="small-12 medium-3 column end">
          <div class="field">
            <span class="label">{{ 'AGENT_PRODUCTS_SEARCH_TIMEFRAME' | resource }}</span>
            <select id="timeframe" [formControl]="timeframe" (change)="onTimeframeChange()">
              <option [value]="-1">
                {{ 'AGENT_SEARCH_NO_TIMEFRAME' | resource }}
              </option>
              <option [value]="7">
                {{ 'AGENT_SEARCH_LAST_WEEK' | resource }}
              </option>
              <option [value]="31">
                {{ 'AGENT_SEARCH_LAST_MONTH' | resource }}
              </option>
              <option [value]="0">
                {{ 'SPECIFIC_TIMEFRAME' | resource }}
              </option>
            </select>
          </div>
        </div>
        <div class="small-12 medium-2 column end">
          <div class="field">
            <span class="label">{{ 'AGENT_PRODUCTS_SEARCH_FROM' | resource }}</span>
            <sbw-datepicker
              [dateControl]="formDateFrom"
              name="dateForm"
              [viewModel]="dateOfBirthSettings.datepickerView"
            ></sbw-datepicker>
          </div>
        </div>
        <div class="small-12 medium-2 column end">
          <div class="">
            <span class="label">{{ 'AGENT_PRODUCTS_SEARCH_TO' | resource }}</span>
            <sbw-datepicker
              [dateControl]="formDateTo"
              name="dateTo"
              [viewModel]="dateOfBirthSettings.datepickerView"
            ></sbw-datepicker>
          </div>
        </div>
      </div>
    </div>
    <div class="search-section">
      <div class="row">
        <div class="box_item small-12 column end half-gutter">
          <span class="radio-button-gutter">
            <input [formControl]="searchFilter" id="bookingNumber" name="CriteriaFilter" value="reservationNumber" type="radio" />
            <label for="bookingNumber">{{ 'AGENT_SEARCH_BOOKING_NUMBER' | resource }}</label>
          </span>
          <span class="radio-button-gutter">
            <input [formControl]="searchFilter" id="surName" name="CriteriaFilter" value="PassengerSurname" type="radio" />
            <label for="surName">{{ 'AGENT_SEARCH_PASSENGER_SURNAME' | resource }}</label>
          </span>
          <span class="radio-button-gutter">
            <input [formControl]="searchFilter" id="agentReference" name="CriteriaFilter" value="AgentContact" type="radio" />
            <label for="agentReference">{{ 'AGENT_SEARCH_AGENCY_REFERENCE' | resource }}</label>
          </span>
          <span class="radio-button-gutter">
            <input [formControl]="searchFilter" id="vehicleRegistration" name="CriteriaFilter" value="VehicleRegistration" type="radio" />
            <label for="vehicleRegistration">{{ 'AGENT_SEARCH_VEHICLE_REGISTRATION' | resource }}</label>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="small-12 medium-3 column end">
          <div class="field">
            <span class="label">{{ 'AGENT_SEARCH_CRITERIA' | resource }}</span>
            <input [formControl]="criteria" id="searchCriteria" type="text" />
          </div>
        </div>
      </div>
    </div>
    <div class="button-section">
      <div class="row">
        <div class="small-12 medium-3 end column">
          <sbw-button
            id="SubmitContinue"
            type="preferred"
            [(state)]="submitButtonState"
            [text]="'AGENT_PRODUCTS_SEARCH_BUTTON' | resource"
            (clicked)="getBookings()"
          ></sbw-button>
        </div>
      </div>
    </div>
    <div class="bookings-section">
      <div class="row">
        <div class="small-12 column flex">
          <h2>Existing bookings</h2>
          <div class="right">{{ displayedBookings.length }}/{{ inputBookings.length }}</div>
        </div>
      </div>
      <div *ngIf="displayedBookings" class="row">
        <div class="small-12 column">
          <div class="group-panel">
            <div class="row uncollapse group-header">
              <div class="column small-2 hide-for-medium-down">
                <h5>{{ 'AGENT_SEARCH_BOOKING_NUMBER' | resource }}</h5>
              </div>
              <div class="column small-2 hide-for-medium-down">
                <h5>{{ 'PASSENGER' | resource }}</h5>
              </div>
              <div class="column small-2 hide-for-medium-down">
                <h5>{{ 'DEPARTURE' | resource }}</h5>
              </div>
              <div class="column small-2 hide-for-medium-down">
                <h5>{{ 'AGENT_PRODUCTS_SEARCH_ROUTE' | resource }}</h5>
              </div>
              <div class="column small-2 hide-for-medium-down">
                <h5>{{ 'AGENT_SEARCH_BOOKED_ON' | resource }}</h5>
              </div>
              <div class="column small-2 hide-for-medium-down">
                <h5>{{ 'AGENT_AGENT_REFERENCE_TITLE' | resource }}</h5>
              </div>
            </div>
            <div class="all-bookings">
              <div class="row uncollapse group-item" *ngFor="let booking of displayedBookings" [attr.data-reservationcode]="booking.code">
                <a class="anonymous" [routerLink]="routerAction(booking)" [ngClass]="{ cancelled: booking.status === 'CANCELLED' }">
                  <div class="column small-12 large-2">
                    <div class="row">
                      <div class="column small-4 hide-for-large-up option-text">
                        <h5>{{ 'AGENT_SEARCH_BOOKING_NUMBER' | resource }}</h5>
                      </div>
                      <div class="column small-8 large-12 option-count booking-code">
                        {{ booking.code }}
                      </div>
                    </div>
                  </div>

                  <div class="column small-12 large-2">
                    <div class="row">
                      <div class="column small-4 hide-for-large-up option-text">
                        <h5>{{ 'PASSENGER' | resource }}</h5>
                      </div>
                      <div class="column small-8 large-12 option-count">
                        {{ booking.passenger }}
                      </div>
                    </div>
                  </div>

                  <div class="column small-12 large-2">
                    <div class="row">
                      <div class="column small-4 hide-for-large-up option-text">
                        <h5>{{ 'DEPARTURE' | resource }}</h5>
                      </div>
                      <div class="column small-8 large-12 option-count">
                        {{ booking.departure }}
                      </div>
                    </div>
                  </div>

                  <div class="column small-12 large-2">
                    <div class="row">
                      <div class="column small-4 hide-for-large-up option-text">
                        <h5>{{ 'AGENT_PRODUCTS_SEARCH_ROUTE' | resource }}</h5>
                      </div>
                      <div class="column small-8 large-12 option-count">
                        {{ booking.route }}
                      </div>
                    </div>
                  </div>

                  <div class="column small-12 large-2">
                    <div class="row">
                      <div class="column small-4 hide-for-large-up option-text">
                        <h5>{{ 'AGENT_SEARCH_BOOKED_ON' | resource }}</h5>
                      </div>
                      <div class="column small-8 large-12 option-count">
                        {{ booking.bookedOn }}
                      </div>
                    </div>
                  </div>

                  <div class="column small-12 large-2">
                    <div class="row">
                      <div class="column small-4 hide-for-large-up option-text">
                        <h5>{{ 'AGENT_AGENT_REFERENCE_TITLE' | resource }}</h5>
                      </div>
                      <div class="column small-8 large-12 option-count">
                        {{ booking.agentReference }}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="hasMoreBookings()" class="row">
        <div class="small-12 column show-more-container">
          <div class="text-center">
            <a (click)="showMore()" class="button expand show-more">
              {{ 'AMENDMENT_SHOW_MORE' | resource }} <i class="down-arrow">&nbsp;</i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</sbw-layout>
