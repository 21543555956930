<!-- Menu -->
<div class="layout-flex">
    <sbw-top-box [type]="this.type"></sbw-top-box>
    <sbw-agent-menu *ngIf="isAgent && menuVisible"></sbw-agent-menu>
    <sbw-menu *ngIf="menuVisible"></sbw-menu>
    <div class="help wrapper" style="height: 68px" *ngIf="!menuVisible">
        <div class="row">
        </div>
    </div>

    <div class="main-area wrapper main-flex">
        <div class="row">
            <div class="column small-12 main-area"
                [ngClass]="{ 'large-locked-beside left': !isAgent && type === 'Flow' }">
                <div [ngClass]="amendmentPage ? 'content-amendment quote-flow' : 'content flow-container quote-flow'">
                    <sbw-spinner *ngIf="isLoading"></sbw-spinner>

                    <!-- Breadcrumb -->
                    <sb-breadcrumb *ngIf="type === 'Flow'"></sb-breadcrumb>

                    <!-- Content -->
                    <ng-content *ngIf="isBrowserStorageSupported"></ng-content>
                    <div *ngIf="!isBrowserStorageSupported" class="attention">
                        {{'LOCAL_STORAGE_MESSAGE' | resource:'Common'}}</div>
                    <div class="row" *ngIf="type === 'Amendment' && (continueButtonVisible || backButtonVisible)">  
                      <div id="layout-button-container">  
                      <div class="column small-12 medium-4 medium-push-8 large-3 large-push-9 field submit">
                          <sbw-button id="SubmitContinue" type="preferred" [(state)]="continueButtonState" [text]="continueButtonResource | resource" (clicked)="continueEvent.emit()" *ngIf="continueButtonVisible"></sbw-button>
                        </div>
                        <div class="column small-12 medium-4 medium-pull-8 large-3 large-pull-9 field submit">
                            <sbw-button id="SubmitBack" type="previous" [(state)]="backButtonState"
                            (clicked)="backEvent.emit()" [text]="backButtonResource | resource"
                                *ngIf="backButtonVisible"></sbw-button>
                        </div>
                        
                      </div>
                      <div id="layout-sticky-container">
                        <sbw-button id="SubmitContinue" type="preferred" [(state)]="continueButtonState" [text]="continueButtonResource | resource" (clicked)="continueEvent.emit()" *ngIf="continueButtonVisible"></sbw-button>
                      </div>
                    </div>
                    <div class="hide-for-medium-down" *ngIf="footerVisible && !hideDisclaimer">
                        <div class="row">
                            <div class="column small-12" [innerHTML]="'DISCLAIMER_FOOTER' | resource:footerComponent">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!isAgent && type === 'Flow'"
                class="column small-12 itinerary large-locked-width right sticky-quote">
                <div class="quote-container">
                    <sbw-quote [useWithLayout]="true" [showPrice]="quoteShowTotalPrice"
                        [showDepartureTime]="quoteShowDepartureTime" [step]="quoteStep" [quote]="quoteModel">
                    </sbw-quote>
                    <div class="row collapse" *ngIf="continueButtonVisible">
                        <div class="column small-12 field">
                            <sbw-button id="SubmitContinue" type="preferred" [(state)]="continueButtonState"
                                [text]="continueButtonResource | resource" (clicked)="getContinueEvent()">
                            </sbw-button>
                        </div>
                    </div>
                    <div class="row collapse" *ngIf="backButtonVisible">
                        <div class="column small-12 field">
                            <sbw-button id="SubmitBack" type="previous" [(state)]="backButtonState"
                                (clicked)="backEvent.emit()" [text]="backButtonResource | resource"></sbw-button>
                        </div>
                    </div>

                </div>
            </div>
            <div class="column small-12 hide-for-large-up" *ngIf="footerVisible">
                <div class="row">
                    <div class="column small-12 gutter">
                        <div class="disclaimer" [innerHTML]="'DISCLAIMER_FOOTER' | resource:footerComponent"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <sbw-footer></sbw-footer>
</div>
