    <div class="column small-12 surrounding-container contact-container">
        <div class="column small-12 nopadding">
            <div class="row">
                <div data-hj-suppress class="column small-12">
                    <h5>{{ inputContactDetails.title + ' ' + inputContactDetails.firstName + ' ' + inputContactDetails.lastName }}</h5>
                </div>
            </div>
            <div class="row">
                <div id="addressLine1" data-hj-suppress class="column small-12">
                    {{ inputContactDetails.addressLine1 }}
                </div>
            </div>
            <div class="row" data-hj-suppress *ngIf="inputContactDetails.addressLine2">
                <div id="addressLine2" class="column small-12">
                    {{ inputContactDetails.addressLine2 }}
                </div>
            </div>
            <div class="row" data-hj-suppress *ngIf="inputContactDetails.county">
                <div id="county" class="column small-12">
                    {{ inputContactDetails.county }}
                </div>
            </div>
            <div class="row">
                <div id="postal-and-city" data-hj-suppress class="column small-12">
                    {{ inputContactDetails.postalCode }} {{ inputContactDetails.city }}
                </div>
            </div>
            <div class="row">
                <div id="country" data-hj-suppress class="column small-12">
                    {{ inputContactDetails.country }}
                </div>
            </div>
            <div class="row" style="margin-top:.5em;">
                <div id="mobile" data-hj-suppress class="column small-12">
                    {{ 'CONTACT_MOBILE' | resource }}: {{ inputContactDetails.mobileNumber }}
                </div>
            </div>
            <div class="row">
                <div id="email" data-hj-suppress class="column small-12">
                    {{ 'CONTACT_EMAIL' | resource }}: {{ inputContactDetails.emailAddress }}
                </div>
            </div>
        </div>
    </div>
