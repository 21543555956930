import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AgentIdService {

    constructor() { }
    getAgentId(): string {
        return localStorage.getItem('sbw_AgentGenericId')
    }

}
