import { Injectable } from '@angular/core'

import { IFlowReservation, vehiclesParameters } from '../shared/steps/step.model'
import { ResourceService } from 'app/shared/resources/resource.service';
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service';
import { LocalDate } from 'js-joda';
import { Observable } from 'rxjs';
import { toParamsObj } from 'app/shared/utils';
import { ICabinFaresWire, IWireLegSelections, IHybridResult } from './cabin-fares-wire.interfaces';
import { AgentIdService } from 'app/shared/agent-id.service';
import { StatelessHttpService } from 'app/shared/stateless-http.service';

@Injectable()
export class CabinFaresWireService {

    constructor(
        private statelessHttpService: StatelessHttpService,
        private resourceService: ResourceService, 
        private salesChannelService: SalesChannelService,
        private agentIdService: AgentIdService) {}

    public getCabinFaresFlow(reservation: IFlowReservation, outboundDepartureDate: LocalDate, returnDepartureDate: LocalDate): Observable<ICabinFaresWire> {
        let params = {
            salesOwnerId: this.resourceService.getSalesOwner(),
            localeCode: this.resourceService.getLocale(),
            salesChannelCode: this.salesChannelService.getCode(),
            outboundRouteCode: reservation.outboundRouteCode,
            outboundDepartureDate: outboundDepartureDate,
            productCode: reservation.productCode,
            returnRouteCode: reservation.returnRouteCode,
            returnDepartureDate: returnDepartureDate,
            adults: reservation.adults,
            children: reservation.children,
            childAges: reservation.childrenAges,
            infants: reservation.infants,
            isAmendment: false,
            offer: reservation.offerCode,
            ...vehiclesParameters(reservation),
            agentGenericId: this.agentIdService.getAgentId()
        }
        return this.statelessHttpService.get<ICabinFaresWire>(false, 'cabin-fares-flow', { params: toParamsObj(params) })
    }

    public postCabinFaresFlow(selections: IWireLegSelections): Observable<IHybridResult> {
        return (this.statelessHttpService.post(false, 'cabin-fares-flow', selections) as Observable<IHybridResult>)
    }

}
