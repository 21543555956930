<ng-container *ngIf="cabinDetails">

    <div class="content">
        <p [innerHtml]="cabinDetails.description | markdown"></p>
    </div>

    <div class="row carrousel">
        <div *ngFor="let img of carrouselWindow(); index as idx" class="column small-6">
            <div *ngIf="idx == 0 && showArrows()" class="prevnext"><a (click)="prev()">&nbsp;</a>&nbsp;</div>
            <div class="imageContainer">
                <img class="carrouselCabinPhoto" [src]="img.fields.file.url">
                <br>&nbsp;
            </div>
            <div *ngIf="idx == 1 && showArrows()" class="prevnext next">&nbsp;<a (click)="next()">&nbsp;</a></div>
        </div>
    </div>

</ng-container>
