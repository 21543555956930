import { Component, OnInit, Input, ViewChildren, QueryList, OnChanges } from '@angular/core';
import { ContentfulSeabookWebService } from 'app/shared/resources/contentful-seabookweb.service';
import { EnvironmentService } from 'app/environment.service';

@Component({
    selector: 'sbw-cabin-details',
    templateUrl: './cabin-details.component.html',
    styleUrls: ['./cabin-details.component.css']
})
export class CabinDetailsComponent implements OnInit, OnChanges {
    @Input() cabinTypeCode: string
    @Input() routeCode: string
    @Input() cabinDetails: any
    public contentfulEntry: any
    public contentfulEditMode: boolean
    public carrouselOffset = 0

    constructor(private contentfulSeabookWebService: ContentfulSeabookWebService, private environmentService: EnvironmentService) { }

    ngOnInit() {
        this.contentfulEditMode = this.environmentService.contentfulEditMode

        this.environmentService.contentfulEditModeChanged$
            .subscribe(enabled => {
                this.contentfulEditMode = enabled
            })


        if (this.cabinTypeCode && !this.cabinDetails)
            this.contentfulSeabookWebService.loadCabinDetails(this.cabinTypeCode).then(cabinDetails => {
                this.cabinDetails = cabinDetails
            })

    }

    ngOnChanges() {
        this.carrouselOffset = this.photos().length * 100000
    }

    private photos() { return this.cabinDetails && this.cabinDetails.cabinPhotos && this.cabinDetails.cabinPhotos.filter(f => f.fields.file && f.fields.file.url) || [] }

    public showArrows() { return this.photos().length > 2 }

    public carrouselWindow(): any[] {
        let p = this.photos()
        if (p.length == 0) return []
        if (p.length == 1) return [p[this.carrouselOffset % p.length]]
        return [p[this.carrouselOffset % p.length], p[(this.carrouselOffset + 1) % p.length]]
    }

    public next() { this.carrouselOffset++ }

    public prev() { this.carrouselOffset-- }

}
