import { Component, OnInit, Input, OnChanges } from '@angular/core'
import { IVehicle, ITrailer, IVehicleTypes } from 'app/vehicles/vehicles.interfaces'
import { FormGroup, FormControl } from '@angular/forms'
import { startWith } from 'rxjs/operators';
import { SelectItem } from 'app/shared/select-field/select.model';

@Component({
    selector: 'sbw-vehicles-selector',
    templateUrl: './vehicles-selector.component.html',
    styleUrls: ['./vehicles-selector.component.css']
})
export class VehiclesSelectorComponent implements OnInit, OnChanges {

    @Input() vehicles: IVehicleTypes
    @Input() supportMultiple = false
    @Input() formVehicleSelector: FormGroup
    @Input() errorVehicle = false
    @Input() errorTrailer = false

    get vehicleControl(): FormControl { return <FormControl>this.formVehicleSelector.get('vehicle') }
    get trailerControl(): FormControl { return <FormControl>this.formVehicleSelector.get('trailer') }
    get vehicleCountControl(): FormControl { return <FormControl>this.formVehicleSelector.get('count') }

    get vehicle(): IVehicle { return <IVehicle>this.vehicleControl.value }
    get trailer(): ITrailer { return <ITrailer>this.trailerControl.value }

    get isVehicleSelected(): boolean { return !!this.vehicleControl.value }

    vehicleItems: SelectItem[] = []
    trailerItems: SelectItem[] = []
    vehicleCountItems: SelectItem[] = new Array(9).fill(0).map((_, i) => i + 1).map(i => new SelectItem(i, i.toString()))

    ngOnInit() {
        this.initForm()
    }

    ngOnChanges() {
        const noVehicle = this.vehicles.isVehicleMandatory ? [] : [new SelectItem('NCAR', this.vehicles.localizedNoVehicle)]
        this.vehicleItems = noVehicle.concat(this.vehicles.vehicleTypes.map(v => new SelectItem(v.code, v.fullDescription)))
    }

    private initForm() {
        // Listen for vehicle & trailer changes from intern component
        this.vehicleControl.valueChanges.pipe(startWith(this.vehicleControl.value))
            .subscribe(s => {
                const selectedVehicle = this.vehicles.vehicleTypes.find(v => v.code == s)
                this.trailerItems = selectedVehicle
                    ? [new SelectItem('', this.vehicles.localizedNoTrailer)].concat(selectedVehicle.availableTrailers.map(v => new SelectItem(v.code, v.fullDescription)))
                    : []
                if (this.trailerItems.length) this.trailerControl.enable()
                else this.trailerControl.disable()
            })
    }

}

export function selectedVehicle(vehicles: IVehicleTypes, form: FormGroup) {
    return vehicles.vehicleTypes.find(v => v.code == form.get('vehicle').value)
}

export function vehicleName(vehicles: IVehicleTypes, form: FormGroup) {
    const sv = selectedVehicle(vehicles, form)
    return sv && sv.fullDescription
}

export function selectedTrailer(vehicles: IVehicleTypes, form: FormGroup) {
    const sv = selectedVehicle(vehicles, form)
    return sv && sv.availableTrailers.find(t => t.code == form.get('trailer').value)
}

export function trailerName(vehicles: IVehicleTypes, form: FormGroup) {
    const t = this.selectedTrailer(vehicles, form)
    return t && t.description
}
