<div class="content quote">
    <sbw-spinner *ngIf="isLoading"></sbw-spinner>
    
    <div class="header-item" [innerHTML]="'ITINERARY' | resource"></div>

    <div *ngIf="model && show" id="QuoteComponent" class="row uncollapse container">
        <div class="column small-12">
            <div *ngIf="model.offerSpecification" class="plan">
                <div class="header">
                    <span class="offer-header" [innerHTML]="'OFFER_LEGEND' | resource"></span>
                </div>
                <span class="offer-description" [innerHTML]="model.offerSpecification.description"></span>
            </div>
            <div class="plan outbound">
                <sbw-quotedetail [showDepartureTime]="showDepartureTime" [leg]="model.outbound" [dateFormat]="'ITINERARY_DATETIME_FORMAT' | resource"></sbw-quotedetail>
            </div>
            <div *ngIf="model.return" class="plan return">
                <sbw-quotedetail [showDepartureTime]="showDepartureTime" [leg]="model.return" [dateFormat]="'ITINERARY_DATETIME_FORMAT' | resource"></sbw-quotedetail>
            </div>
            <div *ngIf="model.final" class="plan final">
                <sbw-quotedetail [showDepartureTime]="showDepartureTime" [leg]="model.final"></sbw-quotedetail>
            </div>
        </div>
        <ul *ngIf="showPrice" class="total-price">
            <li class="total" [ngClass]="{'deposit-selected': model.deposit}">
                <div class="label" [innerHTML]="'TOTAL_PRICE' | resource"></div>
                <div class="price currency">{{model.currencyCode | resource}}</div>
                <div class="price amount" [innerHTML]="model.totalPrice | resourceformat:'CURRENCY_FORMAT'"></div>
            </li>
            <li *ngIf="model.deposit" class="quote-deposit up-front">
                <div class="label" [innerHTML]="'DEPOSIT_PAYNOW' | resource"></div>
                <div class="price currency">{{model.currencyCode | resource}}</div>
                <div class="price amount" [innerHTML]="model.deposit | resourceformat:'CURRENCY_FORMAT'"></div>
            </li>
            <li *ngIf="model.deposit" class="quote-deposit remaining">
                <div class="label" [innerHTML]="'DEPOSIT_REMAINING' | resource:'Quote'"></div>
                <div class="price currency">{{model.currencyCode | resource}}</div>
                <div class="price amount" [innerHTML]="model.remainingAmount | resourceformat:'CURRENCY_FORMAT'"></div>
            </li>
        </ul>
    </div>

</div>
