import { Injectable } from "@angular/core";
import { StatelessHttpService } from "app/shared/stateless-http.service";
import { ResourceService } from "app/shared/resources/resource.service";
import { toParamsObj } from "app/shared/utils";
import { Observable, of } from 'rxjs';
import { IPetsFlowResult, PetAndCountModel, PetsPostWire, PetsWire } from './pets.interface';
import { StepService } from 'app/shared/steps/step.service';
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service';
import { LocalStorageStepData, SelectionTypeEnum } from 'app/shared/steps/step.model'
import { QuoteChanged, PriceSpecificationType, QuoteLine, UserQuoteDetails } from 'app/shared/quote/quote.events';
import { StatefulHttpService } from 'app/shared/stateful-http.service';

@Injectable()
export class PetsService {
    constructor(
        private statelessHttpService: StatelessHttpService,
        private statefulHttpService: StatefulHttpService,
        private resourceService: ResourceService,
        private salesChannelService: SalesChannelService,
        private stepService: StepService
    ) { }

    public getPetsFlow(): Observable<PetsWire> {
        const reservation: any = this.stepService.combineToReservation()
        let params = {
            'salesOwnerId': this.resourceService.getSalesOwner(),
            'locale': this.resourceService.getLocale(),
            'outboundDepartureId': reservation.outboundDepartureId,
            'returnDepartureId': reservation.returnDepartureId || "",
            'productCode': reservation.productCode,
            'salesChannelCode': this.salesChannelService.getCode(),
            'numberOfPets' : reservation.pets
        }

        return this.statelessHttpService.get<PetsWire>(false, 'pets-flow', { params: toParamsObj(params)})
    }

    public postHybridPets(petsWire: PetsPostWire): Observable<IPetsFlowResult> {
        this.updateStepInLocalStorage(petsWire.selections) // Should maybe be moved out so it calls after post is done?
        return this.statefulHttpService.post<IPetsFlowResult>(false, 'pets-flow', petsWire)
    }

    private updateStepInLocalStorage(selections: PetAndCountModel[]) {
        let step: any = {}
        step.petsSelection = new LocalStorageStepData(SelectionTypeEnum.User, selections)
        this.stepService.saveStepData(step, window.location.pathname, false)
    }

    public getQuoteModel(petsSelection: PetAndCountModel[], currencyCode: string, oneway: boolean): QuoteChanged {
        return new QuoteChanged(PriceSpecificationType.Pets,
            this.getQuoteData(petsSelection, currencyCode, oneway),
            !oneway ? this.getQuoteData(petsSelection, currencyCode, oneway) : null,
            null)
    }

    private getQuoteData(petsSelection: PetAndCountModel[], currencyCode: string, oneway: boolean): UserQuoteDetails {
        if (petsSelection == null) { return null }
        return new UserQuoteDetails(
            petsSelection.filter(x => x.count >= 1).map((x: PetAndCountModel) => new QuoteLine(x.pet.name, oneway ? x.pet.price : x.pet.price / 2, currencyCode, x.count)))
    }
}

