import { LocalDate, LocalDateTime } from 'js-joda'
import { ITracking } from '../shared/tracking/tracking-wire.interfaces'

export module Fare {
    export enum FarePriceCategory {
        Cheapest,
        None
    }
    export enum RowItemStatus {
        Available = 'Available',
        SoldOut = 'SoldOut',
        Hide = 'Hide',
        Disabled = 'Disabled'
    }
    export enum ProductClassChanged {
        Saver,
        Economy,
        Flexi,
        Other
    }

    /* Wire model */
    export interface IWire extends IOutReturn<IDeparture> {
        currency: string
        products: IProduct[]
        daysDuration: number
    }
    /* Hold data for one direction/leg (out/return) */
    export interface IDeparture {
        dateOfDeparture: LocalDate
        route: string
        rows: IRow[]
        limitDate?: Date
        selectedDeparture: ISelectedDeparture
        dates: ILegDates
    }

    export interface ILegDates {
        disabled: LocalDate[]
        min: LocalDate
        max: LocalDate
        offer: LocalDate[]
    }

    /* Selected departure represented as departureId and productCode */
    export interface ISelectedDeparture {
        departureId: string
        productCode: string
    }
    /* One row in grid */
    export interface IRow {
        departureId: string
        departureTime: LocalDateTime
        arrivalTime: LocalDateTime
        route: string
        routeCode: string
        items: IRowItem[]
    }
    /* One item in a row */
    export interface IRowItem {
        productCode: string
        price: number
        category?: FarePriceCategory,
        status: RowItemStatus
        _oldStatus?: RowItemStatus
    }
    /* Type of products and text */
    export interface IProduct {
        productCode: string
        description: string,
        category?: FarePriceCategory
    }
    /* Period that a product is valid */
    export interface IDatePeriod {
        from: LocalDate
        to: LocalDate
    }
    /* EventEmitter object when an item is clicked */
    export interface IRowItemChanged {
        direction: Direction
        price: number
        departureTime: LocalDateTime
        route: string,
        product: string
    }

    export interface IFaresWire extends IOutReturn<string> { }
    export interface IFaresFlowResult {
        errorList: { errorCode: number, message: string }[]
        nextStepUrl: string,
        step: any
        quote: any
        trackingData: ITracking
    }

    export interface IFareChangedMessage extends IOutReturn<IRowItemChanged> {
        currency: string
    }

    export type Direction = 'out' | 'return'

    export interface IOutReturn<T> {
        out: T
        return?: T
    }

    export interface IDepartureChangeDateForDirection {
        direction: Direction
        date: LocalDateTime
    }

    export interface IFaresPostRequest extends IOutReturn<IPostDeparture> { }

    export interface IPostDeparture {
        departureId: string
        productCode: string
        expectedPrice: number
    }

}

export interface IFaresContentModel {
    tier3WhatIsIncluded: string
    tier3CategoryChangedHeader: string
    tier3CategoryChanged: string
    acceptAndContinue: string
}
