import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { trigger, state, style, transition, animate, } from '@angular/animations'

import { zip } from 'rxjs'
import { LocalTime } from 'js-joda'

import { IMealArrangementModel, IRestaurantModel, IRoomCodeWithTime } from 'app/meals/meals-selector-models'
import { ResourceService } from '../../shared/resources/resource.service'
import { flatMap } from '../../shared/utils'
import { MealsTrackingService } from '../../shared/tracking/meals-tracking.service'
import { StepService } from 'app/shared/steps/step.service'

@Component({
    selector: 'sbw-meals-selector',
    templateUrl: './meals-selector.component.html',
    styleUrls: ['./meals-selector.component.css'],
    animations: [
        trigger('hourState', [
            state('expand', style({ opacity: 1 })),
            transition('void => *', [
                style({
                    opacity: 0,
                }),
                animate('0.4s ease-in')
            ])
        ])
    ]
})

export class MealsSelectorComponent implements OnInit {

    collapseExpand: string;
    @Input() currencyCode: string
    @Input() routeCode: string // routes in contentful are defined both ways so doesn't really matter if we put OSCH or CHOS except it can improve caching if we always use outbound route code or else it would have do get content twice
    @Output() mealsChanged = new EventEmitter()
    @Input() restaurants: IRestaurantModel[]
    highlightedRestaurantResource = ''
    expandedMealArrangements: string[] = []
    tableHourLessThan10: string = null
    tableHourLessThan20: string = null

    constructor(
        private resourceService: ResourceService,
        private mealsTrackingService: MealsTrackingService,
        private stepService: StepService
    ) { }

    ngOnInit() {
        let codes = this.restaurants.flatMap<IRestaurantModel, IRoomCodeWithTime>(x => x.mealArrangements.flatMap<IMealArrangementModel, IRoomCodeWithTime>(y => y.roomCodesWithTime)).map(x => x.code.match(/^[\D]*/g).toString())
        let uniqueCodes = codes.filter((elem, pos) => { return codes.indexOf(elem) === pos })

        uniqueCodes.map(x => {
            const key = `HIGHLIGHT_RESTAURANT_${x}`
            this.resourceService.get(key).subscribe(result => {
                if (result && result !== key && result.trim() !== '') { this.highlightedRestaurantResource = key }
            })
        })

        zip(this.resourceService.get('TABLE_HOUR_LESS_THAN_10'), this.resourceService.get('TABLE_HOUR_LESS_THAN_20')).subscribe(response => {
            const [tableHourLessThan10, tableHourLessThan20] = response
            if (tableHourLessThan10 && tableHourLessThan10 !== 'TABLE_HOUR_LESS_THAN_10' && tableHourLessThan10.trim() !== '') { this.tableHourLessThan10 = tableHourLessThan10 }
            if (tableHourLessThan20 && tableHourLessThan20 !== 'TABLE_HOUR_LESS_THAN_20' && tableHourLessThan20.trim() !== '') { this.tableHourLessThan20 = tableHourLessThan20 }
        })
        this.expandedMealArrangements = flatMap(this.restaurants, r => r.mealArrangements.filter(m => m.selected).map(z => z.id))
    }

    selectedMeal(mealArrangement: IMealArrangementModel) {
        if ((mealArrangement.selected && this.expandedMealArrangements.indexOf(mealArrangement.id) > -1) ||
            (!mealArrangement.selected && this.expandedMealArrangements.indexOf(mealArrangement.id) === -1)) {
            this.toggleCollapseExpand(mealArrangement.id)
        }
        mealArrangement.selected = !mealArrangement.selected
        this.mealsChanged.emit(mealArrangement)
    }

    selectedTimeMeal(value: LocalTime, mealArrangement: IMealArrangementModel) {
        mealArrangement.selectedTime = value
        mealArrangement.selected = true
        this.mealsChanged.emit(mealArrangement)
        // this.trackingService.trackChangedMealHour('meals-hour-selection-table', value.toString())
    }

    isHighlightedRestaurant(restaurant: IRestaurantModel): boolean {
        let restaurantCode = this.getRestaurantCode(restaurant)
        let result = this.highlightedRestaurantResource === 'HIGHLIGHT_RESTAURANT_' + restaurantCode.toString().toUpperCase()
        return result
    }

    toggleCollapseExpand(mealArrangementId: string): void {
        this.expandedMealArrangements.indexOf(mealArrangementId) === -1 ? this.expandedMealArrangements.push(mealArrangementId) : this.expandedMealArrangements.splice(this.expandedMealArrangements.indexOf(mealArrangementId), 1)
        this.collapseExpand = this.collapseExpand === 'collapse' ? 'expand' : 'collapse'
    }

    expand(mealArrangementId: string): boolean {
        return this.expandedMealArrangements.some(id => id === mealArrangementId)
    }

    showCapacityOnTableHour(capacity: number): boolean {
        return capacity < 10 && this.tableHourLessThan10 !== null
    }

    showCapacityWarningWhenUnder10(mealArrangements: IMealArrangementModel[]): boolean {
        if (this.tableHourLessThan10 === null) { return false }
        return this.showCapacityWarning(mealArrangements, 10, 1)
    }

    showCapacityWarningWhenUnder20(mealArrangements: IMealArrangementModel[]): boolean {
        if (this.tableHourLessThan20 === null) { return false }
        return this.showCapacityWarning(mealArrangements, 20, 10)
    }

    private showCapacityWarning(mealArrangements: IMealArrangementModel[], upperLimit: number, lowerLimit: number): boolean {
        if (mealArrangements && mealArrangements.length > 0 && mealArrangements[0].mealTimes && mealArrangements[0].mealTimes.length > 0) {
            const mealTimes = mealArrangements[0].mealTimes
            const capacityOnRoomArrangement = mealTimes.map(x => x.avalilableCapacity).reduce((a, b) => (a + b))
            if (capacityOnRoomArrangement < upperLimit && capacityOnRoomArrangement >= lowerLimit) {
                return true
            }
        }
        return false;
    }

    isSelected(selectedTme: LocalTime, timeFromMealTimes: LocalTime): boolean {
        return selectedTme.equals(timeFromMealTimes)
    }

    getRestaurantCode(restaurant: IRestaurantModel): string {
        let restaurantCode = restaurant.mealArrangements[0].roomCodesWithTime[0].code
        return restaurantCode.match(/^[\D]*/g).toString().toUpperCase()
    }

    getMenuIconForRestaurantWithCapacity(restaurant: IRestaurantModel) {
        return this.isHighlightedRestaurant(restaurant) &&
            !(this.showCapacityWarningWhenUnder10(restaurant.mealArrangements) || this.showCapacityWarningWhenUnder20(restaurant.mealArrangements))
            ? 'food-menu-white.svg' : 'food-menu-blue.svg';
    }

    getMenuIconForRestaurant(restaurant: IRestaurantModel) {
        return this.isHighlightedRestaurant(restaurant) ? 'food-menu-white.svg' : 'food-menu-blue.svg';
    }

    restaurantPopupStateChanged(state, restaurant) {

        if (state) {
            let flowReservation = this.stepService.combineToReservation()
            this.mealsTrackingService.trackRestaurantMenuPopup(flowReservation, restaurant)
        }
    }

    getRestaurantName(restaurant: IRestaurantModel): string {
        return restaurant.name ? restaurant.name : restaurant.code
    }
}

