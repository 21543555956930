import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service';

import { UIModule } from 'app/shared/UI.module';
import { FlowGuard } from 'app/shared/guards/flow.guard';

import { PetsComponent } from './flow-pets/pets.component';
import { PetsService } from './pets.service';
import { StepService } from 'app/shared/steps/step.service';
import { SbEvent } from 'app/shared/sb-event.emitter';
import { PetsSelectorComponent } from './pets-selector/pets-selector.component';

const appRoutes: Routes = [
    { path: 'pets', component: PetsComponent, canActivate: [FlowGuard] },
];

@NgModule({
    imports: [
      CommonModule,
      RouterModule.forRoot(appRoutes),
      SharedModule,
      UIModule,
      FormsModule,
      ReactiveFormsModule,
    ],
    declarations: [PetsComponent, PetsSelectorComponent],
    bootstrap: [],
    providers: [PetsService, StepService, SbEvent, SalesChannelService]
  })
  export class PetsModule { }

