<div class="main-area wrapper">
    <form [formGroup]="amendmentsForm" novalidate>
        <div class="row">
            <div class="column small-12 main-area">
                <div class="content flow-container quote-flow">
                    <div *ngIf="!reservation" class="is-loading active"></div>
                    <div class="sbw_pagebox">
                        <div class="row uncollapse allocation-container">
                            <h1>{{'CABIN_ALLOCATIONS_TITLE' | resource}}</h1>

                            <div class="row">
                                <div class="column small-12 large-10 sbw_legend gutter" [innerHTML]="'CABIN_ALLOCATIONS_TEASER' | resource"></div>
                            </div>
                            <div *ngIf="errors.length > 0" class="row">
                                <div class="column small-12 gutter">
                                    <sbw-validation-summary [errorList]="errors"></sbw-validation-summary>
                                </div>
                            </div>

                            <!-- Outbound -->
                            <sbw-cabin-allocation-group *ngIf="outboundDepartureGroup" [formDepartureGroup]="outboundDepartureGroup" [formPassengerSelections]="amendmentsForm.controls.outboundPassengerSelections"></sbw-cabin-allocation-group>

                            <!-- Return -->
                            <sbw-cabin-allocation-group *ngIf="returnDepartureGroup" [formDepartureGroup]="returnDepartureGroup" [formPassengerSelections]="amendmentsForm.controls.returnPassengerSelections"></sbw-cabin-allocation-group>

                            <div class="row">
                                <div class="column small-12 medium-4 medium-push-8 large-3 large-push-9 field submit">
                                    <sbw-button [id]="'SubmitContinue'" [type]="'preferred'" [(state)]="buttonState" [text]="'CONTINUE' | resource" (clicked)="continue()"></sbw-button>
                                </div>
                                <div class="column small-12 medium-4 medium-pull-8 large-3 large-pull-9 field submit">
                                    <sbw-button [type]="'previous'" [link]="'/amendment/booking/' + reservationCode" [text]="'BACK' | resource"></sbw-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Disclaimer -->
                    <div class="row hide-for-medium-down">
                        <div class="column small-12">
                            <sbw-disclaimer [resourceKey]="'DISCLAIMER_FOOTER'"></sbw-disclaimer>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column small-12 hide-for-large-up ">
                <!-- Disclaimer -->
                <div class="row">
                    <div class="column small-12 gutter">
                        <sbw-disclaimer [resourceKey]="'DISCLAIMER_FOOTER'"></sbw-disclaimer>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>