import { Component, OnInit, OnChanges, Input, EventEmitter, Output, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'

import { LocalDateTime, convert as JsJodaConvert, LocalDate } from 'js-joda'

import { Fare, IFaresContentModel } from 'app/fares/fares.interfaces'
import { Mode } from 'app/shared/prev-next/prev-next.component'
import { findNested } from 'app/shared/utils'
import { ResourceService } from 'app/shared/resources/resource.service'
import { DatepickerLocaleType, DatepickerView } from 'app/shared/datepicker/datepicker.models'

@Component({
    selector: 'sbw-fares-selector',
    templateUrl: './fares-selector.component.html',
    styleUrls: ['./fares-selector.component.css']
})
export class FaresSelectorComponent implements OnInit, OnChanges {

    @Input() data: Fare.IWire
    @Input() currency: string
    @Input() faresForm: FormGroup
    @Input() isLoading = false
    @Input() content: IFaresContentModel
    @Input() filterProducts = false // should be true for amendmend
    @Input() hideArrow: boolean = false
    @Input() activeOut: boolean = false
    @Input() activeRet: boolean = false
    @Input() isQuotePresent: boolean = true
    @Input() datePickerInfoLabel: string
    @Output() fareChanged: EventEmitter<Fare.IFareChangedMessage> = new EventEmitter<Fare.IFareChangedMessage>()

    faresOutDateSettings: FaresDateSettings
    faresReturnDateSettings: FaresDateSettings

    public popupOpen = false;

    get departureDate(): FormControl {
        return this.faresForm.get('departureDate') as FormControl
    }

    get returnDate(): FormControl {
        return this.faresForm.get('returnDate') as FormControl
    }

    public datepickerLocale: 'en'
    public rowItemStatus = Fare.RowItemStatus
    public products: Fare.IProduct[] = []
    public daysDuration: number = null

    public model: Fare.IOutReturn<ISelectorDto> = {
        out: {
            departure: null,
            dateOfDeparture: null,
            dateOfDepartureNative: null,
            validationControl: null,
            selected: null,
            changedMessage: null,
            index: -1,
            dates: <Fare.ILegDates>{
                max: null,
                min: null,
                disabled: [],
                offer: []
            },
        },
        return: {
            departure: null,
            dateOfDeparture: null,
            dateOfDepartureNative: null,
            validationControl: null,
            selected: null,
            changedMessage: null,
            index: -1,
            dates: <Fare.ILegDates>{
                max: null,
                min: null,
                disabled: [],
                offer: []
            }
        }
    }

    public isOneWay = false
    modeEnum = Mode

    constructor(
        private fb: FormBuilder,
        private resourceService: ResourceService
    ) { }

    ngOnInit() {
        const locale = this.resourceService.getLocale()
        const outDisabledDates = this.data.out.dates.disabled.map(x => new Date(x.toString()))
        const outOfferDates = this.data.out.dates.offer.map(x => x.toString() )
        this.faresOutDateSettings = new FaresDateSettings(
            new Date(this.data.out.dates.min.toString()), 
            new Date(this.data.out.dates.max.toString()), 
            outDisabledDates, 
            locale,
            outOfferDates
        )
        if (!this.isOneWay && this.data.return) {
            const retDisabledDates = this.data.return.dates.disabled.map(x => new Date(x.toString()))
            const retOfferDates = this.data.return.dates.offer.map(x => x.toString() )
            this.faresReturnDateSettings = new FaresDateSettings(
                new Date(this.data.return.dates.min.toString()), 
                new Date(this.data.return.dates.max.toString()), 
                retDisabledDates, 
                locale,
                retOfferDates
            )
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.data) {
          this.data = changes.data.currentValue
          
          const locale = this.resourceService.getLocale()
          const outDisabledDates = this.data.out.dates.disabled.map((x) => new Date(x.toString()))
          const outOfferDates = this.data.out.dates.offer.map((x) => x.toString())
          this.faresOutDateSettings = new FaresDateSettings(
          new Date(this.data.out.dates.min.toString()),
          new Date(this.data.out.dates.max.toString()),
          outDisabledDates,
          locale,
          outOfferDates
        )
        if (!this.isOneWay && this.data.return) {
            const retDisabledDates = this.data.return.dates.disabled.map((x) => new Date(x.toString()))
            const retOfferDates = this.data.return.dates.offer.map((x) => x.toString())
            this.faresReturnDateSettings = new FaresDateSettings(
            new Date(this.data.out.dateOfDeparture.toString() ?? new Date(this.data.return.dates.min.toString())),
            new Date(this.data.return.dates.max.toString()),
            retDisabledDates,
            locale,
            retOfferDates
        )
    }
        }
        this.applyChanges()
    }
    
    public popupChanged(value) {
      this.popupOpen = value
    }

    private applyChanges() {
        this.products = this.data.products
        this.isOneWay = !this.data.return
        let outRow = this.setDataOnModel('out', this.data.out)
        if (!this.isOneWay) {
            this.setDataOnModel('return', this.data.return)
            if (!outRow) { return }
            let dto = this.model['return']
            if (dto.departure.rows) { dto.departure.rows.forEach(row => this.setRowStates('return', outRow.arrivalTime, row)) }
        }
    }

    private generateDates(start: LocalDate, end: LocalDate): LocalDate[] {
        let dateArray = []
        let currentDate = start
    
        while (currentDate && currentDate.compareTo(end) <= 0) {
          dateArray.push(currentDate)
          currentDate = currentDate.plusDays(1)
        }
    
        return dateArray
    }

    private setDataOnModel(direction: Fare.Direction, departure: Fare.IDeparture): Fare.IRow {
        this.daysDuration = this.data.daysDuration
        this.model[direction].departure = departure

        let localDateOfDeparture = null
        let nativeDateOfDeparture = null
        if (direction === 'return') {
            this.model[direction].dates.disabled = this.data.return.dates.disabled || []
            this.model[direction].dates.min = this.data.out.dateOfDeparture ?? this.data.return.dates.min
      
            localDateOfDeparture = this.data.return.dateOfDeparture
            let allDates = this.generateDates(this.model[direction].dates.min, this.data.return.dates.max)
            let enabledDates = allDates.filter(
              (date) => !this.model[direction].dates.disabled.find((disabled) => disabled.toString() === date.toString())
            )
            this.model[direction].dates.max = enabledDates.reduce((a, b) => (a.compareTo(b) > 0 ? a : b))
          } else {
            this.model[direction].dates.disabled = this.data.out.dates.disabled || []
            this.model[direction].dates.min = this.data.out.dates.min
            localDateOfDeparture = this.data.out.dateOfDeparture
            let allDates = this.generateDates(this.data.out.dates.min, this.data.out.dates.max)
            let enabledDates = allDates.filter(
              (date) => !this.model[direction].dates.disabled.find((disabled) => disabled.toString() === date.toString())
            )
            this.model[direction].dates.max = enabledDates.reduce((a, b) => (a.compareTo(b) > 0 ? a : b))
          }
      
        if (localDateOfDeparture) {
            nativeDateOfDeparture = JsJodaConvert(localDateOfDeparture).toDate()
        }
        this.model[direction].dateOfDeparture = localDateOfDeparture
        this.model[direction].dateOfDepartureNative = nativeDateOfDeparture

        if (departure.rows && departure.selectedDeparture) {
            let nested = findNested<Fare.IRow, Fare.IRowItem>(departure.rows.filter(x => x.departureId === departure.selectedDeparture.departureId), x => x.items, x => x.productCode.toLowerCase() === departure.selectedDeparture.productCode.toLowerCase())
            let selected = nested && nested.inner
            let validationControl = selected ?
                this.fb.control({ departureId: departure.selectedDeparture.departureId, productCode: departure.selectedDeparture.productCode, price: selected.price }, Validators.required)
                : this.fb.control({ departureId: departure.selectedDeparture.departureId, productCode: departure.selectedDeparture.productCode })
            this.model[direction].validationControl = validationControl
            this.model[direction].index = this.findIndexInSelectedItem(departure)
            if (this.faresForm) {
                this.faresForm.setControl(direction, validationControl)
            }
            return nested && nested.outer
        } else {
            let validationControl = this.fb.control(null, Validators.required)
            this.model[direction].validationControl = validationControl
            if (this.faresForm) {
                this.faresForm.setControl(direction, validationControl)
            }
        }
        return null
    }

    private findIndexInSelectedItem(departure: Fare.IDeparture): number {
        let row = departure.rows.find(x => x.departureId === departure.selectedDeparture.departureId)
        return row ? row.items.findIndex(item => item.productCode === departure.selectedDeparture.productCode) : undefined
    }

    public productsFor(leg: ISelectorDto): Fare.IProduct[] {
        return this.filterProducts
            ? this.products.filter(p => p.productCode == leg.departure.selectedDeparture.productCode)
            : this.products
    }

    public selectDepartureProduct(direction: Fare.Direction, fareRowItem: Fare.IRowItem, fareRow: Fare.IRow, columnIndex: number) {
        let dto = this.model[direction]
        this.handleSelection(dto, direction, fareRowItem, fareRow, columnIndex)
    }

    private handleSelection(dto: ISelectorDto, direction: Fare.Direction, fareRowItem: Fare.IRowItem, fareRow: Fare.IRow, newIndex: number) {
        this.handleChangeProductForDeparture(dto, fareRowItem, fareRow, direction)
        if (!this.isOneWay) {
            let otherDirection: Fare.Direction = direction === 'out' ? 'return' : 'out'
            let otherDto = this.model[otherDirection]
            if (!otherDto.departure.rows) return
            otherDto.departure.rows.forEach(row => {
                if (otherDirection === 'return') {
                    this.setRowStates(otherDirection, fareRow.arrivalTime, row)
                }
            })
            if (otherDto.validationControl) { otherDto.validationControl.setValue(null) }
            let otherSelectedDeparture = this.getOtherSelectedDeparture(otherDirection, otherDto.departure.selectedDeparture?.productCode ?? fareRowItem.productCode, otherDto)
            otherDirection === 'return' ? this.data.return.selectedDeparture = otherSelectedDeparture : this.data.out.selectedDeparture = otherSelectedDeparture
        }
        dto.index = newIndex
        this.fareChanged.emit({ currency: this.currency, out: this.model.out.changedMessage, return: this.model.return.changedMessage })
    }

    private handleChangeProductForDeparture(dto: ISelectorDto, selectedItem: Fare.IRowItem, selectedRow: Fare.IRow, direction: Fare.Direction) {
        if (selectedItem == null) return
        const selectedDeparture = <Fare.ISelectedDeparture>{ departureId: selectedRow.departureId, productCode: selectedItem.productCode }
        if (direction === 'out') { this.data.out.selectedDeparture = selectedDeparture }
        if (direction === 'return') { this.data.return.selectedDeparture = selectedDeparture }
        this.setValidatorValue(dto.validationControl, selectedDeparture, selectedItem)
        dto.changedMessage = this.createChangedMessage(direction, selectedRow, selectedItem)
    }

    private getOtherSelectedDeparture(otherDirection: Fare.Direction, productCode: string, otherDto: ISelectorDto): Fare.ISelectedDeparture {
        let nested = otherDto.departure.selectedDeparture ? findNested<Fare.IRow, Fare.IRowItem>(otherDto.departure.rows.filter(x => x.departureId === otherDto.departure.selectedDeparture.departureId), x => x.items, z => z.productCode.toLowerCase() === productCode.toLowerCase()) : null
        if (!nested || nested.inner.status !== Fare.RowItemStatus.Available) {
            nested = findNested<Fare.IRow, Fare.IRowItem>(otherDto.departure.rows, x => x.items.filter(y => y.status === Fare.RowItemStatus.Available), z => z.productCode?.toLowerCase() === productCode?.toLowerCase())
            if (nested) { otherDto.departure.selectedDeparture = <Fare.ISelectedDeparture>{ departureId: nested.outer.departureId, productCode: productCode } }
        }
        if (nested) {
            otherDto.changedMessage = this.createChangedMessage(otherDirection, nested.outer, nested.inner)
            this.setValidatorValue(otherDto.validationControl, otherDto.departure.selectedDeparture, nested.inner)
            return <Fare.ISelectedDeparture>{ departureId: nested.outer.departureId, productCode: productCode }
        }
        return null
    }

    private setValidatorValue(control: FormControl, selectedDeparture: Fare.ISelectedDeparture, selectedItem: Fare.IRowItem) {
        control.setValue({
            departureId: selectedDeparture.departureId,
            productCode: selectedItem.status == 'Available' ? selectedDeparture.productCode : null,
            price: selectedItem.price
        })
    }

    private setRowStates(direction: Fare.Direction, selectedDepartureTime: LocalDateTime, row: Fare.IRow) {
        let comparer = (d1: LocalDateTime, d2: LocalDateTime) => direction === 'return' ? d1.isAfter(d2) : d1.isBefore(d2)
        if (comparer(selectedDepartureTime, row.departureTime)) {
            this.disableRow(row)
        } else {
            this.enableRow(row)
        }
    }

    private disableRow(row: Fare.IRow) {
        row.items.forEach(item => {
            if (item._oldStatus === undefined) {
                item._oldStatus = item.status
                item.status = Fare.RowItemStatus.Disabled
            }
        })
    }

    private enableRow(row: Fare.IRow) {
        row.items.forEach(item => {
            if (item._oldStatus !== undefined) {
                item.status = item._oldStatus
                item._oldStatus = undefined
            }
        })
    }

    private createChangedMessage(direction: Fare.Direction, row: Fare.IRow, item: Fare.IRowItem): Fare.IRowItemChanged {
        let productName = this.data.products.find(product => product.productCode === item.productCode).description
        return { product: productName, departureTime: row.departureTime, direction, route: row.route, price: item.price }
    }

    public getTextFromCombinedResourceKey(baseKey: string, productCode: string) {
        if (!productCode) return
        let value: string
        const combinedKey = `${baseKey}_${productCode}`
        this.resourceService.get(`${baseKey}_${productCode}`).subscribe(s => {
            if (s === combinedKey) {
                this.resourceService.get(`${baseKey}_DEFAULT`).subscribe(r => { value = r })
                return
            }
            value = s
        })
        return value
    }

    public setSelected(row: Fare.IRow, item: Fare.IRowItem, selectedDeparture: Fare.ISelectedDeparture): boolean {
        return selectedDeparture && row.departureId === selectedDeparture.departureId && item.productCode.toLowerCase() === selectedDeparture.productCode.toLowerCase()
    }

    public showOrHide(showCalendar: boolean) {
        this.hideArrow = showCalendar
    }

    public useNarrow() {
      if (this.isOneWay) { return true }
      if (!this.isQuotePresent) { return true }
      return false
    }

    public activeCalendarOut(showCalendar: boolean) {
      this.activeOut = showCalendar
      this.hideArrow = showCalendar
    }

    public activeCalendarRet(showCalendar: boolean) {
        this.activeRet = showCalendar
        this.hideArrow = showCalendar
    }

    toUpper(str: string) {
        return str && str.toUpperCase()
    }

}
export interface ISelectorDto {
    validationControl: FormControl
    departure: Fare.IDeparture,
    dateOfDeparture: LocalDate,
    dateOfDepartureNative: Date,
    selected: Fare.IRowItem,
    changedMessage: Fare.IRowItemChanged
    index: number
    dates: Fare.ILegDates
}

export class FaresDateSettings {
    private _datepickerView: DatepickerView

    constructor(private minDate: Date, private maxDate: Date, private disableDates: Date[], private locale: string, private highlightedDates?: string[]) {
        this._datepickerView = new DatepickerView(<DatepickerLocaleType>this.locale)
        this._datepickerView.defaultDate = this.minDate
        this._datepickerView.minDate = this.minDate
        this._datepickerView.maxDate = this.maxDate
        this._datepickerView.highlightedDates = this.highlightedDates
        this.setYearSelectorRange()
    }

    public get datepickerView(): DatepickerView {
        return this._datepickerView
    }

    private setYearSelectorRange() {
        this._datepickerView.minDate.getFullYear()
        this._datepickerView.maxDate.getFullYear()
        this._datepickerView.disabledDates = this.disableDates
    }
}